define("itarp-business-application/components/sidebar/link-list", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/object/computed"], function (_exports, _component, _tracking, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="sidebar__component">
    <ul class="sidebar__links">
      {{#each this.items as |item|}}
        <li class="sidebar__links__item">
          <a class="sidebar__links__link" href={{item.location}}>
            {{item.content}}
          </a>
        </li>
      {{/each}}
    </ul>
  </div>
  */
  {
    "id": "uZWxTLZV",
    "block": "[[[10,0],[14,0,\"sidebar__component\"],[12],[1,\"\\n  \"],[10,\"ul\"],[14,0,\"sidebar__links\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"items\"]]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[14,0,\"sidebar__links__item\"],[12],[1,\"\\n        \"],[10,3],[14,0,\"sidebar__links__link\"],[15,6,[30,1,[\"location\"]]],[12],[1,\"\\n          \"],[1,[30,1,[\"content\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"item\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "itarp-business-application/components/sidebar/link-list.hbs",
    "isStrictMode": false
  });

  let SidebarLinkListComponent = (_dec = (0, _computed.alias)('args.config.attributes'), _dec2 = (0, _computed.alias)('args.config.attributes.items'), (_class = class SidebarLinkListComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "attributes", _descriptor, this);

      _initializerDefineProperty(this, "items", _descriptor2, this);

      _initializerDefineProperty(this, "classList", _descriptor3, this);
    }

    setClassList(classes) {
      this.classList += classes;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "attributes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "items", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "classList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setClassList", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setClassList"), _class.prototype)), _class));
  _exports.default = SidebarLinkListComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SidebarLinkListComponent);
});