define("itarp-business-application/util/parser/attribute/helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.attributeIsOfType = attributeIsOfType;

  const type = (definition, {
    array
  }) => ({
    'type-definition': definition,
    'is-array': array
  });

  const types = {
    modelCollection: type('model_reference', {
      array: true
    }),
    model: type('model_reference', {
      array: false
    })
  };

  function attributeIsOfType(name, type) {
    return types[name] && compareType(types[name], type);
  }

  function compareType(first, second) {
    const compare = attr => first[attr] === second[attr];

    return compare('type-definition') && compare('is-array');
  }
});