define("itarp-template-designer/components/preview", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='designer__content__body pad-r--5 pad-l--5'>
    <div class='row'>
      <div class='col--12-12'>
        <div class='component-wrapper'>
          <h3 class="ts--22">Template Preview</h3>
        </div>
      </div>
    </div>
    <div class='row'>
      <div class='col--12-12'>
        <div class='component-wrapper'>
          <div class='template-preview'>
            <div class='template-preview__header'>
              <ul class='template-preview__info'>
                <li class='template-preview__info__item'>
                  <span>Sent By:</span>
                  System
                </li>
                <li class='template-preview__info__item'>
                  <span>To:</span>
                  Candidate
                </li>
                <li class='template-preview__info__item'>
                  <span>Subject:</span>
                  {{@subject}}
                </li>
              </ul>
            </div>
            <div class='template-preview__body'>
              <div class='template-preview__content'>
                <p data-test-accordion-message>
                  {{@body}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  */
  {
    "id": "obgOR2LF",
    "block": "[[[10,0],[14,0,\"designer__content__body pad-r--5 pad-l--5\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col--12-12\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"component-wrapper\"],[12],[1,\"\\n        \"],[10,\"h3\"],[14,0,\"ts--22\"],[12],[1,\"Template Preview\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col--12-12\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"component-wrapper\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"template-preview\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"template-preview__header\"],[12],[1,\"\\n            \"],[10,\"ul\"],[14,0,\"template-preview__info\"],[12],[1,\"\\n              \"],[10,\"li\"],[14,0,\"template-preview__info__item\"],[12],[1,\"\\n                \"],[10,1],[12],[1,\"Sent By:\"],[13],[1,\"\\n                System\\n              \"],[13],[1,\"\\n              \"],[10,\"li\"],[14,0,\"template-preview__info__item\"],[12],[1,\"\\n                \"],[10,1],[12],[1,\"To:\"],[13],[1,\"\\n                Candidate\\n              \"],[13],[1,\"\\n              \"],[10,\"li\"],[14,0,\"template-preview__info__item\"],[12],[1,\"\\n                \"],[10,1],[12],[1,\"Subject:\"],[13],[1,\"\\n                \"],[1,[30,1]],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"template-preview__body\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"template-preview__content\"],[12],[1,\"\\n              \"],[10,2],[14,\"data-test-accordion-message\",\"\"],[12],[1,\"\\n                \"],[1,[30,2]],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@subject\",\"@body\"],false,[]]",
    "moduleName": "itarp-template-designer/components/preview.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});