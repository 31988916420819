define("itarp-components/components/ui/table/menu/item", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <li class='table-row-menu__controls__item' ...attributes>{{yield
      (hash
        link=(component 'ui/table/menu/link')
        linkClass='table-row-menu__controls__link'
      )
    }}</li>
  */
  {
    "id": "y9B/JmKo",
    "block": "[[[11,\"li\"],[24,0,\"table-row-menu__controls__item\"],[17,1],[12],[18,2,[[28,[37,1],null,[[\"link\",\"linkClass\"],[[50,\"ui/table/menu/link\",0,null,null],\"table-row-menu__controls__link\"]]]]],[13]],[\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-components/components/ui/table/menu/item.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});