define("itarp-opportunity-designer/adapters/taxonomies-industry", ["exports", "itarp-opportunity-designer/adapters/opportunity-application"], function (_exports, _opportunityApplication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TaxonomiesIndustryAdapter extends _opportunityApplication.default {}

  _exports.default = TaxonomiesIndustryAdapter;
});