define("itarp-opportunity-designer/utils/route-helper", ["exports", "itarp-opportunity-designer/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = route;

  function route(routeName) {
    return `${_environment.default.engineName}.${routeName}`;
  }
});