define("itarp-expert-preview/adapters/ad-token-data", ["exports", "itarp-auth-addon/adapters/ad-token-data"], function (_exports, _adTokenData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AdTokenDataAdapter extends _adTokenData.default {}

  _exports.default = AdTokenDataAdapter;
});