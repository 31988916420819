define("itarp-business-application/util/model-object-generator", ["exports", "@ember/object", "@ember/array", "ember-inflector", "ember-changeset"], function (_exports, _object, _array, _emberInflector, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ModelObjectGenerator {
    constructor(collectionName, modelName, data, store) {
      const root = this;
      return class extends _object.default {
        constructor() {
          super(...arguments);

          _defineProperty(this, "id", undefined);

          _defineProperty(this, "collectionName", collectionName);

          _defineProperty(this, "modelName", modelName);

          root.registerClassAttributes(this, data);
          this.changeset = (0, _emberChangeset.Changeset)(this);
          this.store = store;
          return this.changeset;
        }

        init(params) {
          this.id = (params === null || params === void 0 ? void 0 : params._id) && params._id['$oid'];
          super.init(...arguments);
          this.changeset.execute();
        }

        toJSON() {
          const result = {};
          result[this.collectionName] = { ...this.getProperties(Object.getPrototypeOf(this).attributes)
          };
          return JSON.stringify(result);
        }

        save() {
          return this.id ? this.update() : this.create();
        }

        async update() {
          return this.store.updateModelRecord(this.collectionName, this);
        }

        async create() {
          return this.store.createModelRecord(this.collectionName, this);
        }

        async destroy() {
          return this.store.deleteModelRecord(this.collectionName, this);
        }

      };
    }

    defineRelations(context, protoype, data) {
      if (!data.model_detail_relations) return;

      for (const relation of data.model_detail_relations) {
        const name = `_${relation.name}`;
        const className = `${name}Class`;
        context.set(className, this.createRelationClass(relation));
        context.set(name, undefined);
        Object.defineProperty(context, relation.name, {
          get: function () {
            return context[name];
          },
          set: function (value) {
            if ((0, _array.isArray)(value)) {
              context[name] = value.map(v => context[className].create(v));
            } else {
              context[name] = context[className].create(value);
            }
          }
        });
        protoype.attributes.pushObject(relation.name);
      }
    }

    createRelationClass(data) {
      return class extends _object.default {
        constructor() {
          var _data$attributes;

          super(...arguments);

          _defineProperty(this, "collectionName", data.name);

          _defineProperty(this, "modelName", (0, _emberInflector.singularize)(data.name));

          (_data$attributes = data.attributes) === null || _data$attributes === void 0 ? void 0 : _data$attributes.forEach(name => {
            this[name] = undefined;
          });
        }

        init(relationParams) {
          this.id = relationParams._id && relationParams._id['$oid'];
          return super.init(...arguments);
        }

      };
    }

    registerClassAttributes(context, data) {
      var _data$model_detail_at;

      const protoype = Object.getPrototypeOf(context);
      protoype.attributes = [];
      (_data$model_detail_at = data.model_detail_attributes) === null || _data$model_detail_at === void 0 ? void 0 : _data$model_detail_at.forEach(name => {
        context[name] = undefined;
        protoype.attributes.pushObject(name);
      });
      this.defineRelations(context, protoype, data);
    }

  }

  _exports.default = ModelObjectGenerator;
});