define("itarp-opportunity-ui/components/question/types/certification", ["exports", "@ember/component/template-only"], function (_exports, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class="form-options in-line
      {{if @question.isRequired "is-required"}}
      {{if @isError "has-error"}}"
  >
    <label class="form-options__label">
      {{#if @question.isRequired}}
        <span class="label-note label-note--required"></span>
      {{/if}}
      Do you have
      {{@question.title}}
      certification?
    </label>
    <div class="form-options__elements">
      <div class="radio-group {{if @isError "has-error"}}">
        <input
          type="radio"
          class="radio-group__field"
          name="certification_{{@question.id}}"
          checked={{eq @value "Yes"}}
          id="{{@question.id}}_yes"
          value="Yes"
          {{on "change" @onChange}}
          disabled={{@disabled}}
        />
        <label class="radio-group__label" for="{{@question.id}}_yes">Yes</label>
      </div>
      <div class="radio-group {{if @isError "has-error"}}">
        <input
          type="radio"
          class="radio-group__field"
          name="certification_{{@question.id}}"
          id="{{@question.id}}_no"
          checked={{eq @value "No"}}
          value="No"
          {{on "change" @onChange}}
          disabled={{@disabled}}
        />
        <label class="radio-group__label" for="{{@question.id}}_no">No</label>
      </div>
    </div>
  </div>
  */
  {
    "id": "8S/eCqG9",
    "block": "[[[10,0],[15,0,[29,[\"form-options in-line\\n    \",[52,[30,1,[\"isRequired\"]],\"is-required\"],\"\\n    \",[52,[30,2],\"has-error\"]]]],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"form-options__label\"],[12],[1,\"\\n\"],[41,[30,1,[\"isRequired\"]],[[[1,\"      \"],[10,1],[14,0,\"label-note label-note--required\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"    Do you have\\n    \"],[1,[30,1,[\"title\"]]],[1,\"\\n    certification?\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"form-options__elements\"],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[\"radio-group \",[52,[30,2],\"has-error\"]]]],[12],[1,\"\\n      \"],[11,\"input\"],[24,0,\"radio-group__field\"],[16,3,[29,[\"certification_\",[30,1,[\"id\"]]]]],[16,\"checked\",[28,[37,1],[[30,3],\"Yes\"],null]],[16,1,[29,[[30,1,[\"id\"]],\"_yes\"]]],[24,2,\"Yes\"],[16,\"disabled\",[30,4]],[24,4,\"radio\"],[4,[38,2],[\"change\",[30,5]],null],[12],[13],[1,\"\\n      \"],[10,\"label\"],[14,0,\"radio-group__label\"],[15,\"for\",[29,[[30,1,[\"id\"]],\"_yes\"]]],[12],[1,\"Yes\"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[15,0,[29,[\"radio-group \",[52,[30,2],\"has-error\"]]]],[12],[1,\"\\n      \"],[11,\"input\"],[24,0,\"radio-group__field\"],[16,3,[29,[\"certification_\",[30,1,[\"id\"]]]]],[16,1,[29,[[30,1,[\"id\"]],\"_no\"]]],[16,\"checked\",[28,[37,1],[[30,3],\"No\"],null]],[24,2,\"No\"],[16,\"disabled\",[30,4]],[24,4,\"radio\"],[4,[38,2],[\"change\",[30,5]],null],[12],[13],[1,\"\\n      \"],[10,\"label\"],[14,0,\"radio-group__label\"],[15,\"for\",[29,[[30,1,[\"id\"]],\"_no\"]]],[12],[1,\"No\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@question\",\"@isError\",\"@value\",\"@disabled\",\"@onChange\"],false,[\"if\",\"eq\",\"on\"]]",
    "moduleName": "itarp-opportunity-ui/components/question/types/certification.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});