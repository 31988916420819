define("itarp-components/components/ui/modals/reasons/button", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
    type='button'
    class='app-btn {{if @className @className}}'
    {{on 'click' @action}}
    ...attributes
  >{{yield}}</button>
  */
  {
    "id": "0FLn/M3T",
    "block": "[[[11,\"button\"],[24,4,\"button\"],[16,0,[29,[\"app-btn \",[52,[30,1],[30,1]]]]],[17,2],[4,[38,1],[\"click\",[30,3]],null],[12],[18,4,null],[13]],[\"@className\",\"&attrs\",\"@action\",\"&default\"],false,[\"if\",\"on\",\"yield\"]]",
    "moduleName": "itarp-components/components/ui/modals/reasons/button.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});