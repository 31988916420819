define("itarp-opportunity-designer/engine", ["exports", "@ember/engine", "ember-load-initializers", "ember-resolver", "itarp-opportunity-designer/config/environment"], function (_exports, _engine, _emberLoadInitializers, _emberResolver, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const {
    modulePrefix
  } = _environment.default;

  class ItarpOpportunityDesignerEngine extends _engine.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "modulePrefix", modulePrefix);

      _defineProperty(this, "Resolver", _emberResolver.default);

      _defineProperty(this, "dependencies", {
        services: ['store', 'appRouter', 'layout', 'micro-frontend', 'toast', 'workflows', 'favorites', 'opportunity-reasons', 'markup-rate', 'stage-rules'],
        externalRoutes: ['schedule-interview', 'my-favorites']
      });

      _defineProperty(this, "engines", {
        'itarp-expert-preview': {
          dependencies: {
            services: ['layout', 'micro-frontend', 'store', 'workflows', {
              toast: 'toast'
            }, {
              router: 'appRouter'
            }, 'markup-rate'],
            externalRoutes: {
              'schedule-interview': 'interviews.schedule-interview',
              opportunity: 'itarp-opportunity-designer.details.overview'
            }
          }
        }
      });
    }

  }

  _exports.default = ItarpOpportunityDesignerEngine;
  (0, _emberLoadInitializers.default)(ItarpOpportunityDesignerEngine, modulePrefix);
});