define("shared/adapters/api", ["exports", "itarp-auth-addon/adapters/ad-token-adapter", "@ember-data/adapter/error", "@ember/string"], function (_exports, _adTokenAdapter, _error, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ApiAdapter extends _adTokenAdapter.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", '/routing/data');
    }

    get headers() {
      const result = super.headers;
      result['Content-Type'] = 'application/json';
      return result;
    }

    findAll(definition, params) {
      const url = this.buildPath(definition, params);
      return super.ajax(url, definition.method);
    }

    find(definition, params) {
      const url = this.buildPath(definition, params);
      return super.ajax(url, definition.method);
    }

    create(definition, params) {
      const url = this.buildPath(definition, params);
      delete params.data.relationships;
      let data = {
        [definition.type]: params.data
      };
      data = JSON.stringify(data);
      return super.ajax(url, definition.method.toUpperCase(), {
        data
      });
    }

    update(definition, params) {
      const url = this.buildPath(definition, params);
      delete params.data.relationships;
      const page = params.page;
      delete params.page;
      let data = {
        page
      };

      if (Object.keys(params.data).length) {
        data[definition.type] = { ...params.data
        };
        delete data[definition.type].changeset;
      } else {
        data[definition.type] = {};
      } // add profiles key to users data if not present so to trigger validations


      if (definition.type === 'users' && !data.users.profiles) {
        data.users.profiles = {};
      }

      data = JSON.stringify(data);
      return super.ajax(url, definition.method.toUpperCase(), {
        data
      });
    }

    delete(definition, params) {
      const url = this.buildPath(definition, params);
      return super.ajax(url, definition.method);
    }

    buildPath(definition, params) {
      this.parseArgs(definition, params);
      return this.namespace + definition.route;
    }

    parseArgs(definition, params) {
      const {
        args
      } = definition;

      if (!!args.length) {
        for (const arg of args) {
          definition.route = definition.route.replace(arg, params[arg.slice(1, arg.length)]);
        }
      }
    }

    handleResponse(status, headers, payload) {
      if (this.isInvalid(status, headers, payload)) {
        return new _error.InvalidError(this.formatErrors(payload));
      }

      return super.handleResponse(...arguments);
    }

    formatErrors(payload) {
      let errors = payload.errors;
      let model = Object.keys(errors)[0];
      errors = errors[model];
      const result = [];

      for (const field in errors) {
        result.pushObjects(this.parseErrors(errors, field, model));
      }

      return result;
    }

    parseErrors(errors, field, attrPath) {
      const attribute = `${attrPath}.${field}`;

      if (Array.isArray(errors[field])) {
        const pointer = `/data/attributes/${attribute.replaceAll('.', '/')}`;
        const source = {
          pointer
        };
        const fieldName = (0, _string.capitalize)(field.replaceAll('_', ' '));
        const detail = `${fieldName} ${errors[field]}`;
        return [{
          source,
          detail,
          attribute
        }];
      } else {
        const result = [];

        for (const child in errors[field]) {
          const message = this.parseErrors(errors[field], child, attribute);
          result.pushObjects(message);
        }

        return result;
      }
    }

  }

  _exports.default = ApiAdapter;
});