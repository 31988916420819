define("itarp-business-application/util/parser/attribute/parser", ["exports", "itarp-business-application/util/parser/attribute/attributes/model-collection", "itarp-business-application/util/parser/attribute/attributes/model", "itarp-business-application/util/parser/attribute/helpers"], function (_exports, _modelCollection, _model, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseAttributes = parseAttributes;
  _exports.parseAttribute = parseAttribute;

  async function parseAttributes(data, store) {
    const attributes = {};
    if (!data) return attributes;
    const fields = [];

    for (const attribute in data) {
      const [value, field] = await parseAttribute(data[attribute], store);
      Object.defineProperty(attributes, attribute, value);
      if (field) fields.push(field);
    }

    return [attributes, fields];
  }

  async function parseAttribute(attribute, store) {
    const {
      type,
      value
    } = attribute;

    if ((0, _helpers.attributeIsOfType)('modelCollection', type)) {
      return await (0, _modelCollection.default)(value, store);
    }

    if ((0, _helpers.attributeIsOfType)('model', type)) {
      return await (0, _model.default)(value, store);
    }

    return [defaultAttribute(value)];
  }

  const defaultAttribute = function (val) {
    let value = val;
    return {
      get: () => value,
      set: v => value = v
    };
  };
});