define("itarp-workflow-designer/templates/workflow/process-pipeline", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "r3HbmsOt",
    "block": "[[[10,0],[14,0,\"designer__content__body\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col--12-12\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"component-wrapper\"],[12],[1,\"\\n        \"],[10,\"h3\"],[14,0,\"mar-b--0 text-with-controls\"],[12],[1,\"\\n          Process Pipeline\\n          \"],[10,\"ul\"],[14,0,\"text-controls\"],[12],[1,\"\\n            \"],[10,\"li\"],[14,0,\"text-controls__controls__item\"],[12],[1,\"\\n              \"],[8,[39,0],[[24,0,\"text-controls__controls__link for-edit with-label\"]],[[\"@route\",\"@model\"],[\"workflow.edit\",[30,0,[\"workflow\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[10,1],[14,0,\"text-controls__controls__link__label\"],[12],[1,\"\\n                  Edit Process Pipeline\\n                \"],[13],[1,\"\\n              \"]],[]]]]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col--12-12\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"component-wrapper\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"process-stages\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"process-stages__sortable\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"workflow\",\"stageGroups\"]]],null]],null],null,[[[1,\"              \"],[10,0],[14,0,\"process-stages__item\"],[12],[1,\"\\n                \"],[10,1],[15,0,[29,[\"process-stages__item__icon for-\",[30,1,[\"stageGroupType\"]]]]],[12],[13],[1,\"\\n                \"],[10,1],[14,0,\"process-stages__item__title\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n              \"],[13],[1,\"\\n\"]],[1]],null],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"item\"],false,[\"link-to\",\"each\",\"-track-array\"]]",
    "moduleName": "itarp-workflow-designer/templates/workflow/process-pipeline.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});