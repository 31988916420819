define("itarp-workflow-designer/components/panels/form-panel", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='workflow-stages__list__form'>
    <Forms::Stage
      @stageGroupList={{@stageGroupList}}
      @stage={{@stage}}
      @save={{@save}}
      @cancel={{@cancel}}
      @groupField={{@groupField}}
      @workflow={{@workflow}}
      as |Form|
    >
      <Panel>
        <:title>
          {{#if @stage.id}}
            {{@stage.name}}
          {{else}}
            Add New Stage
          {{/if}}
        </:title>
        <:body>
          <div class='row'>
            <div class='col--12-6'>
              <div class='component-wrapper has-padding'>
                {{yield Form.inputGroup to='input'}}
              </div>
            </div>
            <div class='col--12-6'>
              <div class='component-wrapper has-padding'>
                {{yield Form.selectGroup to='select'}}
              </div>
            </div>
  
          </div>
        </:body>
        <:footer>
          <Form.save @class='app-btn--primary app-btn--sm'>
            Save
          </Form.save>
          <Form.cancel @class='app-btn--sm'>
            Cancel
          </Form.cancel>
        </:footer>
      </Panel>
    </Forms::Stage>
  </div>
  
  */
  {
    "id": "wlJgAIO7",
    "block": "[[[10,0],[14,0,\"workflow-stages__list__form\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@stageGroupList\",\"@stage\",\"@save\",\"@cancel\",\"@groupField\",\"@workflow\"],[[30,1],[30,2],[30,3],[30,4],[30,5],[30,6]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,null,[[\"title\",\"body\",\"footer\"],[[[[1,\"\\n\"],[41,[30,2,[\"id\"]],[[[1,\"          \"],[1,[30,2,[\"name\"]]],[1,\"\\n\"]],[]],[[[1,\"          Add New Stage\\n\"]],[]]],[1,\"      \"]],[]],[[[1,\"\\n        \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"col--12-6\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"component-wrapper has-padding\"],[12],[1,\"\\n              \"],[18,8,[[30,7,[\"inputGroup\"]]]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"col--12-6\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"component-wrapper has-padding\"],[12],[1,\"\\n              \"],[18,9,[[30,7,[\"selectGroup\"]]]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\\n        \"],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[30,7,[\"save\"]],null,[[\"@class\"],[\"app-btn--primary app-btn--sm\"]],[[\"default\"],[[[[1,\"\\n          Save\\n        \"]],[]]]]],[1,\"\\n        \"],[8,[30,7,[\"cancel\"]],null,[[\"@class\"],[\"app-btn--sm\"]],[[\"default\"],[[[[1,\"\\n          Cancel\\n        \"]],[]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[7]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@stageGroupList\",\"@stage\",\"@save\",\"@cancel\",\"@groupField\",\"@workflow\",\"Form\",\"&input\",\"&select\"],false,[\"forms/stage\",\"panel\",\"if\",\"yield\"]]",
    "moduleName": "itarp-workflow-designer/components/panels/form-panel.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});