define("itarp-workflow-designer/components/forms/input-group", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='input-group' ...attributes>
    {{#if @label}}
      <label class='input-group__label'>
        {{#if @required}}
          <span class='label-note label-note--required'></span>
        {{/if}}
        {{@label}}
      </label>
    {{/if}}
    <Input
      @type='text'
      @value={{@value}}
      class='input-group__field {{@class}}'
      id='stage-name'
      minlength='3'
      placeholder={{@placeholder}}
      {{did-insert @didInsert}}
      {{will-destroy @willRemove}}
    />
    {{#unless @class}}
      {{#if @message}}
        <span class='label-note label-note--required'>
          {{#if @stageNameError}}
            Stage name already exists
          {{else}}
            Please fill field
          {{/if}}</span>
      {{/if}}
    {{/unless}}
  </div>
  */
  {
    "id": "nhja0Q/x",
    "block": "[[[11,0],[24,0,\"input-group\"],[17,1],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[10,\"label\"],[14,0,\"input-group__label\"],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"        \"],[10,1],[14,0,\"label-note label-note--required\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[1,[30,2]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[8,[39,1],[[16,0,[29,[\"input-group__field \",[30,4]]]],[24,1,\"stage-name\"],[24,\"minlength\",\"3\"],[16,\"placeholder\",[30,5]],[4,[38,2],[[30,7]],null],[4,[38,3],[[30,8]],null]],[[\"@type\",\"@value\"],[\"text\",[30,6]]],null],[1,\"\\n\"],[41,[51,[30,4]],[[[41,[30,9],[[[1,\"      \"],[10,1],[14,0,\"label-note label-note--required\"],[12],[1,\"\\n\"],[41,[30,10],[[[1,\"          Stage name already exists\\n\"]],[]],[[[1,\"          Please fill field\\n        \"]],[]]],[13],[1,\"\\n\"]],[]],null]],[]],null],[13]],[\"&attrs\",\"@label\",\"@required\",\"@class\",\"@placeholder\",\"@value\",\"@didInsert\",\"@willRemove\",\"@message\",\"@stageNameError\"],false,[\"if\",\"input\",\"did-insert\",\"will-destroy\",\"unless\"]]",
    "moduleName": "itarp-workflow-designer/components/forms/input-group.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});