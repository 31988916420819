define("itarp-opportunity-designer/controllers/details-public", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@glimmer/tracking", "itarp-opportunity-designer/utils/constants/opportunity", "itarp-opportunity-designer/config/environment", "@ember/string"], function (_exports, _controller, _object, _service, _tracking, _opportunity, _environment, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DetailsPublicController = (_class = class DetailsPublicController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "appRouter", _descriptor, this);

      _initializerDefineProperty(this, "workflows", _descriptor2, this);

      _defineProperty(this, "queryParams", ['referrer']);

      _initializerDefineProperty(this, "referrer", _descriptor3, this);

      _initializerDefineProperty(this, "opportunityStatus", _descriptor4, this);
    }

    get statusClass() {
      const status = this.opportunityStatus;

      const result = _opportunity.STATUS_CLASSES.find(item => item.status === status);

      return result ? result === null || result === void 0 ? void 0 : result.class : 'is-in-progress';
    }

    redirectTo() {
      const originatedFrom = this.referrer ? atob(this.referrer.replaceAll('-', '/')) : null;

      if (originatedFrom) {
        this.appRouter.transitionTo(originatedFrom);
      } else {
        this.appRouter.transitionTo(`${_environment.default.modulePrefix}.browse-public`);
      }
    }

    setWorkflowStatus() {
      const status = this.project.status;
      const workflowApprovalId = this.project.workflowApprovalId;
      const workflowApprovalStageId = this.project.workflowApprovalStageId;

      if (status === 'in_approval_workflow') {
        this.workflows.findStageByStageId(workflowApprovalId, workflowApprovalStageId).then(stage => {
          this.opportunityStatus = stage ? stage.stageType : '';
        });
      } else {
        this.setStatusBasedOnStage();
      }
    }

    setStatusBasedOnStage() {
      const workflowId = this.project.workflowId;
      const workflowStageId = this.project.workflowStageId;

      if (!workflowStageId) {
        this.opportunityStatus = 'open';
        return;
      }

      this.workflows.findStageByStageId(workflowId, workflowStageId).then(stage => {
        const completedStages = ['onboarding', 'offer_accepted', 'hired', 'job_complete'];

        if (completedStages.includes(stage.stageType)) {
          this.opportunityStatus = 'job_completed';
        } else {
          this.opportunityStatus = 'open';
        }
      });
    }

    resolveOpportunityStatus() {
      if (this.project.status === 'draft') {
        this.opportunityStatus = this.project.status;
      } else {
        this.setWorkflowStatus();
      }
    }

    get opportunityStatusLabel() {
      if (!this.opportunityStatus) return '';
      return this.opportunityStatus.replaceAll('_', ' ').split(' ').map(word => {
        return (0, _string.capitalize)(word);
      }).join(' ');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appRouter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "workflows", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "referrer", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "opportunityStatus", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "redirectTo", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "redirectTo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setWorkflowStatus", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setWorkflowStatus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setStatusBasedOnStage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setStatusBasedOnStage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resolveOpportunityStatus", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "resolveOpportunityStatus"), _class.prototype)), _class);
  _exports.default = DetailsPublicController;
});