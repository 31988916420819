define("ember-moment/helpers/utc", ["exports", "moment", "ember-moment/helpers/-base"], function (_exports, _moment, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    compute([utcTime, format]) {
      this._super(...arguments);

      return this.moment.utc(_moment.default.utc(utcTime, format));
    }

  });

  _exports.default = _default;
});