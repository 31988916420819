define("itarp-business-application/controllers/my-favorite-talentclouds", ["exports", "@ember/controller", "@ember/object", "@ember/service"], function (_exports, _controller, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MyFavoriteTalentcloudsController = (_dec = (0, _service.inject)('ad-token-service'), (_class = class MyFavoriteTalentcloudsController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "toast", _descriptor2, this);

      _initializerDefineProperty(this, "adTokenService", _descriptor3, this);

      _defineProperty(this, "queryParams", ['talentCloudId', 'page']);
    }

    handleTalentCloudClick(talentcloud) {
      let encodedUrl = btoa(`${window.location.pathname}`);
      const referrer = `${encodedUrl.replaceAll('/', '-')}`;
      this.transitionToRoute('itarp-talentcloud-ui.details', talentcloud.id, {
        queryParams: {
          referrer: referrer
        }
      });
    }

    deleteFavorite(resourceId) {
      const adapter = this.store.adapterFor('user-favorite');
      const endpoint = `${adapter.namespace}/favorites`;
      const method = 'DELETE';
      const headers = adapter.headers;
      fetch(endpoint, {
        method,
        headers,
        body: JSON.stringify({
          ids: [resourceId],
          resource_type: 'talent_cloud'
        })
      }).then(async response => await response.json()).then(() => {
        this.loadFavorites();
        this.toast.show('TalentCloud removed from favorites');
      });
    }

    loadFavorites() {
      const id = this.adTokenService.tokenInformation.sub;
      this.store.query('user-favorite', {
        user_id: id,
        resource_type: 'talent_cloud',
        page: this.pageMeta.page,
        per_page: this.pageMeta.per_page,
        adapterOptions: {
          talentCloudFavorites: true
        },
        order_by: ['updated_at', 'desc']
      }).then(favorites => {
        this.set('favorites', favorites);
      }).catch(error => {
        console.error('Error loading favorites:', error);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "adTokenService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleTalentCloudClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleTalentCloudClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteFavorite", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteFavorite"), _class.prototype)), _class));
  _exports.default = MyFavoriteTalentcloudsController;
});