define("ember-concurrency/-private/external/scheduler/state-tracker/state", ["exports", "ember-concurrency/-private/external/task-instance/completion-states"], function (_exports, _completionStates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RefreshState {
    constructor(taskable, tag) {
      this.taskable = taskable;
      this.group = taskable.group;
      this.numRunning = 0;
      this.numQueued = 0;
      this.numPerformedInc = 0;
      this.attrs = {};
      this.tag = tag;
    }

    onCompletion(taskInstance) {
      let state = taskInstance.completionState;
      this.attrs.lastRunning = null;
      this.attrs.lastComplete = taskInstance;

      if (state === _completionStates.COMPLETION_SUCCESS) {
        this.attrs.lastSuccessful = taskInstance;
      } else {
        if (state === _completionStates.COMPLETION_ERROR) {
          this.attrs.lastErrored = taskInstance;
        } else if (state === _completionStates.COMPLETION_CANCEL) {
          this.attrs.lastCanceled = taskInstance;
        }

        this.attrs.lastIncomplete = taskInstance;
      }
    }

    onPerformed(taskInstance) {
      this.numPerformedInc += 1;
      this.attrs.lastPerformed = taskInstance;
    }

    onStart(taskInstance) {
      // a.k.a. lastStarted
      this.attrs.last = taskInstance;
    }

    onRunning(taskInstance) {
      this.attrs.lastRunning = taskInstance;
      this.numRunning += 1;
    }

    onQueued() {
      this.numQueued += 1;
    }

    recurseTaskGroups(callback) {
      let group = this.group;

      while (group) {
        callback(group);
        group = group.group;
      }
    }

    applyStateFrom(other) {
      Object.assign(this.attrs, other.attrs);
      this.numRunning += other.numRunning;
      this.numQueued += other.numQueued;
      this.numPerformedInc += other.numPerformedInc;
    }

  }

  var _default = RefreshState;
  _exports.default = _default;
});