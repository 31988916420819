define("itarp-opportunity-designer/serializers/approved-talentcloud-application", ["exports", "itarp-shared-assets-addon/serializers/data", "@ember-data/serializer/rest", "@ember/object", "@ember/string"], function (_exports, _data, _rest, _object, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ApprovedTalentcloudApplicationSerializer extends _data.default.extend(_rest.EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        user: {
          embedded: 'always'
        }
      });
    }

    serialize(snapshot) {
      const json = super.serialize(...arguments);
      return {
        applications: json.proposals
      };
    }

    normalizeQueryResponse(store, modelClass, payload, ...rest) {
      payload = payload && payload.approved_tc_applications && payload.approved_tc_applications.length ? payload.approved_tc_applications.map(rec => ({ ...rec,
        name: rec.talent_cloud[0].name,
        talent_cloud_id: rec.talent_cloud[0]._id,
        description: rec.talent_cloud[0].description,
        workflow_id: rec.talent_cloud[0].workflow_id,
        workflow_stage_id: rec.workflow_stage_id
      })) : [];
      const data = {
        records: payload,
        meta: {}
      };
      return super.normalizeQueryResponse(store, modelClass, data, ...rest);
    }

  }

  _exports.default = ApprovedTalentcloudApplicationSerializer;
});