define("itarp-business-application/util/store-manager/index", ["exports", "itarp-business-application/util/schema-manager", "@glimmer/tracking", "ember-changeset", "@ember/string", "ember-inflector"], function (_exports, _schemaManager, _tracking, _emberChangeset, _string, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let StoreManager = (_class = class StoreManager {
    constructor(options = {}) {
      _initializerDefineProperty(this, "models", _descriptor, this);

      _defineProperty(this, "isUserModel", name => name === 'user' && !!this.ids.user === false);

      _defineProperty(this, "hasIdForModel", name => !!this.ids[name]);

      this.store = options.store;
      this.ids = options.ids || {};
      this.useChangeset = options.useChangeset || false;
      this.schemaManager = new _schemaManager.default(this, this.store);
    }

    async metaModel(name) {
      const model = await this.store.peekAll('meta-model').findBy('name', name);
      if (model) return model;
      const models = await this.store.findAll('meta-model');
      return await models.findBy('name', name);
    }

    async getSchema(modelName) {
      const name = (0, _string.camelize)(modelName).toLowerCase();
      const schema = await this.schemaManager.get(name);
      if (schema) return schema;
      const model = await this.metaModel(name);
      const data = await model.details();
      return this.schemaManager.register(name, data);
    }

    async getRecord(name) {
      const models = expressionToArray(name);
      const [record, modelName] = await this.initModels(models);
      return [record, modelName];
    }

    async getAttributeDetails(modelName, attributeName) {
      const schema = await this.schemaManager.get(modelName);
      const attribute = {
        type: '',
        name: attributeName,
        isArray: false
      };

      if (schema.model.attrDetails) {
        const details = schema.model.attrDetails.findBy('name', attributeName);

        if (details) {
          attribute.type = details.type_definition;
          attribute.isArray = details.is_array;
        }
      }

      return attribute;
    }

    async getEnumData(modelName, attribute) {
      const name = modelName.charAt(0).toUpperCase() + modelName.slice(1);
      const query = `?model_name=${name}&attribute_name=${attribute}`;
      let result = [];
      const token = localStorage.getItem('itarp_token');
      await fetch(`/routing/data/models/parameters${query}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(async response => {
        if (response.ok) {
          result = await response.json();
        }
      });
      return result;
    }

    booleanData(record, attributePath, state) {
      if (state !== undefined) {
        const states = [undefined, null, state];
        return {
          get: () => states.includes(record.changeset.get(attributePath))
        };
      }

      return {
        get: () => record.changeset.get(attributePath),
        set: val => record.changeset.set(attributePath, val)
      };
    }

    async getRecordAttribute(expression, attrName, state) {
      const enumModelName = expression.split('->').lastObject;
      const modelNames = expressionToArray(expression);
      const [m, modelName] = await this.initModels([...modelNames]);
      const record = this.models[modelNames[0]];
      const modelPath = this.toModelPath(expression, record);
      const attributePath = modelPath ? `${modelPath}.${attrName}` : attrName;
      const attribute = await this.getAttributeDetails(modelName, attrName);
      let fullPath = `${(0, _emberInflector.pluralize)(modelNames[0])}.${attributePath}`;
      fullPath = fullPath.replaceAll('..', '.');
      let result;

      switch (attribute.type) {
        case 'enumerable':
          const enums = await this.getEnumData(enumModelName, attrName);
          result = {
            get: () => enums.data ? enums.data : enums
          };
          return [result, fullPath];
          break;

        case 'boolean':
          result = this.booleanData(record, attributePath, state);
          return [result, fullPath];
          break;

        default:
          result = {
            get: () => record.changeset.get(attributePath),
            set: val => record.changeset.set(attributePath, val)
          };
          return [result, fullPath];
      }
    }

    async retreiveModel(name) {
      const schema = await this.getSchema(name);
      let result = null;

      if (this.hasIdForModel(name)) {
        const id = this.ids[name];
        result = await schema.api.find({
          id
        });
      } else if (this.isUserModel(name)) {
        result = await schema.api.find({
          id: this.ids.userId
        });
      } else {
        result = await schema.model.instantiate({});
      }

      return result;
    }

    async initModels(models, previous) {
      let model = null;

      if (!previous) {
        const loaded = this.models[models[0]];

        if (loaded) {
          model = loaded;
        } else {
          model = await this.retreiveModel(models[0]);
          this.models[models[0]] = model;
        }

        if (model.changeset === undefined) {
          model.changeset = (0, _emberChangeset.Changeset)(model);
        }

        if (this.useChangeset) {
          model = model.changeset;
        }
      } else {
        model = previous;
      }

      if (models.length > 1) {
        const relationship = model.relationships[models[1]];

        if (relationship && !relationship.loaded) {
          await relationship.instantiate(model[relationship.attribute]);
        }

        model = model[relationship.attribute];
        models.shift();
        return await this.initModels(models, model);
      }

      return [model, models[0]];
    }

    toModelPath(expression, record) {
      let models = expressionToArray(expression);
      let path = '';
      models = models.slice(1);

      for (let i = 0; i < models.length; i++) {
        const relationship = record.relationships[models[i]];
        const model = relationship === null || relationship === void 0 ? void 0 : relationship.attribute;
        path = i === 0 ? `${model}` : `${path}.${model}`;
      }

      return path;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "models", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  })), _class);
  _exports.default = StoreManager;

  function expressionToArray(value) {
    return (v => v.split('->'))(value).map(v => v.toLowerCase());
  }
});