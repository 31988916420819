define("itarp-components/components/table/candidates/column/applied-at", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let @row as |row|}}
    <row.cell ...attributes>
      <Ui::Wrapper>
        <div class='card-block--paragraph'>
          {{moment-format @application.createdAt 'MMM DD, YYYY' 'ddd MMM D YYYY'}}
        </div>
      </Ui::Wrapper>
    </row.cell>
  {{/let}}
  */
  {
    "id": "MToAq6WF",
    "block": "[[[44,[[30,1]],[[[1,\"  \"],[8,[30,2,[\"cell\"]],[[17,3]],null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"card-block--paragraph\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[[30,4,[\"createdAt\"]],\"MMM DD, YYYY\",\"ddd MMM D YYYY\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]],[\"@row\",\"row\",\"&attrs\",\"@application\"],false,[\"let\",\"ui/wrapper\",\"moment-format\"]]",
    "moduleName": "itarp-components/components/table/candidates/column/applied-at.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});