define("itarp-shared-assets-addon/presenters/application", ["exports", "itarp-shared-assets-addon/utils/presenter", "itarp-shared-assets-addon/utils/helpers/date-formats", "@ember/string"], function (_exports, _presenter, _dateFormats, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApplicationPresenter extends _presenter.default {
    get jobTitle() {
      return this.get('opportunity.jobTitle');
    }

    get jobID() {
      return this.get('opportunity.internalId');
    }

    get scorecardIds() {
      return this.get('opportunity.scorecardIds');
    }

    get candidateId() {
      return this.get('user.id');
    }

    get jobPostedDate() {
      const postedDate = this.get('opportunity.createdAt');
      if (postedDate) return moment(postedDate).format(_dateFormats.FORMATS.LLLL);
    }

    get hiringManager() {
      return this.get('opportunity.hiringManager');
    }

    get appliedAt() {
      const createdAt = this.get('createdAt');
      if (createdAt) return moment(createdAt).format(_dateFormats.FORMATS.LLLL);
    }

    get fullName() {
      const user = this.get('user');
      return [user.firstName, user.lastName].compact().join(' ');
    }

    get userInitials() {
      return this.fullName.split(' ').map(v => v[0]).join('').toUpperCase();
    }

    get professionalRole() {
      return this.get('user.profile.professionalRole');
    }

    get yearsOfExperience() {
      return this.get('user.profile.yearsOfExperience');
    }

    get address() {
      return this.get('user.profile.fullAddress');
    }

    get resumeLink() {
      const resume = this.get('resume');
      const profileResume = this.get('user.profile.resume');
      return resume ? resume : profileResume;
    }

    get linkedinUrl() {
      return this.get('user.profile.linkedinUrl');
    }

    get email() {
      return this.get('user.email');
    }

    get experfyExpert() {
      return this.get('user.profile.experfyExpert');
    }

    get highlights() {
      const highlights = this.get('user.profile.highlightsSanitized');
      if (highlights) return highlights;
    }

    get hourlyRate() {
      const rate = this.get('rate');
      const profileRate = this.get('user.profile.hourlyRate');
      return rate ? rate : profileRate;
    }

    get hoursAvailable() {
      return this.get('user.profile.hoursPerWeek');
    }

    get isAvailable() {
      return this.get('user.profile.workAvailable');
    }

    get pitchVideo() {
      return this.get('user.profile.pitchVideo');
    }

    get pitch() {
      return this.get('user.profile.pitchSanitized');
    }

    get skills() {
      return this.get('user.profile.skills');
    }

    get employment() {
      return this.get('user.profile.experiences');
    }

    get educations() {
      return this.get('user.profile.educations');
    }

    get certifications() {
      return this.get('user.profile.certifications');
    }

    get industry() {
      return this.get('user.profile.industry');
    }

    get practiceArea() {
      return this.get('user.profile.practiceArea');
    }

    get mentorshipInterest() {
      return this.get('user.profile.mentorshipInterest');
    }

    get usWorkAuthorized() {
      return this.get('user.profile.usWorkAuthorized');
    }

    get requiresUsVisaSponsorship() {
      return this.get('user.profile.requiresUsVisaSponsorship');
    }

    get hasBusiness() {
      return this.get('user.profile.hasBusiness');
    }

    get diversity() {
      const gender = this.get('user.profile.gender');
      const race = this.get('user.profile.race');
      const disability = this.get('user.profile.disability');
      const veteran = this.get('user.profile.veteran');
      const disabled = disability ? 'disabled' : 'no disability';
      const isVeteran = veteran ? 'veteran' : 'not a veteran';
      return [gender, race, disabled, isVeteran].compact().without('').map(v => (0, _string.capitalize)(v)).join(', ');
    }

    get dob() {
      return this.get('user.profile.bornDate');
    }

    get workPreference() {
      const fullTime = this.get('user.profile.fullTimePreference');
      const partTime = this.get('user.profile.partTimePreference');
      const contractor = this.get('user.profile.contractor');
      const result = [];
      if (contractor) result.pushObject('Contractor');
      if (fullTime) result.pushObject('Full-time Permanent');
      if (partTime) result.pushObject('Part-time Permanent');
      return result.join(', ');
    }

    get locationPreference() {
      const commute = this.get('user.profile.localCommuteOnly');
      const remote = this.get('user.profile.remote');
      const relocate = this.get('user.profile.willingToRelocate');
      const travel = this.get('user.profile.willingToTravel');
      const travelAvailability = this.get('user.profile.travelAvailability');
      const result = [];
      if (commute) result.pushObject('I am open to local commute only');
      if (remote) result.pushObject('I am interested in remote work only');
      relocate ? result.pushObject('I am willing to relocate') : result.pushObject('I am not willing to relocate');
      travel ? result.pushObject(`I am willing to travel ${travelAvailability}`) : result.pushObject('I am not willing to travel');
      return result.join(', ');
    }

    get cloudStatus() {
      return this.get('user.cloudStatus');
    }

  }

  _exports.default = ApplicationPresenter;
});