define("itarp-opportunity-designer/serializers/permission", ["exports", "itarp-shared-assets-addon/serializers/data"], function (_exports, _data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class PermissionSerializer extends _data.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", 'id');
    }

    normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      const data = {
        records: payload
      };
      return super.normalizeQueryResponse(store, primaryModelClass, data, id, requestType);
    }

  }

  _exports.default = PermissionSerializer;
});