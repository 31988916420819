define("itarp-business-application/components/forms/form-item", ["exports", "@glimmer/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#unless @config.attributes.hidden}}
    <div class="form-item">
      <dl class={{concat "form-item__list " (if this.inline "is-inline")}}>
        <dt class="form-item__list__title">{{this.label}}</dt>
        <dd class="form-item__list__text">{{this.value}}</dd>
      </dl>
    </div>
  {{/unless}}
  */
  {
    "id": "pFj2L0pu",
    "block": "[[[41,[51,[30,1,[\"attributes\",\"hidden\"]]],[[[1,\"  \"],[10,0],[14,0,\"form-item\"],[12],[1,\"\\n    \"],[10,\"dl\"],[15,0,[28,[37,1],[\"form-item__list \",[52,[30,0,[\"inline\"]],\"is-inline\"]],null]],[12],[1,\"\\n      \"],[10,\"dt\"],[14,0,\"form-item__list__title\"],[12],[1,[30,0,[\"label\"]]],[13],[1,\"\\n      \"],[10,\"dd\"],[14,0,\"form-item__list__text\"],[12],[1,[30,0,[\"value\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@config\"],false,[\"unless\",\"concat\",\"if\"]]",
    "moduleName": "itarp-business-application/components/forms/form-item.hbs",
    "isStrictMode": false
  });

  let FormsFormItemComponent = (_dec = (0, _computed.alias)('args.config.attributes'), _dec2 = (0, _object.computed)('attributes.label'), _dec3 = (0, _object.computed)('attributes.inline'), _dec4 = (0, _object.computed)('attributes.value'), (_class = class FormsFormItemComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "attributes", _descriptor, this);
    }

    get label() {
      return this.attributes.label || '';
    }

    get inline() {
      return this.attributes.inline;
    }

    get value() {
      return this.attributes.value || '';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "attributes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "label", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "label"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "inline", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "inline"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "value", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "value"), _class.prototype)), _class));
  _exports.default = FormsFormItemComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormsFormItemComponent);
});