define("itarp-workflow-designer/mirage/endpoints/action-templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(context) {
    context.get('action-templates');
    context.get('action-templates/:id');
    context.post('action-templates');
    context.patch('action-templates/:id');
    context.del('action-templates/:id');
  }
});