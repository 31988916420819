define("itarp-business-application/components/forms/icon-button", ["exports", "@ember/object/computed", "@ember/object", "@glimmer/component"], function (_exports, _computed, _object, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#unless @config.attributes.hidden}}
    <button
      data-icon-button-component
      class="btn-inline icon-{{this.icon}}"
      type="button"
      {{on "click" this.onClickAction}}
    >
      {{#if this.label}}
        <span class="btn-inline__label" data-icon-button-label>
          {{this.label}}
        </span>
      {{/if}}
    </button>
  {{/unless}}
  */
  {
    "id": "ybEj0C4A",
    "block": "[[[41,[51,[30,1,[\"attributes\",\"hidden\"]]],[[[1,\"  \"],[11,\"button\"],[24,\"data-icon-button-component\",\"\"],[16,0,[29,[\"btn-inline icon-\",[30,0,[\"icon\"]]]]],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"onClickAction\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"label\"]],[[[1,\"      \"],[10,1],[14,0,\"btn-inline__label\"],[14,\"data-icon-button-label\",\"\"],[12],[1,\"\\n        \"],[1,[30,0,[\"label\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@config\"],false,[\"unless\",\"on\",\"if\"]]",
    "moduleName": "itarp-business-application/components/forms/icon-button.hbs",
    "isStrictMode": false
  });

  let FormsIconButtonComponent = (_dec = (0, _computed.alias)('args.config.attributes'), _dec2 = (0, _object.computed)('attributes.label'), _dec3 = (0, _object.computed)('attributes.icon'), _dec4 = (0, _object.computed)('attributes.actionParameter'), _dec5 = (0, _object.computed)('attributes.action'), (_class = class FormsIconButtonComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "attributes", _descriptor, this);
    }

    get label() {
      return this.attributes.label;
    }

    get icon() {
      return ['pencil', 'trash'].find(value => value === this.attributes.icon);
    }

    get actionParameter() {
      return this.attributes.actionParameter;
    }

    get action() {
      return this.attributes.action;
    }

    onClickAction() {
      if (this.action) {
        this.args.emit(this.action, this.actionParameter);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "attributes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "label", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "label"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "icon", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "icon"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "actionParameter", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "actionParameter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "action", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "action"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClickAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClickAction"), _class.prototype)), _class));
  _exports.default = FormsIconButtonComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormsIconButtonComponent);
});