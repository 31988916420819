define("itarp-workflow-designer/services/workflow-rules", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let WorkflowRulesService = (_dec = (0, _service.inject)('store'), (_class = class WorkflowRulesService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _defineProperty(this, "rawRules", null);

      _defineProperty(this, "structuredRules", null);
    }

    async getRawRules() {
      if (!this.rawRules) {
        this.rawRules = await this.fetchRules();
      }

      return this.rawRules;
    }

    async fetchRules() {
      const adapter = this.store.adapterFor('workflow');
      const endpoint = `${adapter.namespace}rules`;
      return await fetch(endpoint, {
        headers: adapter.headers
      }).then(async res => await res.json());
    }

    async getStructuredRules() {
      if (!this.structuredRules) {
        const rawRules = await this.getRawRules();
        const result = this.reverseRulesMap(rawRules['valid-workflow-types-for-stage-groups']);
        const stageGroupsToStages = this.reverseRulesMap(rawRules['valid-stage-groups-for-stages']);
        const stageGroupsToActions = this.reverseRulesMap(rawRules['valid-stage-groups-for-actions']);

        for (const workflowType in result) {
          const stageGroups = result[workflowType];
          const resultStageGroupsList = [];

          for (const stageGroup of stageGroups) {
            resultStageGroupsList.push({
              name: stageGroup,
              stages: stageGroupsToStages[stageGroup],
              actions: stageGroupsToActions[stageGroup]
            });
          }

          result[workflowType] = resultStageGroupsList;
        }

        this.structuredRules = result;
      }

      return this.structuredRules;
    }

    reverseRulesMap(rulesMap) {
      const result = {};

      for (const key in rulesMap) {
        const values = rulesMap[key];

        for (const value of values) {
          if (!result[value]) {
            result[value] = new Set();
          }

          result[value].add(key);
        }
      }

      for (const key in result) {
        result[key] = Array.from(result[key]);
      }

      return result;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = WorkflowRulesService;
});