define("@onehilltech/ember-cli-google-analytics/services/g-analytics", ["exports", "@ember/service", "@ember/application", "@ember/object", "@ember/debug", "@ember/utils"], function (_exports, _service, _application, _object, _debug, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Normalize the page name as expected by Google Analytics.
   *
   * @param name
   * @returns {string}
   */
  function normalizePageName(name) {
    return `/${name.replace('.', '/')}`;
  }

  let GoogleAnalyticsService = (_class = class GoogleAnalyticsService extends _service.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _defineProperty(this, "_trackPageViews", false);

      _defineProperty(this, "_scriptPromise", null);
    }

    init() {
      super.init(...arguments);
      this.ga.then(ga => this.configure(ga));
    }

    get trackPageViews() {
      return this._trackPageViews;
    }

    set trackPageViews(value) {
      if (this._trackPageViews === value) {
        return;
      } // Update the state.


      this._trackPageViews = value;

      if (this._trackPageViews) {
        this.router.on('routeDidChange', this, 'didTransition');
      } else {
        this.router.off('routeDidChange', this, 'didTransition');
      }
    }

    configure(ga) {
      // Create the one and only tracker need for the application.
      let ENV = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      let trackerId = (0, _object.get)(ENV, 'ember-cli-google.analytics.trackerId');
      (false && !((0, _utils.isPresent)(trackerId)) && (0, _debug.assert)('Missing ember-cli-google.analytics.trackerId in config/environment.', (0, _utils.isPresent)(trackerId)));
      let cookieDomain = (0, _object.getWithDefault)(ENV, 'ember-cli-google.analytics.cookieDomain', 'auto');
      let trackerName = (0, _object.get)(ENV, 'ember-cli-google.analytics.trackerName'); // Create the tracker for the application.

      ga('create', trackerId, cookieDomain, trackerName); // We only apply Google Analytics in the production environment. Otherwise, we run
      // the risk of collecting analytics of the application while it is in development,
      // testing, or any non-production environment.

      let {
        environment
      } = ENV;
      this.trackPageViews = environment === 'production';
    }

    destroy() {
      super.destroy();
      this.trackPageViews = false;
    }

    didTransition(transition) {
      if (this.trackPageViews) {
        this.ga.then(ga => {
          const {
            to
          } = transition;
          let page = normalizePageName(to.name);
          ga('send', 'pageview', page);
        });
      }
    }

    get ga() {
      if ((0, _utils.isPresent)(window.ga)) {
        return Promise.resolve(window.ga);
      }

      if ((0, _utils.isPresent)(this._scriptPromise)) {
        return this._scriptPromise;
      }

      this._scriptPromise = new Promise((resolve, reject) => {
        // Dynamically load the Google Analytics script.
        let script = document.createElement('script');
        script.setAttribute('src', 'https://www.google-analytics.com/analytics.js');
        script.async = true;

        script.onload = () => resolve(window.ga);

        script.onerror = reject; // Append the script tag to the document.

        document.body.appendChild(script);
      });
      return this._scriptPromise;
    }

    send(category, action, label, value) {
      ga('send', 'event', category, action, label, value);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = GoogleAnalyticsService;
});