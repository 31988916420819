define("itarp-workflow-designer/components/button", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
    type={{if @type @type 'button'}}
    class='app-btn {{@class}}'
    {{on 'click' @onClick}}
    ...attributes
  >{{yield}}</button>
  
  */
  {
    "id": "167wFL+T",
    "block": "[[[11,\"button\"],[16,4,[52,[30,1],[30,1],\"button\"]],[16,0,[29,[\"app-btn \",[30,2]]]],[17,3],[4,[38,1],[\"click\",[30,4]],null],[12],[18,5,null],[13],[1,\"\\n\"]],[\"@type\",\"@class\",\"&attrs\",\"@onClick\",\"&default\"],false,[\"if\",\"on\",\"yield\"]]",
    "moduleName": "itarp-workflow-designer/components/button.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});