define("itarp-opportunity-designer/routes/details/overview", ["exports", "@ember/routing/route", "rsvp"], function (_exports, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DetailsOverviewRoute extends _route.default {
    async model() {
      const project = this.modelFor('details').project;
      return (0, _rsvp.hash)({
        project,
        questions: project.questionnaire ? await project.questionnaire.reload() : []
      });
    }

    setupController(controller, model) {
      super.setupController(...arguments);
      controller.set('project', model.project);
      controller.set('questions', model.questions);
      const parentController = this.controllerFor('details');
      controller.set('parentController', parentController);
    }

  }

  _exports.default = DetailsOverviewRoute;
});