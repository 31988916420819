define("ember-engines-router-service/utils/namespace-engine-route-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.namespaceEngineRouteName = namespaceEngineRouteName;

  /**
   * This method is responsible for return route name from engine namespace
   *
   * @public
   * @method namespaceEngineRouteName
   * @param {String} mountPoint
   * @param {String} routeName
   * @return {String}
   */
  function namespaceEngineRouteName(mountPoint, routeName) {
    if (routeName === 'application') {
      return mountPoint;
    } else {
      return `${mountPoint}.${routeName}`;
    }
  }
});