define("itarp-workflow-designer/components/workflow-list", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr class='app-table-light__row'>
    <td class='app-table-light__body__header' colspan='4'>
      <div class='component-wrapper'>
        <div class='card-block--heading is-primary'>
          {{yield}}
        </div>
      </div>
    </td>
  </tr>
  {{#each @workflows as |workflow|}}
    <WorkflowList::Item
      @workflow={{workflow}}
      @toggleDeleteModal={{@toggleDeleteModal}}
      @editWorkflow={{@editWorkflow}}
      @expandedItem={{@expandedItem}}
      @setExpandedItem={{@setExpandedItem}}
    />
  {{/each}}
  */
  {
    "id": "ZPjjNbLK",
    "block": "[[[10,\"tr\"],[14,0,\"app-table-light__row\"],[12],[1,\"\\n  \"],[10,\"td\"],[14,0,\"app-table-light__body__header\"],[14,\"colspan\",\"4\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"component-wrapper\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"card-block--heading is-primary\"],[12],[1,\"\\n        \"],[18,7,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1]],null]],null],null,[[[1,\"  \"],[8,[39,3],null,[[\"@workflow\",\"@toggleDeleteModal\",\"@editWorkflow\",\"@expandedItem\",\"@setExpandedItem\"],[[30,2],[30,3],[30,4],[30,5],[30,6]]],null],[1,\"\\n\"]],[2]],null]],[\"@workflows\",\"workflow\",\"@toggleDeleteModal\",\"@editWorkflow\",\"@expandedItem\",\"@setExpandedItem\",\"&default\"],false,[\"yield\",\"each\",\"-track-array\",\"workflow-list/item\"]]",
    "moduleName": "itarp-workflow-designer/components/workflow-list.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});