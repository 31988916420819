define("itarp-workflow-designer/components/forms/select-group", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='select-group {{@class}}' ...attributes>
    {{#if @label}}
      <label class='input-group__label'>
        {{#if @required}}
          <span class='label-note label-note--required'></span>
        {{/if}}
        {{@label}}
      </label>
    {{/if}}
    {{yield
      (component
        'power-select'
        options=@options
        selected=@selected
        placeholder=@placeholder
        onChange=(fn (mut @selected))
      )
    }}
    {{#if @required}}
      {{#if @errorMessage}}
        {{#unless @selected}}
          <span class='label-note label-note--required'>Please select field
          </span>
        {{/unless}}
      {{/if}}
    {{/if}}
  </div>
  */
  {
    "id": "XqO9p6BL",
    "block": "[[[11,0],[16,0,[29,[\"select-group \",[30,1]]]],[17,2],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"    \"],[10,\"label\"],[14,0,\"input-group__label\"],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"        \"],[10,1],[14,0,\"label-note label-note--required\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[1,[30,3]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[18,9,[[50,\"power-select\",0,null,[[\"options\",\"selected\",\"placeholder\",\"onChange\"],[[30,5],[30,6],[30,7],[28,[37,3],[[28,[37,4],[[30,6]],null]],null]]]]]],[1,\"\\n\"],[41,[30,4],[[[41,[30,8],[[[41,[51,[30,6]],[[[1,\"        \"],[10,1],[14,0,\"label-note label-note--required\"],[12],[1,\"Please select field\\n        \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[]],null],[13]],[\"@class\",\"&attrs\",\"@label\",\"@required\",\"@options\",\"@selected\",\"@placeholder\",\"@errorMessage\",\"&default\"],false,[\"if\",\"yield\",\"component\",\"fn\",\"mut\",\"unless\"]]",
    "moduleName": "itarp-workflow-designer/components/forms/select-group.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});