define("itarp-business-application/components/modal", ["exports", "@glimmer/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#unless this.isHidden}}
    {{#let (unique-id) as |modal-id|}}
      <ModalDialog
        {{on "close" this.toggleModal}}
        @targetAttachment="center"
        @translucentOverlay="true"
        @clickOutsideToClose="true"
        @overlayClass="app-modal-overlay"
        @containerClass="app-modal-wrapper"
      >
        <div class="app-modal app-modal--lg" data-test-model-component>
          <div class="app-modal-content">
            <div class="app-modal-content__header">
              <h4 class="app-modal-content__header__title" data-test-modal-label>
                {{this.label}}
              </h4>
              <a
                class="app-modal-content__header__close"
                data-test-model-close
                {{on "click" this.toggleModal}}
              ></a>
            </div>
            <div class="app-modal-content__body">
              <div class="app-modal-group__panel" data-test-model-content>
                {{yield}}
              </div>
            </div>
          </div>
        </div>
      </ModalDialog>
    {{/let}}
  {{/unless}}
  */
  {
    "id": "hxGrU5RU",
    "block": "[[[41,[51,[30,0,[\"isHidden\"]]],[[[44,[[28,[37,2],null,null]],[[[1,\"    \"],[8,[39,3],[[4,[38,4],[\"close\",[30,0,[\"toggleModal\"]]],null]],[[\"@targetAttachment\",\"@translucentOverlay\",\"@clickOutsideToClose\",\"@overlayClass\",\"@containerClass\"],[\"center\",\"true\",\"true\",\"app-modal-overlay\",\"app-modal-wrapper\"]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"app-modal app-modal--lg\"],[14,\"data-test-model-component\",\"\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"app-modal-content\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"app-modal-content__header\"],[12],[1,\"\\n            \"],[10,\"h4\"],[14,0,\"app-modal-content__header__title\"],[14,\"data-test-modal-label\",\"\"],[12],[1,\"\\n              \"],[1,[30,0,[\"label\"]]],[1,\"\\n            \"],[13],[1,\"\\n            \"],[11,3],[24,0,\"app-modal-content__header__close\"],[24,\"data-test-model-close\",\"\"],[4,[38,4],[\"click\",[30,0,[\"toggleModal\"]]],null],[12],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"app-modal-content__body\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"app-modal-group__panel\"],[14,\"data-test-model-content\",\"\"],[12],[1,\"\\n              \"],[18,2,null],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1]]]],[]],null]],[\"modal-id\",\"&default\"],false,[\"unless\",\"let\",\"unique-id\",\"modal-dialog\",\"on\",\"yield\"]]",
    "moduleName": "itarp-business-application/components/modal.hbs",
    "isStrictMode": false
  });

  let ModalComponent = (_dec = (0, _computed.alias)('args.config.attributes'), _dec2 = (0, _object.computed)('attributes.hidden'), _dec3 = (0, _object.computed)('attributes.label'), (_class = class ModalComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "attributes", _descriptor, this);
    }

    get isHidden() {
      return this.attributes.hidden;
    }

    get label() {
      return this.attributes.label;
    }

    toggleModal() {
      (0, _object.set)(this.attributes, 'hidden', !this.isHidden);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "attributes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isHidden", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isHidden"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "label", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "label"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleModal"), _class.prototype)), _class));
  _exports.default = ModalComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ModalComponent);
});