define("itarp-template-designer/serializers/template", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class TemplateSerializer extends _jsonApi.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        cloneId: {
          serialize: false
        },
        order: {
          serialize: false
        }
      });
    }

    //TODO Remove/adjust this once relationships are linked
    serialize(snapshot, options) {
      const json = super.serialize(...arguments);
      const adapterOptions = snapshot.adapterOptions;
      let templateWorkflows = [];

      if (json.data.attributes.payload) {
        templateWorkflows = snapshot.record.templateWorkflows.toArray();
      }

      const updatedPayload = [];
      templateWorkflows.forEach(w => {
        const matchingWorkflow = json.data.attributes.payload.find(workflow => workflow.workflow_id === w.get('workflow.id'));

        if (matchingWorkflow) {
          updatedPayload.push({
            id: w.get('id'),
            workflow_id: w.get('workflow.id'),
            stage_ids: matchingWorkflow.stage_ids
          });
        } else if (json.data.attributes.payload.length > 0 || snapshot.record.workflows.length === 0) {
          updatedPayload.push({
            id: w.get('id'),
            workflow_id: w.get('workflow.id'),
            _destroy: true
          });
        }
      });

      if (updatedPayload.length > 0) {
        json.data.attributes.payload.forEach(workflow => {
          const matchingWorkflow = updatedPayload.find(w => w.workflow_id === workflow.workflow_id);

          if (!matchingWorkflow) {
            updatedPayload.push(workflow);
          }
        });
        json.data.attributes.payload = updatedPayload;
      }

      if (json.data.attributes.payload) {
        json.data.relationships.workflows = {
          data: json.data.attributes.payload
        };
      }

      if (json.data.attributes['stage-relevant-meta']) {
        if (!json.data.attributes['stage-relevant-meta'].interview_statuses) {
          json.data.attributes['stage-relevant-meta'] = {
            interview_statuses: json.data.attributes['stage-relevant-meta']
          };
        }
      }

      delete json.data.attributes.payload;
      delete json.data.attributes.workflows;

      if (adapterOptions && adapterOptions.isSorting) {
        delete json.data.attributes['is-published'];
      }

      return json;
    }

    normalize(modelClass, data) {
      data.relationships['template-workflows'] ||= {
        data: []
      };
      data.relationships['template-workflows'].data = data.relationships.workflows.data.map(workflow => {
        return {
          id: workflow.id,
          type: 'template-workflow'
        };
      });
      return super.normalize(...arguments);
    }

    normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      const templateWorkflows = payload.included.filter(w => w.type === 'workflow');

      for (const templateWorkflow of templateWorkflows) {
        templateWorkflow.type = 'template-workflow';
      }

      this.updateCategoryIncluded(payload);
      return super.normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType);
    }

    updateCategoryIncluded(payload) {
      const categories = payload.included.filter(i => i.type === 'category');

      for (const category of categories) {
        var _category$relationshi, _category$relationshi2;

        const templateRel = ((_category$relationshi = category.relationships) === null || _category$relationshi === void 0 ? void 0 : (_category$relationshi2 = _category$relationshi.templates) === null || _category$relationshi2 === void 0 ? void 0 : _category$relationshi2.data) || [];

        if (!templateRel.isAny('id', payload.data.id)) {
          templateRel.push({
            id: payload.data.id,
            type: 'template'
          });
        }
      }
    }

  }

  _exports.default = TemplateSerializer;
});