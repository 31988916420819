define("itarp-opportunity-designer/serializers/questionnaire", ["exports", "itarp-shared-assets-addon/serializers/data"], function (_exports, _data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class QuestionnaireSerializer extends _data.default {
    normalize(modelClass, data) {
      var _result$data;

      const type = data.type;
      const result = super.normalize(...arguments);

      if ((_result$data = result.data) !== null && _result$data !== void 0 && _result$data.attributes) {
        result.data.attributes.type = type;
      }

      return result;
    }

    normalizeFindHasManyResponse(store, modelClass, payload, id, type) {
      const records = payload.records;
      const result = super.normalizeFindHasManyResponse(store, modelClass, payload, id, type);
      if (records.length === 0) return result;
      const opportunityID = payload.records[0].resource_id;
      if (!opportunityID) return result;
      const opportunity = this.store.peekRecord('opportunity', opportunityID);
      const orderedIDs = opportunity.hasMany('questionnaire').ids();

      const sortByID = data => orderedIDs.map(id => data.findBy('id', id));

      result.data = sortByID(result.data).compact();
      return result;
    }

  }

  _exports.default = QuestionnaireSerializer;
});