define("itarp-opportunity-designer/components/board/settings-option", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='form-switch-group as-row'>
    {{#let (unique-id) as |uid|}}
      <div class='form-switch form-switch--sm'>
        <Input
          @type='checkbox'
          id={{uid}}
          @checked={{@option.value}}
          {{on 'input' (fn (mut @option.value) (not @option.value))}}
        />
        <label for={{uid}} class='form-switch__label'></label>
      </div>
    {{/let}}
    <span class='form-switch-group__label'>{{@option.label}}</span>
  </div>
  
  */
  {
    "id": "ahkY91B6",
    "block": "[[[10,0],[14,0,\"form-switch-group as-row\"],[12],[1,\"\\n\"],[44,[[28,[37,1],null,null]],[[[1,\"    \"],[10,0],[14,0,\"form-switch form-switch--sm\"],[12],[1,\"\\n      \"],[8,[39,2],[[16,1,[30,1]],[4,[38,3],[\"input\",[28,[37,4],[[28,[37,5],[[30,2,[\"value\"]]],null],[28,[37,6],[[30,2,[\"value\"]]],null]],null]],null]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,2,[\"value\"]]]],null],[1,\"\\n      \"],[10,\"label\"],[15,\"for\",[30,1]],[14,0,\"form-switch__label\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]]],[1,\"  \"],[10,1],[14,0,\"form-switch-group__label\"],[12],[1,[30,2,[\"label\"]]],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"uid\",\"@option\"],false,[\"let\",\"unique-id\",\"input\",\"on\",\"fn\",\"mut\",\"not\"]]",
    "moduleName": "itarp-opportunity-designer/components/board/settings-option.hbs",
    "isStrictMode": false
  });

  class BoardSettingsOptionComponent extends _component2.default {}

  _exports.default = BoardSettingsOptionComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BoardSettingsOptionComponent);
});