define("itarp-shared-assets-addon/components/ui/offscreen/body", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='offscreen-preview__body'>
    {{yield
      (hash
        nav=(component 'ui/offscreen/body/nav')
        content=(component 'ui/offscreen/body/content')
      )
    }}
  </div>
  */
  {
    "id": "xXDM3Goy",
    "block": "[[[10,0],[14,0,\"offscreen-preview__body\"],[12],[1,\"\\n  \"],[18,1,[[28,[37,1],null,[[\"nav\",\"content\"],[[50,\"ui/offscreen/body/nav\",0,null,null],[50,\"ui/offscreen/body/content\",0,null,null]]]]]],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-shared-assets-addon/components/ui/offscreen/body.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});