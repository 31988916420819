define("ember-bootstrap/components/bs-popover", ["exports", "@ember/component", "@ember/template-factory", "ember-bootstrap/components/bs-contextual-help", "ember-bootstrap/utils/decorators/arg", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _templateFactory, _bsContextualHelp, _arg, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-unbound }}
  {{unbound this._parentFinder}}
  {{#if this.inDom}}
    {{#let (ensure-safe-component (bs-default @elementComponent (component "bs-popover/element"))) as |Element|}}
      <Element
        @placement={{this.placement}}
        @fade={{this.fade}}
        @showHelp={{this.showHelp}}
        @title={{@title}}
        @renderInPlace={{this._renderInPlace}}
        @popperTarget={{this.triggerTargetElement}}
        @destinationElement={{this.destinationElement}}
        @autoPlacement={{this.autoPlacement}}
        @viewportElement={{this.viewportElement}}
        @viewportPadding={{this.viewportPadding}}
        @class={{@class}}
  
        {{create-ref "overlayElement"}}
        ...attributes
      >
        {{yield
          (hash
            close=this.close
          )
        }}
      </Element>
    {{/let}}
  {{/if}}
  {{did-insert this.setup}}
  
  */
  {
    "id": "+MZ0kpH3",
    "block": "[[[1,[28,[35,0],[[30,0,[\"_parentFinder\"]]],null]],[1,\"\\n\"],[41,[30,0,[\"inDom\"]],[[[44,[[28,[37,3],[[28,[37,4],[[30,1],[50,\"bs-popover/element\",0,null,null]],null]],null]],[[[1,\"    \"],[8,[30,2],[[17,3],[4,[38,6],[\"overlayElement\"],[[\"debugName\",\"bucket\"],[\"create-ref\",[30,0]]]]],[[\"@placement\",\"@fade\",\"@showHelp\",\"@title\",\"@renderInPlace\",\"@popperTarget\",\"@destinationElement\",\"@autoPlacement\",\"@viewportElement\",\"@viewportPadding\",\"@class\"],[[30,0,[\"placement\"]],[30,0,[\"fade\"]],[30,0,[\"showHelp\"]],[30,4],[30,0,[\"_renderInPlace\"]],[30,0,[\"triggerTargetElement\"]],[30,0,[\"destinationElement\"]],[30,0,[\"autoPlacement\"]],[30,0,[\"viewportElement\"]],[30,0,[\"viewportPadding\"]],[30,5]]],[[\"default\"],[[[[1,\"\\n      \"],[18,6,[[28,[37,8],null,[[\"close\"],[[30,0,[\"close\"]]]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2]]]],[]],null],[1,[28,[35,9],[[30,0,[\"setup\"]]],null]],[1,\"\\n\"]],[\"@elementComponent\",\"Element\",\"&attrs\",\"@title\",\"@class\",\"&default\"],false,[\"unbound\",\"if\",\"let\",\"ensure-safe-component\",\"bs-default\",\"component\",\"create-ref\",\"yield\",\"hash\",\"did-insert\"]]",
    "moduleName": "ember-bootstrap/components/bs-popover.hbs",
    "isStrictMode": false
  });

  let Popover =
  /**
    Component that implements Bootstrap [popovers](http://getbootstrap.com/javascript/#popovers).
  
    By default it will attach its listeners (click) to the parent DOM element to trigger
    the popover:
  
    ```hbs
    <button class="btn">
      <BsPopover @title="this is a title">and this the body</BsPopover>
    </button>
    ```
  
    ### Trigger
  
    The trigger element is the DOM element that will cause the popover to be shown when one of the trigger events occur on
    that element. By default the trigger element is the parent DOM element of the component, and the trigger event will be
    "click".
  
    The `triggerElement` property accepts any CSS selector to attach the popover to any other existing DOM element.
    With the special value "parentView" you can attach the popover to the DOM element of the parent component:
  
    ```hbs
    <MyComponent>
      <BsPopover @triggerElement="parentView">This is a popover</BsPopover>
    </MyComponent>
    ```
  
    To customize the events that will trigger the popover use the `triggerEvents` property, that accepts an array or a
    string of events, with "hover", "focus" and "click" being supported.
  
    ### Placement options
  
    By default the popover will show up to the right of the trigger element. Use the `placement` property to change that
    ("top", "bottom", "left" and "right"). To make sure the popover will not exceed the viewport (see Advanced customization)
    you can set `autoPlacement` to true. A popover with `placement="right" will be placed to the right if there is enough
    space, otherwise to the left.
  
    ### Advanced customization
  
    Several other properties allow for some advanced customization:
    * `visible` to show/hide the popover programmatically
    * `fade` to disable the fade in transition
    * `delay` (or `delayShow` and `delayHide`) to add a delay
    * `viewportSelector` and `viewportPadding` to customize the viewport that affects `autoPlacement`
    *  a `close` action is yielded, that allows you to close the tooltip:
  
    ```hbs
    <BsPopover as |po| >This is a popover <button onclick={{action po.close}}>Close</button></BsPopover>
    ```
  
    See the individual API docs for each property.
  
    ### Actions
  
    When you want to react on the popover being shown or hidden, you can use one of the following supported actions:
    * `onShow`
    * `onShown`
    * `onHide`
    * `onHidden`
  
    *Note that only invoking the component in a template as shown above is considered part of its public API. Extending from it (subclassing) is generally not supported, and may break at any time.*
  
    @class Popover
    @namespace Components
    @extends Components.ContextualHelp
    @public
  */
  (0, _deprecateSubclassing.default)(_class = (_class2 = class Popover extends _bsContextualHelp.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "placement", _descriptor, this);

      _initializerDefineProperty(this, "triggerEvents", _descriptor2, this);
    }

    /**
     * @property elementComponent
     * @type {String}
     * @private
     */

    /**
     * The DOM element of the arrow element.
     *
     * @property arrowElement
     * @type object
     * @readonly
     * @private
     */
    get arrowElement() {
      return this.overlayElement.querySelector('.arrow');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "placement", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'right';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "triggerEvents", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'click';
    }
  })), _class2)) || _class;

  _exports.default = Popover;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Popover);
});