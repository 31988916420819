define("itarp-business-application/routes/profile", ["exports", "@ember/routing/route", "@ember/service", "itarp-business-application/config/environment"], function (_exports, _route, _service, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProfileRoute = (_dec = (0, _service.inject)('ad-token-service'), (_class = class ProfileRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "auth", _descriptor2, this);
    }

    buildRouteInfoMetadata() {
      return {
        title: 'Profile',
        padding: {
          top: 0
        }
      };
    }

    async model() {
      const id = this.auth.tokenInformation.sub;
      return this.store.findRecord('user', id);
    }

    redirect(model, transition) {
      var _transition$to;

      const name = transition === null || transition === void 0 ? void 0 : (_transition$to = transition.to) === null || _transition$to === void 0 ? void 0 : _transition$to.name;

      if (!name || name.includes('profile.index')) {
        var _model$profile, _model$profile2, _model$profile3, _model$profile4, _model$profile5;

        if (!(model !== null && model !== void 0 && model.isEmailVerified)) {
          window.location.href = `${_environment.default.LANDING_FRONTEND_URL}/signup/expert?id_token=${this.auth.token}&resend_verification=true`;
        }

        if (!((_model$profile = model.profile) !== null && _model$profile !== void 0 && _model$profile.linkedinUrl) && !((_model$profile2 = model.profile) !== null && _model$profile2 !== void 0 && _model$profile2.resume)) {
          return this.router.transitionTo('profile.autofill');
        } else if (!((_model$profile3 = model.profile) !== null && _model$profile3 !== void 0 && _model$profile3.basicInfoComplete)) {
          return this.router.transitionTo('profile.basic-info');
        } else if (!((_model$profile4 = model.profile) !== null && _model$profile4 !== void 0 && _model$profile4.expertiseComplete)) {
          return this.router.transitionTo('profile.expertise');
        } else if (!((_model$profile5 = model.profile) !== null && _model$profile5 !== void 0 && _model$profile5.workArrangmentComplete)) {
          return this.router.transitionTo('profile.work-arrangement');
        }

        this.router.transitionTo('itarp-opportunity-ui.browse');
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ProfileRoute;
});