define("itarp-model-designer/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    this.route('authenticated', {
      path: ''
    }, function () {
      this.route('model-management', {
        path: '/'
      }, function () {
        this.route('create');
        this.route('update', {
          path: '/:model_id'
        });
        this.route('meta-model-attribute-update', {
          path: '/update-attributes/:meta_model_id/:meta_model_attribute_id'
        });
      });
    });
  });

  _exports.default = _default;
});