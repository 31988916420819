define("itarp-components/components/ui/accordion/item", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='filter-group {{unless this.isOpen 'is-collapsed'}}' ...attributes>
    {{yield
      (hash
        header=(component 'ui/accordion/header' toggle=this.toggle)
        body=(component 'ui/accordion/body' isOpen=this.isOpen)
      )
    }}
  </div>
  
  */
  {
    "id": "uH9Be2y6",
    "block": "[[[11,0],[16,0,[29,[\"filter-group \",[52,[51,[30,0,[\"isOpen\"]]],\"is-collapsed\"]]]],[17,1],[12],[1,\"\\n  \"],[18,2,[[28,[37,2],null,[[\"header\",\"body\"],[[50,\"ui/accordion/header\",0,null,[[\"toggle\"],[[30,0,[\"toggle\"]]]]],[50,\"ui/accordion/body\",0,null,[[\"isOpen\"],[[30,0,[\"isOpen\"]]]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"unless\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-components/components/ui/accordion/item.hbs",
    "isStrictMode": false
  });

  let UiAccordionItemComponent = (_class = class UiAccordionItemComponent extends _component2.default {
    get isOpen() {
      const selected = this.args.selectedIndex;

      if (selected !== null && selected !== undefined) {
        return selected === this.args.value;
      }

      return false;
    }

    toggle() {
      this.args.toggle(this.args.value);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "toggle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype)), _class);
  _exports.default = UiAccordionItemComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiAccordionItemComponent);
});