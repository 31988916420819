define("itarp-components/components/table/candidates/column/rate", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let @row @candidate.profile as |row profile|}}
    <row.cell ...attributes>
      <Ui::Wrapper>
        {{#if @application.displayRate}}
          <div class='card-block--rates'>
            <ul class='card-block--rates__list'>
              <li class='card-block--rates__list__item'>
                <span class='card-block--rates__currency'>
                  {{or profile.hourlyRateCurrency '$'}}
                </span>
                {{@application.displayRate}}
                <span class='card-block--rates__unit'>/hr</span>
              </li>
            </ul>
          </div>
        {{/if}}
      </Ui::Wrapper>
    </row.cell>
  {{/let}}
  */
  {
    "id": "UFAOIZ7+",
    "block": "[[[44,[[30,1],[30,2,[\"profile\"]]],[[[1,\"  \"],[8,[30,3,[\"cell\"]],[[17,5]],null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,6,[\"displayRate\"]],[[[1,\"        \"],[10,0],[14,0,\"card-block--rates\"],[12],[1,\"\\n          \"],[10,\"ul\"],[14,0,\"card-block--rates__list\"],[12],[1,\"\\n            \"],[10,\"li\"],[14,0,\"card-block--rates__list__item\"],[12],[1,\"\\n              \"],[10,1],[14,0,\"card-block--rates__currency\"],[12],[1,\"\\n                \"],[1,[28,[35,3],[[30,4,[\"hourlyRateCurrency\"]],\"$\"],null]],[1,\"\\n              \"],[13],[1,\"\\n              \"],[1,[30,6,[\"displayRate\"]]],[1,\"\\n              \"],[10,1],[14,0,\"card-block--rates__unit\"],[12],[1,\"/hr\"],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[3,4]]]],[\"@row\",\"@candidate\",\"row\",\"profile\",\"&attrs\",\"@application\"],false,[\"let\",\"ui/wrapper\",\"if\",\"or\"]]",
    "moduleName": "itarp-components/components/table/candidates/column/rate.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});