define("itarp-opportunity-designer/routes/details/unsuccessful", ["exports", "@ember/routing/route", "rsvp", "@ember/service"], function (_exports, _route, _rsvp, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DetailsUnsuccessfulRoute = (_dec = (0, _service.inject)(), _dec2 = (0, _service.inject)('opportunity/authorization'), (_class = class DetailsUnsuccessfulRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "candidates", _descriptor, this);

      _initializerDefineProperty(this, "authorization", _descriptor2, this);
    }

    model() {
      const project = this.modelFor('details').project;
      const id = project.id;
      return (0, _rsvp.hash)({
        project,
        candidates: this.candidates.get('unsuccessful', id).then(([candidates]) => candidates)
      });
    }

    async beforeModel(transition) {
      var _transition$from;

      const permissions = this.authorization.permissions;
      const permission = permissions.candidates.view.unsuccessful;
      const isPermitted = await this.authorization.isPermitted(permission);
      const originatedFrom = (_transition$from = transition.from) === null || _transition$from === void 0 ? void 0 : _transition$from.name;

      if (!isPermitted) {
        transition.abort();

        if (originatedFrom) {
          return this.appRouter.transitionTo(originatedFrom);
        }

        this.appRouter.transitionTo('itarp-opportunity-designer.browse');
      }
    }

    setupController(controller, model) {
      super.setupController(...arguments);
      const {
        meta,
        candidates
      } = model.candidates;
      controller.set('candidates', candidates);
      controller.set('total', meta.page.total);
      controller.set('project', model.project);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "candidates", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "authorization", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = DetailsUnsuccessfulRoute;
});