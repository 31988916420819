define("itarp-opportunity-ui/models/applied-opportunity", ["exports", "@ember-data/model", "@ember/array", "moment", "@ember/template", "itarp-opportunity-ui/utils/sortable-questions"], function (_exports, _model, _array, _moment, _template, _sortableQuestions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _dec42, _dec43, _dec44, _dec45, _dec46, _dec47, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39, _descriptor40, _descriptor41, _descriptor42, _descriptor43, _descriptor44, _descriptor45, _descriptor46, _descriptor47;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AppliedOpportunityModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('date'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string', {
    defaultValue: 'open'
  }), _dec9 = (0, _model.attr)('number', {
    defaultValue: 1
  }), _dec10 = (0, _model.attr)({
    defaultValue() {
      return (0, _array.A)([]);
    }

  }), _dec11 = (0, _model.attr)({
    defaultValue() {
      return (0, _array.A)([]);
    }

  }), _dec12 = (0, _model.attr)({
    defaultValue() {
      return (0, _array.A)([]);
    }

  }), _dec13 = (0, _model.attr)({
    defaultValue() {
      return (0, _array.A)([]);
    }

  }), _dec14 = (0, _model.attr)(), _dec15 = (0, _model.attr)(), _dec16 = (0, _model.attr)(), _dec17 = (0, _model.attr)(), _dec18 = (0, _model.attr)('number'), _dec19 = (0, _model.attr)('boolean'), _dec20 = (0, _model.attr)('boolean'), _dec21 = (0, _model.attr)('boolean'), _dec22 = (0, _model.attr)('date'), _dec23 = (0, _model.attr)('date'), _dec24 = (0, _model.attr)(), _dec25 = (0, _model.attr)(), _dec26 = (0, _model.attr)('number'), _dec27 = (0, _model.attr)('number'), _dec28 = (0, _model.attr)('boolean'), _dec29 = (0, _model.attr)('boolean'), _dec30 = (0, _model.attr)(), _dec31 = (0, _model.attr)('string'), _dec32 = (0, _model.attr)(), _dec33 = (0, _model.attr)(), _dec34 = (0, _model.attr)('string'), _dec35 = (0, _model.attr)('string'), _dec36 = (0, _model.attr)('string'), _dec37 = (0, _model.attr)('string'), _dec38 = (0, _model.attr)('string'), _dec39 = (0, _model.attr)('string'), _dec40 = (0, _model.attr)('string'), _dec41 = (0, _model.attr)('string'), _dec42 = (0, _model.attr)('string'), _dec43 = (0, _model.attr)('string'), _dec44 = (0, _model.attr)('string'), _dec45 = (0, _model.attr)('string'), _dec46 = (0, _model.attr)('sortable', {
    defaultValue() {
      return _sortableQuestions.default.create({
        content: (0, _array.A)([])
      });
    }

  }), _dec47 = (0, _model.attr)({
    defaultValue() {
      return (0, _array.A)([]);
    }

  }), (_class = class AppliedOpportunityModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "opportunityId", _descriptor, this);

      _initializerDefineProperty(this, "applicationId", _descriptor2, this);

      _initializerDefineProperty(this, "createdAt", _descriptor3, this);

      _initializerDefineProperty(this, "hiringManager", _descriptor4, this);

      _initializerDefineProperty(this, "jobTitle", _descriptor5, this);

      _initializerDefineProperty(this, "internalId", _descriptor6, this);

      _initializerDefineProperty(this, "description", _descriptor7, this);

      _initializerDefineProperty(this, "status", _descriptor8, this);

      _initializerDefineProperty(this, "numberOfResources", _descriptor9, this);

      _initializerDefineProperty(this, "skillLevels", _descriptor10, this);

      _initializerDefineProperty(this, "skills", _descriptor11, this);

      _initializerDefineProperty(this, "tools", _descriptor12, this);

      _initializerDefineProperty(this, "questions", _descriptor13, this);

      _initializerDefineProperty(this, "educations", _descriptor14, this);

      _initializerDefineProperty(this, "certification", _descriptor15, this);

      _initializerDefineProperty(this, "talentCloud", _descriptor16, this);

      _initializerDefineProperty(this, "jobType", _descriptor17, this);

      _initializerDefineProperty(this, "timeCommitment", _descriptor18, this);

      _initializerDefineProperty(this, "officeHours", _descriptor19, this);

      _initializerDefineProperty(this, "outsideOfficeHours", _descriptor20, this);

      _initializerDefineProperty(this, "flexible", _descriptor21, this);

      _initializerDefineProperty(this, "startDate", _descriptor22, this);

      _initializerDefineProperty(this, "endDate", _descriptor23, this);

      _initializerDefineProperty(this, "targetDaysToFill", _descriptor24, this);

      _initializerDefineProperty(this, "remunerations", _descriptor25, this);

      _initializerDefineProperty(this, "minRate", _descriptor26, this);

      _initializerDefineProperty(this, "maxRate", _descriptor27, this);

      _initializerDefineProperty(this, "selectedPreferredLocation", _descriptor28, this);

      _initializerDefineProperty(this, "isProject", _descriptor29, this);

      _initializerDefineProperty(this, "idealCandidateLocation", _descriptor30, this);

      _initializerDefineProperty(this, "openToRemote", _descriptor31, this);

      _initializerDefineProperty(this, "travelRequirement", _descriptor32, this);

      _initializerDefineProperty(this, "industry", _descriptor33, this);

      _initializerDefineProperty(this, "projectThumbnail", _descriptor34, this);

      _initializerDefineProperty(this, "preferredCountry", _descriptor35, this);

      _initializerDefineProperty(this, "preferredCity", _descriptor36, this);

      _initializerDefineProperty(this, "preferredState", _descriptor37, this);

      _initializerDefineProperty(this, "preferredZipCode", _descriptor38, this);

      _initializerDefineProperty(this, "workflowApprovalId", _descriptor39, this);

      _initializerDefineProperty(this, "workflowApprovalStageId", _descriptor40, this);

      _initializerDefineProperty(this, "workflowId", _descriptor41, this);

      _initializerDefineProperty(this, "workflowStageId", _descriptor42, this);

      _initializerDefineProperty(this, "companyName", _descriptor43, this);

      _initializerDefineProperty(this, "companyDisplayName", _descriptor44, this);

      _initializerDefineProperty(this, "hiringManagerDisplayName", _descriptor45, this);

      _initializerDefineProperty(this, "questions", _descriptor46, this);

      _initializerDefineProperty(this, "practiceAreas", _descriptor47, this);
    }

    get safeDescription() {
      if (this.description) {
        return (0, _template.htmlSafe)(this.description);
      }
    }

    get shortDescription() {
      if (this.description) return (0, _template.htmlSafe)(this.description.slice(0, 100) + '...');
    }

    get fillByDate() {
      const {
        startDate,
        targetDaysToFill
      } = this;

      if (startDate && targetDaysToFill) {
        const date = new Date(startDate);
        date.setDate(date.getDate() + targetDaysToFill);
        return date.toLocaleDateString('en-US');
      }
    }

    get daysOpen() {
      const currentDate = _moment.default.utc(new Date()).toDate();

      if (this.createdAt) {
        const createdAt = _moment.default.utc(this.createdAt).toDate();

        const difference = (0, _moment.default)(currentDate).diff(createdAt, 'hours') / 24;

        const duration = _moment.default.duration(difference * -1, 'days').humanize();

        if (duration === 'a month') return '1 Month';
        return duration;
      }
    }

    get duration() {
      const {
        startDate,
        endDate,
        jobType
      } = this;

      if (jobType === 'Contract' && startDate && endDate) {
        const start = _moment.default.utc(startDate).toDate();

        const end = _moment.default.utc(endDate).toDate();

        const difference = (0, _moment.default)(end).diff(start, 'months');

        const duration = _moment.default.duration(difference * -1, 'months').humanize();

        if (duration === 'a few seconds') return '1 Day';
        if (duration === 'a month') return '1 Month';
        return duration;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "opportunityId", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "applicationId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "hiringManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "jobTitle", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "internalId", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "numberOfResources", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "skillLevels", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "skills", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "tools", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "questions", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "educations", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "certification", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "talentCloud", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "jobType", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "timeCommitment", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "officeHours", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "outsideOfficeHours", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "flexible", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "startDate", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "endDate", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "targetDaysToFill", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "remunerations", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "minRate", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "maxRate", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "selectedPreferredLocation", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "isProject", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "idealCandidateLocation", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "openToRemote", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "travelRequirement", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "industry", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "projectThumbnail", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "preferredCountry", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "preferredCity", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "preferredState", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = _applyDecoratedDescriptor(_class.prototype, "preferredZipCode", [_dec38], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = _applyDecoratedDescriptor(_class.prototype, "workflowApprovalId", [_dec39], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor40 = _applyDecoratedDescriptor(_class.prototype, "workflowApprovalStageId", [_dec40], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor41 = _applyDecoratedDescriptor(_class.prototype, "workflowId", [_dec41], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor42 = _applyDecoratedDescriptor(_class.prototype, "workflowStageId", [_dec42], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor43 = _applyDecoratedDescriptor(_class.prototype, "companyName", [_dec43], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor44 = _applyDecoratedDescriptor(_class.prototype, "companyDisplayName", [_dec44], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor45 = _applyDecoratedDescriptor(_class.prototype, "hiringManagerDisplayName", [_dec45], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor46 = _applyDecoratedDescriptor(_class.prototype, "questions", [_dec46], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor47 = _applyDecoratedDescriptor(_class.prototype, "practiceAreas", [_dec47], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AppliedOpportunityModel;
});