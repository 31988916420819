define("itarp-business-application/components/radio", ["exports", "@ember/component/template-only"], function (_exports, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="">
    <div class="">
      <label>
        <span></span>
  
        <span></span>
        <span></span>
      </label>
      <span></span>
    </div>
    <div class="">
  
    </div>
  </div>
  */
  {
    "id": "n50M9LVD",
    "block": "[[[10,0],[14,0,\"\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,\"\\n      \"],[10,1],[12],[13],[1,\"\\n\\n      \"],[10,1],[12],[13],[1,\"\\n      \"],[10,1],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,1],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"\"],[12],[1,\"\\n\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "itarp-business-application/components/radio.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});