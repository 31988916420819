define("itarp-opportunity-designer/services/collaborators", ["exports", "@ember/service", "@ember/array", "@ember/object"], function (_exports, _service, _array, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _dec3, _dec4, _class3, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let CollaboratorsService = (_dec3 = (0, _service.inject)(), _dec4 = (0, _service.inject)(), (_class3 = class CollaboratorsService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "adTokenService", _descriptor2, this);
    }

    initialize(opportunity, {
      persist = false
    } = {}) {
      const collection = new CollaboratorsRepository(opportunity, persist);
      collection.store = this.store;
      collection.fetchOptions = this.fetchOptions;
      collection.load();
      return collection;
    }

    fetchOptions() {
      if (this.userType === 'tod_client') {
        return this.store.query('user', {
          per_page: 'infinity',
          filter: {
            company_id: this.companyId
          }
        }).then(result => {
          return result.filter(user => user.id !== this.adTokenService.userId);
        });
      }

      return this.store.query('user', {
        per_page: 'infinity',
        advance_filter: {
          user_type: {
            $nin: ['guest_user', 'expert']
          }
        }
      }).then(result => {
        return result.filter(user => user.id !== this.adTokenService.userId);
      });
    }

    get userType() {
      return this.adTokenService.userType;
    }

    get companyId() {
      return this.adTokenService.companyId;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class3.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class3.prototype, "adTokenService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "initialize", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "initialize"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "fetchOptions", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "fetchOptions"), _class3.prototype)), _class3));
  _exports.default = CollaboratorsService;
  let CollaboratorsRepository = (_dec = (0, _object.computed)('collaborators.[]'), _dec2 = (0, _object.computed)('collaborators.[]'), (_class = class CollaboratorsRepository {
    constructor(opportunity, persist) {
      _defineProperty(this, "collaborators", (0, _array.A)([]));

      _defineProperty(this, "persistOnChange", false);

      _defineProperty(this, "invoker", void 0);

      this.opportunity = opportunity;
      if (persist) this.persistOnChange = true;
    }

    async load() {
      if (!this.opportunity.id) return;
      this.store.query('collaborator', {
        per_page: 'infinity',
        filter: {
          resource_id: this.opportunity.id,
          resource_type: 'opportunity'
        }
      }).then(async result => {
        const data = result.toArray();
        if (data.length) await this.loadUsers(data);
        this.collaborators.addObjects(result);
      });
    }

    async loadUsers(collaborators) {
      return this.store.query('user', {
        per_page: 'infinity',
        advance_filter: {
          id: {
            $in: collaborators.map(collaborator => collaborator.collaboratorUserId).filter(Boolean)
          }
        }
      });
    }

    get users() {
      return this.collaborators.map(collaborator => collaborator.user);
    }

    get limited() {
      if (this.collaborators.length > 2) return this.collaborators.slice(0, 2);
      return this.collaborators;
    }

    select(option) {
      var _option$user;

      let existing;

      if ((_option$user = option.user) !== null && _option$user !== void 0 && _option$user.id) {
        var _option$user2;

        existing = this.collaborators.findBy('user.id', (_option$user2 = option.user) === null || _option$user2 === void 0 ? void 0 : _option$user2.id);
      } else {
        existing = this.collaborators.findBy('email', option.email);
      }

      if (existing) {
        this.remove(existing);
      } else if (this.collaborators.length === 5) {
        return 'Maximum of 5 collaborators allowed';
      } else {
        this.add(option);
      }
    }

    add(option) {
      this.invoker.execute(AddCommand, option);
    }

    remove(collaborator) {
      this.invoker.execute(RemoveCommand, collaborator);
    }

    delete(collaborator) {
      this.collaborators.removeObject(collaborator);
      collaborator.destroyRecord();
    }

    track() {
      this.invoker = new CommandInvoker(this.collaborators, this.store, this.opportunity);
    }

    stopTracking() {
      this.invoker.commit(this.persistOnChange);
    }

    undo() {
      this.invoker.drop();
      this.invoker = new CommandInvoker(this.collaborators, this.store, this.opportunity);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "load", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "load"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadUsers", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadUsers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "users", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "users"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "limited", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "limited"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "add", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "track", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "track"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "stopTracking", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "stopTracking"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "undo", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "undo"), _class.prototype)), _class));

  class Command {
    constructor(collaborators, store, opportunity) {
      this.collaborators = collaborators;
      this.store = store;
      this.opportunity = opportunity;
    }

    execute() {}

    commit() {}

    undo() {}

  }

  class RemoveCommand extends Command {
    commit() {
      this.collaborator.destroyRecord();
    }

    execute(collaborator) {
      this.collaborator = collaborator;
      this.collaborators.removeObject(this.collaborator);
    }

    undo() {
      this.collaborators.addObject(this.collaborator);
    }

  }

  class AddCommand extends Command {
    commit(persist) {
      if (persist) this.collaborator.save();
    }

    execute(option) {
      var _option$user3;

      if ((_option$user3 = option.user) !== null && _option$user3 !== void 0 && _option$user3.id) {
        this.collaborator = this.store.createRecord('collaborator', {
          user: option.user,
          collaboratorUserId: option.user.id,
          resourceType: 'opportunity',
          resourceId: this.opportunity.id,
          email: option.user.email,
          invitationAccepted: true
        });
      } else {
        this.collaborator = this.store.createRecord('collaborator', {
          email: option.email,
          resourceType: 'opportunity',
          resourceId: this.opportunity.id
        });
      }

      this.collaborators.addObject(this.collaborator);
    }

    undo() {
      this.collaborators.removeObject(this.collaborator);
      if (this.collaborator.isNew) this.collaborator.destroyRecord();
    }

  }

  class CommandInvoker {
    constructor(collaborators, store, opportunity) {
      _defineProperty(this, "commands", []);

      this.collaborators = collaborators;
      this.store = store;
      this.opportunity = opportunity;
    }

    execute(CommandClass, ...params) {
      const command = new CommandClass(this.collaborators, this.store, this.opportunity);
      command.execute(...params);
      this.commands.push(command);
    }

    commit(persist) {
      while (this.commands.length > 0) {
        const command = this.commands.shift();
        command.commit(persist);
      }
    }

    drop() {
      while (this.commands.length > 0) {
        const command = this.commands.pop();
        command.undo();
      }
    }

  }
});