define("itarp-workflow-designer/routes/workflow/edit", ["exports", "@ember/routing/route", "@ember/service", "@ember/object"], function (_exports, _route, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let WorkflowEditRoute = (_class = class WorkflowEditRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    async model(params) {
      return this.store.findRecord('workflow', params.id, {
        include: 'stage-groups',
        reload: true
      });
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.set('workflow', model);
      controller.set('stageGroups', model.stageGroups.sortBy('displayOrder'));
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.stageGroups.filterBy('hasDirtyAttributes', true).map(stage => {
          stage.rollbackAttributes();
        });
        controller.selected = null;
      }
    }

    async willTransition(transition) {
      if (this.controller.workflow.hasDirtyAttributes) {
        transition.abort();
        this.controller.workflow.rollbackAttributes();
        transition.retry();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "willTransition", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype)), _class);
  _exports.default = WorkflowEditRoute;
});