define("ember-moment/helpers/moment-format", ["exports", "@ember/utils", "@ember/object", "ember-moment/utils/helper-compute", "ember-moment/helpers/-base"], function (_exports, _utils, _object, _helperCompute, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    // eslint-disable-next-line ember/no-observers
    defaultFormatDidChange: (0, _object.observer)('moment.defaultFormat', function () {
      this.recompute();
    }),
    compute: (0, _helperCompute.default)(function (params, {
      locale,
      timeZone
    }) {
      this._super(...arguments);

      const moment = this.moment;
      const {
        length
      } = params;

      if (length > 3) {
        throw new TypeError('ember-moment: Invalid number of arguments, expected at most 3');
      }

      const args = [];
      const formatArgs = []; // eslint-disable-next-line ember/no-get

      const defaultFormat = (0, _object.get)(this, 'moment.defaultFormat');
      args.push(params[0]);

      if (length === 1 && !(0, _utils.isEmpty)(defaultFormat)) {
        formatArgs.push(defaultFormat);
      } else if (length === 2) {
        formatArgs.push(params[1]);
      } else if (length > 2) {
        args.push(params[2]);
        formatArgs.push(params[1]);
      }

      return this.morphMoment(moment.moment(...args), {
        locale,
        timeZone
      }).format(...formatArgs);
    })
  });

  _exports.default = _default;
});