define("itarp-talentcloud-ui/components/forms/errors", ["exports", "@ember/component/template-only"], function (_exports, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="row">
    <div class="col--12-12">
      <div class="component-wrapper has-hp">
        <div class="form-alert">
          <div class="form-alert__icon"></div>
          <div class="form-alert__content">
  
            <ul>
              {{#each @errors as |error|}}
                {{#if error}}
                  <li>{{error}}</li>
                {{/if}}
              {{/each}}
  
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  */
  {
    "id": "rkEwdg0z",
    "block": "[[[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"col--12-12\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"component-wrapper has-hp\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"form-alert\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"form-alert__icon\"],[12],[13],[1,\"\\n        \"],[10,0],[14,0,\"form-alert__content\"],[12],[1,\"\\n\\n          \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[41,[30,2],[[[1,\"                \"],[10,\"li\"],[12],[1,[30,2]],[13],[1,\"\\n\"]],[]],null]],[2]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@errors\",\"error\"],false,[\"each\",\"-track-array\",\"if\"]]",
    "moduleName": "itarp-talentcloud-ui/components/forms/errors.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});