define("itarp-opportunity-designer/components/chat-widget", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let @data as |chat|}}
    <div class={{concat 'card-thread ' @classList}}>
      {{#if chat}}
        <div class='card-thread__inner'>
          {{#each chat.messages as |message|}}
            <div
              class={{concat
                'card-thread__post'
                (if message.senderId.isSender ' is-sent' ' is-received')
              }}
            >
              <div class='card-thread__message'>
                <span
                  class={{concat
                    'card-thread__message__avatar '
                    (if message.senderId.isSender 'is-sender' 'is-receiver')
                  }}
                >
                  {{to-initials
                    message.senderId.user.first_name
                    message.senderId.user.last_name
                  }}
                </span>
                <div class='card-thread__message__header'>
                  <span>{{(if
                      message.senderId.isSender
                      'Me'
                      message.senderId.user.first_name
                    )}}</span>
                  <span>{{moment-format
                      message.updatedAt
                      'MMMM Do, YYYY, h:mm:ss A'
                    }}</span>
                </div>
                <div class='card-thread__message__body'>
                  {{message.message}}
                </div>
              </div>
            </div>
          {{/each}}
        </div>
      {{else}}
        <div class='card-thread--empty'>
          Send a message to initiate a chat...
        </div>
      {{/if}}
    </div>
  {{/let}}
  */
  {
    "id": "F5g4q+Y/",
    "block": "[[[44,[[30,1]],[[[1,\"  \"],[10,0],[15,0,[28,[37,1],[\"card-thread \",[30,3]],null]],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"      \"],[10,0],[14,0,\"card-thread__inner\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,2,[\"messages\"]]],null]],null],null,[[[1,\"          \"],[10,0],[15,0,[28,[37,1],[\"card-thread__post\",[52,[30,4,[\"senderId\",\"isSender\"]],\" is-sent\",\" is-received\"]],null]],[12],[1,\"\\n            \"],[10,0],[14,0,\"card-thread__message\"],[12],[1,\"\\n              \"],[10,1],[15,0,[28,[37,1],[\"card-thread__message__avatar \",[52,[30,4,[\"senderId\",\"isSender\"]],\"is-sender\",\"is-receiver\"]],null]],[12],[1,\"\\n                \"],[1,[28,[35,5],[[30,4,[\"senderId\",\"user\",\"first_name\"]],[30,4,[\"senderId\",\"user\",\"last_name\"]]],null]],[1,\"\\n              \"],[13],[1,\"\\n              \"],[10,0],[14,0,\"card-thread__message__header\"],[12],[1,\"\\n                \"],[10,1],[12],[1,[52,[30,4,[\"senderId\",\"isSender\"]],\"Me\",[30,4,[\"senderId\",\"user\",\"first_name\"]]]],[13],[1,\"\\n                \"],[10,1],[12],[1,[28,[35,6],[[30,4,[\"updatedAt\"]],\"MMMM Do, YYYY, h:mm:ss A\"],null]],[13],[1,\"\\n              \"],[13],[1,\"\\n              \"],[10,0],[14,0,\"card-thread__message__body\"],[12],[1,\"\\n                \"],[1,[30,4,[\"message\"]]],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[4]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"card-thread--empty\"],[12],[1,\"\\n        Send a message to initiate a chat...\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[2]]]],[\"@data\",\"chat\",\"@classList\",\"message\"],false,[\"let\",\"concat\",\"if\",\"each\",\"-track-array\",\"to-initials\",\"moment-format\"]]",
    "moduleName": "itarp-opportunity-designer/components/chat-widget.hbs",
    "isStrictMode": false
  });

  class ChatWidgetComponent extends _component2.default {}

  _exports.default = ChatWidgetComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatWidgetComponent);
});