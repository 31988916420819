define("shared/serializers/participant", ["exports", "@ember-data/serializer/rest", "@ember/string"], function (_exports, _rest, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ParticipantSerializer extends _rest.default {
    extractAttributes(_mc, resource) {
      Object.keys(resource).forEach(attrName => {
        const value = resource[attrName];
        resource[(0, _string.camelize)(attrName)] = value;
        delete resource[attrName];
      });
      return resource;
    }

    normalize(_mc, resource) {
      const user = resource.user;
      const result = super.normalize(...arguments);
      result.data.attributes.user = user;
      return result;
    }

    normalizeResponse(_s, _mc, payload, _id, _rq) {
      let result;

      if (Array.isArray(payload)) {
        result = {
          participants: payload
        };
      } else {
        result = {
          participant: payload
        };
      }

      return super.normalizeResponse(_s, _mc, result, _id, _rq);
    }

  }

  _exports.default = ParticipantSerializer;
});