define("ember-engines-router-service/services/engine-router-service", ["exports", "@ember/service", "@ember/debug", "@ember/object", "@ember/object/computed", "@ember/application", "@ember/object/evented", "ember-engines-router-service/utils/namespace-engine-route-name", "ember-engines-router-service/utils/root-owner", "ember-engines-router-service/utils/resembles-url"], function (_exports, _service, _debug, _object, _computed, _application, _evented, _namespaceEngineRouteName, _rootOwner, _resemblesUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let EngineRouterService = (_dec = (0, _computed.reads)('externalRouter.rootURL'), _dec2 = (0, _computed.reads)('externalRouter.currentURL'), _dec3 = (0, _object.computed)('externalRouter.currentRouteName'), (_class = class EngineRouterService extends _service.default.extend(_evented.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "rootURL", _descriptor, this);

      _initializerDefineProperty(this, "currentURL", _descriptor2, this);
    }

    init() {
      super.init();
      this._externalRoutes = (0, _application.getOwner)(this)._externalRoutes;
      this._mountPoint = (0, _application.getOwner)(this).mountPoint;
      this.rootApplication = (0, _rootOwner.getRootOwner)(this);
      this.externalRouter.on('routeWillChange', this.onRouteWillChange);
      this.externalRouter.on('routeDidChange', this.onRouteDidChange);
    }

    destroy() {
      this.externalRouter.off('routeWillChange', this.onRouteWillChange);
      this.externalRouter.off('routeDidChange', this.onRouteDidChange);
      super.destroy();
    }

    onRouteWillChange(...args) {
      this.trigger('routeWillChange', ...args);
    }

    onRouteDidChange(...args) {
      this.trigger('routeDidChange', ...args);
    }

    get currentRouteName() {
      if ((0, _object.get)(this, 'externalRouter').currentRouteName === this._mountPoint) {
        return 'application';
      }

      return (0, _object.get)(this, 'externalRouter').currentRouteName.slice(this._mountPoint.length + 1);
    }

    get externalRouter() {
      return this.rootApplication.lookup('service:router');
    }

    getExternalRouteName(externalRouteName) {
      (false && !(externalRouteName in this._externalRoutes) && (0, _debug.assert)(`External route '${externalRouteName}' is unknown.`, externalRouteName in this._externalRoutes));
      return this._externalRoutes[externalRouteName];
    }

    transitionTo(routeName, ...args) {
      if ((0, _resemblesUrl.resemblesURL)(routeName)) {
        return (0, _object.get)(this, 'externalRouter').transitionTo(routeName);
      }

      return (0, _object.get)(this, 'externalRouter').transitionTo((0, _namespaceEngineRouteName.namespaceEngineRouteName)(this._mountPoint, routeName), ...args);
    }

    transitionToExternal(routeName, ...args) {
      return (0, _object.get)(this, 'externalRouter').transitionTo(this.getExternalRouteName(routeName), ...args);
    }

    replaceWith(routeName, ...args) {
      if ((0, _resemblesUrl.resemblesURL)(routeName)) {
        return (0, _object.get)(this, 'externalRouter').replaceWith(routeName);
      }

      return (0, _object.get)(this, 'externalRouter').replaceWith((0, _namespaceEngineRouteName.namespaceEngineRouteName)(this._mountPoint, routeName), ...args);
    }

    replaceWithExternal(routeName, ...args) {
      return (0, _object.get)(this, 'externalRouter').replaceWith(this.getExternalRouteName(routeName), ...args);
    }

    urlFor(routeName, ...args) {
      return (0, _object.get)(this, 'externalRouter').urlFor((0, _namespaceEngineRouteName.namespaceEngineRouteName)(this._mountPoint, routeName), ...args);
    }

    urlForExternal(routeName, ...args) {
      return (0, _object.get)(this, 'externalRouter').urlFor(this.getExternalRouteName(routeName), ...args);
    }

    isActive(routeName, ...args) {
      return (0, _object.get)(this, 'externalRouter').isActive((0, _namespaceEngineRouteName.namespaceEngineRouteName)(this._mountPoint, routeName), ...args);
    }

    isActiveExternal(routeName, ...args) {
      return (0, _object.get)(this, 'externalRouter').isActive(this.getExternalRouteName(routeName), ...args);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onRouteWillChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onRouteWillChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onRouteDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onRouteDidChange"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "rootURL", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentURL", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "currentRouteName", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "currentRouteName"), _class.prototype)), _class));
  _exports.default = EngineRouterService;
});