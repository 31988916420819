define("itarp-workflow-designer/engine", ["exports", "@ember/engine", "ember-load-initializers", "ember-resolver", "itarp-workflow-designer/config/environment"], function (_exports, _engine, _emberLoadInitializers, _emberResolver, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const {
    modulePrefix
  } = _environment.default;

  class YourEngine extends _engine.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "modulePrefix", modulePrefix);

      _defineProperty(this, "Resolver", _emberResolver.default);

      _defineProperty(this, "dependencies", {
        services: ['store', 'appRouter', 'layout', 'toast', 'placeholders', 'router']
      });
    }

  }

  _exports.default = YourEngine;
  (0, _emberLoadInitializers.default)(YourEngine, modulePrefix);
});