define("itarp-opportunity-ui/serializers/invitation", ["exports", "@ember-data/serializer/json", "@ember/string"], function (_exports, _json, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class InvitationSerializer extends _json.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", '_id');
    }

    keyForAttribute(attr, method) {
      return (0, _string.underscore)(attr);
    }

    normalizeQueryResponse(store, modelClass, payload, ...rest) {
      let data = payload ? payload.records : [];
      data = data.map(d => {
        if (d.opportunity && d.opportunity[0]) {
          return { ...d,
            ...d.opportunity[0],
            invitation_id: d._id,
            invitation_status: d.status
          };
        }

        return d;
      });
      data.meta = payload.meta || {};
      return super.normalizeQueryResponse(store, modelClass, data, ...rest);
    }

  }

  _exports.default = InvitationSerializer;
});