define("shared/utils/factory", ["exports", "shared/utils/transform/keyword", "shared/utils/transform/boolean", "shared/utils/transform/text", "shared/utils/transform/year", "shared/utils/transform/number", "shared/utils/transform/range", "shared/utils/transform/geopoint", "shared/utils/transform/array"], function (_exports, _keyword, _boolean, _text, _year, _number, _range, _geopoint, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const factories = {
    keyword: _keyword.default,
    boolean: _boolean.default,
    text: _text.default,
    year: _year.default,
    number: _number.default,
    range: _range.default,
    geopoint: _geopoint.default,
    array: _array.default
  };

  class FiltersFactory {
    create(type, value) {
      const TransformClass = factories[type];
      if (!TransformClass) throw new Error('Unknown filter type');
      return new TransformClass(value);
    }

  }

  _exports.default = FiltersFactory;
});