define("itarp-components/components/ui/table/row", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr class='{{this.rowClass}} {{this.optionalClassList}}'>
    {{#if this.hasMenu}}
      {{yield
        (hash
          cell=(component
            'ui/table/cell'
            className=@class
            options=(hash menu=(component 'ui/table/menu'))
          )
        )
      }}
    {{else}}
      {{yield (hash cell=(component 'ui/table/cell' className=@class))}}
    {{/if}}
  </tr>
  */
  {
    "id": "Bk5yZCnI",
    "block": "[[[10,\"tr\"],[15,0,[29,[[30,0,[\"rowClass\"]],\" \",[30,0,[\"optionalClassList\"]]]]],[12],[1,\"\\n\"],[41,[30,0,[\"hasMenu\"]],[[[1,\"    \"],[18,2,[[28,[37,2],null,[[\"cell\"],[[50,\"ui/table/cell\",0,null,[[\"className\",\"options\"],[[30,1],[28,[37,2],null,[[\"menu\"],[[50,\"ui/table/menu\",0,null,null]]]]]]]]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[18,2,[[28,[37,2],null,[[\"cell\"],[[50,\"ui/table/cell\",0,null,[[\"className\"],[[30,1]]]]]]]]],[1,\"\\n\"]],[]]],[13]],[\"@class\",\"&default\"],false,[\"if\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-components/components/ui/table/row.hbs",
    "isStrictMode": false
  });

  class UiTableRowComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "menuClass", 'with-menu');

      _defineProperty(this, "wideClass", 'with-wider-cells');
    }

    get isWider() {
      return this.args.wide || false;
    }

    get hasMenu() {
      return this.args.menu || false;
    }

    get optionalClassList() {
      let classes = '';

      if (this.hasMenu) {
        classes = classes.concat(this.menuClass);
      }

      if (this.isWider) {
        classes = classes.concat(` ${this.wideClass}`);
      }

      return classes;
    }

    get rowClass() {
      if (this.args.class) return this.args.class;
      return 'table__body__row';
    }

  }

  _exports.default = UiTableRowComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiTableRowComponent);
});