define("itarp-opportunity-designer/controllers/edit-opportunity/scorecards", ["exports", "itarp-opportunity-designer/controllers/opportunity/scorecards"], function (_exports, _scorecards) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class EditOpportunityScorecardsController extends _scorecards.default {}

  _exports.default = EditOpportunityScorecardsController;
});