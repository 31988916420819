define("itarp-workflow-designer/utils/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.UI_ACTIONS = _exports.USER_ROLE_OPTIONS = _exports.USER_ROLE = _exports.WORKFLOW_ARTIFICIAL_COLLECTION = _exports.WORKFLOW_TYPE = _exports.ACTION_TYPE = void 0;
  const ACTION_TYPE = {
    FACTORY_FROM_ARRAY: 'action_factory:from_array',
    FACTORY_CONCAT: 'action_factory:concat',
    SEND_EMAIL: 'send_email'
  };
  _exports.ACTION_TYPE = ACTION_TYPE;
  const WORKFLOW_TYPE = {
    OPPORTUNITY_APPROVAL: 'opportunity_approval',
    TALENTCLOUD_APPROVAL: 'talentcloud_approval',
    OPPORTUNITY_RECRUITING: 'opportunity_recruiting',
    TALENTCLOUD_RECRUITING: 'talentcloud_recruiting'
  };
  _exports.WORKFLOW_TYPE = WORKFLOW_TYPE;
  const WORKFLOW_ARTIFICIAL_COLLECTION = {
    [WORKFLOW_TYPE.OPPORTUNITY_RECRUITING]: 'opportunities',
    [WORKFLOW_TYPE.TALENTCLOUD_RECRUITING]: 'talent_clouds',
    [WORKFLOW_TYPE.TALENTCLOUD_APPROVAL]: 'applications',
    [WORKFLOW_TYPE.OPPORTUNITY_APPROVAL]: 'applications'
  };
  _exports.WORKFLOW_ARTIFICIAL_COLLECTION = WORKFLOW_ARTIFICIAL_COLLECTION;
  const USER_ROLE = {
    SITE_ADMIN: 'site_admin',
    TOD_CLIENT: 'tod_client',
    EXPERFY_STAFF_ADMIN_USER: 'experfy_staff_admin_user',
    EXPERFY_STAFF_USER: 'experfy_staff_user',
    EXPERT: 'expert',
    EXPERFY_SME: 'experfy_sme',
    GUEST_USER: 'guest_user'
  };
  _exports.USER_ROLE = USER_ROLE;
  const USER_ROLE_OPTIONS = [{
    label: 'Site Admin',
    code: USER_ROLE.SITE_ADMIN
  }, {
    label: 'Experfy Staff Admin Users',
    code: USER_ROLE.EXPERFY_STAFF_ADMIN_USER
  }, {
    label: 'Experfy Staff Users',
    code: USER_ROLE.EXPERFY_STAFF_USER
  }];
  _exports.USER_ROLE_OPTIONS = USER_ROLE_OPTIONS;
  const UI_ACTIONS = ['Fit Buttons', 'Shortlist for Interview', 'Chat', 'Send Message', 'Decline'];
  _exports.UI_ACTIONS = UI_ACTIONS;
  var _default = {
    ACTION_TYPE,
    WORKFLOW_TYPE,
    WORKFLOW_ARTIFICIAL_COLLECTION,
    USER_ROLE,
    USER_ROLE_OPTIONS,
    UI_ACTIONS
  };
  _exports.default = _default;
});