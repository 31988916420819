define("itarp-opportunity-ui/components/question/types/travel", ["exports", "@ember/component/template-only"], function (_exports, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class="form-options
      {{if @question.isRequired "is-required"}}
      {{if @isError "has-error"}}"
  >
    <label class="form-options__label" for="{{@question.id}}_travel">
      {{#if @question.isRequired}}
        <span class="label-note label-note--required"></span>
      {{/if}}
      What percentage of time are you willing to travel?
    </label>
    <div class="form-options__elements">
      <div
        class="select-group
          {{if @question.isRequired "is-required"}}
          {{if @isError "has-error"}}"
      >
        <PowerSelect
          @options={{array "1-25%" "26-50%" "51-75%" "76-100%"}}
          @selected={{@value}}
          @placeholder="Select a percentage"
          @onChange={{fn (mut @value)}}
          @disabled={{@disabled}}
          id="{{@question.id}}_travel"
          as |option|
        >
          {{option}}
        </PowerSelect>
      </div>
    </div>
  </div>
  */
  {
    "id": "JF7eoLmE",
    "block": "[[[10,0],[15,0,[29,[\"form-options\\n    \",[52,[30,1,[\"isRequired\"]],\"is-required\"],\"\\n    \",[52,[30,2],\"has-error\"]]]],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"form-options__label\"],[15,\"for\",[29,[[30,1,[\"id\"]],\"_travel\"]]],[12],[1,\"\\n\"],[41,[30,1,[\"isRequired\"]],[[[1,\"      \"],[10,1],[14,0,\"label-note label-note--required\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"    What percentage of time are you willing to travel?\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"form-options__elements\"],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[\"select-group\\n        \",[52,[30,1,[\"isRequired\"]],\"is-required\"],\"\\n        \",[52,[30,2],\"has-error\"]]]],[12],[1,\"\\n      \"],[8,[39,1],[[16,1,[29,[[30,1,[\"id\"]],\"_travel\"]]]],[[\"@options\",\"@selected\",\"@placeholder\",\"@onChange\",\"@disabled\"],[[28,[37,2],[\"1-25%\",\"26-50%\",\"51-75%\",\"76-100%\"],null],[30,3],\"Select a percentage\",[28,[37,3],[[28,[37,4],[[30,3]],null]],null],[30,4]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,5]],[1,\"\\n      \"]],[5]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@question\",\"@isError\",\"@value\",\"@disabled\",\"option\"],false,[\"if\",\"power-select\",\"array\",\"fn\",\"mut\"]]",
    "moduleName": "itarp-opportunity-ui/components/question/types/travel.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});