define("itarp-components/components/ui/grid/column", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='col--12-{{or @size '12'}}' ...attributes>
    {{yield}}
  </div>
  
  */
  {
    "id": "7I7lE8wP",
    "block": "[[[11,0],[16,0,[29,[\"col--12-\",[28,[37,0],[[30,1],\"12\"],null]]]],[17,2],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@size\",\"&attrs\",\"&default\"],false,[\"or\",\"yield\"]]",
    "moduleName": "itarp-components/components/ui/grid/column.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});