define("itarp-business-application/templates/application-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Thss5wE6",
    "block": "[[[10,0],[14,0,\"page-loader\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"page-loader__inner\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"page-loader__content\"],[12],[1,\"\\n      \"],[10,\"svg\"],[14,0,\"whole\"],[14,\"width\",\"100\"],[14,\"height\",\"100\"],[14,\"viewBox\",\"0 0 100 100\"],[14,\"fill\",\"none\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n        \"],[10,\"g\"],[14,1,\"app_loader\"],[12],[1,\"\\n          \"],[10,\"animateTransform\"],[14,\"xlink:href\",\"#app_loader\",\"http://www.w3.org/1999/xlink\"],[14,\"attributeName\",\"transform\"],[14,\"attributeType\",\"XML\"],[14,\"from\",\"0 50 50\"],[14,\"to\",\"360 50 50\"],[14,\"dur\",\"1s\"],[14,\"begin\",\"0s\"],[14,\"repeatCount\",\"indefinite\"],[14,\"restart\",\"always\"],[14,4,\"rotate\"],[12],[13],[1,\"\\n          \"],[10,\"path\"],[14,0,\"a\"],[14,\"fill-rule\",\"evenodd\"],[14,\"clip-rule\",\"evenodd\"],[14,\"d\",\"M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100ZM50 90C72.0914 90 90 72.0914 90 50C90 27.9086 72.0914 10 50 10C27.9086 10 10 27.9086 10 50C10 72.0914 27.9086 90 50 90Z\"],[14,\"fill\",\"#d1dbe3\"],[12],[13],[1,\"\\n          \"],[10,\"path\"],[14,0,\"b\"],[14,\"fill-rule\",\"evenodd\"],[14,\"clip-rule\",\"evenodd\"],[14,\"d\",\"M100 50C100 22.3858 77.6142 0 50 0V10C72.0914 10 90 27.9086 90 50H100Z\"],[14,\"fill\",\"#4ba4da\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"h4\"],[14,0,\"page-loader__text\"],[12],[1,\"Loading...\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "itarp-business-application/templates/application-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});