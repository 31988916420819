define("itarp-components/helpers/is-not-null-or-true", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IsNotNullOrTrueHelper extends _helper.default {
    compute([value]) {
      return value !== null && value !== true;
    }

  }

  _exports.default = IsNotNullOrTrueHelper;
});