define("shared/helpers/date-difference", ["exports", "@ember/component/helper", "moment"], function (_exports, _helper, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateDifference = dateDifference;
  _exports.default = void 0;

  function dateDifference([start, end]) {
    let momentStart = (0, _moment.default)(start).format('YYYYDDMM');
    let momentEnd = (0, _moment.default)(end).format('YYYYDDMM');
    let dateNow = (0, _moment.default)(new Date()).format('YYYYDDMM');
    let months;
    if (momentStart > momentEnd || !momentStart) return '';

    if (!end) {
      months = (0, _moment.default)(dateNow).diff(momentStart, 'months') / 12;
      return Math.floor(months) + ' years ' + Math.floor(months % 1 * 12) + ' months';
    }

    months = (0, _moment.default)(momentEnd).diff(momentStart, 'months') / 12;
    return Math.floor(months) + ' years ' + Math.floor(months % 1 * 12) + ' months';
  }

  var _default = (0, _helper.helper)(dateDifference);

  _exports.default = _default;
});