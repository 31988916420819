define("itarp-components/helpers/countries", ["exports", "@ember/component/helper", "@ember/array", "country-flag-icons/string/3x2", "@ember/template"], function (_exports, _helper, _array, flags, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.countries = void 0;
  const countries = (0, _array.A)([{
    value: 'Afghanistan',
    code: 'AF',
    flag: (0, _template.htmlSafe)(flags.AF)
  }, {
    value: 'Albania',
    code: 'AL',
    flag: (0, _template.htmlSafe)(flags.AL)
  }, {
    value: 'Algeria',
    code: 'DZ',
    flag: (0, _template.htmlSafe)(flags.DZ)
  }, {
    value: 'Andorra',
    code: 'AD',
    flag: (0, _template.htmlSafe)(flags.AD)
  }, {
    value: 'Angola',
    code: 'AO',
    flag: (0, _template.htmlSafe)(flags.AO)
  }, {
    value: 'Anguilla',
    code: 'AI',
    flag: (0, _template.htmlSafe)(flags.AI)
  }, {
    value: 'Antigua & Barbuda',
    code: 'AG',
    flag: (0, _template.htmlSafe)(flags.AG)
  }, {
    value: 'Argentina',
    code: 'AR',
    flag: (0, _template.htmlSafe)(flags.AR)
  }, {
    value: 'Armenia',
    code: 'AM',
    flag: (0, _template.htmlSafe)(flags.AM)
  }, {
    value: 'Aruba',
    code: 'AW',
    flag: (0, _template.htmlSafe)(flags.AW)
  }, {
    value: 'Australia',
    code: 'AU',
    flag: (0, _template.htmlSafe)(flags.AU)
  }, {
    value: 'Austria',
    code: 'AT',
    flag: (0, _template.htmlSafe)(flags.AT)
  }, {
    value: 'Azerbaijan',
    code: 'AZ',
    flag: (0, _template.htmlSafe)(flags.AZ)
  }, {
    value: 'Bahamas',
    code: 'BS',
    flag: (0, _template.htmlSafe)(flags.BS)
  }, {
    value: 'Bahrain',
    code: 'BH',
    flag: (0, _template.htmlSafe)(flags.BH)
  }, {
    value: 'Bangladesh',
    code: 'BD',
    flag: (0, _template.htmlSafe)(flags.BD)
  }, {
    value: 'Barbados',
    code: 'BB',
    flag: (0, _template.htmlSafe)(flags.BB)
  }, {
    value: 'Belarus',
    code: 'BY',
    flag: (0, _template.htmlSafe)(flags.BY)
  }, {
    value: 'Belgium',
    code: 'BE',
    flag: (0, _template.htmlSafe)(flags.BE)
  }, {
    value: 'Belize',
    code: 'BZ',
    flag: (0, _template.htmlSafe)(flags.BZ)
  }, {
    value: 'Benin',
    code: 'BJ',
    flag: (0, _template.htmlSafe)(flags.BJ)
  }, {
    value: 'Bermuda',
    code: 'BM',
    flag: (0, _template.htmlSafe)(flags.BM)
  }, {
    value: 'Bhutan',
    code: 'BT',
    flag: (0, _template.htmlSafe)(flags.BT)
  }, {
    value: 'Bolivia',
    code: 'BO',
    flag: (0, _template.htmlSafe)(flags.BO)
  }, {
    value: 'Bosnia & Herzegovina',
    code: 'BA',
    flag: (0, _template.htmlSafe)(flags.BA)
  }, {
    value: 'Botswana',
    code: 'BW',
    flag: (0, _template.htmlSafe)(flags.BW)
  }, {
    value: 'Brazil',
    code: 'BR',
    flag: (0, _template.htmlSafe)(flags.BR)
  }, {
    value: 'British Virgin Islands',
    code: 'VG',
    flag: (0, _template.htmlSafe)(flags.VG)
  }, {
    value: 'Brunei',
    code: 'BN',
    flag: (0, _template.htmlSafe)(flags.BN)
  }, {
    value: 'Bulgaria',
    code: 'BG',
    flag: (0, _template.htmlSafe)(flags.BG)
  }, {
    value: 'Burkina Faso',
    code: 'BF',
    flag: (0, _template.htmlSafe)(flags.BF)
  }, {
    value: 'Burundi',
    code: 'BI',
    flag: (0, _template.htmlSafe)(flags.BI)
  }, {
    value: 'Cambodia',
    code: 'KH',
    flag: (0, _template.htmlSafe)(flags.KH)
  }, {
    value: 'Cameroon',
    code: 'CM',
    flag: (0, _template.htmlSafe)(flags.CM)
  }, {
    value: 'Canada',
    code: 'CA',
    flag: (0, _template.htmlSafe)(flags.CA)
  }, {
    value: 'Cape Verde',
    code: 'CV',
    flag: (0, _template.htmlSafe)(flags.CV)
  }, {
    value: 'Cayman Islands',
    code: 'KY',
    flag: (0, _template.htmlSafe)(flags.KY)
  }, {
    value: 'Chad',
    code: 'TD',
    flag: (0, _template.htmlSafe)(flags.TD)
  }, {
    value: 'Chile',
    code: 'CL',
    flag: (0, _template.htmlSafe)(flags.CL)
  }, {
    value: 'China',
    code: 'CN',
    flag: (0, _template.htmlSafe)(flags.CN)
  }, {
    value: 'Colombia',
    code: 'CO',
    flag: (0, _template.htmlSafe)(flags.CO)
  }, {
    value: 'Congo',
    code: 'CD',
    flag: (0, _template.htmlSafe)(flags.CD)
  }, {
    value: 'Cook Islands',
    code: 'CK',
    flag: (0, _template.htmlSafe)(flags.CK)
  }, {
    value: 'Costa Rica',
    code: 'CR',
    flag: (0, _template.htmlSafe)(flags.CR)
  }, {
    value: 'Cote D Ivoire',
    code: 'CI',
    flag: (0, _template.htmlSafe)(flags.CI)
  }, {
    value: 'Croatia',
    code: 'HR',
    flag: (0, _template.htmlSafe)(flags.HR)
  }, {
    value: 'Cuba',
    code: 'CU',
    flag: (0, _template.htmlSafe)(flags.CU)
  }, {
    value: 'Cyprus',
    code: 'CY',
    flag: (0, _template.htmlSafe)(flags.CY)
  }, {
    value: 'Czech Republic',
    code: 'CZ',
    flag: (0, _template.htmlSafe)(flags.CZ)
  }, {
    value: 'Denmark',
    code: 'DK',
    flag: (0, _template.htmlSafe)(flags.DK)
  }, {
    value: 'Djibouti',
    code: 'DJ',
    flag: (0, _template.htmlSafe)(flags.DJ)
  }, {
    value: 'Dominica',
    code: 'DM',
    flag: (0, _template.htmlSafe)(flags.DM)
  }, {
    value: 'Dominican Republic',
    code: 'DO',
    flag: (0, _template.htmlSafe)(flags.DO)
  }, {
    value: 'Ecuador',
    code: 'EC',
    flag: (0, _template.htmlSafe)(flags.EC)
  }, {
    value: 'Egypt',
    code: 'EG',
    flag: (0, _template.htmlSafe)(flags.EG)
  }, {
    value: 'El Salvador',
    code: 'SV',
    flag: (0, _template.htmlSafe)(flags.SV)
  }, {
    value: 'Equatorial Guinea',
    code: 'GQ',
    flag: (0, _template.htmlSafe)(flags.GQ)
  }, {
    value: 'Estonia',
    code: 'EE',
    flag: (0, _template.htmlSafe)(flags.EE)
  }, {
    value: 'Ethiopia',
    code: 'ET',
    flag: (0, _template.htmlSafe)(flags.ET)
  }, {
    value: 'Falkland Islands',
    code: 'FK',
    flag: (0, _template.htmlSafe)(flags.FK)
  }, {
    value: 'Faroe Islands',
    code: 'FO',
    flag: (0, _template.htmlSafe)(flags.FO)
  }, {
    value: 'Fiji',
    code: 'FJ',
    flag: (0, _template.htmlSafe)(flags.FJ)
  }, {
    value: 'Finland',
    code: 'FI',
    flag: (0, _template.htmlSafe)(flags.FI)
  }, {
    value: 'France',
    code: 'FR',
    flag: (0, _template.htmlSafe)(flags.FR)
  }, {
    value: 'French Polynesia',
    code: 'PF',
    flag: (0, _template.htmlSafe)(flags.PF)
  }, {
    value: 'Gabon',
    code: 'GA',
    flag: (0, _template.htmlSafe)(flags.GA)
  }, {
    value: 'Gambia',
    code: 'GM',
    flag: (0, _template.htmlSafe)(flags.GM)
  }, {
    value: 'Georgia',
    code: 'GE',
    flag: (0, _template.htmlSafe)(flags.GE)
  }, {
    value: 'Germany',
    code: 'DE',
    flag: (0, _template.htmlSafe)(flags.DE)
  }, {
    value: 'Ghana',
    code: 'GH',
    flag: (0, _template.htmlSafe)(flags.GH)
  }, {
    value: 'Gibraltar',
    code: 'GI',
    flag: (0, _template.htmlSafe)(flags.GI)
  }, {
    value: 'Greece',
    code: 'GR',
    flag: (0, _template.htmlSafe)(flags.GR)
  }, {
    value: 'Greenland',
    code: 'GL',
    flag: (0, _template.htmlSafe)(flags.GL)
  }, {
    value: 'Grenada',
    code: 'GD',
    flag: (0, _template.htmlSafe)(flags.GD)
  }, {
    value: 'Guam',
    code: 'GU',
    flag: (0, _template.htmlSafe)(flags.GU)
  }, {
    value: 'Guatemala',
    code: 'GT',
    flag: (0, _template.htmlSafe)(flags.GT)
  }, {
    value: 'Guernsey',
    code: 'GG',
    flag: (0, _template.htmlSafe)(flags.GG)
  }, {
    value: 'Guinea',
    code: 'GN',
    flag: (0, _template.htmlSafe)(flags.GN)
  }, {
    value: 'Guinea Bissau',
    code: 'GW',
    flag: (0, _template.htmlSafe)(flags.GW)
  }, {
    value: 'Guyana',
    code: 'GY',
    flag: (0, _template.htmlSafe)(flags.GY)
  }, {
    value: 'Haiti',
    code: 'HT',
    flag: (0, _template.htmlSafe)(flags.HT)
  }, {
    value: 'Honduras',
    code: 'HN',
    flag: (0, _template.htmlSafe)(flags.HN)
  }, {
    value: 'Hong Kong',
    code: 'HK',
    flag: (0, _template.htmlSafe)(flags.HK)
  }, {
    value: 'Hungary',
    code: 'HU',
    flag: (0, _template.htmlSafe)(flags.HU)
  }, {
    value: 'Iceland',
    code: 'IS',
    flag: (0, _template.htmlSafe)(flags.IS)
  }, {
    value: 'India',
    code: 'IN',
    flag: (0, _template.htmlSafe)(flags.IN)
  }, {
    value: 'Indonesia',
    code: 'ID',
    flag: (0, _template.htmlSafe)(flags.ID)
  }, {
    value: 'Iran',
    code: 'IR',
    flag: (0, _template.htmlSafe)(flags.IR)
  }, {
    value: 'Iraq',
    code: 'IQ',
    flag: (0, _template.htmlSafe)(flags.IQ)
  }, {
    value: 'Ireland',
    code: 'IE',
    flag: (0, _template.htmlSafe)(flags.IE)
  }, {
    value: 'Isle of Man',
    code: 'IM',
    flag: (0, _template.htmlSafe)(flags.IM)
  }, {
    value: 'Israel',
    code: 'IL',
    flag: (0, _template.htmlSafe)(flags.IL)
  }, {
    value: 'Italy',
    code: 'IT',
    flag: (0, _template.htmlSafe)(flags.IT)
  }, {
    value: 'Jamaica',
    code: 'JM',
    flag: (0, _template.htmlSafe)(flags.JM)
  }, {
    value: 'Japan',
    code: 'JP',
    flag: (0, _template.htmlSafe)(flags.JP)
  }, {
    value: 'Jersey',
    code: 'JE',
    flag: (0, _template.htmlSafe)(flags.JE)
  }, {
    value: 'Jordan',
    code: 'JO',
    flag: (0, _template.htmlSafe)(flags.JO)
  }, {
    value: 'Kazakhstan',
    code: 'KZ',
    flag: (0, _template.htmlSafe)(flags.KZ)
  }, {
    value: 'Kenya',
    code: 'KE',
    flag: (0, _template.htmlSafe)(flags.KE)
  }, {
    value: 'Kuwait',
    code: 'KW',
    flag: (0, _template.htmlSafe)(flags.KW)
  }, {
    value: 'Kyrgyz Republic',
    code: 'KG',
    flag: (0, _template.htmlSafe)(flags.KG)
  }, {
    value: 'Laos',
    code: 'LA',
    flag: (0, _template.htmlSafe)(flags.LA)
  }, {
    value: 'Latvia',
    code: 'LV',
    flag: (0, _template.htmlSafe)(flags.LV)
  }, {
    value: 'Lebanon',
    code: 'LB',
    flag: (0, _template.htmlSafe)(flags.LB)
  }, {
    value: 'Lesotho',
    code: 'LS',
    flag: (0, _template.htmlSafe)(flags.LS)
  }, {
    value: 'Liberia',
    code: 'LR',
    flag: (0, _template.htmlSafe)(flags.LR)
  }, {
    value: 'Libya',
    code: 'LY',
    flag: (0, _template.htmlSafe)(flags.LY)
  }, {
    value: 'Liechtenstein',
    code: 'LI',
    flag: (0, _template.htmlSafe)(flags.LI)
  }, {
    value: 'Lithuania',
    code: 'LT',
    flag: (0, _template.htmlSafe)(flags.LT)
  }, {
    value: 'Luxembourg',
    code: 'LU',
    flag: (0, _template.htmlSafe)(flags.LU)
  }, {
    value: 'Macau',
    code: 'MO',
    flag: (0, _template.htmlSafe)(flags.MO)
  }, {
    value: 'Macedonia',
    code: 'MK',
    flag: (0, _template.htmlSafe)(flags.MK)
  }, {
    value: 'Madagascar',
    code: 'MG',
    flag: (0, _template.htmlSafe)(flags.MG)
  }, {
    value: 'Malawi',
    code: 'MW',
    flag: (0, _template.htmlSafe)(flags.MW)
  }, {
    value: 'Malaysia',
    code: 'MY',
    flag: (0, _template.htmlSafe)(flags.MY)
  }, {
    value: 'Maldives',
    code: 'MV',
    flag: (0, _template.htmlSafe)(flags.MV)
  }, {
    value: 'Mali',
    code: 'ML',
    flag: (0, _template.htmlSafe)(flags.ML)
  }, {
    value: 'Malta',
    code: 'MT',
    flag: (0, _template.htmlSafe)(flags.MT)
  }, {
    value: 'Mauritania',
    code: 'MR',
    flag: (0, _template.htmlSafe)(flags.MR)
  }, {
    value: 'Mauritius',
    code: 'MU',
    flag: (0, _template.htmlSafe)(flags.MU)
  }, {
    value: 'Mexico',
    code: 'MX',
    flag: (0, _template.htmlSafe)(flags.MX)
  }, {
    value: 'Moldova',
    code: 'MD',
    flag: (0, _template.htmlSafe)(flags.MD)
  }, {
    value: 'Monaco',
    code: 'MC',
    flag: (0, _template.htmlSafe)(flags.MC)
  }, {
    value: 'Mongolia',
    code: 'MN',
    flag: (0, _template.htmlSafe)(flags.MN)
  }, {
    value: 'Montenegro',
    code: 'ME',
    flag: (0, _template.htmlSafe)(flags.ME)
  }, {
    value: 'Montserrat',
    code: 'MS',
    flag: (0, _template.htmlSafe)(flags.MS)
  }, {
    value: 'Morocco',
    code: 'MA',
    flag: (0, _template.htmlSafe)(flags.MA)
  }, {
    value: 'Mozambique',
    code: 'MZ',
    flag: (0, _template.htmlSafe)(flags.MZ)
  }, {
    value: 'Namibia',
    code: 'NA',
    flag: (0, _template.htmlSafe)(flags.NA)
  }, {
    value: 'Nepal',
    code: 'NP',
    flag: (0, _template.htmlSafe)(flags.NP)
  }, {
    value: 'Netherlands',
    code: 'NL',
    flag: (0, _template.htmlSafe)(flags.NL)
  }, {
    value: 'New Caledonia',
    code: 'NC',
    flag: (0, _template.htmlSafe)(flags.NC)
  }, {
    value: 'New Zealand',
    code: 'NZ',
    flag: (0, _template.htmlSafe)(flags.NZ)
  }, {
    value: 'Nicaragua',
    code: 'NI',
    flag: (0, _template.htmlSafe)(flags.NI)
  }, {
    value: 'Niger',
    code: 'NE',
    flag: (0, _template.htmlSafe)(flags.NE)
  }, {
    value: 'Nigeria',
    code: 'NG',
    flag: (0, _template.htmlSafe)(flags.NG)
  }, {
    value: 'Norway',
    code: 'NO',
    flag: (0, _template.htmlSafe)(flags.NO)
  }, {
    value: 'Oman',
    code: 'OM',
    flag: (0, _template.htmlSafe)(flags.OM)
  }, {
    value: 'Pakistan',
    code: 'PK',
    flag: (0, _template.htmlSafe)(flags.PK)
  }, {
    value: 'Palestine',
    code: 'PS',
    flag: (0, _template.htmlSafe)(flags.PS)
  }, {
    value: 'Panama',
    code: 'PA',
    flag: (0, _template.htmlSafe)(flags.PA)
  }, {
    value: 'Papua New Guinea',
    code: 'PG',
    flag: (0, _template.htmlSafe)(flags.PG)
  }, {
    value: 'Paraguay',
    code: 'PY',
    flag: (0, _template.htmlSafe)(flags.PY)
  }, {
    value: 'Peru',
    code: 'PE',
    flag: (0, _template.htmlSafe)(flags.PE)
  }, {
    value: 'Philippines',
    code: 'PH',
    flag: (0, _template.htmlSafe)(flags.PH)
  }, {
    value: 'Poland',
    code: 'PL',
    flag: (0, _template.htmlSafe)(flags.PL)
  }, {
    value: 'Portugal',
    code: 'PT',
    flag: (0, _template.htmlSafe)(flags.PT)
  }, {
    value: 'Puerto Rico',
    code: 'PR',
    flag: (0, _template.htmlSafe)(flags.PR)
  }, {
    value: 'Qatar',
    code: 'QA',
    flag: (0, _template.htmlSafe)(flags.QA)
  }, {
    value: 'Reunion',
    code: 'RE',
    flag: (0, _template.htmlSafe)(flags.RE)
  }, {
    value: 'Romania',
    code: 'RO',
    flag: (0, _template.htmlSafe)(flags.RO)
  }, {
    value: 'Russia',
    code: 'RU',
    flag: (0, _template.htmlSafe)(flags.RU)
  }, {
    value: 'Rwanda',
    code: 'RW',
    flag: (0, _template.htmlSafe)(flags.RW)
  }, {
    value: 'Saint Pierre & Miquelon',
    code: 'PM',
    flag: (0, _template.htmlSafe)(flags.PM)
  }, {
    value: 'Samoa',
    code: 'WS',
    flag: (0, _template.htmlSafe)(flags.WS)
  }, {
    value: 'San Marino',
    code: 'SM',
    flag: (0, _template.htmlSafe)(flags.SM)
  }, {
    value: 'Saudi Arabia',
    code: 'SA',
    flag: (0, _template.htmlSafe)(flags.SA)
  }, {
    value: 'Senegal',
    code: 'SN',
    flag: (0, _template.htmlSafe)(flags.SN)
  }, {
    value: 'Serbia',
    code: 'RS',
    flag: (0, _template.htmlSafe)(flags.RS)
  }, {
    value: 'Seychelles',
    code: 'SC',
    flag: (0, _template.htmlSafe)(flags.SC)
  }, {
    value: 'Sierra Leone',
    code: 'SL',
    flag: (0, _template.htmlSafe)(flags.SL)
  }, {
    value: 'Singapore',
    code: 'SG',
    flag: (0, _template.htmlSafe)(flags.SG)
  }, {
    value: 'Slovakia',
    code: 'SK',
    flag: (0, _template.htmlSafe)(flags.SK)
  }, {
    value: 'Slovenia',
    code: 'SI',
    flag: (0, _template.htmlSafe)(flags.SI)
  }, {
    value: 'South Africa',
    code: 'ZA',
    flag: (0, _template.htmlSafe)(flags.ZA)
  }, {
    value: 'South Korea',
    code: 'KR',
    flag: (0, _template.htmlSafe)(flags.KR)
  }, {
    value: 'Spain',
    code: 'ES',
    flag: (0, _template.htmlSafe)(flags.ES)
  }, {
    value: 'Sri Lanka',
    code: 'LK',
    flag: (0, _template.htmlSafe)(flags.LK)
  }, {
    value: 'St Kitts & Nevis',
    code: 'KN',
    flag: (0, _template.htmlSafe)(flags.KN)
  }, {
    value: 'St Lucia',
    code: 'LC',
    flag: (0, _template.htmlSafe)(flags.LC)
  }, {
    value: 'St Vincent',
    code: 'VC',
    flag: (0, _template.htmlSafe)(flags.VC)
  }, {
    value: 'Sudan',
    code: 'SD',
    flag: (0, _template.htmlSafe)(flags.SD)
  }, {
    value: 'Suriname',
    code: 'SR',
    flag: (0, _template.htmlSafe)(flags.SR)
  }, {
    value: 'Swaziland',
    code: 'SZ',
    flag: (0, _template.htmlSafe)(flags.SZ)
  }, {
    value: 'Sweden',
    code: 'SE',
    flag: (0, _template.htmlSafe)(flags.SE)
  }, {
    value: 'Switzerland',
    code: 'CH',
    flag: (0, _template.htmlSafe)(flags.CH)
  }, {
    value: 'Syria',
    code: 'SY',
    flag: (0, _template.htmlSafe)(flags.SY)
  }, {
    value: 'Taiwan',
    code: 'TW',
    flag: (0, _template.htmlSafe)(flags.TW)
  }, {
    value: 'Tajikistan',
    code: 'TJ',
    flag: (0, _template.htmlSafe)(flags.TJ)
  }, {
    value: 'Tanzania',
    code: 'TZ',
    flag: (0, _template.htmlSafe)(flags.TZ)
  }, {
    value: 'Thailand',
    code: 'TH',
    flag: (0, _template.htmlSafe)(flags.TH)
  }, {
    value: "Timor L'Este",
    code: 'TL',
    flag: (0, _template.htmlSafe)(flags.TL)
  }, {
    value: 'Togo',
    code: 'TG',
    flag: (0, _template.htmlSafe)(flags.TG)
  }, {
    value: 'Tonga',
    code: 'TO',
    flag: (0, _template.htmlSafe)(flags.TO)
  }, {
    value: 'Trinidad & Tobago',
    code: 'TT',
    flag: (0, _template.htmlSafe)(flags.TT)
  }, {
    value: 'Tunisia',
    code: 'TN',
    flag: (0, _template.htmlSafe)(flags.TN)
  }, {
    value: 'Turkey',
    code: 'TR',
    flag: (0, _template.htmlSafe)(flags.TR)
  }, {
    value: 'Turkmenistan',
    code: 'TM',
    flag: (0, _template.htmlSafe)(flags.TM)
  }, {
    value: 'Turks & Caicos',
    code: 'TC',
    flag: (0, _template.htmlSafe)(flags.TC)
  }, {
    value: 'Uganda',
    code: 'UG',
    flag: (0, _template.htmlSafe)(flags.UG)
  }, {
    value: 'Ukraine',
    code: 'UA',
    flag: (0, _template.htmlSafe)(flags.UA)
  }, {
    value: 'United Arab Emirates',
    code: 'AE',
    flag: (0, _template.htmlSafe)(flags.AE)
  }, {
    value: 'United Kingdom',
    code: 'GB',
    flag: (0, _template.htmlSafe)(flags.GB)
  }, {
    value: 'United States',
    code: 'US',
    flag: (0, _template.htmlSafe)(flags.US)
  }, {
    value: 'Uruguay',
    code: 'UY',
    flag: (0, _template.htmlSafe)(flags.UY)
  }, {
    value: 'Uzbekistan',
    code: 'UZ',
    flag: (0, _template.htmlSafe)(flags.UZ)
  }, {
    value: 'Venezuela',
    code: 'VE',
    flag: (0, _template.htmlSafe)(flags.VE)
  }, {
    value: 'Vietnam',
    code: 'VN',
    flag: (0, _template.htmlSafe)(flags.VN)
  }, {
    value: 'Virgin Islands (US)',
    code: 'VI',
    flag: (0, _template.htmlSafe)(flags.VI)
  }, {
    value: 'Yemen',
    code: 'YE',
    flag: (0, _template.htmlSafe)(flags.YE)
  }, {
    value: 'Zambia',
    code: 'ZM',
    flag: (0, _template.htmlSafe)(flags.ZM)
  }, {
    value: 'Zimbabwe',
    code: 'ZW',
    flag: (0, _template.htmlSafe)(flags.ZW)
  }]);
  _exports.countries = countries;

  var _default = (0, _helper.helper)(() => countries);

  _exports.default = _default;
});