define("shared/services/store", ["exports", "@ember-data/store", "@ember/application"], function (_exports, _store, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BusinessStore extends _store.default {
    constructor() {
      super(...arguments);
      this.adapter = 'business-application';
    }

    adapterFor() {
      const adapter = super.adapterFor(...arguments);
      return adapter;
    }

    modelFor(name) {
      const owner = (0, _application.getOwner)(this);
      return super.modelFor(...arguments);
    }

  }

  _exports.default = BusinessStore;
});