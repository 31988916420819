define("itarp-business-application/util/schema-manager/schemas/api", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApiSchema {
    constructor(modelSchema, store) {
      this.modelSchema = modelSchema;
      this.collectionName = modelSchema.collectionName;
      this.modelName = modelSchema.modelName;
      this.store = store;
    }

    generate() {
      const modelSchema = this.modelSchema;
      const methods = {
        findAll: {
          method: 'get',
          route: `/${this.collectionName}`,
          args: [],
          type: this.collectionName,
          description: 'find all'
        },
        find: {
          method: 'get',
          route: `/${this.collectionName}/:id`,
          args: [':id'],
          type: this.collectionName,
          description: 'find one'
        },
        create: {
          method: 'post',
          route: `/${this.collectionName}`,
          args: [],
          data: {},
          type: this.collectionName,
          description: 'create'
        },
        update: {
          method: 'patch',
          route: `/${this.collectionName}/:id`,
          args: [':id'],
          data: {},
          type: this.collectionName,
          description: 'update'
        },
        remove: {
          method: 'delete',
          route: `/${this.collectionName}/:id`,
          args: [':id'],
          type: this.collectionName,
          description: 'remove'
        }
      };
      return this.assignMethods(methods);
    }

    assignMethods(definitions) {
      const methodNames = Object.keys(definitions);
      const adapter = this.store.adapterFor('api');
      const initRecords = this.initSchemaRecords.bind(this);
      const methods = {};

      for (const method of methodNames) {
        if (definitions[method].args.length > 0) {
          methods[method] = async function (params) {
            const response = await adapter[method](definitions[method], params);

            if (definitions[method].method === 'get') {
              return initRecords(response);
            }

            return response;
          };
        } else {
          methods[method] = async function () {
            const response = await adapter[method](definitions[method]);

            if (definitions[method].method === 'get') {
              return initRecords(response);
            }
          };
        }
      }

      return methods;
    }

    async initSchemaRecords(data) {
      if (data.records && data.records.length > 0) {
        const records = data.records;
        const result = [];

        for (const record of records) {
          const res = await this.initSingleRecord(record);
          result.push(res);
        }

        return result;
      } else {
        const result = await this.initSingleRecord(data);
        return result;
      }
    }

    async initSingleRecord(data) {
      const modelSchema = this.modelSchema;
      const record = await modelSchema.instantiate(data);
      return record;
    } // generate REST methods for provided model schema and for model schema relations
    // it uses collectionName of model schema for generating REST methods


    static generate(modelSchema, store) {
      return new ApiSchema(modelSchema, store).generate();
    }

  }

  _exports.default = ApiSchema;
});