define("itarp-query-filters/utils/adapter/route", ["exports", "@ember/object", "@glimmer/tracking"], function (_exports, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let RouteAdapter = (_class = class RouteAdapter {
    constructor(route) {
      _defineProperty(this, "defaultParamValues", new Map());

      _defineProperty(this, "route", null);

      this.route = route;
      extractDefaultParamValues.apply(this);
    }

    get name() {
      return this.route.fullRouteName;
    }

    get filters() {
      return this.route.filterItems;
    }

    get paramNames() {
      return Object.keys(this.route.queryParams).filter(key => this.route.queryParams[key].type);
    }

    get paramsSchema() {
      const schema = { ...this.route.queryParams
      };
      Object.keys(schema).forEach(key => {
        if (!schema[key].type) delete schema[key];
      });
      return schema;
    }

    filterTypeFor(name) {
      const parameter = this.route.queryParams[name];
      return parameter === null || parameter === void 0 ? void 0 : parameter.type;
    }

    get controller() {
      return this.route.controllerFor(this.route.routeName);
    }

    defaultValueFor(name) {
      return this.defaultParamValues.get(name);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "filterTypeFor", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "filterTypeFor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "defaultValueFor", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "defaultValueFor"), _class.prototype)), _class);
  _exports.default = RouteAdapter;

  function extractDefaultParamValues() {
    const properties = this.controller.getProperties(this.paramNames);

    for (const [property, value] of Object.entries(properties)) {
      this.defaultParamValues.set(property, value);
    }
  }
});