define("itarp-talentcloud-ui/utils/talent-cloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TalentCloudParams = _exports.DISPLAY_STATUS_ARRAY = _exports.RECORDS_PER_PAGE = _exports.DEFAULT_PAGE_NUMBER = void 0;
  const DEFAULT_PAGE_NUMBER = 1;
  _exports.DEFAULT_PAGE_NUMBER = DEFAULT_PAGE_NUMBER;
  const RECORDS_PER_PAGE = 12;
  _exports.RECORDS_PER_PAGE = RECORDS_PER_PAGE;
  const DISPLAY_STATUS_ARRAY = ['open', 'applied', 'shortlisted', 'screening', 'interviewing', 'assessment', 'offer_pending', 'offer_accepted', 'offer_declined', 'candidate_onboarding', 'job_started', 'rejected', 'closed', 'on_hold', 'approved'];
  _exports.DISPLAY_STATUS_ARRAY = DISPLAY_STATUS_ARRAY;
  const TalentCloudParams = {
    keywords: {
      refreshModel: true,
      type: 'keyword',
      hidden: true
    },
    job_type: {
      refreshModel: true,
      type: 'text',
      key: 'job_type',
      label: '{value}'
    },
    open_to_remote: {
      refreshModel: true,
      type: 'boolean',
      key: 'open_to_remote',
      label: '{value}'
    },
    location: {
      refreshModel: true,
      type: 'geopoint',
      hidden: true,
      key: 'location_coordinates'
    },
    skills: {
      refreshModel: true,
      type: 'keyword',
      key: 'skills',
      multiple: true,
      object: true,
      objectValue: 'value',
      label: '{value}'
    }
  };
  _exports.TalentCloudParams = TalentCloudParams;
});