define("itarp-opportunity-designer/components/question/types/custom", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='component-wrapper has-padding'>
    <div class='card no-header'>
      <div class='card__body'>
        <p class='ts--18 text--bold text--flex text--with-icon for-custom'>
          Add a free form question
        </p>
        <div class='textarea-group mar-t--0-5'>
          <Textarea
            class='textarea-group__input'
            placeholder=''
            rows='3'
            @value={{@question.title}}
            disabled={{@isView}}
          />
        </div>
      </div>
      {{yield}}
    </div>
  </div>
  */
  {
    "id": "NUp6jgVs",
    "block": "[[[10,0],[14,0,\"component-wrapper has-padding\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"card no-header\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"card__body\"],[12],[1,\"\\n      \"],[10,2],[14,0,\"ts--18 text--bold text--flex text--with-icon for-custom\"],[12],[1,\"\\n        Add a free form question\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"textarea-group mar-t--0-5\"],[12],[1,\"\\n        \"],[8,[39,0],[[24,0,\"textarea-group__input\"],[24,\"placeholder\",\"\"],[24,\"rows\",\"3\"],[16,\"disabled\",[30,1]]],[[\"@value\"],[[30,2,[\"title\"]]]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@isView\",\"@question\",\"&default\"],false,[\"textarea\",\"yield\"]]",
    "moduleName": "itarp-opportunity-designer/components/question/types/custom.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});