define("itarp-workflow-designer/controllers/workflow", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/array"], function (_exports, _controller, _tracking, _object, _service, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let WorkflowController = (_class = class WorkflowController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "layout", _descriptor, this);

      _initializerDefineProperty(this, "toast", _descriptor2, this);

      _initializerDefineProperty(this, "appRouter", _descriptor3, this);

      _initializerDefineProperty(this, "openPublishModal", _descriptor4, this);

      _initializerDefineProperty(this, "cancelModalVisible", _descriptor5, this);
    }

    togglePublishModal() {
      this.openPublishModal = !this.openPublishModal;
      this.workflow.isPublished = !!this.openPublishModal;
    }

    get isEditRoute() {
      const routeName = this.appRouter.currentRoute.name;
      return routeName.split('.').lastObject === 'edit-stages';
    }

    async savePublishModal() {
      this.openPublishModal = false;

      try {
        await this.workflow.save({
          adapterOptions: {
            publish: true
          }
        });
        const transition = this.appRouter.transitionTo('itarp-workflow-designer.workflows');
        transition.finally(() => {
          this.toast.show('The publishing process has been started, and you will receive a notification once it is finished.', {
            autoHide: false,
            size: 'lg'
          });
        });
      } catch (e) {
        var _e$errors;

        const items = (e === null || e === void 0 ? void 0 : (_e$errors = e.errors) === null || _e$errors === void 0 ? void 0 : _e$errors.map(err => err.detail.replaceAll('_', ' '))) || [];
        this.workflow.isPublished = false;
        this.toast.show(`Publish failed. ${items}`);
      }
    }

    cancelPublishModal() {
      this.openPublishModal = false;
    }

    unpublishWorkflow() {
      this.openPublishModal = false;
    }

    editWorkflow() {
      const adapter = this.store.adapterFor('workflow');
      const id = this.workflow.id;
      const endpoint = adapter.urlForUnpublishedRevision(id);
      const method = 'PATCH';
      const headers = adapter.headers;
      const body = JSON.stringify(this.workflow.serialize());
      return fetch(endpoint, {
        method,
        headers,
        body
      }).then(response => response.json()).then(result => {
        this.appRouter.transitionTo('itarp-workflow-designer.workflow.edit-stages', result.data.id);
      });
    }

    toggleCancelModal() {
      this.cancelModalVisible = !this.cancelModalVisible;
    }

    closeCancelModal() {
      this.cancelModalVisible = false;
    }

    discardWorkflowChanges() {
      this.cancelModalVisible = false;
      this.appRouter.transitionTo('itarp-workflow-designer.workflows');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "layout", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "appRouter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "openPublishModal", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "cancelModalVisible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "togglePublishModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "togglePublishModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "savePublishModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "savePublishModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelPublishModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancelPublishModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unpublishWorkflow", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "unpublishWorkflow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editWorkflow", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "editWorkflow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleCancelModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleCancelModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeCancelModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeCancelModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "discardWorkflowChanges", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "discardWorkflowChanges"), _class.prototype)), _class);
  _exports.default = WorkflowController;
});