define("itarp-components/components/ui/input/checkbox", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let (unique-id) as |uid|}}
    <div class='checkbox-group'>
      <Input
        id={{uid}}
        class='checkbox-group__field'
        @type='checkbox'
        @checked={{or (eq @value true) (eq @value 'true')}}
        {{on 'input' this.toggle}}
      />
      <label class='checkbox-group__label' for={{uid}}>{{yield}}</label>
    </div>
  {{/let}}
  */
  {
    "id": "dV96f0Uu",
    "block": "[[[44,[[28,[37,1],null,null]],[[[1,\"  \"],[10,0],[14,0,\"checkbox-group\"],[12],[1,\"\\n    \"],[8,[39,2],[[16,1,[30,1]],[24,0,\"checkbox-group__field\"],[4,[38,5],[\"input\",[30,0,[\"toggle\"]]],null]],[[\"@type\",\"@checked\"],[\"checkbox\",[28,[37,3],[[28,[37,4],[[30,2],true],null],[28,[37,4],[[30,2],\"true\"],null]],null]]],null],[1,\"\\n    \"],[10,\"label\"],[14,0,\"checkbox-group__label\"],[15,\"for\",[30,1]],[12],[18,3,null],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]]]],[\"uid\",\"@value\",\"&default\"],false,[\"let\",\"unique-id\",\"input\",\"or\",\"eq\",\"on\",\"yield\"]]",
    "moduleName": "itarp-components/components/ui/input/checkbox.hbs",
    "isStrictMode": false
  });

  let UiInputCheckboxComponent = (_class = class UiInputCheckboxComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "unchecked", _descriptor, this);

      _initializerDefineProperty(this, "hasUnchecked", _descriptor2, this);

      if (Object.keys(this.args).includes('unchecked')) {
        this.unchecked = this.args.unchecked;
        this.hasUnchecked = true;
      }
    }

    toggle() {
      let value = !this.args.value;

      if (value === false && this.hasUnchecked) {
        value = this.unchecked;
      }

      this.args.setValue(value);
      this.args.onChange && this.args.onChange(value);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "unchecked", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hasUnchecked", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype)), _class);
  _exports.default = UiInputCheckboxComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiInputCheckboxComponent);
});