define("ember-engines/components/link-to-component", ["exports", "@ember/routing/link-component", "@ember/application", "@ember/object", "@ember/utils", "@ember/debug"], function (_exports, _linkComponent, _application, _object, _utils, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let LinkTo;
  {
    (false && !(false) && (0, _debug.deprecate)(`Importing from 'ember-engines/components/link-to-component' is deprecated, please use '@ember/routing/link-component' directly`, false, {
      id: 'ember-engines.link-to-override',
      until: '0.9.0',
      for: 'ember-engines',
      since: {
        enabled: '0.8.10'
      }
    }));
    LinkTo = _linkComponent.default;
  }
  var _default = LinkTo;
  _exports.default = _default;
});