define("ember-moment/services/moment", ["exports", "@ember/service", "@ember/object/evented", "@ember/application", "moment-timezone", "@ember/object"], function (_exports, _service, _evented, _application, _momentTimezone, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line ember/no-classic-classes
  var _default = _service.default.extend(_evented.default, {
    _timeZone: null,
    locale: null,
    localeOptions: null,
    defaultFormat: null,
    __config__: (0, _object.computed)(function () {
      let config = (0, _application.getOwner)(this).factoryFor('config:environment').class || {};
      return config.moment || {};
    }).readOnly(),
    timeZone: (0, _object.computed)('_timeZone', {
      get() {
        return this._timeZone;
      },

      set(propertyKey, timeZone) {
        if (!_momentTimezone.default.tz) {
          /* eslint-disable no-console */
          console.warn('[ember-moment] attempted to set timezone, but moment-timezone is not setup.');
          return;
        }

        (0, _object.set)(this, '_timeZone', timeZone);
        return timeZone;
      }

    }),

    setLocale(locale) {
      this.changeLocale(locale);
    },

    updateLocale(locale, localeOptions = {}) {
      this.changeLocale(locale, localeOptions);
    },

    changeLocale(locale, localeOptions = {}) {
      (0, _object.setProperties)(this, {
        locale,
        localeOptions
      });

      _momentTimezone.default.updateLocale(locale, localeOptions);

      this.trigger('localeChanged', locale);
    },

    setTimeZone(timeZone) {
      this.changeTimeZone(timeZone);
    },

    changeTimeZone(timeZone) {
      (0, _object.set)(this, 'timeZone', timeZone);
      this.trigger('timeZoneChanged', timeZone);
    },

    isMoment(obj) {
      return _momentTimezone.default.isMoment(obj);
    },

    moment() {
      let momentObj = (0, _momentTimezone.default)(...arguments);
      let {
        locale,
        timeZone
      } = this;

      if (locale && momentObj.locale) {
        momentObj = momentObj.locale(locale);
      }

      if (timeZone && momentObj.tz) {
        momentObj = momentObj.tz(timeZone);
      }

      return momentObj;
    },

    utc() {
      let momentObj = _momentTimezone.default.utc(...arguments);

      let {
        locale
      } = this;

      if (locale && momentObj.locale) {
        momentObj = momentObj.locale(locale);
      }

      return momentObj;
    }

  });

  _exports.default = _default;
});