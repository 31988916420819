define("itarp-opportunity-designer/routes/details-public/overview-loading", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DetailsPublicOverviewLoadingRoute extends _route.default {}

  _exports.default = DetailsPublicOverviewLoadingRoute;
});