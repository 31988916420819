define("itarp-talentcloud-ui/models/applied-talentcloud", ["exports", "@ember-data/model", "@ember/array", "moment", "@ember/template", "itarp-talentcloud-ui/utils/sortable-questions"], function (_exports, _model, _array, _moment, _template, _sortableQuestions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _dec42, _dec43, _dec44, _dec45, _dec46, _dec47, _dec48, _dec49, _dec50, _dec51, _dec52, _dec53, _dec54, _dec55, _dec56, _dec57, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39, _descriptor40, _descriptor41, _descriptor42, _descriptor43, _descriptor44, _descriptor45, _descriptor46, _descriptor47, _descriptor48, _descriptor49, _descriptor50, _descriptor51, _descriptor52, _descriptor53, _descriptor54, _descriptor55, _descriptor56, _descriptor57;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AppliedTalentcloudModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('date'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('number'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('number'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('boolean'), _dec16 = (0, _model.attr)('boolean'), _dec17 = (0, _model.attr)('number'), _dec18 = (0, _model.attr)('number'), _dec19 = (0, _model.attr)('number'), _dec20 = (0, _model.attr)('number'), _dec21 = (0, _model.attr)('string'), _dec22 = (0, _model.attr)('string'), _dec23 = (0, _model.attr)('string'), _dec24 = (0, _model.attr)('string'), _dec25 = (0, _model.attr)('string'), _dec26 = (0, _model.attr)('string'), _dec27 = (0, _model.attr)('string'), _dec28 = (0, _model.attr)('string'), _dec29 = (0, _model.attr)('number'), _dec30 = (0, _model.attr)('date'), _dec31 = (0, _model.attr)('string'), _dec32 = (0, _model.attr)('string', {
    defaultValue: 'published'
  }), _dec33 = (0, _model.belongsTo)('user'), _dec34 = (0, _model.attr)('string'), _dec35 = (0, _model.attr)('string'), _dec36 = (0, _model.attr)(), _dec37 = (0, _model.attr)('number'), _dec38 = (0, _model.attr)('number'), _dec39 = (0, _model.attr)('string'), _dec40 = (0, _model.attr)('string'), _dec41 = (0, _model.attr)('string'), _dec42 = (0, _model.attr)('boolean'), _dec43 = (0, _model.attr)('boolean'), _dec44 = (0, _model.attr)('boolean'), _dec45 = (0, _model.attr)('boolean'), _dec46 = (0, _model.attr)('boolean'), _dec47 = (0, _model.attr)('boolean'), _dec48 = (0, _model.attr)('boolean'), _dec49 = (0, _model.attr)('boolean'), _dec50 = (0, _model.attr)('boolean'), _dec51 = (0, _model.attr)('boolean'), _dec52 = (0, _model.attr)({
    defaultValue() {
      return (0, _array.A)([]);
    }

  }), _dec53 = (0, _model.attr)({
    defaultValue() {
      return (0, _array.A)([]);
    }

  }), _dec54 = (0, _model.attr)({
    defaultValue() {
      return (0, _array.A)([]);
    }

  }), _dec55 = (0, _model.attr)({
    defaultValue() {
      return (0, _array.A)([]);
    }

  }), _dec56 = (0, _model.attr)({
    defaultValue() {
      return (0, _array.A)([]);
    }

  }), _dec57 = (0, _model.attr)('sortable', {
    defaultValue() {
      return _sortableQuestions.default.create({
        content: (0, _array.A)([])
      });
    }

  }), (_class = class AppliedTalentcloudModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "talentcloudId", _descriptor, this);

      _initializerDefineProperty(this, "applicationId", _descriptor2, this);

      _initializerDefineProperty(this, "message", _descriptor3, this);

      _initializerDefineProperty(this, "createdAt", _descriptor4, this);

      _initializerDefineProperty(this, "name", _descriptor5, this);

      _initializerDefineProperty(this, "companyId", _descriptor6, this);

      _initializerDefineProperty(this, "description", _descriptor7, this);

      _initializerDefineProperty(this, "expectedHires", _descriptor8, this);

      _initializerDefineProperty(this, "owner_id", _descriptor9, this);

      _initializerDefineProperty(this, "targetSize", _descriptor10, this);

      _initializerDefineProperty(this, "preferredCountryCode", _descriptor11, this);

      _initializerDefineProperty(this, "preferredCity", _descriptor12, this);

      _initializerDefineProperty(this, "preferredCountry", _descriptor13, this);

      _initializerDefineProperty(this, "preferredState", _descriptor14, this);

      _initializerDefineProperty(this, "isGlobal", _descriptor15, this);

      _initializerDefineProperty(this, "openToRemote", _descriptor16, this);

      _initializerDefineProperty(this, "hourlyMinRate", _descriptor17, this);

      _initializerDefineProperty(this, "hourlyMaxRate", _descriptor18, this);

      _initializerDefineProperty(this, "annualMinSalary", _descriptor19, this);

      _initializerDefineProperty(this, "annualMaxSalary", _descriptor20, this);

      _initializerDefineProperty(this, "workflowId", _descriptor21, this);

      _initializerDefineProperty(this, "workflowApprovalId", _descriptor22, this);

      _initializerDefineProperty(this, "workflowApprovalStageId", _descriptor23, this);

      _initializerDefineProperty(this, "expertBannerTitle", _descriptor24, this);

      _initializerDefineProperty(this, "expertBannerColor", _descriptor25, this);

      _initializerDefineProperty(this, "expertBannerDescription", _descriptor26, this);

      _initializerDefineProperty(this, "expertBannerTitleColor", _descriptor27, this);

      _initializerDefineProperty(this, "tenantId", _descriptor28, this);

      _initializerDefineProperty(this, "expectedHires", _descriptor29, this);

      _initializerDefineProperty(this, "createdAt", _descriptor30, this);

      _initializerDefineProperty(this, "compensationType", _descriptor31, this);

      _initializerDefineProperty(this, "status", _descriptor32, this);

      _initializerDefineProperty(this, "owner", _descriptor33, this);

      _initializerDefineProperty(this, "idealCandidateLocation", _descriptor34, this);

      _initializerDefineProperty(this, "openToRemote", _descriptor35, this);

      _initializerDefineProperty(this, "travelRequirement", _descriptor36, this);

      _initializerDefineProperty(this, "minRate", _descriptor37, this);

      _initializerDefineProperty(this, "maxRate", _descriptor38, this);

      _initializerDefineProperty(this, "workflowId", _descriptor39, this);

      _initializerDefineProperty(this, "workflowApprovalId", _descriptor40, this);

      _initializerDefineProperty(this, "workflowApprovalStageId", _descriptor41, this);

      _initializerDefineProperty(this, "contractor", _descriptor42, this);

      _initializerDefineProperty(this, "partTimeAvailable", _descriptor43, this);

      _initializerDefineProperty(this, "fullTimeAvailable", _descriptor44, this);

      _initializerDefineProperty(this, "anyTimePreference", _descriptor45, this);

      _initializerDefineProperty(this, "officeHours", _descriptor46, this);

      _initializerDefineProperty(this, "outsideOfficeHours", _descriptor47, this);

      _initializerDefineProperty(this, "flexible", _descriptor48, this);

      _initializerDefineProperty(this, "betweenPartFullTimeAvailable", _descriptor49, this);

      _initializerDefineProperty(this, "partTimePreference", _descriptor50, this);

      _initializerDefineProperty(this, "fullTimePreference", _descriptor51, this);

      _initializerDefineProperty(this, "experienceLevel", _descriptor52, this);

      _initializerDefineProperty(this, "skills", _descriptor53, this);

      _initializerDefineProperty(this, "tools", _descriptor54, this);

      _initializerDefineProperty(this, "roles", _descriptor55, this);

      _initializerDefineProperty(this, "practiceAreas", _descriptor56, this);

      _initializerDefineProperty(this, "questions", _descriptor57, this);
    }

    get safeDescription() {
      if (this.description) {
        return (0, _template.htmlSafe)(this.description);
      }
    }

    get truncatedSafeDescription() {
      if (this.description) {
        return (0, _template.htmlSafe)(this.truncate(this.description));
      }
    }

    get jobType() {
      let workType = [];
      const data = {
        contractor: 'Contractor',
        partTimeAvailable: 'Part-time Permanent',
        fullTimeAvailable: 'Full-time Permanent',
        anyTimePreference: 'Any'
      };
      ['contractor', 'partTimeAvailable', 'fullTimeAvailable', 'anyTimePreference'].forEach(i => {
        if (this[i]) {
          workType = [...workType, data[i]];
        }
      });
      return workType.join(', ');
    }

    get workingHours() {
      let workHour = [];
      const data = {
        officeHours: 'Office Hours',
        outsideOfficeHours: 'Outside Office Hours',
        flexible: 'Flexible'
      };
      ['officeHours', 'outsideOfficeHours', 'flexible'].forEach(i => {
        if (this[i]) {
          workHour = [...workHour, data[i]];
        }
      });
      return workHour.join(', ');
    }

    get timeCommitment() {
      let minHour = [];
      const data = {
        betweenPartFullTimeAvailable: '0-20',
        partTimePreference: '20-40',
        fullTimePreference: '40+'
      };
      ['betweenPartFullTimeAvailable', 'partTimePreference', 'fullTimePreference'].forEach(i => {
        if (this[i]) {
          minHour = [...minHour, data[i]];
        }
      });
      return minHour.join(', ');
    }

    truncate(description) {
      return description.length > 400 ? description.slice(0, 400 - 1) + '…' : description;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "talentcloudId", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "applicationId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "message", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "companyId", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "expectedHires", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "owner_id", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "targetSize", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "preferredCountryCode", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "preferredCity", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "preferredCountry", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "preferredState", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "isGlobal", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "openToRemote", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "hourlyMinRate", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "hourlyMaxRate", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "annualMinSalary", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "annualMaxSalary", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "workflowId", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "workflowApprovalId", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "workflowApprovalStageId", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "expertBannerTitle", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "expertBannerColor", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "expertBannerDescription", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "expertBannerTitleColor", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "tenantId", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "expectedHires", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "compensationType", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "owner", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "idealCandidateLocation", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "openToRemote", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "travelRequirement", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "minRate", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = _applyDecoratedDescriptor(_class.prototype, "maxRate", [_dec38], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = _applyDecoratedDescriptor(_class.prototype, "workflowId", [_dec39], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor40 = _applyDecoratedDescriptor(_class.prototype, "workflowApprovalId", [_dec40], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor41 = _applyDecoratedDescriptor(_class.prototype, "workflowApprovalStageId", [_dec41], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor42 = _applyDecoratedDescriptor(_class.prototype, "contractor", [_dec42], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor43 = _applyDecoratedDescriptor(_class.prototype, "partTimeAvailable", [_dec43], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor44 = _applyDecoratedDescriptor(_class.prototype, "fullTimeAvailable", [_dec44], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor45 = _applyDecoratedDescriptor(_class.prototype, "anyTimePreference", [_dec45], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor46 = _applyDecoratedDescriptor(_class.prototype, "officeHours", [_dec46], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor47 = _applyDecoratedDescriptor(_class.prototype, "outsideOfficeHours", [_dec47], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor48 = _applyDecoratedDescriptor(_class.prototype, "flexible", [_dec48], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor49 = _applyDecoratedDescriptor(_class.prototype, "betweenPartFullTimeAvailable", [_dec49], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor50 = _applyDecoratedDescriptor(_class.prototype, "partTimePreference", [_dec50], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor51 = _applyDecoratedDescriptor(_class.prototype, "fullTimePreference", [_dec51], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor52 = _applyDecoratedDescriptor(_class.prototype, "experienceLevel", [_dec52], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor53 = _applyDecoratedDescriptor(_class.prototype, "skills", [_dec53], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor54 = _applyDecoratedDescriptor(_class.prototype, "tools", [_dec54], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor55 = _applyDecoratedDescriptor(_class.prototype, "roles", [_dec55], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor56 = _applyDecoratedDescriptor(_class.prototype, "practiceAreas", [_dec56], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor57 = _applyDecoratedDescriptor(_class.prototype, "questions", [_dec57], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AppliedTalentcloudModel;
});