define("itarp-opportunity-ui/adapters/applied-opportunity", ["exports", "itarp-auth-addon/adapters/ad-token-adapter"], function (_exports, _adTokenAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AppliedOpportunityAdapter extends _adTokenAdapter.default {
    get headers() {
      const result = super.headers;
      return result;
    }

    urlForQuery(query) {
      if (query.adapterOptions.userApplied) {
        delete query.adapterOptions.userApplied;
        return '/api/expert/api/opportunity/data-service/expert/applied_opportunities';
      }

      return super.urlForFindAll(...arguments);
    }

  }

  _exports.default = AppliedOpportunityAdapter;
});