define("itarp-components/components/templates/filter-layout", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class='designer__content__body no-sp has-filters
      {{if this.filtersExpanded 'filters-expanded' 'filters-collapsed'}}'
    ...attributes
  >
    {{yield
      (hash
        filters=(component
          'templates/filter-layout/filters'
          expanded=this.filtersExpanded
          toggle=this.toggleFilters
          fixed=this.isFixed
          sidebar=this.layout.sidebarCollapsed
          sidebarHidden=@sidebarHidden
        )
        content=(component 'templates/filter-layout/content')
      )
    }}
  </div>
  */
  {
    "id": "x3F3bhPX",
    "block": "[[[11,0],[16,0,[29,[\"designer__content__body no-sp has-filters\\n    \",[52,[30,0,[\"filtersExpanded\"]],\"filters-expanded\",\"filters-collapsed\"]]]],[17,1],[12],[1,\"\\n  \"],[18,3,[[28,[37,2],null,[[\"filters\",\"content\"],[[50,\"templates/filter-layout/filters\",0,null,[[\"expanded\",\"toggle\",\"fixed\",\"sidebar\",\"sidebarHidden\"],[[30,0,[\"filtersExpanded\"]],[30,0,[\"toggleFilters\"]],[30,0,[\"isFixed\"]],[30,0,[\"layout\",\"sidebarCollapsed\"]],[30,2]]]],[50,\"templates/filter-layout/content\",0,null,null]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@sidebarHidden\",\"&default\"],false,[\"if\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-components/components/templates/filter-layout.hbs",
    "isStrictMode": false
  });

  let TemplatesFilterLayoutComponent = (_class = class TemplatesFilterLayoutComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "layout", _descriptor, this);

      _initializerDefineProperty(this, "filtersExpanded", _descriptor2, this);

      _initializerDefineProperty(this, "isFixed", _descriptor3, this);

      if (this.args.initiallyExpanded) this.filtersExpanded = true;
      window.addEventListener('scroll', () => {
        let scrollHeight;
        this.args.sidebarHidden ? scrollHeight = 113 : scrollHeight = 382;
        const designerBodyFilters = document.getElementsByClassName('designer__body__filters')[0];

        if (designerBodyFilters) {
          if (scrollY <= scrollHeight) {
            this.isFixed = false;
            designerBodyFilters.scrollTop = 0;
          } else if (scrollY > scrollHeight) {
            this.isFixed = true;
          }
        }
      });
    }

    toggleFilters() {
      this.filtersExpanded = !this.filtersExpanded;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "layout", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "filtersExpanded", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isFixed", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleFilters", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleFilters"), _class.prototype)), _class);
  _exports.default = TemplatesFilterLayoutComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TemplatesFilterLayoutComponent);
});