define("itarp-components/components/ui/input/label", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label class='{{@class}}' for='{{@for}}' ...attributes>
    {{#if @required}}
      <span class='label-note label-note--required'></span>
    {{/if}}
    {{yield}}
  </label>
  
  */
  {
    "id": "V5ABtMH4",
    "block": "[[[11,\"label\"],[16,0,[29,[[30,1]]]],[16,\"for\",[29,[[30,2]]]],[17,3],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"    \"],[10,1],[14,0,\"label-note label-note--required\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[18,5,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"@for\",\"&attrs\",\"@required\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "itarp-components/components/ui/input/label.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});