define("itarp-opportunity-designer/serializers/roster-snapshot", ["exports", "itarp-shared-assets-addon/serializers/data", "@ember-data/serializer/rest"], function (_exports, _data, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class RosterSnapshotSerializer extends _data.default.extend(_rest.EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        experts: {
          embedded: 'always'
        },
        sharer: {
          serialize: false
        }
      });
    }

    shouldSerializeHasMany() {
      return false;
    }

    normalize(modelClass, payload) {
      if (payload.data) {
        return super.normalize(modelClass, payload.data);
      }

      if (payload.roster_users && payload.roster_users.length > 0) {
        payload.experts = payload.roster_users;
      }

      return super.normalize(...arguments);
    }

    serialize(snapshot, options) {
      let json = super.serialize(snapshot, options);

      if (json.roster_snapshots.experts) {
        delete json.roster_snapshots.experts;
      }

      return json;
    }

  }

  _exports.default = RosterSnapshotSerializer;
});