define("itarp-opportunity-ui/serializers/applied-opportunity", ["exports", "@ember-data/serializer/json", "@ember/string"], function (_exports, _json, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class AppliedOpportunitySerializer extends _json.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", 'application_id');
    }

    keyForAttribute(attr, method) {
      return (0, _string.underscore)(attr);
    }

    normalizeQueryResponse(store, modelClass, payload, ...rest) {
      let data = payload && payload.opportunity_applications ? payload.opportunity_applications.records : [];
      data = data.map(d => {
        var _d$opportunity$;

        return {
          application_id: d._id,
          ...d.opportunity[0],
          opportunity_id: (_d$opportunity$ = d.opportunity[0]) === null || _d$opportunity$ === void 0 ? void 0 : _d$opportunity$._id
        };
      });
      return super.normalizeQueryResponse(store, modelClass, data, ...rest);
    }

  }

  _exports.default = AppliedOpportunitySerializer;
});