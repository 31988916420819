define("ember-bootstrap/components/bs-form/element/layout/inline/checkbox", ["exports", "@ember/component", "@ember/template-factory", "ember-bootstrap/components/bs-form/element/layout/inline"], function (_exports, _component, _templateFactory, _inline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="{{if (bs-eq "switch" @controlType) (if (macroCondition (macroGetOwnConfig "isBS5")) "form-check form-switch" "custom-control custom-switch") "form-check"}}">
    {{yield}}
    <@labelComponent/>
    <@errorsComponent/>
    <@helpTextComponent/>
  </div>
  
  */
  {
    "id": "1W8ppERO",
    "block": "[[[10,0],[15,0,[29,[[52,[28,[37,1],[\"switch\",[30,1]],null],\"custom-control custom-switch\",\"form-check\"]]]],[12],[1,\"\\n  \"],[18,5,null],[1,\"\\n  \"],[8,[30,2],null,null,null],[1,\"\\n  \"],[8,[30,3],null,null,null],[1,\"\\n  \"],[8,[30,4],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@controlType\",\"@labelComponent\",\"@errorsComponent\",\"@helpTextComponent\",\"&default\"],false,[\"if\",\"bs-eq\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-form/element/layout/inline/checkbox.hbs",
    "isStrictMode": false
  });

  /**
  
   @class FormElementLayoutInlineCheckbox
   @namespace Components
   @extends Components.FormElementLayout
   @private
   */
  class FormElementLayoutInlineCheckbox extends _inline.default {}

  _exports.default = FormElementLayoutInlineCheckbox;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormElementLayoutInlineCheckbox);
});