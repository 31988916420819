define("@onehilltech/ember-cli-google-analytics/services/gtag", ["exports", "@ember/service", "@ember/application", "@ember/object"], function (_exports, _service, _application, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class GtagService extends _service.default {
    constructor() {
      super(...arguments); // Let's make sure there is a dataLayer variable present.

      window.dataLayer = window.dataLayer || [];
      const ENV = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      const {
        measurementId
      } = (0, _object.getWithDefault)(ENV, 'ember-cli-google.analytics', {});
      this.push('js', new Date());
      this.push('config', measurementId);
    }

    push() {
      window.dataLayer.push(arguments);
    }

    event(name, params) {
      this.push({
        event: name,
        ...params
      });
    }

  }

  _exports.default = GtagService;
});