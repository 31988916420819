define("itarp-opportunity-designer/helpers/object-at", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.objectAt = objectAt;
  _exports.default = void 0;

  function objectAt(index, array) {
    if (!Array.isArray(array) || index === undefined || index === null) {
      return undefined;
    }

    index = parseInt(index, 10);
    if (array.objectAt) return array.objectAt(index);
  }

  var _default = (0, _helper.helper)(function ([index, array]) {
    return objectAt(index, array);
  });

  _exports.default = _default;
});