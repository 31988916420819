define("itarp-shared-assets-addon/helpers/unique-id", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(uniqueId); // Copy-pasted from the ember-source implementation: https://github.com/emberjs/ember.js/pull/19882


  _exports.default = _default;

  function uniqueId() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, a => (a ^ Math.random() * 16 >> a / 4).toString(16));
  }
});