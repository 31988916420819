define("shared/helpers/contains", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Contains extends _helper.default {
    compute([options, target]) {
      return options && options.includes(target);
    }

  }

  _exports.default = Contains;
});