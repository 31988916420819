define("ember-bootstrap/utils/is-fastboot", ["exports", "@ember/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isFastBoot;

  function isFastBoot(context) {
    let owner = (0, _application.getOwner)(context);
    let fastbootService = owner.lookup('service:fastboot');
    return fastbootService ? fastbootService.get('isFastBoot') : false;
  }
});