define("itarp-opportunity-ui/helpers/any", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AnyHelper extends _helper.default {
    compute([array, value]) {
      return array.includes(value);
    }

  }

  _exports.default = AnyHelper;
});