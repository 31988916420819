define("itarp-opportunity-designer/controllers/edit-opportunity/work-arrangement", ["exports", "itarp-opportunity-designer/controllers/opportunity/work-arrangement"], function (_exports, _workArrangement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class EditOpportunityWorkArrangementController extends _workArrangement.default {}

  _exports.default = EditOpportunityWorkArrangementController;
});