define("itarp-shared-assets-addon/components/ui/offscreen/body/nav", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='offscreen-preview__body__nav'>
    <div class='tabs'>
      <div class='tabs__list no-border'>
        {{yield}}
      </div>
    </div>
  </div>
  */
  {
    "id": "YjbCiqpZ",
    "block": "[[[10,0],[14,0,\"offscreen-preview__body__nav\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"tabs\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"tabs__list no-border\"],[12],[1,\"\\n      \"],[18,1,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "itarp-shared-assets-addon/components/ui/offscreen/body/nav.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});