define("@love-open-source/ember-slider/components/slider-handle", ["exports", "@ember/component", "@love-open-source/ember-slider/templates/components/slider-handle"], function (_exports, _component, _sliderHandle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _sliderHandle.default,
    classNames: 'slider-handle',

    onMove() {},

    panStart() {
      this.onStart(event);
    }

  });

  _exports.default = _default;
});