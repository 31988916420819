define("itarp-components/components/filters/location", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/service", "itarp-components/helpers/countries", "@ember/runloop", "itarp-components/utils/constants/google-api", "@ember/object/observers"], function (_exports, _component, _templateFactory, _component2, _object, _tracking, _service, _countries, _runloop, _googleApi, _observers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='row'>
    <div class='col--12-12'>
      <div class='component-wrapper has-padding'>
        <div class='form-group form-group--separated form-group--rows'>
          <label class='form-group__label'>Location</label>
          <div class='form-group__elements'>
            <div class='form-group__select country-select'>
              <PowerSelect
                @tagName='div'
                @dropdownClass='country-select__menu'
                @selected={{this.country}}
                @options={{(countries)}}
                @onChange={{this.selectCountry}}
                @placeholder='Select Country'
                @searchField='value'
                as |country|
              >
                {{country.flag}}
                {{country.value}}
              </PowerSelect>
            </div>
            <input
              type='text'
              class='input-group__field'
              placeholder='Enter City, State or Zip '
              id='google-search'
              autocomplete='false'
              value={{this.value}}
              {{did-insert this.setInput}}
              {{on 'change' this.validateInput}}
            />
          </div>
          <span class='fieldset-note'>
            Type any city, zip code, or address you'd like
          </span>
        </div>
      </div>
    </div>
  </div>
  */
  {
    "id": "BJslzu7h",
    "block": "[[[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"col--12-12\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"component-wrapper has-padding\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"form-group form-group--separated form-group--rows\"],[12],[1,\"\\n        \"],[10,\"label\"],[14,0,\"form-group__label\"],[12],[1,\"Location\"],[13],[1,\"\\n        \"],[10,0],[14,0,\"form-group__elements\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"form-group__select country-select\"],[12],[1,\"\\n            \"],[8,[39,0],null,[[\"@tagName\",\"@dropdownClass\",\"@selected\",\"@options\",\"@onChange\",\"@placeholder\",\"@searchField\"],[\"div\",\"country-select__menu\",[30,0,[\"country\"]],[28,[37,1],null,null],[30,0,[\"selectCountry\"]],\"Select Country\",\"value\"]],[[\"default\"],[[[[1,\"\\n              \"],[1,[30,1,[\"flag\"]]],[1,\"\\n              \"],[1,[30,1,[\"value\"]]],[1,\"\\n            \"]],[1]]]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[11,\"input\"],[24,0,\"input-group__field\"],[24,\"placeholder\",\"Enter City, State or Zip \"],[24,1,\"google-search\"],[24,\"autocomplete\",\"false\"],[16,2,[30,0,[\"value\"]]],[24,4,\"text\"],[4,[38,2],[[30,0,[\"setInput\"]]],null],[4,[38,3],[\"change\",[30,0,[\"validateInput\"]]],null],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,1],[14,0,\"fieldset-note\"],[12],[1,\"\\n          Type any city, zip code, or address you'd like\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"country\"],false,[\"power-select\",\"countries\",\"did-insert\",\"on\"]]",
    "moduleName": "itarp-components/components/filters/location.hbs",
    "isStrictMode": false
  });

  let FiltersLocationComponent = (_dec = (0, _service.inject)('search'), (_class = class FiltersLocationComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "search", _descriptor, this);

      _initializerDefineProperty(this, "input", _descriptor2, this);

      _initializerDefineProperty(this, "value", _descriptor3, this);

      _initializerDefineProperty(this, "country", _descriptor4, this);

      if (this.args.place && !this.value) {
        this.setLocation();
      } else {
        this.attachAutoComplete();
      }

      (0, _observers.addObserver)(this, 'args.place', this, 'observePlaceChange');
    }

    get resultFields() {
      return [_googleApi.PLACE.FIELDS.LOCATION, _googleApi.PLACE.FIELDS.ADDR, _googleApi.PLACE.FIELDS.PRETTY_ADDR, _googleApi.PLACE.FIELDS.PLACE_ID];
    }

    setInput(input) {
      this.input = input;
    }

    validateInput() {
      if (this.value && this.input.value !== this.value) {
        this.input.value = this.value;
      }
    }

    observePlaceChange() {
      const place = this.args.place;
      if (!place) this.value = null;
    }

    setLocation() {
      const placeId = this.args.place;
      this.search.placeDetailsById(placeId, this.resultFields).then(result => {
        if (result) {
          const countryAddr = this.search.getComponent(result.address_components, _googleApi.ADDR_CPNT.COUNTRY);

          if (countryAddr) {
            let country = _countries.countries.findBy('code', countryAddr.short_name);

            country ||= _countries.countries.findBy('value', countryAddr.long_name);
            this.country = country;
          }

          this.value = result[_googleApi.PLACE.FIELDS.PRETTY_ADDR];
          this.selectSuggestion(result);
        } else {
          this.country = _countries.countries.findBy('code', 'US');
        }

        this.attachAutoComplete();
      });
    }

    attachAutoComplete() {
      (0, _runloop.later)(this, () => {
        const options = {
          onSelect: this.selectSuggestion,
          components: [_googleApi.ADDR_CPNT.GEO],
          fields: this.resultFields
        };
        this.search.initAutocomplete(this.input, options);
      });
    }

    async selectSuggestion(place) {
      if (this.input.value) this.value = this.input.value;
      const location = place.geometry.location.toJSON();
      const value = {
        lat: location.lat,
        long: location.lng
      };
      this.update(value, place.place_id);
    }

    update(location, place) {
      this.args.setValue(location);
      this.args.setPlace(place);
      this.args.onChange && this.args.onChange();
    }

    selectCountry(country) {
      this.country = country;
      this.search.setAutocompleteCountry(this.country.code);
      this.input.value = '';
      this.update(null, null);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "search", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "input", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "country", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validateInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "observePlaceChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "observePlaceChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setLocation", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setLocation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "attachAutoComplete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "attachAutoComplete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectSuggestion", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectSuggestion"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "update", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectCountry", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectCountry"), _class.prototype)), _class));
  _exports.default = FiltersLocationComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FiltersLocationComponent);
});