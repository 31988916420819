define("ember-modal-dialog/templates/components/in-place-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OHd/XjJe",
    "block": "[[[10,0],[15,0,[28,[37,0],[[33,1],\" \",[33,2],\" \",[33,3]],null]],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"concat\",\"containerClassNamesString\",\"attachmentClass\",\"containerClass\",\"yield\"]]",
    "moduleName": "ember-modal-dialog/templates/components/in-place-dialog.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});