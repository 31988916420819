define("itarp-expert-preview/adapters/ad-token-adapter", ["exports", "itarp-auth-addon/adapters/ad-token-adapter"], function (_exports, _adTokenAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AdTokenAdapter extends _adTokenAdapter.default {}

  _exports.default = AdTokenAdapter;
});