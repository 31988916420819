define("itarp-opportunity-designer/adapters/proposal", ["exports", "itarp-auth-addon/adapters/ad-token-data"], function (_exports, _adTokenData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ProposalAdapter extends _adTokenData.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", '/opportunity/data_service');

      _defineProperty(this, "pathForType", () => 'applications');
    }

    urlForQuery(options) {
      if (options.opportunityCandidates) {
        delete options.opportunityCandidates;
        return `${this.namespace}/opportunities/applicants`;
      } else if (options.appliedTo) {
        const {
          userId,
          appliedTo
        } = options;
        delete options.appliedTo;
        delete options.userId;

        if (appliedTo === 'talent_cloud') {
          return `/request-talent_clouds/routing/data_service/expert/${userId}/applied_talent_clouds`;
        }

        return `${this.namespace}/expert/${userId}/applied_opportunities`;
      } else if (options.approvedApplications) {
        const {
          expertId
        } = options;
        delete options.approvedApplications;
        delete options.expertId;
        return `${this.namespace}/expert/${expertId}/approved_applications`;
      }

      const {
        opportunity
      } = options;
      delete options.opportunity;
      return `${this.namespace}/opportunities/${opportunity}/application_experts`;
    }

  }

  _exports.default = ProposalAdapter;
});