define("itarp-business-application/templates/dynamic-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tzSjeQ2N",
    "block": "[[[1,[28,[35,0],[[30,0,[\"title\"]]],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"content pad-t--1 pad-l--1 pad-r--1 pad-b--1\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"model\",\"children\"]]],null]],null],null,[[[1,\"    \"],[8,[39,3],null,[[\"@element\",\"@observer\",\"@submit\",\"@reset\",\"@onChange\"],[[30,1],[30,0,[\"observer\"]],[28,[37,4],[[30,0,[\"observer\",\"publish\"]],\"submit\"],null],[28,[37,4],[[30,0,[\"observer\",\"publish\"]],\"cancel\"],null],[28,[37,4],[[30,0,[\"observer\",\"publish\"]],\"change\"],null]]],null],[1,\"\\n\"]],[1]],null],[13]],[\"element\"],false,[\"page-title\",\"each\",\"-track-array\",\"render-element\",\"fn\"]]",
    "moduleName": "itarp-business-application/templates/dynamic-route.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});