define("itarp-components/helpers/is-any", ["exports", "@ember/component/helper", "@ember/array"], function (_exports, _helper, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function isAny([array, key, value]) {
    if (!array || !key) return false;
    return (0, _array.A)(array).isAny(key, value);
  });

  _exports.default = _default;
});