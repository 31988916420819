define("itarp-talentcloud-ui/adapters/reason", ["exports", "itarp-auth-addon/adapters/ad-token-adapter"], function (_exports, _adTokenAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ReasonAdapter extends _adTokenAdapter.default {
    get headers() {
      const result = super.headers;
      return result;
    }

    urlForFindAll(modelName, snapshot) {
      return '/api/expert/api/talentcloud/routing/data_service/talent_clouds/reasons';
    }

    urlForQuery() {
      return '/api/expert/api/talentcloud/routing/data_service/talent_clouds/reasons';
    }

  }

  _exports.default = ReasonAdapter;
});