define("itarp-shared-assets-addon/helpers/color-class", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.colorClass = colorClass;
  _exports.default = void 0;

  function colorClass(extension, order) {
    const colors = ['primary', 'secondary', 'warning', 'info'];
    const index = order % colors.length;
    const color = colors[index];
    return extension + color;
  }

  var _default = (0, _helper.helper)(([extension, order]) => colorClass(extension, order));

  _exports.default = _default;
});