define("itarp-components/components/ui/table/head-cell", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <th class='table__head__cell {{if @center 'table-cell-align--center'}} {{if @selectable 'is-selectable'}}'>
    <span class='table__head__title {{if @filter 'is-filter'}}'>{{yield}}</span>
  </th>
  
  */
  {
    "id": "ohy5MAeS",
    "block": "[[[10,\"th\"],[15,0,[29,[\"table__head__cell \",[52,[30,1],\"table-cell-align--center\"],\" \",[52,[30,2],\"is-selectable\"]]]],[12],[1,\"\\n  \"],[10,1],[15,0,[29,[\"table__head__title \",[52,[30,3],\"is-filter\"]]]],[12],[18,4,null],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@center\",\"@selectable\",\"@filter\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "itarp-components/components/ui/table/head-cell.hbs",
    "isStrictMode": false
  });

  class UiTableHeadCellComponent extends _component2.default {}

  _exports.default = UiTableHeadCellComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiTableHeadCellComponent);
});