define("itarp-opportunity-ui/controllers/details", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/service"], function (_exports, _controller, _object, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DetailsController = (_class = class DetailsController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "newHourlyRate", _descriptor3, this);

      _initializerDefineProperty(this, "errors", _descriptor4, this);

      _initializerDefineProperty(this, "isApplyOpen", _descriptor5, this);

      _initializerDefineProperty(this, "isApplied", _descriptor6, this);

      _initializerDefineProperty(this, "activeTab", _descriptor7, this);

      _initializerDefineProperty(this, "applicationErrors", _descriptor8, this);

      _initializerDefineProperty(this, "referrer", _descriptor9, this);

      _initializerDefineProperty(this, "_opportunityId", _descriptor10, this);

      _initializerDefineProperty(this, "questions", _descriptor11, this);

      _initializerDefineProperty(this, "isSubmitting", _descriptor12, this);

      _defineProperty(this, "queryParams", ['referrer']);
    }

    changeActiveTab(tabName) {
      this.activeTab = tabName;
    }

    registerOffscreenAPI(options) {}

    updateHourlyRate(val) {
      this.newHourlyRate = val;
    }

    async apply(submitModal) {
      this.isSubmitting = true;
      this.application.resourceId = this.opportunity.id;
      this.application.resourceType = 'opportunity';
      this.application.name = this.user.fullName;
      this.application.email = this.user.email;
      this.application.userId = this.user.id;
      this.application.companyId = this.companyId;

      if (this.newHourlyRate) {
        this.user.profile.hourlyRate = this.newHourlyRate;
        this.user.save();
      }

      this.application.save().then(() => {
        this.isApplyOpen = false;
        this.isApplied = true;
        this.applicationErrors = [];
        this.isApplyOpen = {
          isApplied: this.isApplied,
          expertApplies: this.expertApplies
        };

        if (this.user.profiles.get('resume')) {
          this.application.set('resume', this.user.profiles.get('resume'));
        }

        submitModal.open();
        this.isSubmitting = false;
      }).catch(error => {
        this.applicationErrors.pushObject('Your application cannot be submitted, Make sure you have entered a valid rate and uploaded resume');
        this.isSubmitting = false;
      });
    }

    set opportunityId(opportunityId) {
      this._opportunityId = opportunityId;
    }

    get opportunityId() {
      return this._opportunityId;
    }

    get isApplyOpen() {
      return !this.isApplied && this.expertApplies;
    }

    set isApplyOpen({
      isApplied,
      expertApplies
    }) {
      this.isApplyOpen = !!isApplied && expertApplies;
    }

    get originatedFromTitle() {
      const originatedFrom = this.referrer ? atob(this.referrer.replaceAll('-', '/')) : null;

      if (originatedFrom) {
        const title = originatedFrom.split('/').pop().split('?')[0];

        switch (title) {
          case 'my-opportunities':
            return 'Back to My Job Opportunities';

          case 'invites':
            return 'Back to Opportunity Invitations';

          case 'my-offers':
            return 'Back to My Job Offers';

          case 'interviews':
            return 'Back to Interviews';

          case 'workrooms':
            return 'Back to My Workrooms';

          case 'details':
            return 'Back to TalentCloud Overview';

          default:
            return 'Back to Browse Jobs Opportunities';
        }
      }

      return 'Back to Browse Jobs Opportunities';
    }

    navigateTo(route, e) {
      e.preventDefault();
      this.router.transitionTo(route);
    }

    toggleReason(value) {
      if (this.invitation.reason === value) {
        this.invitation.reason = null;
      } else {
        this.invitation.reason = value;
      }
    }

    declineInvitation(dropdown) {
      const data = {
        invitations: {
          reason: this.invitation.reason,
          status: 'declined'
        }
      };

      try {
        fetch(`/api/expert/api/opportunity/data-service/expert/invitations/decline/${this.invitation.id}`, {
          method: 'PATCH',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('itarp_token')
          }
        }).then(res => res.json()).then(json => {
          this.invitation.status = json.status;
          dropdown.actions.close();
        });
      } catch (error) {
        console.log(error);
      }
    }

    redirectTo() {
      const originatedFrom = this.referrer ? atob(this.referrer.replaceAll('-', '/')) : null;

      if (originatedFrom) {
        this.router.transitionTo(originatedFrom);
      } else if (this.previousRoute) {
        this.router.transitionTo(this.previousRoute);
      } else {
        this.router.transitionTo(`browse`);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "newHourlyRate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "errors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isApplyOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isApplied", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "activeTab", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "applicationErrors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "referrer", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "_opportunityId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "questions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isSubmitting", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "changeActiveTab", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeActiveTab"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerOffscreenAPI", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerOffscreenAPI"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateHourlyRate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateHourlyRate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "apply", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "apply"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "navigateTo", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "navigateTo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleReason", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleReason"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "declineInvitation", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "declineInvitation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "redirectTo", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "redirectTo"), _class.prototype)), _class);
  _exports.default = DetailsController;
});