define("itarp-business-application/components/forms/currency", ["exports", "itarp-business-application/components/forms/base", "@ember/object/computed", "@ember/object", "big.js", "@glimmer/tracking"], function (_exports, _base, _computed, _object, _big, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#unless @config.attributes.hidden}}
    {{#let (unique-id) as |input-id|}}
      <div class="component-wrapper">
        <div
          class="form-group {{if this.tip "has-tip"}}"
          data-test-currency-component
        >
          <label
            for={{input-id}}
            class="form-group__label"
            data-test-currency-label
          >
            {{this.label}}
          </label>
          <div class="form-group__elements">
            <div class="form-group__select">
              <PowerSelect
                @options={{this.currency}}
                @selected={{this.selectedCurrency}}
                @onChange={{this.select}}
                id={{input-id}}
                as |option|
              >
                {{option.value}}
              </PowerSelect>
            </div>
            <input
              id={{input-id}}
              name={{input-id}}
              class="input-group__field"
              placeholder={{this.placeholder}}
              value={{this.currencyValue}}
              {{on "change" (fn this.updateValue)}}
            />
          </div>
          {{#if this.tip}}
            <span class="form-tip">
              Tip
              <EmberPopover
                @event="hover"
                @side="left"
                @tooltipClass="form-tip__tooltip"
              >
                {{this.tip}}
              </EmberPopover>
            </span>
          {{/if}}
        </div>
      </div>
    {{/let}}
  {{/unless}}
  */
  {
    "id": "sGyK2QkV",
    "block": "[[[41,[51,[30,1,[\"attributes\",\"hidden\"]]],[[[44,[[28,[37,2],null,null]],[[[1,\"    \"],[10,0],[14,0,\"component-wrapper\"],[12],[1,\"\\n      \"],[10,0],[15,0,[29,[\"form-group \",[52,[30,0,[\"tip\"]],\"has-tip\"]]]],[14,\"data-test-currency-component\",\"\"],[12],[1,\"\\n        \"],[10,\"label\"],[15,\"for\",[30,2]],[14,0,\"form-group__label\"],[14,\"data-test-currency-label\",\"\"],[12],[1,\"\\n          \"],[1,[30,0,[\"label\"]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"form-group__elements\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"form-group__select\"],[12],[1,\"\\n            \"],[8,[39,4],[[16,1,[30,2]]],[[\"@options\",\"@selected\",\"@onChange\"],[[30,0,[\"currency\"]],[30,0,[\"selectedCurrency\"]],[30,0,[\"select\"]]]],[[\"default\"],[[[[1,\"\\n              \"],[1,[30,3,[\"value\"]]],[1,\"\\n            \"]],[3]]]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[11,\"input\"],[16,1,[30,2]],[16,3,[30,2]],[24,0,\"input-group__field\"],[16,\"placeholder\",[30,0,[\"placeholder\"]]],[16,2,[30,0,[\"currencyValue\"]]],[4,[38,5],[\"change\",[28,[37,6],[[30,0,[\"updateValue\"]]],null]],null],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n\"],[41,[30,0,[\"tip\"]],[[[1,\"          \"],[10,1],[14,0,\"form-tip\"],[12],[1,\"\\n            Tip\\n            \"],[8,[39,7],null,[[\"@event\",\"@side\",\"@tooltipClass\"],[\"hover\",\"left\",\"form-tip__tooltip\"]],[[\"default\"],[[[[1,\"\\n              \"],[1,[30,0,[\"tip\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2]]]],[]],null]],[\"@config\",\"input-id\",\"option\"],false,[\"unless\",\"let\",\"unique-id\",\"if\",\"power-select\",\"on\",\"fn\",\"ember-popover\"]]",
    "moduleName": "itarp-business-application/components/forms/currency.hbs",
    "isStrictMode": false
  });

  let FormsCurrencyComponent = (_dec = (0, _computed.alias)('args.config.attributes'), _dec2 = (0, _object.computed)('attributes.value'), _dec3 = (0, _object.computed)('attributes.label'), _dec4 = (0, _object.computed)('attributes.format'), _dec5 = (0, _object.computed)('attributes.placeholder'), _dec6 = (0, _object.computed)('attributes.tip'), _dec7 = (0, _object.computed)('attributes.currency'), (_class = class FormsCurrencyComponent extends _base.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "attributes", _descriptor, this);

      _initializerDefineProperty(this, "currencySymbol", _descriptor2, this);

      _initializerDefineProperty(this, "currencyValue", _descriptor3, this);

      const value = this.attributes.value;

      if (value) {
        if (value.includes('$') || value.includes('€')) {
          this.currencySymbol = value.charAt(0);
        } else {
          this.currencySymbol = '$';
        }

        this.currencyValue = value.substring(1, value.length);
      }
    }

    get value() {
      return this.attributes.value;
    }

    updateValue(val) {
      this.currencyValue = this.convertedValue(val.target.value);
      (0, _object.set)(this.attributes, 'value', `${this.currencySymbol}${this.currencyValue}`);
    }

    get label() {
      return this.attributes.label;
    }

    get format() {
      return this.attributes.format;
    }

    get placeholder() {
      return this.attributes.placeholder;
    }

    get tip() {
      return this.attributes.tip;
    }

    convertedValue(value) {
      if (!value) return null;
      const convertTo = {
        integer: val => parseInt(val),
        decimal: val => (0, _big.default)(val).toFixed(2),
        default: val => Number(val)
      };
      return convertTo[this.format] && convertTo[this.format](value) || convertTo['default'](value);
    }

    get currency() {
      return this.attributes.currency;
    }

    get selectedCurrency() {
      if (this.currencySymbol) return this.currency.findBy('value', this.currencySymbol);
    }

    select(option) {
      this.currencySymbol = option.value;
      (0, _object.set)(this.attributes, 'value', `${this.currencySymbol}${this.currencyValue}`);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "attributes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currencySymbol", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currencyValue", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "value", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "value"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "label", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "label"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "format", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "format"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "placeholder", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "placeholder"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "tip", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "tip"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "convertedValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "convertedValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "currency", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "currency"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype)), _class));
  _exports.default = FormsCurrencyComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormsCurrencyComponent);
});