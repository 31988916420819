define("itarp-opportunity-designer/routes/details", ["exports", "@ember/routing/route", "rsvp", "@ember/object", "itarp-opportunity-designer/utils/constants/opportunity", "@ember/service"], function (_exports, _route, _rsvp, _object, _opportunity, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DetailsRoute = (_dec = (0, _service.inject)(), _dec2 = (0, _service.inject)(), (_class = class DetailsRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "workflows", _descriptor2, this);

      _initializerDefineProperty(this, "collaborators", _descriptor3, this);
    }

    async model({
      id
    }) {
      const project = await this.store.findRecord('opportunity', id, {
        reload: true
      });
      const approvalWorkflowData = !project.workflowApprovalStageId ? await this.workflows.getDefaultWorkflowApproval() : null;
      const approvalWorkflowId = project.workflowApprovalId;
      const recruitmentWorkflowId = project.workflowId;
      return (0, _rsvp.hash)({
        project,
        approvalWorkflowData,
        approvalWorkflowStages: approvalWorkflowId && this.workflows.stageOptionsFor(approvalWorkflowId),
        recruitmentWorkflowStages: recruitmentWorkflowId && this.workflows.stageOptionsFor(recruitmentWorkflowId),
        defaultWorkflowStages: approvalWorkflowData && this.workflows.stageOptionsFor(approvalWorkflowData.workflowApprovalId)
      });
    }

    setupController(controller, model) {
      super.setupController(...arguments);

      if (model.approvalWorkflowData) {
        (0, _object.set)(model.project, 'workflowApprovalId', model.approvalWorkflowData.workflowApprovalId);
        (0, _object.set)(model.project, 'workflowApprovalStageId', model.approvalWorkflowData.workflowApprovalStageId);
      }

      controller.setProperties({
        project: model.project,
        collaboratorsRepository: this.collaborators.initialize(model.project, {
          persist: true
        }),
        approvalWorkflowStages: model.approvalWorkflowStages,
        recruitmentWorkflowStages: model.recruitmentWorkflowStages,
        defaultWorkflowStages: model.defaultWorkflowStages
      });
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          referrer: null,
          opportunityStatus: null,
          isStatusResolved: false
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "workflows", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "collaborators", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = DetailsRoute;
});