define("itarp-business-application/components/forms/checkbox-list", ["exports", "itarp-business-application/components/forms/base", "@glimmer/tracking", "@ember/object", "@ember/object/computed"], function (_exports, _base, _tracking, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#unless @config.attributes.hidden}}
    <div class="component-wrapper">
      <div
        class="form-options in-{{this.alignment}} {{if this.tip "has-tip"}}"
        data-test-checkbox-component
        ...attributes
      >
        <label class="form-options__label" data-test-checkbox-label>
          {{this.label}}
        </label>
        <div class="form-options__elements">
          {{yield}}
        </div>
        {{#if this.tip}}
          <span class="form-tip" data-test-checkbox-tip>
            Tip
            <EmberPopover
              @event="hover"
              @side="left"
              @tooltipClass="form-tip__tooltip"
            >
              {{this.tip}}
            </EmberPopover>
          </span>
        {{/if}}
      </div>
    </div>
  {{/unless}}
  */
  {
    "id": "Wo1Hsian",
    "block": "[[[41,[51,[30,1,[\"attributes\",\"hidden\"]]],[[[1,\"  \"],[10,0],[14,0,\"component-wrapper\"],[12],[1,\"\\n    \"],[11,0],[16,0,[29,[\"form-options in-\",[30,0,[\"alignment\"]],\" \",[52,[30,0,[\"tip\"]],\"has-tip\"]]]],[24,\"data-test-checkbox-component\",\"\"],[17,2],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"form-options__label\"],[14,\"data-test-checkbox-label\",\"\"],[12],[1,\"\\n        \"],[1,[30,0,[\"label\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"form-options__elements\"],[12],[1,\"\\n        \"],[18,3,null],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,0,[\"tip\"]],[[[1,\"        \"],[10,1],[14,0,\"form-tip\"],[14,\"data-test-checkbox-tip\",\"\"],[12],[1,\"\\n          Tip\\n          \"],[8,[39,3],null,[[\"@event\",\"@side\",\"@tooltipClass\"],[\"hover\",\"left\",\"form-tip__tooltip\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,0,[\"tip\"]]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@config\",\"&attrs\",\"&default\"],false,[\"unless\",\"if\",\"yield\",\"ember-popover\"]]",
    "moduleName": "itarp-business-application/components/forms/checkbox-list.hbs",
    "isStrictMode": false
  });

  let FormsCheckboxListComponent = (_dec = (0, _computed.alias)('args.config.attributes'), _dec2 = (0, _object.computed)('attributes.label'), _dec3 = (0, _object.computed)('attributes.{isRequired,required}'), _dec4 = (0, _object.computed)('attributes.alignment'), _dec5 = (0, _object.computed)('attributes.tip'), (_class = class FormsCheckboxListComponent extends _base.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "attributes", _descriptor, this);
    }

    get label() {
      return this.attributes.label;
    }

    get isRequired() {
      return this.attributes.required;
    }

    get alignment() {
      return this.attributes.alignment || 'line';
    }

    get tip() {
      return this.attributes.tip;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "attributes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "label", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "label"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isRequired", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "isRequired"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "alignment", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "alignment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "tip", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "tip"), _class.prototype)), _class));
  _exports.default = FormsCheckboxListComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormsCheckboxListComponent);
});