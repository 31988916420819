define("itarp-workflow-designer/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    this.route('workflows', {
      path: ''
    });
    this.route('workflow', {
      path: '/:id'
    }, function () {
      this.route('edit-stages', {
        path: 'stages'
      });
      this.route('process-pipeline', {
        path: 'settings'
      });
      this.route('show', {
        path: ''
      });
    });
    this.route('workflow.create', {
      path: '/create'
    });
    this.route('workflow.edit', {
      path: '/:id/edit'
    });
  });

  _exports.default = _default;
});