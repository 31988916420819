define("ember-bootstrap/utils/cp/uses-transition", ["exports", "@ember/object", "@ember/debug", "ember-bootstrap/utils/is-fastboot"], function (_exports, _object, _debug, _isFastboot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = usesTransition;

  function usesTransition(fadeProperty) {
    (false && !(typeof fadeProperty === 'string') && (0, _debug.assert)('You have to provide a fadeProperty for typeClass', typeof fadeProperty === 'string'));
    return (0, _object.computed)(fadeProperty, function () {
      return !(0, _isFastboot.default)(this) && this[fadeProperty];
    });
  }
});