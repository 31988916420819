define("shared/serializers/message", ["exports", "@ember-data/serializer/rest", "@ember/string"], function (_exports, _rest, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import BusinessApplicationSerializer from './business-application';
  class MessageSerializer extends _rest.default {
    extractAttributes(_mc, resource) {
      Object.keys(resource).forEach(attrName => {
        const value = resource[attrName];
        resource[(0, _string.camelize)(attrName)] = value;
        delete resource[attrName];
      });
      return resource;
    }

    normalize(_mc, resource) {
      const message = resource.message;
      const result = super.normalize(...arguments);
      result.data.attributes.message = message;
      return result;
    }

  }

  _exports.default = MessageSerializer;
});