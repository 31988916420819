define("ember-a11y-accordion/components/accordion-item", ["exports", "@ember/component", "@ember/template-factory", "ember-a11y-accordion/utils/dom", "@glimmer/component", "ember-a11y-accordion/utils/shared-state", "@ember/object", "@ember/object/internals", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _dom, _component2, _sharedState, _object, _internals, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class={{this.classNames}}
    aria-disabled={{this.ariaDisabled}}
    aria-expanded={{this.ariaExpanded}}
    aria-level={{this.ariaLevel}}
    {{did-insert this.didInsert}}
    ...attributes>
    {{yield (hash
      isExpanded=this.isExpanded
      header=(component "accordion-header"
        id=this.sharedState.headerId
        triggerId=this.sharedState.triggerId
        panelId=this.sharedState.panelId
        isCollapsible=@isCollapsible
        isExpanded=this.isExpanded
        isDisabled=this.isDisabled
        toggle=(fn @toggle this.sharedState)
      )
      panel=(component "accordion-panel"
        id=this.sharedState.panelId
        triggerId=this.sharedState.triggerId
        isCollapsible=@isCollapsible
        isExpanded=this.isExpanded
      )
    )}}
  </section>
  */
  {
    "id": "aQU3v9dJ",
    "block": "[[[11,\"section\"],[16,0,[30,0,[\"classNames\"]]],[16,\"aria-disabled\",[30,0,[\"ariaDisabled\"]]],[16,\"aria-expanded\",[30,0,[\"ariaExpanded\"]]],[16,\"aria-level\",[30,0,[\"ariaLevel\"]]],[17,1],[4,[38,0],[[30,0,[\"didInsert\"]]],null],[12],[1,\"\\n  \"],[18,4,[[28,[37,2],null,[[\"isExpanded\",\"header\",\"panel\"],[[30,0,[\"isExpanded\"]],[50,\"accordion-header\",0,null,[[\"id\",\"triggerId\",\"panelId\",\"isCollapsible\",\"isExpanded\",\"isDisabled\",\"toggle\"],[[30,0,[\"sharedState\",\"headerId\"]],[30,0,[\"sharedState\",\"triggerId\"]],[30,0,[\"sharedState\",\"panelId\"]],[30,2],[30,0,[\"isExpanded\"]],[30,0,[\"isDisabled\"]],[28,[37,4],[[30,3],[30,0,[\"sharedState\"]]],null]]]],[50,\"accordion-panel\",0,null,[[\"id\",\"triggerId\",\"isCollapsible\",\"isExpanded\"],[[30,0,[\"sharedState\",\"panelId\"]],[30,0,[\"sharedState\",\"triggerId\"]],[30,2],[30,0,[\"isExpanded\"]]]]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@isCollapsible\",\"@toggle\",\"&default\"],false,[\"did-insert\",\"yield\",\"hash\",\"component\",\"fn\"]]",
    "moduleName": "ember-a11y-accordion/components/accordion-item.hbs",
    "isStrictMode": false
  });

  /**
   * The accordion-item component is responsible for creating state and sharing it with the
   * accordion-list or collapsible-list. This component provides the primary API to
   * create a header and panel component.
   *
   * @param {Boolean} [expandOnInit] Whether or not to expand this item on init
   * @param {Boolean} [isDisabled] Whether or not this item should be disabled (user will not be able to expand it)
   * @param {String} [class] Any CSS classes to be added to the component's element
   * @param {String} [name] A canonical name to refer to an accordion-item (e.g. "item-1")
   */
  let AccordionItem = (_class = class AccordionItem extends _component2.default {
    get classNames() {
      const classNames = [_dom.CLASS_NAMES.item];

      if (this.isDisabled) {
        classNames.push(_dom.CLASS_NAMES.itemDisabled);
      }

      if (this.isExpanded) {
        classNames.push(_dom.CLASS_NAMES.itemExpanded);
      }

      return classNames.join(' ');
    }

    get isDisabled() {
      return this.args.isDisabled;
    }

    get isExpanded() {
      return this.sharedState.isExpanded;
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "sharedState", _descriptor, this);

      this.sharedState = new _sharedState.default({
        headerId: (0, _internals.guidFor)({}),
        triggerId: (0, _internals.guidFor)({}),
        panelId: (0, _internals.guidFor)({}),
        isExpanded: this.args.expandOnInit,
        isDisabled: this.args.isDisabled,
        name: this.args.name
      });
    }

    didInsert(element) {
      const sharedState = this.sharedState;
      const panelWrapper = element.querySelector(`.${_dom.CLASS_NAMES.panelWrapper}`);
      sharedState.panelWrapper = panelWrapper;
      sharedState.panelContent = panelWrapper.querySelector(`.${_dom.CLASS_NAMES.panelContent}`);
      this.args.register(sharedState);
    }

    willDestroy() {
      super.willDestroy(...arguments);
      this.sharedState = null;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sharedState", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroy"), _class.prototype)), _class);
  _exports.default = AccordionItem;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AccordionItem);
});