define("itarp-components/components/ui/filters/years-of-experience", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='row'>
    <div class='col--12-12'>
      <div class='component-wrapper has-padding'>
        <div class='select-group'>
          <label class='select-group__label'>
            Years of Experience
          </label>
          <PowerSelect
            @renderInPlace='true'
            @tagname='div'
            @selected={{findby (year-options) 'value' this.value}}
            @options={{(year-options)}}
            @placeholder='Select range'
            @onChange={{this.setValue}}
            as |option|
          >
            {{option.label}}
          </PowerSelect>
          <Ui::Input::SuggestionsList
            @items={{(year-options)}}
            @selected={{findby (year-options) 'value' this.value}}
            @setValue={{this.setValue}}
            @onChange={{@onChange}}
          />
        </div>
      </div>
    </div>
  </div>
  
  */
  {
    "id": "MoK27ZL5",
    "block": "[[[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"col--12-12\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"component-wrapper has-padding\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"select-group\"],[12],[1,\"\\n        \"],[10,\"label\"],[14,0,\"select-group__label\"],[12],[1,\"\\n          Years of Experience\\n        \"],[13],[1,\"\\n        \"],[8,[39,0],null,[[\"@renderInPlace\",\"@tagname\",\"@selected\",\"@options\",\"@placeholder\",\"@onChange\"],[\"true\",\"div\",[28,[37,1],[[28,[37,2],null,null],\"value\",[30,0,[\"value\"]]],null],[28,[37,2],null,null],\"Select range\",[30,0,[\"setValue\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,1,[\"label\"]]],[1,\"\\n        \"]],[1]]]]],[1,\"\\n        \"],[8,[39,3],null,[[\"@items\",\"@selected\",\"@setValue\",\"@onChange\"],[[28,[37,2],null,null],[28,[37,1],[[28,[37,2],null,null],\"value\",[30,0,[\"value\"]]],null],[30,0,[\"setValue\"]],[30,2]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"option\",\"@onChange\"],false,[\"power-select\",\"findby\",\"year-options\",\"ui/input/suggestions-list\"]]",
    "moduleName": "itarp-components/components/ui/filters/years-of-experience.hbs",
    "isStrictMode": false
  });

  let UiFiltersYearsOfExperienceComponent = (_dec = (0, _object.computed)('args.{min,max}'), (_class = class UiFiltersYearsOfExperienceComponent extends _component2.default {
    setValue(suggestion) {
      if (suggestion.value.gte !== undefined) {
        this.args.setMin(suggestion.value.gte);
      }

      if (suggestion.value.lte !== undefined) {
        this.args.setMax(suggestion.value.lte);
      }

      this.args.onChange && this.args.onChange();
    }

    get value() {
      if (this.args.min || this.args.max) {
        const result = {};
        if (this.args.min !== undefined) result.gte = this.args.min;
        if (this.args.max) result.lte = this.args.max;
        return result;
      }

      return null;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "setValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "value", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "value"), _class.prototype)), _class));
  _exports.default = UiFiltersYearsOfExperienceComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiFiltersYearsOfExperienceComponent);
});