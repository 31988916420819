define("itarp-components/components/ui/input/checkbox-list", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='component-wrapper has-padding'>
    <div class='form-options'>
      <label class='form-options__label'>
        {{yield to='label'}}
      </label>
      <div class='form-options__elements'>
        {{yield (hash checkbox=(component 'ui/input/checkbox')) to='list'}}
      </div>
    </div>
  </div>
  */
  {
    "id": "kVGQdxWF",
    "block": "[[[10,0],[14,0,\"component-wrapper has-padding\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"form-options\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"form-options__label\"],[12],[1,\"\\n      \"],[18,1,null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"form-options__elements\"],[12],[1,\"\\n      \"],[18,2,[[28,[37,1],null,[[\"checkbox\"],[[50,\"ui/input/checkbox\",0,null,null]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&label\",\"&list\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-components/components/ui/input/checkbox-list.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});