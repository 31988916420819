define("itarp-opportunity-designer/helpers/opportunity/is-invited", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function isInvited([invitations, expertId]) {
    if (invitations === undefined) return false;
    if (invitations.length === 0) return false;
    return invitations.map(invite => invite.userId).includes(expertId);
  });

  _exports.default = _default;
});