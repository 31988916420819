define("itarp-template-designer/mirage/scenarios/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    server.createList('template-setting', 3);
    server.createList('category', 1);
    server.createList('template', 4);
    let field1 = server.create('field', {
      fieldName: 'hiring_manager',
      displayName: 'Hiring Manager'
    });
    let field2 = server.create('field', {
      fieldName: 'job_title',
      displayName: ''
    });
    let field3 = server.create('field', {
      fieldName: 'internal_id',
      displayName: 'Internal ID'
    });
    let field4 = server.create('field', {
      fieldName: 'title',
      displayName: 'Title'
    });
    let field5 = server.create('field', {
      fieldName: 'slug',
      displayName: ''
    });
    let field6 = server.create('field', {
      fieldName: 'email',
      displayName: ''
    });
    let field7 = server.create('field', {
      fieldName: 'first_name',
      displayName: 'First Name'
    });
    let field8 = server.create('field', {
      fieldName: 'users_ids',
      displayName: 'Users IDs'
    });
    let field9 = server.create('field', {
      fieldName: 'owner_id',
      displayName: 'Owner ID'
    });
    let token1 = server.create('token', {
      collectionName: 'opportunities',
      displayName: 'Opportunities',
      fields: [field1, field2, field3]
    });
    let token2 = server.create('token', {
      collectionName: 'practiceAreas',
      displayName: 'Practice Areas',
      fields: [field4, field5]
    });
    let token3 = server.create('token', {
      collectionName: 'users',
      displayName: '',
      fields: [field6, field7]
    });
    let token4 = server.create('token', {
      collectionName: 'rosters',
      displayName: 'Rosters',
      fields: [field8, field9]
    });
  }
});