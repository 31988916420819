define("itarp-components/services/taxonomy", ["exports", "@ember/service", "@glimmer/tracking", "@ember/object", "@ember/object/computed", "itarp-components/utils/taxonomy/category"], function (_exports, _service, _tracking, _object, _computed, _category) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TaxonomyService = (_dec = (0, _computed.sort)('_industries', (a, b) => b.name === 'Other' ? -1 : 0), (_class = class TaxonomyService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "_categories", _descriptor2, this);

      _initializerDefineProperty(this, "_industries", _descriptor3, this);

      _initializerDefineProperty(this, "sortedIndustries", _descriptor4, this);
    }

    get categories() {
      if (this._categories.length) return this._categories;
      return this.loadCategories().then(data => {
        this._categories = data.map(category => new _category.default(category, this.store));
      });
    }

    loadCategories() {
      return this.store.query('taxonomies-category', {
        filter: {
          type: 'main_category'
        },
        per_page: 'infinity',
        order_by: 'name'
      });
    }

    get industries() {
      if (this._industries.length) return this.sortedIndustries;
      return this.loadIndustries().then(data => {
        this._industries = data;
        return this.sortedIndustries;
      });
    }

    loadIndustries() {
      return this.store.query('taxonomies-industry', {
        per_page: 'infinity',
        order_by: 'name'
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_categories", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_industries", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sortedIndustries", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadCategories", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadCategories"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadIndustries", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadIndustries"), _class.prototype)), _class));
  _exports.default = TaxonomyService;
});