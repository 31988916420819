define("ember-math-helpers/helpers/acos", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.acos = acos;
  _exports.default = void 0;

  /**
   * Executes `Math.acos` on the number passed to the helper.
   *
   * ```hbs
   * {{acos a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.acos`
   * @return {number} The acos of the passed number
   */
  function acos(_ref) {
    let [number] = _ref;
    return Math.acos(number);
  }

  var _default = (0, _helper.helper)(acos);

  _exports.default = _default;
});