define("shared/adapters/chat", ["exports", "shared/adapters/business-application"], function (_exports, _businessApplication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ChatAdapter extends _businessApplication.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", '/api/expert/api/messages');
    }

    get headers() {
      const result = super.headers;
      return result;
    }

    handleResponse(status, headers, payload) {
      if (!this.isSuccess(status, headers, payload)) {
        return payload.errors ? `${payload.errors.charAt(0).toUpperCase()}${payload.errors.slice(1)}` : '';
      }

      return super.handleResponse(...arguments);
    }

    urlForCreateRecord() {
      return `${this.namespace}`;
    }

  }

  _exports.default = ChatAdapter;
});