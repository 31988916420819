define("itarp-template-designer/controllers/templates/new", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/object", "@ember/service", "itarp-template-designer/config/environment"], function (_exports, _controller, _tracking, _object, _service, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TemplatesNewController = (_class = class TemplatesNewController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "appRouter", _descriptor, this);

      _initializerDefineProperty(this, "toast", _descriptor2, this);

      _initializerDefineProperty(this, "cancelModalVisible", _descriptor3, this);

      _initializerDefineProperty(this, "emailTextVisible", _descriptor4, this);

      _initializerDefineProperty(this, "isPreviewing", _descriptor5, this);

      _initializerDefineProperty(this, "error", _descriptor6, this);
    }

    toggleCancelModal() {
      this.cancelModalVisible = !this.cancelModalVisible;
    }

    cancelSaveModal() {
      this.cancelModalVisible = false;
      this.template.destroyRecord();
    }

    saveTemplate() {
      if (this.template.attachments.length === 0) {
        this.template.attachments = [];
      }

      if (this.template.categories.length !== 0) {
        const willPublish = this.template.isPublished;
        this.template.save().then(() => {
          const transition = this.appRouter.transitionTo(`${_environment.default.modulePrefix}`);
          transition.finally(() => {
            if (willPublish) {
              this.toast.show('The publishing process has been started, and you will receive a notification once it is finished.', {
                autoHide: false,
                size: 'lg'
              });
            }
          });
        }, errors => {
          console.log(errors);
        });
        this.error = false;
      } else {
        this.error = true;
      }
    }

    togglePreview(event) {
      event.preventDefault();
      this.isPreviewing = !this.isPreviewing;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appRouter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "cancelModalVisible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "emailTextVisible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isPreviewing", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "error", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleCancelModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleCancelModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelSaveModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancelSaveModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveTemplate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveTemplate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "togglePreview", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "togglePreview"), _class.prototype)), _class);
  _exports.default = TemplatesNewController;
});