define("itarp-components/components/ui/table/menu", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='table-row-menu'>
    <ul class='table-row-menu__controls'>
      {{yield (hash item=(component 'ui/table/menu/item'))}}
    </ul>
  </div>
  */
  {
    "id": "zcu9lm4P",
    "block": "[[[10,0],[14,0,\"table-row-menu\"],[12],[1,\"\\n  \"],[10,\"ul\"],[14,0,\"table-row-menu__controls\"],[12],[1,\"\\n    \"],[18,1,[[28,[37,1],null,[[\"item\"],[[50,\"ui/table/menu/item\",0,null,null]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-components/components/ui/table/menu.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});