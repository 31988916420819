define("itarp-workflow-designer/mirage/endpoints/actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(context) {
    context.get('actions');
    context.get('actions/:id');
    context.post('actions');
    context.patch('actions/:id');
    context.del('actions/:id');
  }
});