define("itarp-template-designer/mirage/endpoints/fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(context) {
    context.get('fields');
    context.get('fields/:id');
  }
});