define("itarp-business-application/components/ui/toast", ["exports", "@glimmer/component", "@ember/object", "@ember/runloop", "@glimmer/tracking", "@ember/service"], function (_exports, _component, _object, _runloop, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="alert-dialog-container">
    {{#each this.toasts as |message|}}
      <div
        class="alert-dialog is-collapsed"
        {{did-insert (fn this.registerToast message)}}
      >
        <div class="alert-dialog__inner">
          <span class="alert-dialog__text">
            {{message}}
          </span>
          <a
            class="alert-dialog__close"
            {{on "click" (fn this.hideToast message)}}
          ></a>
        </div>
      </div>
    {{/each}}
  </div>
  */
  {
    "id": "DzfRudB7",
    "block": "[[[10,0],[14,0,\"alert-dialog-container\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"toasts\"]]],null]],null],null,[[[1,\"    \"],[11,0],[24,0,\"alert-dialog is-collapsed\"],[4,[38,2],[[28,[37,3],[[30,0,[\"registerToast\"]],[30,1]],null]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"alert-dialog__inner\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"alert-dialog__text\"],[12],[1,\"\\n          \"],[1,[30,1]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[11,3],[24,0,\"alert-dialog__close\"],[4,[38,4],[\"click\",[28,[37,3],[[30,0,[\"hideToast\"]],[30,1]],null]],null],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null],[13]],[\"message\"],false,[\"each\",\"-track-array\",\"did-insert\",\"fn\",\"on\"]]",
    "moduleName": "itarp-business-application/components/ui/toast.hbs",
    "isStrictMode": false
  });

  let UiToastComponent = (_class = class UiToastComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "toasts", _descriptor, this);

      _initializerDefineProperty(this, "toast", _descriptor2, this);

      if (this.args.registerAPI) this.args.registerAPI(this);
      this.toast.registerComponent(this);
    }

    show(message) {
      this.toasts.pushObject(message);
    }

    registerToast(toast, element) {
      (0, _runloop.debounce)(this, () => this.showToast(element), 100);
      (0, _runloop.debounce)(this, () => this.hideToast(toast, element), 3000);
    }

    showToast(element) {
      element.classList.add('is-expanded');
      element.classList.remove('is-collapsed');
    }

    hideToast(toast, options) {
      const element = options.target ? options.target : options;
      element.classList.remove('is-expanded');
      element.classList.add('is-collapsed');
      (0, _runloop.debounce)(this, () => this.toasts.removeObject(toast), 200);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "toasts", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "show", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "show"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerToast", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerToast"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showToast", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showToast"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hideToast", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "hideToast"), _class.prototype)), _class);
  _exports.default = UiToastComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UiToastComponent);
});