define("itarp-shared-assets-addon/utils/helpers/date-formats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FORMATS = void 0;
  const FORMATS = {
    LT: 'h:mm A',
    LTS: 'h:mm:ss A',
    L: 'MM/DD/YYYY',
    LL: 'MMM Do, YYYY',
    LLL: 'MMMM Do, YYYY',
    LLLL: 'ddd MMM D YYYY',
    MY: 'MMM YYYY',
    YYYY: 'YYYY'
  };
  _exports.FORMATS = FORMATS;
});