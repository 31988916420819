define("shared/serializers/chat", ["exports", "@ember-data/serializer/rest", "@ember/string", "@ember/object"], function (_exports, _rest, _string, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let ChatSerializer = (_class = class ChatSerializer extends _rest.default {
    extractAttributes(_mc, resource) {
      Object.keys(resource).forEach(attrName => {
        const value = resource[attrName];
        resource[(0, _string.camelize)(attrName)] = value;

        if (attrName !== 'user') {
          delete resource[attrName];
        }
      });
      return resource;
    }

    normalizeResponse(_s, _mc, payload, _id, _rq) {
      let result;

      if (Array.isArray(payload)) {
        result = payload.map(this.formatData).reduce((data, item) => {
          data.chats.pushObject(item.chat);
          data.messages.pushObjects(item.messages);
          data.participants.pushObjects(item.participants);
          return data;
        }, {
          chats: [],
          messages: [],
          participants: []
        });
      } else {
        result = this.formatData(payload);
      }

      return super.normalizeResponse(_s, _mc, result, _id, _rq);
    }

    formatData(data) {
      const result = {
        chat: data,
        messages: data.messages,
        participants: data.participants
      };
      result.chat.messages = data.messages.mapBy('id');
      result.chat.participants = data.participants.mapBy('id');
      return result;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "formatData", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "formatData"), _class.prototype)), _class);
  _exports.default = ChatSerializer;
});