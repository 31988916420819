define("itarp-opportunity-designer/mirage/endpoints/opportunities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(context) {
    context.get('opportunities');
    context.post('opportunities', function (schema, request) {
      let params = JSON.parse(request.requestBody);
      const application = schema.opportunities.create(params);
      const json = this.serialize(application);
      return json['application'];
    });
  }
});