define("itarp-expert-preview/helpers/format-resume-link", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function formatResumeLink([resumeLink]) {
    if (resumeLink && !resumeLink.includes('/storage/download/')) {
      return `/api/expert/storage/download/${resumeLink}`;
    }

    return resumeLink;
  }

  var _default = (0, _helper.helper)(formatResumeLink);

  _exports.default = _default;
});