define("itarp-components/components/ui/table/head", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <thead class='table__head' ...attributes>
    <tr class='table__head__row'>
      {{yield (hash cell=(component 'ui/table/head-cell'))}}
    </tr>
  </thead>
  */
  {
    "id": "vaPJXS94",
    "block": "[[[11,\"thead\"],[24,0,\"table__head\"],[17,1],[12],[1,\"\\n  \"],[10,\"tr\"],[14,0,\"table__head__row\"],[12],[1,\"\\n    \"],[18,2,[[28,[37,1],null,[[\"cell\"],[[50,\"ui/table/head-cell\",0,null,null]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-components/components/ui/table/head.hbs",
    "isStrictMode": false
  });

  class UiTableHeadComponent extends _component2.default {}

  _exports.default = UiTableHeadComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiTableHeadComponent);
});