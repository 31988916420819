define("itarp-opportunity-designer/serializers/invitation", ["exports", "itarp-shared-assets-addon/serializers/data"], function (_exports, _data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class InvitationSerializer extends _data.default {
    serialize() {
      const json = super.serialize(...arguments);
      return json.invitations;
    }

  }

  _exports.default = InvitationSerializer;
});