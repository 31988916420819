define("ember-moment/helpers/unix", ["exports", "moment", "ember-moment/helpers/-base"], function (_exports, _moment, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    compute([unixTimeStamp]) {
      this._super(...arguments);

      return this.moment.moment(_moment.default.unix(unixTimeStamp));
    }

  });

  _exports.default = _default;
});