define("itarp-workflow-designer/services/validator", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ValidatorService extends _service.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "validationConfigs", {
        workflows: {
          name: [{
            name: 'required'
          }],
          type: [{
            name: 'required'
          }]
        }
      });
    }

    validateModel(model, validationConfig) {
      const result = {
        isValid: true,
        errors: {}
      };

      for (const attribute in validationConfig) {
        for (const validator of validationConfig[attribute]) {
          const attributeValidateResult = this.validate(model, attribute, validator);
          result.isValid &= attributeValidateResult.isValid;
          result.errors = { ...result.errors,
            ...attributeValidateResult.errors
          };
        }
      }

      return result;
    }

    validate(model, attribute, validator) {
      switch (validator.name) {
        case 'required':
          return this.validateRequired(model, attribute);
      }

      return {
        isValid: true,
        errors: {}
      };
    }

    validateRequired(model, attribute) {
      const val = model[attribute];
      const isValid = val !== undefined && val !== null && val.toString().trim() !== '';
      const errors = {};

      if (!isValid) {
        errors[attribute] = 'The field is required!';
      }

      return {
        isValid,
        errors
      };
    }

  }

  _exports.default = ValidatorService;
});