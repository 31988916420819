define("itarp-business-application/components/forms/text-area", ["exports", "itarp-business-application/components/forms/base", "@glimmer/tracking", "@ember/object", "@ember/object/computed"], function (_exports, _base, _tracking, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (not (eq this.attributes.hidden "true"))}}
    {{#let (unique-id) as |area-id|}}
      <div
        data-test-textarea-component
        class="input-group
          {{if this.isRequired "is-required" "is-optional"}}
          {{if this.tip "has-tip"}}
          {{if this.error "has-error"}}"
      >
        <label
          for={{area-id}}
          class="input-group__label"
          data-test-textarea-label
        >{{this.label}}</label>
  
        <Textarea
          @id={{area-id}}
          @name={{area-id}}
          @rows={{this.rows}}
          @class="input-group__field"
          @placeholder={{this.placeholder}}
          @value={{this.attributes.value}}
        />
  
        {{#if this.tip}}
          <span class="form-tip" data-test-textarea-tip>
            Tip
            <EmberPopover
              @event="hover"
              @side="left"
              @tooltipClass="form-tip__tooltip"
            >
              {{this.tip}}
            </EmberPopover>
          </span>
        {{/if}}
      </div>
    {{/let}}
  {{/if}}
  */
  {
    "id": "wJ+En3hK",
    "block": "[[[41,[28,[37,1],[[28,[37,2],[[30,0,[\"attributes\",\"hidden\"]],\"true\"],null]],null],[[[44,[[28,[37,4],null,null]],[[[1,\"    \"],[10,0],[14,\"data-test-textarea-component\",\"\"],[15,0,[29,[\"input-group\\n        \",[52,[30,0,[\"isRequired\"]],\"is-required\",\"is-optional\"],\"\\n        \",[52,[30,0,[\"tip\"]],\"has-tip\"],\"\\n        \",[52,[30,0,[\"error\"]],\"has-error\"]]]],[12],[1,\"\\n      \"],[10,\"label\"],[15,\"for\",[30,1]],[14,0,\"input-group__label\"],[14,\"data-test-textarea-label\",\"\"],[12],[1,[30,0,[\"label\"]]],[13],[1,\"\\n\\n      \"],[8,[39,5],null,[[\"@id\",\"@name\",\"@rows\",\"@class\",\"@placeholder\",\"@value\"],[[30,1],[30,1],[30,0,[\"rows\"]],\"input-group__field\",[30,0,[\"placeholder\"]],[30,0,[\"attributes\",\"value\"]]]],null],[1,\"\\n\"],[41,[30,0,[\"tip\"]],[[[1,\"        \"],[10,1],[14,0,\"form-tip\"],[14,\"data-test-textarea-tip\",\"\"],[12],[1,\"\\n          Tip\\n          \"],[8,[39,6],null,[[\"@event\",\"@side\",\"@tooltipClass\"],[\"hover\",\"left\",\"form-tip__tooltip\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,0,[\"tip\"]]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[1]]]],[]],null]],[\"area-id\"],false,[\"if\",\"not\",\"eq\",\"let\",\"unique-id\",\"textarea\",\"ember-popover\"]]",
    "moduleName": "itarp-business-application/components/forms/text-area.hbs",
    "isStrictMode": false
  });

  let FormsTextAreaComponent = (_dec = (0, _computed.alias)('args.config.attributes'), _dec2 = (0, _object.computed)('attributes.label'), _dec3 = (0, _object.computed)('attributes.placeholder'), _dec4 = (0, _object.computed)('attributes.isRequired'), _dec5 = (0, _object.computed)('attributes.tip'), _dec6 = (0, _object.computed)('attributes.rows'), (_class = class FormsTextAreaComponent extends _base.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "attributes", _descriptor, this);
    }

    get label() {
      return this.attributes.label;
    }

    get placeholder() {
      return this.attributes.placeholder;
    }

    get isRequired() {
      return this.attributes.required;
    }

    get tip() {
      return this.attributes.tip;
    }

    get rows() {
      return this.attributes.rows || 4;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "attributes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "label", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "label"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "placeholder", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "placeholder"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isRequired", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "isRequired"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "tip", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "tip"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "rows", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "rows"), _class.prototype)), _class));
  _exports.default = FormsTextAreaComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormsTextAreaComponent);
});