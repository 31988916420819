define("itarp-opportunity-designer/components/question/types/travel", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='component-wrapper has-padding'>
    <div class='card no-header'>
      <div class='card__body'>
        <p class='ts--18 text--bold text--with-icon for-travel'>
          What percentage of time are you willing to travel?
        </p>
        <p class='ts--18 text--flex mar-t--1'>
          Ideal answer:
        </p>
        <div class='select-group mar-t--0-5'>
          <PowerSelect
            @placeholder='Select a percentage'
            @options={{array '1-25%' '26-50%' '51-75%' '76-100%'}}
            @selected={{@question.idealAnswer}}
            @onChange={{fn (mut @question.idealAnswer)}}
            @disabled={{@isView}}
            as |option|
          >
            {{option}}
          </PowerSelect>
        </div>
      </div>
      {{yield}}
    </div>
  </div>
  */
  {
    "id": "Ok4dZc+8",
    "block": "[[[10,0],[14,0,\"component-wrapper has-padding\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"card no-header\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"card__body\"],[12],[1,\"\\n      \"],[10,2],[14,0,\"ts--18 text--bold text--with-icon for-travel\"],[12],[1,\"\\n        What percentage of time are you willing to travel?\\n      \"],[13],[1,\"\\n      \"],[10,2],[14,0,\"ts--18 text--flex mar-t--1\"],[12],[1,\"\\n        Ideal answer:\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"select-group mar-t--0-5\"],[12],[1,\"\\n        \"],[8,[39,0],null,[[\"@placeholder\",\"@options\",\"@selected\",\"@onChange\",\"@disabled\"],[\"Select a percentage\",[28,[37,1],[\"1-25%\",\"26-50%\",\"51-75%\",\"76-100%\"],null],[30,1,[\"idealAnswer\"]],[28,[37,2],[[28,[37,3],[[30,1,[\"idealAnswer\"]]],null]],null],[30,2]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,3]],[1,\"\\n        \"]],[3]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[18,4,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@question\",\"@isView\",\"option\",\"&default\"],false,[\"power-select\",\"array\",\"fn\",\"mut\",\"yield\"]]",
    "moduleName": "itarp-opportunity-designer/components/question/types/travel.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});