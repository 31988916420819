define("shared/transforms/asset-url", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const urlmatch = ['www', 'http'];
  const types = {
    file: 'download',
    content: 'show'
  };

  class AssetUrlTransform extends _transform.default {
    deserialize(serialized, options) {
      const resource = options.type;
      const type = types[resource] || types.content;

      if (typeof serialized === 'string') {
        if (urlmatch.any(m => serialized.includes(m))) return serialized;
      }

      if (serialized) {
        return serialized.includes('api/expert/routing/storage') ? serialized : `/api/expert/routing/storage/${type}/${serialized}`;
      }

      return null;
    }

    serialize(deserialized, options) {
      if (deserialized) {
        if (urlmatch.any(m => deserialized.includes(m))) return deserialized;
        const resource = options.type;
        const type = types[resource] || types.content;
        const appendedPath = `/api/expert/routing/storage/${type}/`;
        return deserialized.replace(appendedPath, '');
      }
    }

  }

  _exports.default = AssetUrlTransform;
});