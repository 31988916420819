define("itarp-opportunity-designer/components/link-to", ["exports", "@ember/routing"], function (_exports, _routing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _routing.LinkTo.extend({
    activeClass: 'is-active'
  });

  _exports.default = _default;
});