define("itarp-opportunity-designer/services/candidates", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CandidatesService = (_dec = (0, _service.inject)(), (_class = class CandidatesService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    async get(type, projectId) {
      let ids = [];
      let applications = [];

      if (type === 'unsuccessful') {
        ids = await this.unsuccessful(projectId);
      } else if (type === 'applied') {
        [ids, applications] = await this.active(projectId);
      }

      const candidates = await this.fetchCandidates(ids);
      return [candidates, applications];
    }

    async fetchCandidates(ids) {
      if (ids.length === 0) {
        return {
          meta: {
            page: {
              page: 1,
              total: 0,
              per_page: 0
            }
          },
          candidates: []
        };
      }

      return this.store.query('user', {
        advance_filter: {
          id: {
            $in: ids
          }
        }
      }).then(result => {
        return {
          meta: result.meta,
          candidates: result
        };
      });
    }

    async active(id) {
      const filter = {
        $nin: ['withdrawn', 'rejected']
      };
      return this.getResultAndIds('proposal', filter, id);
    }

    async unsuccessful(id) {
      const [invitedIds, invitations] = await this.getResultAndIds('invitation', 'declined', id);
      const [appliedIds, applications] = await this.getResultAndIds('proposal', {
        $in: ['withdrawn', 'rejected']
      }, id);
      const result = await Promise.all([invitedIds, appliedIds]);
      return result.reduce((acc, val) => acc.concat(val.compact()), []);
    }

    async getResultAndIds(model, status, id) {
      return this.store.query(model, this.filter(status, id)).then(result => {
        const ids = result.map(item => item.userId);
        return [ids, result];
      });
    }

    filter(status, projectId) {
      return {
        filter: {
          resource_id: projectId,
          resource_type: 'opportunity'
        },
        advance_filter: {
          status: status
        },
        per_page: 'infinity',
        opportunity: projectId,
        search: {
          resource_id: projectId,
          resource_type: 'opportunity'
        }
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CandidatesService;
});