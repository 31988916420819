define("ember-gestures/templates/components/fast-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mlw1fiW4",
    "block": "[[[18,1,null],[1,[34,1]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"text\"]]",
    "moduleName": "ember-gestures/templates/components/fast-action.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});