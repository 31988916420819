define("itarp-components/components/ui/input/year-range", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='component-wrapper has-padding'>
    <div class='input-group' ...attributes>
      <label class='input-group__label'>
        {{yield}}
      </label>
      <div class='input-group__elements for-range has-from is-horizontal'>
        <PowerSelect
          @renderInPlace='true'
          @tagname='div'
          @selected={{@from}}
          @options={{this.years}}
          @placeholder={{if @fromPlaceholder @fromPlaceholder 'Year'}}
          @onChange={{this.setStartYear}}
          as |option|
        >
          {{option}}
        </PowerSelect>
        <span class='input-group__range__divider'></span>
        <PowerSelect
          @renderInPlace='true'
          @tagname='div'
          @selected={{@to}}
          @options={{this.years}}
          @placeholder={{if @toPlaceholder @toPlaceholder 'Year'}}
          @onChange={{this.setEndYear}}
          as |option|
        >
          {{option}}
        </PowerSelect>
      </div>
    </div>
  </div>
  */
  {
    "id": "MqzNgTEy",
    "block": "[[[10,0],[14,0,\"component-wrapper has-padding\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"input-group\"],[17,1],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"input-group__label\"],[12],[1,\"\\n      \"],[18,8,null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"input-group__elements for-range has-from is-horizontal\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@renderInPlace\",\"@tagname\",\"@selected\",\"@options\",\"@placeholder\",\"@onChange\"],[\"true\",\"div\",[30,2],[30,0,[\"years\"]],[52,[30,3],[30,3],\"Year\"],[30,0,[\"setStartYear\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,4]],[1,\"\\n      \"]],[4]]]]],[1,\"\\n      \"],[10,1],[14,0,\"input-group__range__divider\"],[12],[13],[1,\"\\n      \"],[8,[39,1],null,[[\"@renderInPlace\",\"@tagname\",\"@selected\",\"@options\",\"@placeholder\",\"@onChange\"],[\"true\",\"div\",[30,5],[30,0,[\"years\"]],[52,[30,6],[30,6],\"Year\"],[30,0,[\"setEndYear\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,7]],[1,\"\\n      \"]],[7]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@from\",\"@fromPlaceholder\",\"option\",\"@to\",\"@toPlaceholder\",\"option\",\"&default\"],false,[\"yield\",\"power-select\",\"if\"]]",
    "moduleName": "itarp-components/components/ui/input/year-range.hbs",
    "isStrictMode": false
  });

  let UiInputYearRangeComponent = (_class = class UiInputYearRangeComponent extends _component2.default {
    get years() {
      let endYear = new Date().getFullYear();
      let startYear = 1930;
      let years = [];

      for (endYear; endYear >= startYear; endYear--) {
        years.push(endYear);
      }

      return years;
    }

    get start() {
      if (this.args.from) return this.args.from;
    }

    get end() {
      if (this.args.to) return this.args.to;
    }

    setStartYear(year) {
      const isValid = this.validateStart(year);

      if (isValid) {
        this.args.setStart(year);
        this.args.onChange && this.args.onChange(year);
      }
    }

    setEndYear(year) {
      const isValid = this.validateEnd(year);

      if (isValid) {
        this.args.setEnd(year);
        this.args.onChange && this.args.onChange(year);
      }
    }

    validateEnd(value) {
      const year = Number(value);
      const startYear = this.start ? Number(this.start) : year;
      const isValid = year >= startYear;
      return isValid;
    }

    validateStart(value) {
      const year = Number(value);
      const endYear = this.end ? Number(this.end) : year;
      const isValid = year <= endYear;
      return isValid;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "setStartYear", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setStartYear"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setEndYear", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setEndYear"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateEnd", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validateEnd"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateStart", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validateStart"), _class.prototype)), _class);
  _exports.default = UiInputYearRangeComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiInputYearRangeComponent);
});