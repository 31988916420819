define("itarp-business-application/services/context-tree", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ContextTreeService = (_dec = (0, _service.inject)('model-loader'), (_class = class ContextTreeService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "modelLoader", _descriptor, this);
    }

    generate(page, formOnly = false) {
      const context = {};
      this.extract(page, context, formOnly);
      return context;
    }

    extract({
      children
    }, context, formOnly) {
      for (const child of children) {
        this.extractSingle(child, context, formOnly);
        if (child.children.length === 0) continue;
        this.extract(child, context, formOnly);
      }
    }

    extractSingle(item, context, formOnly) {
      const attrsValues = Object.values(item.attributes);

      for (const value of attrsValues) {
        const {
          definition,
          isArray,
          content
        } = attrData(value);
        if (definition !== 'model_reference' || !content) continue;
        if (formOnly && !item.type.startsWith('forms/')) continue;
        this.registerContext(context, {
          content,
          isArray
        });
      }
    }

    registerContext(context, {
      content,
      isArray
    }) {
      const {
        model_name: namesPath,
        attribute_name: attribute
      } = content;
      let modelNames = namesPath.toLowerCase().split('->');
      let current = context;
      let previousModel;

      while (modelNames.length > 0) {
        const name = modelNames.shift();

        if (previousModel) {
          const relations = Object.values(previousModel.relations);
          const relation = relations.findBy('type', name);
          const attrName = relation.attribute;

          if (relation.isCollection) {
            current[attrName] = current[attrName] || [{}];
            current = current[attrName][0];
          } else {
            current[attrName] = current[attrName] || {};
            current = current[attrName];
          }
        } else {
          current[name] = current[name] || {};
          current = current[name];
        }

        previousModel = this.modelLoader.models[name];
      }

      current[attribute] = null;
      return current;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modelLoader", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ContextTreeService;

  function attrData(attribute = {}) {
    const {
      type: {
        'type-definition': definition,
        'is-array': isArray
      },
      value
    } = attribute;
    return {
      definition,
      isArray,
      content: value
    };
  }
});