define("itarp-opportunity-designer/mirage/endpoints/taxonomies-industries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(context) {
    context.get('taxonomies-industries');
  }
});