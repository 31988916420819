define("itarp-components/components/ui/input/editor-group", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='textarea-group {{if @required 'is-required'}}' ...attributes>
    {{#let (unique-id) as |uid|}}
      {{yield
        (hash
          label=(component
            'ui/input/label'
            class='textarea-group__label'
            for=uid
            required=@required
          )
          wrapper=(component 'ui/input/editor-wrapper' id=uid required=@required)
        )
      }}
    {{/let}}
  </div>
  
  */
  {
    "id": "Z4yGxtZT",
    "block": "[[[11,0],[16,0,[29,[\"textarea-group \",[52,[30,1],\"is-required\"]]]],[17,2],[12],[1,\"\\n\"],[44,[[28,[37,2],null,null]],[[[1,\"    \"],[18,4,[[28,[37,4],null,[[\"label\",\"wrapper\"],[[50,\"ui/input/label\",0,null,[[\"class\",\"for\",\"required\"],[\"textarea-group__label\",[30,3],[30,1]]]],[50,\"ui/input/editor-wrapper\",0,null,[[\"id\",\"required\"],[[30,3],[30,1]]]]]]]]],[1,\"\\n\"]],[3]]],[13],[1,\"\\n\"]],[\"@required\",\"&attrs\",\"uid\",\"&default\"],false,[\"if\",\"let\",\"unique-id\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-components/components/ui/input/editor-group.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});