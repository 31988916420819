define("itarp-workflow-designer/models/workflow", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let WorkflowModel = (_dec = (0, _model.belongsTo)('targetable'), _dec2 = (0, _model.belongsTo)('trigger'), _dec3 = (0, _model.belongsTo)('template-setting'), _dec4 = (0, _model.hasMany)('stage-group'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('boolean'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec11 = (0, _model.attr)('boolean'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string', {
    defaultValue: 'inactive'
  }), _dec14 = (0, _model.attr)('number', {
    defaultValue: 1
  }), _dec15 = (0, _model.attr)(), _dec16 = (0, _model.attr)('string'), (_class = class WorkflowModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "targetable", _descriptor, this);

      _initializerDefineProperty(this, "trigger", _descriptor2, this);

      _initializerDefineProperty(this, "templateSetting", _descriptor3, this);

      _initializerDefineProperty(this, "stageGroups", _descriptor4, this);

      _initializerDefineProperty(this, "name", _descriptor5, this);

      _initializerDefineProperty(this, "description", _descriptor6, this);

      _initializerDefineProperty(this, "isValid", _descriptor7, this);

      _initializerDefineProperty(this, "type", _descriptor8, this);

      _initializerDefineProperty(this, "usedBy", _descriptor9, this);

      _initializerDefineProperty(this, "isPublished", _descriptor10, this);

      _initializerDefineProperty(this, "isDefault", _descriptor11, this);

      _initializerDefineProperty(this, "templateId", _descriptor12, this);

      _initializerDefineProperty(this, "versionStatus", _descriptor13, this);

      _initializerDefineProperty(this, "version", _descriptor14, this);

      _initializerDefineProperty(this, "metadata", _descriptor15, this);

      _initializerDefineProperty(this, "identifier", _descriptor16, this);
    }

    get statusName() {
      const statuses = {
        inactive: 'Inactive',
        active: 'Published',
        draft: 'Publishing in progress',
        pending_delete: 'Deletion in progress',
        rejected: 'Rejected'
      };
      return statuses[this.versionStatus] || '';
    }

    get isEditable() {
      const statuses = ['draft', 'pending_delete'];
      return !statuses.includes(this.versionStatus);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "targetable", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "trigger", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "templateSetting", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stageGroups", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isValid", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "usedBy", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isPublished", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isDefault", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "templateId", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "versionStatus", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "version", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "metadata", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "identifier", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = WorkflowModel;
});