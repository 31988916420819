define("itarp-workflow-designer/controllers/workflow/create", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/object", "@ember/service", "itarp-workflow-designer/config/environment"], function (_exports, _controller, _tracking, _object, _service, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let WorkflowCreateController = (_dec = (0, _service.inject)('validator'), (_class = class WorkflowCreateController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "appRouter", _descriptor, this);

      _initializerDefineProperty(this, "validatorService", _descriptor2, this);

      _initializerDefineProperty(this, "selected", _descriptor3, this);

      _initializerDefineProperty(this, "newStage", _descriptor4, this);

      _initializerDefineProperty(this, "workflowValidationResult", _descriptor5, this);

      _initializerDefineProperty(this, "availableTemplates", _descriptor6, this);

      _defineProperty(this, "applyRealTimeValidation", false);
    }

    async saveWorkflow(e) {
      e.preventDefault();

      if (this.workflow.templateId) {
        this.workflow.templateId = this.workflow.templateId.id;
      }

      this.applyRealTimeValidation = true;
      this.validateWorkflow();

      if (this.workflowValidationResult.isValid) {
        try {
          await this.workflow.save();
          this.applyRealTimeValidation = false;
          this.appRouter.transitionTo(`${_environment.default.modulePrefix}.workflow.edit-stages`, this.workflow.id);
        } catch (errors) {
          console.log(errors);
        }
      }
    }

    validateWorkflow() {
      if (this.applyRealTimeValidation) {
        this.workflowValidationResult = this.validatorService.validateModel(this.workflow, this.validatorService.validationConfigs['workflows']);
      }
    }

    cancelEditWorkflow() {
      this.selected = null;
      this.applyRealTimeValidation = false;
      this.workflowValidationResult = null;
      this.availableTemplates = null;
      this.workflow.rollbackAttributes();
      this.appRouter.transitionTo(`${_environment.default.modulePrefix}`);
    }

    openCreateForm() {
      this.selected = 'new';
      this.newStage = this.store.createRecord('stage-group');
    }

    saveStageChanges() {
      if (this.newStage.stageGroup) {
        this.workflow.stageGroups.insertAt(this.workflow.stageGroups.length - 1, this.newStage);
        this.selected = null;
        this.newStage = null;
      } else {
        return;
      }
    }

    cancelForm() {
      this.selected = null;
      this.availableTemplates = null;
    }

    openEditForm(stage) {
      this.selected = stage;
    }

    saveEditForm() {
      this.selected = null;
      this.availableTemplates = null;
    }

    selectWorkflowType(optionId) {
      if (this.workflow.templateId) {
        this.workflow.templateId = null;
      }

      this.workflow.type = optionId;
      this.availableTemplates = optionId;
    }

    get workflowCategories() {
      return [{
        id: 'opportunity_approval',
        label: 'Opportunity Approval'
      }, {
        id: 'opportunity_recruiting',
        label: 'Opportunity Recruiting'
      }, {
        id: 'talentcloud_approval',
        label: 'Talentcloud Approval'
      }, {
        id: 'talentcloud_recruiting',
        label: 'Talentcloud Recruiting'
      }];
    } //TODO: Templates should have a unique identifier that is not the name - BE implementation


    get filteredTemplate() {
      if (!this.availableTemplates) return null;
      const categoryAndType = this.availableTemplates.split('_');
      const category = categoryAndType[0] === 'opportunity' ? 'Opportunity' : 'TalentCloud';
      const type = categoryAndType[1] === 'approval' ? 'Approval' : 'Recruiting';
      return this.workflowTemplates.filter(template => template.metadata.category === `Workflow::${category}` && template.metadata.name.includes(type));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appRouter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "validatorService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "newStage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "workflowValidationResult", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "availableTemplates", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "saveWorkflow", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveWorkflow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateWorkflow", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validateWorkflow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelEditWorkflow", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancelEditWorkflow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openCreateForm", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openCreateForm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveStageChanges", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveStageChanges"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelForm", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancelForm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openEditForm", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openEditForm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveEditForm", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveEditForm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectWorkflowType", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectWorkflowType"), _class.prototype)), _class));
  _exports.default = WorkflowCreateController;
});