define("itarp-opportunity-designer/helpers/message-links", ["exports", "@ember/component/helper", "@ember/template"], function (_exports, _helper, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(([value]) => {
    let output = value.replace(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi, url => '<a href="' + url + '">' + url + '</a>');
    return (0, _template.htmlSafe)(output);
  });

  _exports.default = _default;
});