define("itarp-opportunity-ui/components/ui/modal/header", ["exports", "@ember/component/template-only"], function (_exports, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="app-modal-content__header">
    <h4 class="app-modal-content__header__title" ...attributes>{{yield}}</h4>
    <a class="app-modal-content__header__close" {{on "click" @close}}></a>
  </div>
  */
  {
    "id": "8XjHGCaS",
    "block": "[[[10,0],[14,0,\"app-modal-content__header\"],[12],[1,\"\\n  \"],[11,\"h4\"],[24,0,\"app-modal-content__header__title\"],[17,1],[12],[18,3,null],[13],[1,\"\\n  \"],[11,3],[24,0,\"app-modal-content__header__close\"],[4,[38,1],[\"click\",[30,2]],null],[12],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@close\",\"&default\"],false,[\"yield\",\"on\"]]",
    "moduleName": "itarp-opportunity-ui/components/ui/modal/header.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});