define("itarp-opportunity-designer/components/offscreen", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='offscreen-preview__header'>
    <a class='offscreen-preview__header__close' {{on 'click' @toggle}}>
      <span class='offscreen-preview__header__close__icon'></span>
      <span
        class='offscreen-preview__header__close__label'
      >Back</span>
    </a>
    <div class='offscreen-preview__header__content'>
      {{yield to='header'}}
    </div>
  </div>
  <div class='offscreen-preview__body'>
    <div class='offscreen-preview__body__nav'>
      <div class='tabs'>
        <div class='tabs__list no-border'>
          {{yield to='nav'}}
        </div>
      </div>
    </div>
    <div class='offscreen-preview__body__inner'>
      {{yield to='body'}}
    </div>
  </div>
  
  */
  {
    "id": "hX4ZVgej",
    "block": "[[[10,0],[14,0,\"offscreen-preview__header\"],[12],[1,\"\\n  \"],[11,3],[24,0,\"offscreen-preview__header__close\"],[4,[38,0],[\"click\",[30,1]],null],[12],[1,\"\\n    \"],[10,1],[14,0,\"offscreen-preview__header__close__icon\"],[12],[13],[1,\"\\n    \"],[10,1],[14,0,\"offscreen-preview__header__close__label\"],[12],[1,\"Back\"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"offscreen-preview__header__content\"],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"offscreen-preview__body\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"offscreen-preview__body__nav\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"tabs\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"tabs__list no-border\"],[12],[1,\"\\n        \"],[18,3,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"offscreen-preview__body__inner\"],[12],[1,\"\\n    \"],[18,4,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@toggle\",\"&header\",\"&nav\",\"&body\"],false,[\"on\",\"yield\"]]",
    "moduleName": "itarp-opportunity-designer/components/offscreen.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});