define("itarp-business-application/routes/profile/expertise", ["exports", "@ember/routing/route", "itarp-business-application/util/store-manager", "@ember/service", "rsvp", "@ember/runloop"], function (_exports, _route, _storeManager, _service, _rsvp, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProfileExpertiseRoute = (_dec = (0, _service.inject)('ad-token-service'), (_class = class ProfileExpertiseRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "adTokenService", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "taxonomy", _descriptor3, this);

      _defineProperty(this, "storeManager", null);

      _defineProperty(this, "tenantId", this.adTokenService.tokenInformation.extension_tenant_id);
    }

    buildRouteInfoMetadata() {
      return {
        title: 'Expertise',
        sidebarHidden: true,
        padding: {
          top: 0
        }
      };
    }

    async model() {
      const adapter = this.store.adapterFor('application');
      const user = this.modelFor('profile');
      return (0, _rsvp.hash)({
        user,
        practiceAreas: this.store.query('practice-area', {
          per_page: 'infinity',
          order_by: 'name',
          filter: {
            status: 'active',
            tenant_id: this.tenantId
          }
        }),
        industries: this.store.query('industry', {
          per_page: 'infinity',
          order_by: 'name'
        }),
        adapter: adapter
      });
    }

    async beforeModel() {
      this.storeManager = new _storeManager.default({
        ids: {
          userId: this.adTokenService.tokenInformation.sub
        },
        store: this.store,
        useChangeset: true
      });
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.set('storeManager', this.storeManager);
      controller.set('user', model.user.changeset);
      controller.set('profile', model.user.profiles);
      controller.set('practiceAreas', model.practiceAreas);
      controller.set('industries', model.industries);

      if (model.user.profiles) {
        if (model.user.profiles.get('practiceAreas')) {
          controller.set('practiceArea', model.user.profiles.get('practiceAreas'));
        }

        if (model.user.profiles.get('industries')) {
          controller.set('industry', model.user.profiles.get('industries'));
        }
      }

      ['educations', 'experiences', 'certifications', 'skills', 'tools', 'links', 'industries'].forEach(type => {
        if (!controller.profile.get(type)) {
          controller.profile.set(type, []);
        } else {
          controller.profile.set(type, model.user.profiles.get(type).toArray().filter(d => d.id !== null));
        }
      });
      window.scrollTo(0, 0);
      this.taxonomy.getTaxonomies();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "adTokenService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "taxonomy", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ProfileExpertiseRoute;
});