define("itarp-workflow-designer/services/user-role", ["exports", "@ember/service", "@ember/object", "itarp-workflow-designer/utils/constants"], function (_exports, _service, _object, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UserRoleService = (_dec = (0, _service.inject)('ad-token-service'), (_class = class UserRoleService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "auth", _descriptor, this);
    }

    fetchRoleOptions() {
      const endpoint = '/rbac/roles';

      const roleCodeParams = _constants.USER_ROLE_OPTIONS.map(role => `filter[code][]=${role.code}`).join('&');

      const tenantParams = `filter[tenant_id]=${this.auth.tenantId}`;
      return fetch(`${endpoint}?${tenantParams}&${roleCodeParams}`).then(async res => await res.json()).then(res => {
        return _constants.USER_ROLE_OPTIONS.map(roleOption => {
          const role = res.find(r => r.code === roleOption.code);
          return { ...roleOption,
            value: role.code,
            id: role === null || role === void 0 ? void 0 : role.id
          };
        });
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchRoleOptions", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "fetchRoleOptions"), _class.prototype)), _class));
  _exports.default = UserRoleService;
});