define("itarp-components/components/ui/wrapper", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class='component-wrapper
      {{if @padding 'has-padding'}}
      {{if this.alignment this.alignment}}'
    ...attributes
  >
    {{yield}}
  </div>
  
  */
  {
    "id": "yp6kQatH",
    "block": "[[[11,0],[16,0,[29,[\"component-wrapper\\n    \",[52,[30,1],\"has-padding\"],\"\\n    \",[52,[30,0,[\"alignment\"]],[30,0,[\"alignment\"]]]]]],[17,2],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@padding\",\"&attrs\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "itarp-components/components/ui/wrapper.hbs",
    "isStrictMode": false
  });

  class UiWrapperComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "positions", {
        r: 'right',
        l: 'left',
        t: 'top',
        b: 'bottom',
        c: 'center'
      });
    }

    get alignment() {
      if (!this.args.align) return;
      const direction = this.args.align.slice(0, 1);
      const positionId = this.args.align.slice(1, 2);
      const position = this.positions[positionId];
      return `component-align--${direction}-${position}`;
    }

  }

  _exports.default = UiWrapperComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiWrapperComponent);
});