define("itarp-expert-preview/engine", ["exports", "ember-load-initializers", "itarp-expert-preview/config/environment", "ember-resolver", "@ember/engine"], function (_exports, _emberLoadInitializers, _environment, _emberResolver, _engine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const {
    modulePrefix
  } = _environment.default;

  class ItarpExpertPreviewEngine extends _engine.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "modulePrefix", modulePrefix);

      _defineProperty(this, "Resolver", _emberResolver.default);

      _defineProperty(this, "dependencies", {
        externalRoutes: ['schedule-interview', 'opportunity'],
        services: ['layout', 'micro-frontend', 'store', 'router', 'toast', 'workflows', 'markup-rate']
      });
    }

  }

  _exports.default = ItarpExpertPreviewEngine;
  (0, _emberLoadInitializers.default)(ItarpExpertPreviewEngine, modulePrefix);
});