define("itarp-business-application/components/forms/field-group", ["exports", "@glimmer/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#unless @config.attributes.hidden}}
    <div class="component-wrapper">
      <div class="form-fieldset">
        {{#if this.label}}
          <label class="form-fieldset__label">
            {{this.label}}
            {{#unless this.isRequired}}
              <span class="label-note label-note--optional"></span>
            {{/unless}}
          </label>
        {{/if}}
        <div class="form-fieldset__panel">
          {{yield (hash observer=@observer)}}
        </div>
      </div>
    </div>
  {{/unless}}
  */
  {
    "id": "yZbV6bad",
    "block": "[[[41,[51,[30,1,[\"attributes\",\"hidden\"]]],[[[1,\"  \"],[10,0],[14,0,\"component-wrapper\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"form-fieldset\"],[12],[1,\"\\n\"],[41,[30,0,[\"label\"]],[[[1,\"        \"],[10,\"label\"],[14,0,\"form-fieldset__label\"],[12],[1,\"\\n          \"],[1,[30,0,[\"label\"]]],[1,\"\\n\"],[41,[51,[30,0,[\"isRequired\"]]],[[[1,\"            \"],[10,1],[14,0,\"label-note label-note--optional\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[10,0],[14,0,\"form-fieldset__panel\"],[12],[1,\"\\n        \"],[18,3,[[28,[37,3],null,[[\"observer\"],[[30,2]]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@config\",\"@observer\",\"&default\"],false,[\"unless\",\"if\",\"yield\",\"hash\"]]",
    "moduleName": "itarp-business-application/components/forms/field-group.hbs",
    "isStrictMode": false
  });

  let FormsFieldGroupComponent = (_dec = (0, _computed.alias)('args.config.attributes'), _dec2 = (0, _object.computed)('attributes.required'), _dec3 = (0, _object.computed)('attributes.label'), (_class = class FormsFieldGroupComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "attributes", _descriptor, this);

      this.setObservers();
    }

    get isRequired() {
      return this.attributes.required || false;
    }

    get label() {
      return this.attributes.label || undefined;
    }

    onToggle(component, value) {
      if (component !== 'save') {
        (0, _object.set)(this.attributes, 'hidden', !this.attributes.hidden);
      }
    }

    setObservers() {
      this.args.observer.subscribe('submit', this.onToggle);
      this.args.observer.subscribe('toggle', this.onToggle);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "attributes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isRequired", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isRequired"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "label", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "label"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onToggle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onToggle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setObservers", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setObservers"), _class.prototype)), _class));
  _exports.default = FormsFieldGroupComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormsFieldGroupComponent);
});