define("itarp-opportunity-designer/utils/constants/opportunity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.STATUS_CLASSES = _exports.UNAPPROVED_STATUSES = _exports.IN_HIRING_WORKFLOW_STATUS = _exports.IN_APPROVAL_WORKFLOW_STATUS = _exports.DRAFT_STATUS = _exports.CLOSED_STATUS = _exports.PENDING_APPROVAL_STATUS = _exports.ON_HOLD_STATUS = _exports.INITIAL_STATUS = _exports.DEFAULT_STATUS = _exports.RECORDS_PER_PAGE = _exports.DEFAULT_PAGE_NUMBER = void 0;
  const DEFAULT_PAGE_NUMBER = 1;
  _exports.DEFAULT_PAGE_NUMBER = DEFAULT_PAGE_NUMBER;
  const RECORDS_PER_PAGE = 20;
  _exports.RECORDS_PER_PAGE = RECORDS_PER_PAGE;
  const DEFAULT_STATUS = 'open';
  _exports.DEFAULT_STATUS = DEFAULT_STATUS;
  const INITIAL_STATUS = 'draft';
  _exports.INITIAL_STATUS = INITIAL_STATUS;
  const ON_HOLD_STATUS = 'on_hold';
  _exports.ON_HOLD_STATUS = ON_HOLD_STATUS;
  const PENDING_APPROVAL_STATUS = 'pending_approval';
  _exports.PENDING_APPROVAL_STATUS = PENDING_APPROVAL_STATUS;
  const CLOSED_STATUS = 'closed';
  _exports.CLOSED_STATUS = CLOSED_STATUS;
  const DRAFT_STATUS = 'draft';
  _exports.DRAFT_STATUS = DRAFT_STATUS;
  const IN_APPROVAL_WORKFLOW_STATUS = 'in_approval_workflow';
  _exports.IN_APPROVAL_WORKFLOW_STATUS = IN_APPROVAL_WORKFLOW_STATUS;
  const IN_HIRING_WORKFLOW_STATUS = 'in_hiring_workflow';
  _exports.IN_HIRING_WORKFLOW_STATUS = IN_HIRING_WORKFLOW_STATUS;
  const UNAPPROVED_STATUSES = [DRAFT_STATUS, PENDING_APPROVAL_STATUS, IN_APPROVAL_WORKFLOW_STATUS];
  _exports.UNAPPROVED_STATUSES = UNAPPROVED_STATUSES;
  const STATUS_CLASSES = [{
    status: 'pending_approval',
    class: 'is-draft'
  }, {
    status: 'open',
    class: 'is-active'
  }, {
    status: 'Draft',
    class: 'is-draft'
  }, {
    status: 'closed',
    class: 'is-closed'
  }, {
    status: 'declined',
    class: 'is-closed'
  }, {
    status: 'on_hold',
    class: 'is-draft'
  }];
  _exports.STATUS_CLASSES = STATUS_CLASSES;
});