define("itarp-components/components/ui/confirm-dialog", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='dialog-wrapper'>
    <div class='dialog'>
      <div class='dialog__body'>
        <p class='dialog__text'>
          {{yield}}
        </p>
      </div>
      <div class='dialog__footer'>
        {{#if @confirmText}}
          <button
            {{on 'click' @confirmAction}}
            type='button'
            class='app-btn {{@buttonClass}}'
          >{{@confirmText}}</button>
        {{/if}}
        {{#if @cancelText}}
          <button
            {{on 'click' @cancelAction}}
            type='button'
            class='app-btn'
          >{{@cancelText}}</button>
        {{/if}}
      </div>
    </div>
  </div>
  */
  {
    "id": "EdC5XdCB",
    "block": "[[[10,0],[14,0,\"dialog-wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"dialog\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"dialog__body\"],[12],[1,\"\\n      \"],[10,2],[14,0,\"dialog__text\"],[12],[1,\"\\n        \"],[18,6,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"dialog__footer\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"        \"],[11,\"button\"],[16,0,[29,[\"app-btn \",[30,2]]]],[24,4,\"button\"],[4,[38,2],[\"click\",[30,3]],null],[12],[1,[30,1]],[13],[1,\"\\n\"]],[]],null],[41,[30,4],[[[1,\"        \"],[11,\"button\"],[24,0,\"app-btn\"],[24,4,\"button\"],[4,[38,2],[\"click\",[30,5]],null],[12],[1,[30,4]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@confirmText\",\"@buttonClass\",\"@confirmAction\",\"@cancelText\",\"@cancelAction\",\"&default\"],false,[\"yield\",\"if\",\"on\"]]",
    "moduleName": "itarp-components/components/ui/confirm-dialog.hbs",
    "isStrictMode": false
  });

  let ConfirmDialogComponent = (_class = class ConfirmDialogComponent extends _component2.default {
    confirmAction() {
      this.confirmAction();
    }

    cancelAction() {
      this.cancelAction();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "confirmAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "confirmAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancelAction"), _class.prototype)), _class);
  _exports.default = ConfirmDialogComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ConfirmDialogComponent);
});