define("itarp-business-application/util/schema-manager/schemas/model", ["exports", "@ember/utils", "itarp-business-application/util/schema-manager/schemas/relation", "@ember/object", "ember-inflector"], function (_exports, _utils, _relation, _object, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = ModelSchema;

  function ModelSchema(data, storeManager) {
    const {
      attrDetails,
      type,
      collectionName,
      attributes,
      relations
    } = data;
    this.type = type;
    this.collectionName = collectionName;
    this.modelName = type;
    this.attributes = attributes;
    this.relationships = defineRelations(relations, storeManager);
    this.attrDetails = data.attrDetails;

    const record = _object.default.extend({
      type: type,
      collectionName: collectionName,
      relationships: this.relationships
    });

    this.instantiate = async function (data) {
      let result = await record.create();
      result = { ...result
      };
      result.relationships = { ...this.relationships
      };
      result = _object.default.create(result);
      await populateAttributes(result, data, this.attributes);
      return result;
    };
  }

  function defineRelations(relations, storeManager) {
    const relationships = {};
    if (!relations || !relations.length) return relationships;

    for (const relation of relations) {
      const modelName = (0, _emberInflector.singularize)(relation.attribute);
      const relationship = relations.findBy('attribute', relation.attribute);

      if (relationship) {
        relationships[modelName] = new _relation.default(relationship, storeManager);
      }
    }

    return relationships;
  }

  async function populateAttributes(record, data = {}, attributes) {
    for (const attribute of attributes) {
      let relationship = null;

      for (const key of Object.keys(record.relationships)) {
        if (record.relationships[key].attribute === attribute) {
          relationship = record.relationships[key];
        }
      }

      if (relationship) {
        if (!relationship.loaded) {
          const relationRecord = await relationship.instantiate(data[attribute]);
          relationship.loaded = true;
          record.set(attribute, relationRecord);
        } else {
          record.set(attribute, relationship.value);
        }
      } else if (data && data[attribute] !== undefined) {
        record.set(attribute, data[attribute]);
      }
    }

    record.id = getRecordId(data);
  }

  function getRecordId(data) {
    if (data && data._id) {
      return data._id['$oid'] ? data._id['$oid'] : data._id;
    }

    return null;
  }
});