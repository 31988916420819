define("ember-bootstrap/components/bs-alert", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component", "@glimmer/tracking", "@ember/runloop", "ember-bootstrap/utils/decorators/uses-transition", "ember-bootstrap/utils/deprecate-subclassing", "ember-bootstrap/utils/decorators/arg", "tracked-toolbox"], function (_exports, _component, _templateFactory, _object, _component2, _tracking, _runloop, _usesTransition, _deprecateSubclassing, _arg, _trackedToolbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="{{unless this.hidden "alert"}} {{if this.fade "fade"}} {{if this.dismissible "alert-dismissible"}} {{bs-type-class "alert" @type}} {{if this.showAlert (if (macroCondition (macroGetOwnConfig "isNotBS3")) "show" "in")}}"
    ...attributes
    {{did-update this.showOrHide this._visible}}
  >
    {{#unless this.hidden}}
      {{#if this.dismissible}}
        <button type="button" class="close" aria-label="Close" {{on "click" this.dismiss}}>
          <span aria-hidden="true">&times;</span>
        </button>
      {{/if}}
  
      {{#if (has-block "header")}}
        {{#let (element (bs-default @headerTag "h4")) as |Tag|}}
          <Tag class="alert-heading">
            {{yield to="header"}}
          </Tag>
        {{/let}}
      {{/if}}
  
      {{#if (has-block "body")}}
        {{yield to="body"}}
      {{else}}
        {{yield}}
      {{/if}}
    {{/unless}}
  </div>
  */
  {
    "id": "1IlCpF5K",
    "block": "[[[11,0],[16,0,[29,[[52,[51,[30,0,[\"hidden\"]]],\"alert\"],\" \",[52,[30,0,[\"fade\"]],\"fade\"],\" \",[52,[30,0,[\"dismissible\"]],\"alert-dismissible\"],\" \",[28,[37,2],[\"alert\",[30,1]],null],\" \",[52,[30,0,[\"showAlert\"]],\"show\"]]]],[17,2],[4,[38,3],[[30,0,[\"showOrHide\"]],[30,0,[\"_visible\"]]],null],[12],[1,\"\\n\"],[41,[51,[30,0,[\"hidden\"]]],[[[41,[30,0,[\"dismissible\"]],[[[1,\"      \"],[11,\"button\"],[24,0,\"close\"],[24,\"aria-label\",\"Close\"],[24,4,\"button\"],[4,[38,4],[\"click\",[30,0,[\"dismiss\"]]],null],[12],[1,\"\\n        \"],[10,1],[14,\"aria-hidden\",\"true\"],[12],[1,\"×\"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[48,[30,5]],[[[44,[[50,[28,[37,8],[[28,[37,9],[[28,[37,10],[[30,3],\"h4\"],null]],null]],null],0,null,[[\"tagName\"],[[28,[37,10],[[30,3],\"h4\"],null]]]]],[[[1,\"        \"],[8,[30,4],[[24,0,\"alert-heading\"]],null,[[\"default\"],[[[[1,\"\\n          \"],[18,5,null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[4]]]],[]],null],[1,\"\\n\"],[41,[48,[30,6]],[[[1,\"      \"],[18,6,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[18,7,null],[1,\"\\n\"]],[]]]],[]],null],[13]],[\"@type\",\"&attrs\",\"@headerTag\",\"Tag\",\"&header\",\"&body\",\"&default\"],false,[\"unless\",\"if\",\"bs-type-class\",\"did-update\",\"on\",\"has-block\",\"let\",\"component\",\"ensure-safe-component\",\"-element\",\"bs-default\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-alert.hbs",
    "isStrictMode": false
  });

  let Alert = (
  /**
    Implements [Bootstrap alerts](http://getbootstrap.com/components/#alerts)
  
    ### Usage
  
    By default it is a user dismissible alert with a fade out animation, both of which can be disabled. Be sure to set the
    `type` property for proper styling.
  
    ```hbs
    <BsAlert @type="success">
      <strong>Well done!</strong> You successfully read this important alert message.
    </BsAlert>
    ```
  
    Optionally you can render a header for the alert using named blocks syntax:
  
    ```hbs
    <BsAlert>
      <:header>
        Well done!
      </:header>
      <:body>
        You successfully read this important alert message.
      </:body>
    </BsAlert>
    ```
  
    The header is rendered using a `<h4>` element by default. You can customize
    that one by setting `@headerTag` argument of `<BsAlert>`.
  
    Using named block syntax as shown above may require to install
    [ember-named-blocks-polyfill](https://github.com/ember-polyfills/ember-named-blocks-polyfill)
    in your project depending on the Ember version used.
  
    *Note that only invoking the component in a template as shown above is considered part of its public API. Extending from it (subclassing) is generally not supported, and may break at any time.*
  
    @class Alert
    @namespace Components
    @extends Glimmer.Component
    @public
  */
  _dec = (0, _trackedToolbox.localCopy)('visible'), _dec2 = (0, _usesTransition.default)('fade'), (0, _deprecateSubclassing.default)(_class = (_class2 = class Alert extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "dismissible", _descriptor, this);

      _initializerDefineProperty(this, "hidden", _descriptor2, this);

      _initializerDefineProperty(this, "visible", _descriptor3, this);

      _initializerDefineProperty(this, "_visible", _descriptor4, this);

      _initializerDefineProperty(this, "fade", _descriptor5, this);

      _initializerDefineProperty(this, "fadeDuration", _descriptor6, this);

      _initializerDefineProperty(this, "usesTransition", _descriptor7, this);
    }

    get showAlert() {
      return this._visible && this.args.fade !== false;
    }
    /**
     * The duration of the fade out animation
     *
     * @property fadeDuration
     * @type number
     * @default 150
     * @public
     */


    /**
     * The action to be sent after the alert has been dismissed (including the CSS transition).
     *
     * @event onDismissed
     * @public
     */

    /**
     * The action is called when the close button is clicked.
     *
     * You can return false to prevent closing the alert automatically, and do that in your action by
     * setting `visible` to false.
     *
     * @event onDismiss
     * @public
     */
    dismiss() {
      var _this$args$onDismiss, _this$args;

      if (((_this$args$onDismiss = (_this$args = this.args).onDismiss) === null || _this$args$onDismiss === void 0 ? void 0 : _this$args$onDismiss.call(_this$args)) !== false) {
        this._visible = false;
      }
    }
    /**
     * Call to make the alert visible again after it has been hidden
     *
     * @method show
     * @private
     */


    show() {
      this.hidden = false;
    }
    /**
     * Call to hide the alert. If the `fade` property is true, this will fade out the alert before being finally
     * dismissed.
     *
     * @method hide
     * @private
     */


    hide() {
      if (this.hidden) {
        return;
      }

      if (this.usesTransition) {
        (0, _runloop.later)(this, function () {
          if (!this.isDestroyed) {
            var _this$args$onDismisse, _this$args2;

            this.hidden = true;
            (_this$args$onDismisse = (_this$args2 = this.args).onDismissed) === null || _this$args$onDismisse === void 0 ? void 0 : _this$args$onDismisse.call(_this$args2);
          }
        }, this.fadeDuration);
      } else {
        var _this$args$onDismisse2, _this$args3;

        this.hidden = true;
        (_this$args$onDismisse2 = (_this$args3 = this.args).onDismissed) === null || _this$args$onDismisse2 === void 0 ? void 0 : _this$args$onDismisse2.call(_this$args3);
      }
    }

    showOrHide() {
      if (this._visible) {
        this.show();
      } else {
        this.hide();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "dismissible", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "hidden", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return !this.visible;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "visible", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "_visible", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "fade", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "fadeDuration", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 150;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "usesTransition", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "dismiss", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "dismiss"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "showOrHide", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "showOrHide"), _class2.prototype)), _class2)) || _class);
  _exports.default = Alert;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Alert);
});