define("itarp-business-application/util/parser/page/pages/presentational", ["exports", "itarp-business-application/util/parser/component", "itarp-business-application/util/store-manager", "itarp-business-application/util/parser/page/pages/base"], function (_exports, _component, _storeManager, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PresentationalPage = void 0;

  class PresentationalPage extends _base.default {
    constructor(data, options) {
      super(...arguments);
      this.store = new _storeManager.default({
        store: options.store,
        ids: options.ids
      });
      return this.generate(data.children);
    }

    async generate(data) {
      this.children = await (0, _component.parseComponents)(data, this.store);
      return this;
    }

  }

  _exports.PresentationalPage = PresentationalPage;
});