define("shared/adapters/industry", ["exports", "shared/adapters/business-application"], function (_exports, _businessApplication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class IndustryAdapter extends _businessApplication.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", '/api/expert/routing/data');
    }

    urlForQuery(query, modelName) {
      return `${this.namespace}/taxonomies_industries`;
    }

  }

  _exports.default = IndustryAdapter;
});