define("itarp-opportunity-designer/adapters/roster", ["exports", "itarp-auth-addon/adapters/ad-token-data"], function (_exports, _adTokenData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class RosterAdapter extends _adTokenData.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", '/roster/data_service');
    }

    formatErrors(payload) {
      return super.formatErrors({
        errors: {
          rosters: payload.errors
        }
      });
    }

    updateRecord(store, schema, snapshot) {
      const data = this.serialize(snapshot, {
        includeId: true
      });
      delete data.user_ids;
      const type = snapshot.modelName;
      const id = snapshot.id;
      let url = this.buildURL(type, id, snapshot, 'updateRecord');
      return this.ajax(url, 'PATCH', {
        data: data
      });
    }

    urlForUpdateRecord(id, modelName, snapshot) {
      var _snapshot$adapterOpti;

      const type = (_snapshot$adapterOpti = snapshot.adapterOptions) === null || _snapshot$adapterOpti === void 0 ? void 0 : _snapshot$adapterOpti.type;

      if (type === 'remove_user') {
        return `${this.namespace}/${id}/remove_user`;
      }

      return `${this.namespace}/${id}/add_users`;
    }

  }

  _exports.default = RosterAdapter;
});