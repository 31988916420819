define("shared/helpers/is-any", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function isAny(params) {
    const array = params[0];
    const key = params[1];
    const value = params[2];
    return array.isAny(key, value);
  });

  _exports.default = _default;
});