define("itarp-opportunity-designer/serializers/accessibility-setting", ["exports", "itarp-shared-assets-addon/serializers/data", "@ember-data/serializer/rest"], function (_exports, _data, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class AcessibilitySettingSerializer extends _data.default.extend(_rest.EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        groups: {
          deserialize: 'records',
          serialize: false
        },
        options: {
          embedded: 'always'
        }
      });
    }

    normalize(modelClass, data) {
      const type = data.type;
      data.options = data.groups.map(({
        options
      }) => options).flat(1);
      const result = super.normalize(...arguments);
      result.data.attributes.type = type;
      return result;
    }

    normalizeQueryResponse(store, modelClass, data, ...rest) {
      const recordsWithId = data.filter(({
        _id
      }) => !_id === false);
      const payload = {
        records: recordsWithId
      };
      return super.normalizeQueryResponse(store, modelClass, payload, ...rest);
    }

  }

  _exports.default = AcessibilitySettingSerializer;
});