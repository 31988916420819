define("itarp-opportunity-designer/components/ui/modals/collaborators/options", ["exports", "@ember/component", "@ember/template-factory", "ember-power-select/components/power-select/options", "@ember/object"], function (_exports, _component, _templateFactory, _options, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ul
    role='listbox'
    {{did-insert this.addHandlers}}
    {{will-destroy this.removeHandlers}}
    ...attributes
  >
    {{#let
      (component (ensure-safe-component @groupComponent))
      (component (ensure-safe-component @optionsComponent))
      as |Group Options|
    }}
      {{#each @options as |opt index|}}
        {{#unless opt.hidden}}
          {{#if (ember-power-select-is-group opt)}}
            <Group @group={{opt}} @select={{@select}} @extra={{@extra}}>
              <Options
                @options={{opt.options}}
                @select={{@select}}
                @groupIndex='{{@groupIndex}}{{index}}.'
                @optionsComponent={{@optionsComponent}}
                @groupComponent={{@groupComponent}}
                @extra={{@extra}}
                role='group'
                class='ember-power-select-options'
                as |option|
              >
                {{yield option @select}}
              </Options>
            </Group>
          {{else}}
            <li
              class='ember-power-select-option'
              id='{{@select.uniqueId}}-{{@groupIndex}}{{index}}'
              aria-selected='{{ember-power-select-is-selected
                opt
                @select.selected
              }}'
              aria-disabled={{if opt.disabled 'true'}}
              aria-current='{{eq opt @select.highlighted}}'
              data-option-index='{{@groupIndex}}{{index}}'
              role='option'
            >
              {{yield opt @select}}
            </li>
          {{/if}}
        {{/unless}}
      {{/each}}
    {{/let}}
  </ul>
  
  */
  {
    "id": "bQejhvwD",
    "block": "[[[11,\"ul\"],[24,\"role\",\"listbox\"],[17,1],[4,[38,0],[[30,0,[\"addHandlers\"]]],null],[4,[38,1],[[30,0,[\"removeHandlers\"]]],null],[12],[1,\"\\n\"],[44,[[50,[28,[37,4],[[30,2]],null],0,null,null],[50,[28,[37,4],[[30,3]],null],0,null,null]],[[[42,[28,[37,6],[[28,[37,6],[[30,6]],null]],null],null,[[[41,[51,[30,7,[\"hidden\"]]],[[[41,[28,[37,9],[[30,7]],null],[[[1,\"          \"],[8,[30,4],null,[[\"@group\",\"@select\",\"@extra\"],[[30,7],[30,9],[30,10]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[30,5],[[24,\"role\",\"group\"],[24,0,\"ember-power-select-options\"]],[[\"@options\",\"@select\",\"@groupIndex\",\"@optionsComponent\",\"@groupComponent\",\"@extra\"],[[30,7,[\"options\"]],[30,9],[29,[[30,11],[30,8],\".\"]],[30,3],[30,2],[30,10]]],[[\"default\"],[[[[1,\"\\n              \"],[18,13,[[30,12],[30,9]]],[1,\"\\n            \"]],[12]]]]],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,\"li\"],[14,0,\"ember-power-select-option\"],[15,1,[29,[[30,9,[\"uniqueId\"]],\"-\",[30,11],[30,8]]]],[15,\"aria-selected\",[29,[[28,[37,11],[[30,7],[30,9,[\"selected\"]]],null]]]],[15,\"aria-disabled\",[52,[30,7,[\"disabled\"]],\"true\"]],[15,\"aria-current\",[29,[[28,[37,12],[[30,7],[30,9,[\"highlighted\"]]],null]]]],[15,\"data-option-index\",[29,[[30,11],[30,8]]]],[14,\"role\",\"option\"],[12],[1,\"\\n            \"],[18,13,[[30,7],[30,9]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]]]],[]],null]],[7,8]],null]],[4,5]]],[13],[1,\"\\n\"]],[\"&attrs\",\"@groupComponent\",\"@optionsComponent\",\"Group\",\"Options\",\"@options\",\"opt\",\"index\",\"@select\",\"@extra\",\"@groupIndex\",\"option\",\"&default\"],false,[\"did-insert\",\"will-destroy\",\"let\",\"component\",\"ensure-safe-component\",\"each\",\"-track-array\",\"unless\",\"if\",\"ember-power-select-is-group\",\"yield\",\"ember-power-select-is-selected\",\"eq\"]]",
    "moduleName": "itarp-opportunity-designer/components/ui/modals/collaborators/options.hbs",
    "isStrictMode": false
  });

  let UiModalsCollaboratorsOptionsComponent = (_class = class UiModalsCollaboratorsOptionsComponent extends _options.default {
    addHandlers() {
      super.addHandlers(...arguments);
    }

    removeHandlers() {
      super.removeHandlers(...arguments);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "addHandlers", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addHandlers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeHandlers", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeHandlers"), _class.prototype)), _class);
  _exports.default = UiModalsCollaboratorsOptionsComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiModalsCollaboratorsOptionsComponent);
});