define("itarp-components/components/filters/taxonomy", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/service", "@ember/array", "@ember/runloop"], function (_exports, _component, _templateFactory, _component2, _object, _tracking, _service, _array, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='row'>
    <div class='col--12-12'>
      <div class='component-wrapper has-padding'>
        <div class='select-group'>
          <label class='select-group__label'>
            {{@label}}
          </label>
          <PowerSelectMultiple
            @optionsComponent='filters/taxonomy/options'
            @extra={{hash loadMore=this.loadMore searching=this.searchTerm}}
            @onChange={{this.onSelectionChange}}
            @onKeydown={{this.onKeydown}}
            @placeholder={{@placeholder}}
            @selected={{this.selected}}
            @options={{this.taxonomy}}
            @searchEnabled={{true}}
            @onOpen={{this.onOpen}}
            @search={{this.search}}
            @onInput={{fn (mut this.searchTerm)}}
            @onClose={{this.resetSearch}}
            @noMatchesMessage='...'
            as |option|
          >
            {{option}}
          </PowerSelectMultiple>
        </div>
      </div>
    </div>
  </div>
  */
  {
    "id": "7JUBmVu3",
    "block": "[[[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"col--12-12\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"component-wrapper has-padding\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"select-group\"],[12],[1,\"\\n        \"],[10,\"label\"],[14,0,\"select-group__label\"],[12],[1,\"\\n          \"],[1,[30,1]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[39,0],null,[[\"@optionsComponent\",\"@extra\",\"@onChange\",\"@onKeydown\",\"@placeholder\",\"@selected\",\"@options\",\"@searchEnabled\",\"@onOpen\",\"@search\",\"@onInput\",\"@onClose\",\"@noMatchesMessage\"],[\"filters/taxonomy/options\",[28,[37,1],null,[[\"loadMore\",\"searching\"],[[30,0,[\"loadMore\"]],[30,0,[\"searchTerm\"]]]]],[30,0,[\"onSelectionChange\"]],[30,0,[\"onKeydown\"]],[30,2],[30,0,[\"selected\"]],[30,0,[\"taxonomy\"]],true,[30,0,[\"onOpen\"]],[30,0,[\"search\"]],[28,[37,2],[[28,[37,3],[[30,0,[\"searchTerm\"]]],null]],null],[30,0,[\"resetSearch\"]],\"...\"]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,3]],[1,\"\\n        \"]],[3]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@label\",\"@placeholder\",\"option\"],false,[\"power-select-multiple\",\"hash\",\"fn\",\"mut\"]]",
    "moduleName": "itarp-components/components/filters/taxonomy.hbs",
    "isStrictMode": false
  });

  let FiltersTaxonomyComponent = (_class = class FiltersTaxonomyComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "page", 1);

      _defineProperty(this, "size", 50);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "initiallyLoaded", _descriptor2, this);

      _initializerDefineProperty(this, "loading", _descriptor3, this);

      _initializerDefineProperty(this, "options", _descriptor4, this);

      _initializerDefineProperty(this, "searchTerm", _descriptor5, this);

      _initializerDefineProperty(this, "searchResults", _descriptor6, this);
    }

    get query() {
      return {
        page: this.page,
        per_page: this.size,
        filter: {
          type: this.args.type,
          label: false
        }
      };
    }

    get selected() {
      if (!this.args.selected || !this.args.selected.length) return [];
      return this.args.selected.map(s => s.value);
    }

    get taxonomy() {
      return this.searchTerm ? this.searchResults : this.options;
    }

    resetSearch() {
      this.searchResults = [];
      this.searchTerm = null;
    }

    onSelectionChange(selection) {
      this.args.updateValue(selection.map(v => ({
        label: v,
        value: v
      })));
      if (this.args.onChange) this.args.onChange();
    }

    async fetchTaxonomy(query) {
      return this.store.query('taxonomies-category', query).then(data => {
        return data.toArray().map(({
          name
        }) => name).uniq();
      });
    }

    searchTaxonomy(term) {
      this.fetchTaxonomy({ ...this.query,
        page: 1,
        advance_filter: {
          name: {
            $regex: term,
            $options: 'i'
          }
        }
      }).then(data => {
        this.searchResults = data;
      });
    }

    search(term) {
      (0, _runloop.debounce)(this, 'searchTaxonomy', term, 500);
    }

    async onOpen() {
      if (this.initiallyLoaded) return;
      this.options = await this.fetchTaxonomy(this.query);
      this.initiallyLoaded = true;
    }

    async loadMore() {
      if (this.loading) return;
      this.loading = true;
      const options = await this.options;
      this.page += 1;
      this.fetchTaxonomy(this.query).then(data => {
        this.options = [...this.options, ...data];
        this.loading = false;
      });
    }

    onKeydown(dropdown, event) {
      const whitelist = ['Enter', 'Tab', 'Comma'];
      const code = event.code;
      const value = event.target.value;
      const valueSelected = this.selected.includes(value);
      if (!whitelist.includes(code) || !value || valueSelected) return;
      event.stopPropagation();
      event.preventDefault();
      const selected = dropdown.highlighted || value;
      dropdown.actions.choose(selected, event);
      return false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "initiallyLoaded", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "options", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "searchTerm", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "searchResults", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "resetSearch", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "resetSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectionChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectionChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchTaxonomy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "fetchTaxonomy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchTaxonomy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "searchTaxonomy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "search", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onOpen", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onOpen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadMore", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadMore"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeydown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onKeydown"), _class.prototype)), _class);
  _exports.default = FiltersTaxonomyComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FiltersTaxonomyComponent);
});