define("itarp-components/helpers/format-btn-name", ["exports", "@ember/component/helper", "@ember/string"], function (_exports, _helper, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function capitalize(params) {
    const title = params[0].split('_').join(' ');
    if (!title) return;
    return (0, _string.capitalize)(title);
  });

  _exports.default = _default;
});