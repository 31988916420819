define("ember-bootstrap/utils/default-decorator", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = defaultValue;

  function defaultValue(target, key, descriptor) {
    let {
      initializer,
      value
    } = descriptor;
    return (0, _object.computed)({
      get() {
        return initializer ? initializer.call(this) : value;
      },

      set(_, v) {
        if (v !== undefined) {
          return v;
        }

        return initializer ? initializer.call(this) : value;
      }

    })(target, key, { ...descriptor,
      value: undefined,
      initializer: undefined
    });
  }
});