define("itarp-opportunity-designer/mirage/scenarios/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    server.createList('opportunity', 10);
    server.createList('taxonomies-industry', 10);
  }
});