define("itarp-business-application/util/parser/page/pages/form", ["exports", "itarp-business-application/util/store-manager", "itarp-business-application/utils/observable", "itarp-business-application/util/parser/component", "ember-changeset", "itarp-business-application/util/parser/page/helpers", "itarp-business-application/util/parser/page/pages/base", "@ember/object", "@glimmer/tracking", "@ember/utils"], function (_exports, _storeManager, _observable, _component, _emberChangeset, _helpers, _base, _object, _tracking, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FormPage = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FormPage = (_class = class FormPage extends _base.default {
    constructor(data, options) {
      super(...arguments);

      _defineProperty(this, "observer", new _observable.default());

      _initializerDefineProperty(this, "errors", _descriptor, this);

      this.template = (0, _helpers.template)(data, 'form/layout');
      this.store = new _storeManager.default({
        useChangeset: true,
        ids: options.ids,
        store: options.store
      });
      this.observer.subscribe('submit', this.save);
      this.transition = options.transition;
      this.slug = options.slug;
      this.context = options.context;
      return this.generate(data.children);
    }

    setPayloadData(model, changeset, context) {
      for (const attr of Object.keys(context)) {
        if ((0, _utils.typeOf)(context[attr]) === 'object') {
          let nestedModel = model ? model[attr] : null;
          let nestedChangeset = changeset ? changeset[attr] : null;
          this.setPayloadData(nestedModel, nestedChangeset, context[attr]);
        } else if (changeset && changeset[attr] !== undefined) {
          context[attr] = changeset[attr];
        } else if (model && model[attr] !== undefined) {
          context[attr] = model[attr];
        }
      }
    }

    async save(type, event) {
      event.preventDefault();
      this.clearErrors();
      const models = this.store.models;

      for (const model in models) {
        const changeset = models[model].changeset;
        const schema = await this.store.getSchema('user');
        this.setPayloadData(models[model], changeset.change, this.context[model]);

        if (models[model].id) {
          await schema.api.update({
            id: models[model].id,
            data: this.context[model],
            page: {
              slug: this.slug
            }
          }).then(() => {
            changeset.execute();
            this.transition(event);
          }, response => {
            this.errors = response.errors;
            this.emitErrors(response.errors);
          });
        } else {
          await schema.api.create({
            data: this.context[model]
          });
        }
      }
    }

    emitErrors(errors) {
      for (const error of errors) {
        this.observer.publish(`error.${error.attribute}`, 'invalid', error.detail);
      }
    }

    clearErrors() {
      this.errors = null;
      this.observer.publish('clearError');
    }

    async generate(data) {
      await (0, _component.parseComponents)(data, this.store, this.observer).then(result => {
        this.children = result;
      });
      return this;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "errors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setPayloadData", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setPayloadData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "emitErrors", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "emitErrors"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearErrors", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearErrors"), _class.prototype)), _class);
  _exports.FormPage = FormPage;
});