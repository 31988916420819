define("itarp-shared-assets-addon/components/ui/offscreen/header", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='offscreen-preview__header'>
    <a class='offscreen-preview__header__close' {{on 'click' @close}}>
      <span class='offscreen-preview__header__close__icon'></span>
      <span class='offscreen-preview__header__close__label'>Back</span>
    </a>
    <div class='offscreen-preview__header__content'>
      {{yield}}
    </div>
  </div>
  */
  {
    "id": "JU6GfmDe",
    "block": "[[[10,0],[14,0,\"offscreen-preview__header\"],[12],[1,\"\\n  \"],[11,3],[24,0,\"offscreen-preview__header__close\"],[4,[38,0],[\"click\",[30,1]],null],[12],[1,\"\\n    \"],[10,1],[14,0,\"offscreen-preview__header__close__icon\"],[12],[13],[1,\"\\n    \"],[10,1],[14,0,\"offscreen-preview__header__close__label\"],[12],[1,\"Back\"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"offscreen-preview__header__content\"],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@close\",\"&default\"],false,[\"on\",\"yield\"]]",
    "moduleName": "itarp-shared-assets-addon/components/ui/offscreen/header.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});