define("ember-tooltips/components/ember-tooltip", ["exports", "ember-tooltips/components/ember-tooltip-base"], function (_exports, _emberTooltipBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberTooltipBase.default.extend({
    _tooltipVariantClass: 'ember-tooltip'
  });

  _exports.default = _default;
});