define("itarp-business-application/util/schema-manager/schemas/relation-types/single", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Single = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Single {
    constructor(data, storeManager) {
      _defineProperty(this, "type", 'single');

      _defineProperty(this, "value", {});

      const modelName = (0, _emberInflector.singularize)(data.attribute);
      this.loaded = false;
      this.storeManager = storeManager;
      this.name = modelName;
      this.attribute = data.attribute;
      this.embedded = true;
      this.isArray = false;
    }

    async instantiate(data) {
      if (this.embedded) {
        const schema = await this.storeManager.getSchema(this.name);
        const record = await schema.model.instantiate(data);
        this.value = record;
        this.loaded = true;
        return this.value;
      }

      return this.value;
    }

  }

  _exports.Single = Single;
});