define("itarp-components/components/filters/taxonomy-list/options", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @taxonomy.isCategory}}
    {{#let (get @taxonomy @type) as |subTaxonomies|}}
      {{#if (get subTaxonomies 'length')}}
        <Ui::Input::Checkbox
          @value={{this.allChecked}}
          @setValue={{this.toggleGroup}}
        >
          {{@taxonomy.name}}
        </Ui::Input::Checkbox>
        <Filters::TaxonomyList::Group
          @group={{@taxonomy}}
          @type={{@type}}
          @selected={{@selected}}
          @onChange={{@onChange}}
        />
      {{/if}}
    {{/let}}
  {{else}}
    <Ui::Input::Checkbox
      @value={{any @selected @taxonomy.id}}
      @setValue={{fn this.toggleOption @taxonomy.id}}
    >
      {{@taxonomy.name}}
    </Ui::Input::Checkbox>
  {{/if}}
  
  */
  {
    "id": "uLJxWfci",
    "block": "[[[41,[30,1,[\"isCategory\"]],[[[44,[[28,[37,2],[[30,1],[30,2]],null]],[[[41,[28,[37,2],[[30,3],\"length\"],null],[[[1,\"      \"],[8,[39,3],null,[[\"@value\",\"@setValue\"],[[30,0,[\"allChecked\"]],[30,0,[\"toggleGroup\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,1,[\"name\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[39,4],null,[[\"@group\",\"@type\",\"@selected\",\"@onChange\"],[[30,1],[30,2],[30,4],[30,5]]],null],[1,\"\\n\"]],[]],null]],[3]]]],[]],[[[1,\"  \"],[8,[39,3],null,[[\"@value\",\"@setValue\"],[[28,[37,5],[[30,4],[30,1,[\"id\"]]],null],[28,[37,6],[[30,0,[\"toggleOption\"]],[30,1,[\"id\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,1,[\"name\"]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]],[\"@taxonomy\",\"@type\",\"subTaxonomies\",\"@selected\",\"@onChange\"],false,[\"if\",\"let\",\"get\",\"ui/input/checkbox\",\"filters/taxonomy-list/group\",\"any\",\"fn\"]]",
    "moduleName": "itarp-components/components/filters/taxonomy-list/options.hbs",
    "isStrictMode": false
  });

  let FiltersTaxonomyListOptionsComponent = (_class = class FiltersTaxonomyListOptionsComponent extends _component2.default {
    get allChecked() {
      const selected = this.args.selected;
      if (!selected || selected.length === 0) return false;
      return this.groupOptions.every(id => selected.includes(id));
    }

    get taxonomies() {
      const type = this.args.type;
      const taxonomy = this.args.taxonomy;
      if (taxonomy.isCategory) return taxonomy[type];
    }

    get groupOptions() {
      if (this.taxonomies && this.taxonomies.length) {
        return this.taxonomies.filter(t => !t.isCategory).map(({
          id
        }) => id);
      }
    }

    set allChecked(selectAll) {
      const selected = this.args.selected;

      if (selectAll) {
        const selection = this.groupOptions.filter(id => !selected.includes(id));
        selected.pushObjects(selection);
      } else {
        this.args.selected.removeObjects(this.groupOptions);
      }

      this.args.onChange && this.args.onChange();
    }

    toggleOption(id) {
      if (this.args.selected.includes(id)) {
        this.args.selected.removeObject(id);
      } else {
        this.args.selected.pushObject(id);
      }

      this.args.onChange && this.args.onChange();
    }

    toggleGroup() {}

  }, (_applyDecoratedDescriptor(_class.prototype, "toggleOption", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleOption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleGroup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleGroup"), _class.prototype)), _class);
  _exports.default = FiltersTaxonomyListOptionsComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FiltersTaxonomyListOptionsComponent);
});