define("itarp-workflow-designer/controllers/workflow/edit", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/object", "@ember/service", "itarp-workflow-designer/config/environment"], function (_exports, _controller, _tracking, _object, _service, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let WorkflowEditController = (_class = class WorkflowEditController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "appRouter", _descriptor, this);

      _initializerDefineProperty(this, "previewModalVisible", _descriptor2, this);

      _initializerDefineProperty(this, "deleteModalVisible", _descriptor3, this);

      _initializerDefineProperty(this, "selected", _descriptor4, this);

      _initializerDefineProperty(this, "newStage", _descriptor5, this);
    }

    async saveWorkflow(e) {
      e.preventDefault();
      this.workflow.save().then(() => {
        this.appRouter.transitionTo(`${_environment.default.modulePrefix}`);
      }, errors => {
        console.log(errors);
      });
      const unsaved = this.stageGroups.filterBy('hasDirtyAttributes', true);
      await Promise.all(unsaved.map(stageGroup => stageGroup.save()));
      this.selected = null;
    }

    cancelEditWorkflow() {
      this.selected = null;
      this.previewModalVisible = false;
      this.deleteModalVisible = false;
    }

    openStageForm() {
      this.selected = 'new';
      this.newStage = this.store.createRecord('stage-group');
    }

    saveStageChanges() {
      const includeCompleted = this.stageGroups.findBy('stageGroupType', 'job_complete');

      if (!this.newStage.stageGroup) {
        return;
      }

      const newStageGroup = this.store.createRecord('stage-group', {
        workflow: this.workflow,
        name: this.newStage.name,
        displayOrder: this.stageGroups.length + 1,
        workflowId: this.workflow.id,
        stageGroupType: this.newStage.stageGroup
      });
      this.stageGroups.pushObject(newStageGroup);

      if (includeCompleted) {
        includeCompleted.displayOrder = this.stageGroups.lastObject.displayOrder + 1;
        includeCompleted.workflowId = this.workflow.id;
        includeCompleted.save();
      }

      this.stageGroups = this.stageGroups.sortBy('displayOrder');
      this.selected = null;
      this.newStage = null;
    }

    cancelForm() {
      this.selected = null;
    }

    togglePreviewModal() {
      this.previewModalVisible = !this.previewModalVisible;
    }

    toggleDeleteModal(stage) {
      this.selected = stage;
      this.deleteModalVisible = !this.deleteModalVisible;
    }

    deleteStage() {
      this.selected.destroyRecord();
      this.stageGroups.removeObject(this.selected);
      this.deleteModalVisible = false;
      this.selected = null;
    }

    get deleteMessage() {
      if (this.selected.stages.length > 0) {
        const stagesLength = this.selected.stages.length > 1 ? 'Stages' : 'Stage';
        return `This Stage Group contains ${this.selected.stages.length} ${stagesLength}. Deleting this Stage Group will also delete all Stages within it. Are you sure you want to proceed?`;
      }
    }

    openEditForm(stage) {
      this.selected = stage;
    }

    saveEditForm() {
      if (!this.selected.workflowId) {
        this.selected.workflowId = this.workflow.id;
      }

      this.selected.set('name', this.selected.name);
      this.selected.set('stageGroupType', this.selected.stageGroupType);
      this.selected = null;
    }

    sortEndAction() {
      this.stageGroups.forEach((stage, index) => {
        stage.set('displayOrder', index + 1);
        stage.set('workflowId', this.workflow.id);
      });
    }

    get stageGroupList() {
      return ['Screening', 'Assessment', 'Shortlisted', 'Interview', 'Offer', 'Onboarding', 'Hired'];
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appRouter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "previewModalVisible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "deleteModalVisible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "newStage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "saveWorkflow", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveWorkflow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelEditWorkflow", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancelEditWorkflow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openStageForm", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openStageForm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveStageChanges", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveStageChanges"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelForm", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancelForm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "togglePreviewModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "togglePreviewModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleDeleteModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleDeleteModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteStage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteStage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openEditForm", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openEditForm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveEditForm", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveEditForm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortEndAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sortEndAction"), _class.prototype)), _class);
  _exports.default = WorkflowEditController;
});