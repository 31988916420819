define("itarp-business-application/services/google-api", ["exports", "@ember/service", "itarp-business-application/config/environment", "moment", "@glimmer/tracking", "itarp-business-application/utils/constants/google-api"], function (_exports, _service, _environment, _moment, _tracking, _googleApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let GoogleApiService = (_class = class GoogleApiService extends _service.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "geocodeUrl", 'https://maps.googleapis.com/maps/api/geocode/json?');

      _defineProperty(this, "autocomplete", null);

      _initializerDefineProperty(this, "token", _descriptor, this);

      _initializerDefineProperty(this, "timestamp", _descriptor2, this);

      _initializerDefineProperty(this, "countries", _descriptor3, this);
    }

    get options() {
      return {
        types: [_googleApi.PLACE.TYPES.GEO],
        fields: [_googleApi.PLACE.FIELDS.GEO, _googleApi.PLACE.FIELDS.ADDR],
        sessionToken: this.sessionToken,
        componentRestrictions: {
          country: this.countries
        }
      };
    }

    async initAutocomplete(input, opts) {
      const loaded = await this.isLoaded();
      if (loaded === false) return;
      this.countries.pushObject(opts.countries);
      this.autocomplete = new google.maps.places.Autocomplete(input, this.options);
      this.autocomplete.addListener('place_changed', this.getComponents.bind(this, opts.components, opts.onSelect));
    }

    setAutocompleteCountry(country) {
      this.countries = [country];
      this.autocomplete.setOptions(this.options);
    }

    generateSessionToken() {
      this.timestamp = (0, _moment.default)(new Date());
      this.token = new google.maps.places.AutocompleteSessionToken();
      return this.token;
    }

    get tokenIsExpired() {
      if (!this.timestamp) return true;
      const tokenAge = (0, _moment.default)(new Date()).diff(this.timestamp, 'seconds');
      const expired = tokenAge > 90;
      return expired;
    }

    get sessionToken() {
      if (this.tokenIsExpired) return this.generateSessionToken();
      return this.token;
    }

    async getComponents(componentTypes, callbackFn) {
      const place = await this.autocomplete.getPlace();
      const geopoint = place.geometry.location.toJSON();
      const result = {
        geopoint
      };

      for (const addrCpnt of componentTypes) {
        result[addrCpnt] = this.getComponent(place[_googleApi.PLACE.FIELDS.ADDR], addrCpnt);
      }

      if (!result[_googleApi.ADDR_CPNT.ZIP]) {
        result[_googleApi.ADDR_CPNT.ZIP] = await this.getPostalCode(place[_googleApi.PLACE.FIELDS.GEO]);
      }

      this.token = null;
      this.autocomplete.setOptions(this.options);
      return callbackFn(result);
    }

    async getPostalCode(geometry) {
      if (!geometry) return null;

      const params = this._toUrlQuery({
        latlng: geometry.location.toUrlValue(),
        result_type: _googleApi.ADDR_CPNT.ZIP,
        key: _environment.default.GOOGLE_API_KEY
      });

      return fetch(_googleApi.URLS.GEOCODE + params).then(async response => {
        if (response.ok) {
          const data = await response.json();
          if (!data.results.length) return null;
          const result = data.results[0];
          return this.getComponent(result[_googleApi.PLACE.FIELDS.ADDR], _googleApi.ADDR_CPNT.ZIP);
        }

        return null;
      });
    }

    getComponent(components, type) {
      const component = components.find(c => c.types[0] === type);
      return component ? component.long_name : null;
    }

    _toUrlQuery(obj) {
      return Object.keys(obj).map(key => `${key}=${obj[key]}`).join('&');
    }

    async isLoaded() {
      let loaded = !!window.google;

      if (loaded === false) {
        await this.loadMapsScript().then(() => {
          loaded = true;
        }).catch(error => {
          return error;
        });
      }

      return loaded;
    }

    async loadMapsScript() {
      return new Promise((resolve, reject) => {
        if (!_environment.default.GOOGLE_API_KEY) return reject('No Google API key');
        const maps = document.createElement('script');
        maps.type = 'text/javascript';
        maps.src = _googleApi.URLS.MAPS(_environment.default.GOOGLE_API_KEY);
        document.body.appendChild(maps);

        maps.onload = () => resolve();
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "token", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "timestamp", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "countries", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  })), _class);
  _exports.default = GoogleApiService;
});