define("itarp-opportunity-designer/routes/my-opportunities", ["exports", "@ember/routing/route", "rsvp", "itarp-opportunity-designer/utils/constants/opportunity", "itarp-query-filters/utils/schema/opportunity", "@ember/service", "@ember/object"], function (_exports, _route, _rsvp, _opportunity, _opportunity2, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MyOpportunitiesRoute = (_dec = (0, _service.inject)('filters'), (_class = class MyOpportunitiesRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        page: {
          refreshModel: true
        },
        status: {
          refreshModel: true
        },
        ..._opportunity2.default
      });

      _initializerDefineProperty(this, "filtersService", _descriptor, this);

      _defineProperty(this, "staleParams", null);

      _defineProperty(this, "initialLoad", true);
    }

    async model(params) {
      const shouldResetPage = this.shouldResetPageParams(params);
      if (shouldResetPage) params.page = _opportunity.DEFAULT_PAGE_NUMBER;
      const filters = this.filtersService.buildQuery(this, params);
      const keywords = this.getKeywords(filters, params);
      const jobType = this.filtersService.extractJobType(filters);
      const travel = this.filtersService.extractTravelRequirement(filters);
      let filter = { ...jobType,
        ...travel
      };
      const nonWorkflowStatus = ['draft', 'filled'];
      const isStageTypeFilter = !nonWorkflowStatus.includes(params.status);
      const queryParams = {
        keywords,
        filters,
        search_required: true,
        page: params.page,
        per_page: _opportunity.RECORDS_PER_PAGE,
        order_by: ['updated_at', 'desc'],
        adapterOptions: {
          myOpportunities: true
        }
      }; // Remove stage_type filter if status is 'all', so we get all opportunities

      if (params.status === 'all') {
        delete queryParams['stage_type'];
      } else {
        if (isStageTypeFilter) {
          queryParams['stage_type'] = params.status;
        } else {
          filter['status'] = params.status;
        }
      }

      queryParams['filter'] = filter;
      return (0, _rsvp.hash)({
        params,
        // for after model hook
        shouldResetPage,
        opportunities: this.store.query('opportunity', queryParams).then(data => ({
          data,
          meta: data.meta
        }))
      });
    }

    setupController(controller, model) {
      const {
        data,
        meta
      } = model.opportunities;
      if (model.shouldResetPage) controller.set('page', _opportunity.DEFAULT_PAGE_NUMBER);
      super.setupController(controller, data);

      if (meta) {
        controller.set('pageMeta', meta);
        controller.set('total', meta.total);
      }

      const hasMultiplePages = (meta === null || meta === void 0 ? void 0 : meta.total) > _opportunity.RECORDS_PER_PAGE;
      controller.set('hasMultiplePages', hasMultiplePages);
      controller.set('filterItems', { ...this.filterItems
      });
      controller.set('filterChips', this.filterChips);
      controller.set('filterSchema', this.queryParams);
      controller.set('updateQueryParams', this.updateQueryParams);
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('status', _opportunity.DEFAULT_STATUS);
        controller.set('page', _opportunity.DEFAULT_PAGE_NUMBER);
        ['keywords', 'job_type', 'travel', 'location', 'skills', 'min_hourly_rate', 'max_hourly_rate', 'min_annualy_rate', 'max_annualy_rate', 'min_monthly_rate', 'max_monthly_rate', 'min_fixed_rate', 'max_fixed_rate', 'remuneration', 'functional_skills', 'tools', 'place'].map(prop => controller.set(prop, null));
        this.initialLoad = true;
      }
    }

    beforeModel() {
      if (this.filterItems) return;
      const route = this;
      this.filterItems = this.filtersService.setupFilters(route);
    }

    afterModel(model) {
      const chips = this.filtersService.deserializeFilters(model.params, this.filterItems, this);
      this.initialLoad = false;
      this.filterChips = chips;
    }

    loading() {
      return this.initialLoad;
    }

    updateQueryParams() {
      const route = this;
      this.filtersService.serializeFilters(this.filterItems, route);
    }

    parametersMatch(params) {
      if (!this.staleParams) return true;
      const currentParams = Object.assign({}, params);
      const asString = JSON.stringify;
      delete currentParams.page;
      delete this.staleParams.page;
      return asString(currentParams) === asString(this.staleParams);
    }

    shouldResetPageParams(params) {
      const shouldReset = this.parametersMatch(params) !== true;
      this.staleParams = Object.assign({}, params);
      return shouldReset;
    }

    getKeywords(filters, {
      keywords
    }) {
      return this.filtersService.extractKeywords(filters, this.filterItems, keywords);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "filtersService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "beforeModel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "beforeModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterModel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "afterModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loading", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loading"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateQueryParams", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateQueryParams"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "parametersMatch", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "parametersMatch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "shouldResetPageParams", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "shouldResetPageParams"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getKeywords", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getKeywords"), _class.prototype)), _class));
  _exports.default = MyOpportunitiesRoute;
});