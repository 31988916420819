define("itarp-business-application/components/forms/slider", ["exports", "@glimmer/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#unless @config.attributes.hidden}}
    {{#let (unique-id) as |slider-button-id|}}
      <div
        class="form-switch-group
          {{if this.isRequired "is-required" "is-optional"}}
          {{if this.tip "has-tip"}}
          {{if this.error "has-error"}}"
        data-test-slider-component
      >
        <div class="form-switch">
          {{this.value}}
          <Input
            @type="checkbox"
            id="{{this.label}}"
            data-test-slider-input
            value="{{this.value}}"
            @checked={{this.value}}
            {{on "input" (fn this.updateValue)}}
          />
          <label
            data-test-slider-click-label
            for="{{this.label}}"
            class="form-switch__label
              {{if this.isRequired "label-note--required"}}"
          ></label>
        </div>
        <span class="form-switch-group__label" data-test-slider-label>
          {{this.label}}
        </span>
        {{#if this.tip}}
          <span class="form-tip" data-test-slider-tip>
            Tip
            <EmberPopover
              @event="hover"
              @side="left"
              @tooltipClass="form-tip__tooltip"
            >
              {{this.tip}}
            </EmberPopover>
          </span>
        {{/if}}
      </div>
    {{/let}}
  {{/unless}}
  */
  {
    "id": "KmYk/aYC",
    "block": "[[[41,[51,[30,1,[\"attributes\",\"hidden\"]]],[[[44,[[28,[37,2],null,null]],[[[1,\"    \"],[10,0],[15,0,[29,[\"form-switch-group\\n        \",[52,[30,0,[\"isRequired\"]],\"is-required\",\"is-optional\"],\"\\n        \",[52,[30,0,[\"tip\"]],\"has-tip\"],\"\\n        \",[52,[30,0,[\"error\"]],\"has-error\"]]]],[14,\"data-test-slider-component\",\"\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"form-switch\"],[12],[1,\"\\n        \"],[1,[30,0,[\"value\"]]],[1,\"\\n        \"],[8,[39,4],[[16,1,[29,[[30,0,[\"label\"]]]]],[24,\"data-test-slider-input\",\"\"],[16,2,[29,[[30,0,[\"value\"]]]]],[4,[38,5],[\"input\",[28,[37,6],[[30,0,[\"updateValue\"]]],null]],null]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"value\"]]]],null],[1,\"\\n        \"],[10,\"label\"],[14,\"data-test-slider-click-label\",\"\"],[15,\"for\",[29,[[30,0,[\"label\"]]]]],[15,0,[29,[\"form-switch__label\\n            \",[52,[30,0,[\"isRequired\"]],\"label-note--required\"]]]],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[14,0,\"form-switch-group__label\"],[14,\"data-test-slider-label\",\"\"],[12],[1,\"\\n        \"],[1,[30,0,[\"label\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,0,[\"tip\"]],[[[1,\"        \"],[10,1],[14,0,\"form-tip\"],[14,\"data-test-slider-tip\",\"\"],[12],[1,\"\\n          Tip\\n          \"],[8,[39,7],null,[[\"@event\",\"@side\",\"@tooltipClass\"],[\"hover\",\"left\",\"form-tip__tooltip\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,0,[\"tip\"]]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[2]]]],[]],null]],[\"@config\",\"slider-button-id\"],false,[\"unless\",\"let\",\"unique-id\",\"if\",\"input\",\"on\",\"fn\",\"ember-popover\"]]",
    "moduleName": "itarp-business-application/components/forms/slider.hbs",
    "isStrictMode": false
  });

  let FormsSliderComponent = (_dec = (0, _computed.alias)('args.config.attributes'), _dec2 = (0, _object.computed)('attributes.label'), _dec3 = (0, _object.computed)('attributes.isRequired'), _dec4 = (0, _object.computed)('attributes.alignment'), _dec5 = (0, _object.computed)('attributes.tip'), _dec6 = (0, _object.computed)('attributes.value'), (_class = class FormsSliderComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "attributes", _descriptor, this);
    }

    get label() {
      return this.attributes.label;
    }

    get isRequired() {
      return this.attributes.required;
    }

    get alignment() {
      return this.attributes.alignment || 'line';
    }

    get tip() {
      return this.attributes.tip;
    }

    get value() {
      return this.attributes.value;
    }

    updateValue() {
      (0, _object.set)(this.attributes, 'value', !this.value);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "attributes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "label", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "label"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isRequired", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "isRequired"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "alignment", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "alignment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "tip", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "tip"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "value", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "value"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateValue"), _class.prototype)), _class));
  _exports.default = FormsSliderComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormsSliderComponent);
});