define("itarp-opportunity-designer/serializers/accessibility-group", ["exports", "itarp-shared-assets-addon/serializers/data", "@ember-data/serializer/rest"], function (_exports, _data, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class AcessibilityGroupSerializer extends _data.default.extend(_rest.EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        options: {
          embedded: 'always'
        }
      });
    }

  }

  _exports.default = AcessibilityGroupSerializer;
});