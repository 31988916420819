define("itarp-gui-designer/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    this.route('gui-designer', {
      path: ''
    });
    this.route('new');
    this.route('edit', {
      path: 'pages/:id/edit'
    });
  });

  _exports.default = _default;
});