define("ember-a11y-accordion/utils/dom", ["exports", "@ember/runloop"], function (_exports, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setOpenHeight = setOpenHeight;
  _exports.setClosedHeight = setClosedHeight;
  _exports.addEventListenerOnce = addEventListenerOnce;
  _exports.CLASS_NAMES = void 0;

  /**
   * All addon selectos.
   */
  const CLASS_NAMES = {
    list: 'a11y-accordion-list',
    item: 'a11y-accordion-item',
    itemExpanded: 'a11y-accordion-item--is-expanded',
    itemDisabled: 'a11y-accordion-item--is-disabled',
    header: 'a11y-accordion-header',
    trigger: 'a11y-accordion-header__trigger',
    panelWrapper: 'a11y-accordion-panel-wrapper',
    panelContent: 'a11y-accordion-panel-content'
  };
  /**
   * Calculates and set's an items open height.
   *
   * @param {Object} item
   * @public
   */

  _exports.CLASS_NAMES = CLASS_NAMES;

  function setOpenHeight(_ref) {
    let {
      panelWrapper,
      panelContent
    } = _ref;
    let height = panelContent.getBoundingClientRect().height;
    panelWrapper.style.height = `${height}px`;
  }
  /**
   * Calculates and set's an items closed height.
   *
   * @param {Object} item
   * @public
   */


  function setClosedHeight(_ref2) {
    let {
      panelWrapper
    } = _ref2;
    panelWrapper.style.height = `0px`;
  }
  /**
   * Attaches an event listener, and when the listener is
   * executed, it removes it immediately.
   *
   * @param {HTMLElement} element
   * @param {String} eventName
   * @param {Function} callback
   * @public
   */


  function addEventListenerOnce(element, eventName, callback) {
    element.addEventListener(eventName, function handler(e) {
      (0, _runloop.run)(() => {
        element.removeEventListener(eventName, handler);
        callback && callback(e);
      });
    });
  }
});