define("itarp-components/components/table/candidates/column/availability", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let @row as |row|}}
    <row.cell ...attributes>
      <Ui::Wrapper>
        <div class='card-block--work'>
          <ul class='card-block--work__list'>
            {{#if @candidate.profile.hoursPerWeek}}
              <li class='card-block--work__list__hours'>
                {{@candidate.profile.hoursPerWeek}}
                <span class='card-block--work__list__hours__unit'>Hrs/Wk</span>
              </li>
            {{/if}}
            <li
              class='card-block--work__list__availability
                {{if
                  @candidate.profile.workAvailable
                  'is-available'
                  'not-available'
                }}'
            ></li>
          </ul>
        </div>
      </Ui::Wrapper>
    </row.cell>
  {{/let}}
  
  */
  {
    "id": "j/H6/x5b",
    "block": "[[[44,[[30,1]],[[[1,\"  \"],[8,[30,2,[\"cell\"]],[[17,3]],null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"card-block--work\"],[12],[1,\"\\n        \"],[10,\"ul\"],[14,0,\"card-block--work__list\"],[12],[1,\"\\n\"],[41,[30,4,[\"profile\",\"hoursPerWeek\"]],[[[1,\"            \"],[10,\"li\"],[14,0,\"card-block--work__list__hours\"],[12],[1,\"\\n              \"],[1,[30,4,[\"profile\",\"hoursPerWeek\"]]],[1,\"\\n              \"],[10,1],[14,0,\"card-block--work__list__hours__unit\"],[12],[1,\"Hrs/Wk\"],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"          \"],[10,\"li\"],[15,0,[29,[\"card-block--work__list__availability\\n              \",[52,[30,4,[\"profile\",\"workAvailable\"]],\"is-available\",\"not-available\"]]]],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]],[\"@row\",\"row\",\"&attrs\",\"@candidate\"],false,[\"let\",\"ui/wrapper\",\"if\"]]",
    "moduleName": "itarp-components/components/table/candidates/column/availability.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});