define("ember-bootstrap/components/bs-modal/footer", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let (ensure-safe-component (bs-default @buttonComponent (component "bs-button"))) as |Button|}}
    <form class="modal-footer" ...attributes {{on "submit" (bs-default @onSubmit (bs-noop))}}>
      {{#if (has-block)}}
        {{yield}}
      {{else}}
        {{#if @submitTitle}}
          <Button @onClick={{@onClose}}>{{bs-default @closeTitle "Ok"}}</Button>
          <Button @type={{bs-default @submitButtonType "primary"}} onClick={{@onSubmit}} disabled={{bs-default @submitDisabled false}}>{{@submitTitle}}</Button>
        {{else}}
          <Button @type="primary" @onClick={{@onClose}}>{{bs-default @closeTitle "Ok"}}</Button>
        {{/if}}
      {{/if}}
    </form>
  {{/let}}
  */
  {
    "id": "3xOojTH4",
    "block": "[[[44,[[28,[37,1],[[28,[37,2],[[30,1],[50,\"bs-button\",0,null,null]],null]],null]],[[[1,\"  \"],[11,\"form\"],[24,0,\"modal-footer\"],[17,3],[4,[38,4],[\"submit\",[28,[37,2],[[30,4],[28,[37,5],null,null]],null]],null],[12],[1,\"\\n\"],[41,[48,[30,10]],[[[1,\"      \"],[18,10,null],[1,\"\\n\"]],[]],[[[41,[30,5],[[[1,\"        \"],[8,[30,2],null,[[\"@onClick\"],[[30,6]]],[[\"default\"],[[[[1,[28,[35,2],[[30,7],\"Ok\"],null]]],[]]]]],[1,\"\\n        \"],[8,[30,2],[[16,\"onClick\",[30,4]],[16,\"disabled\",[28,[37,2],[[30,8],false],null]]],[[\"@type\"],[[28,[37,2],[[30,9],\"primary\"],null]]],[[\"default\"],[[[[1,[30,5]]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[30,2],null,[[\"@type\",\"@onClick\"],[\"primary\",[30,6]]],[[\"default\"],[[[[1,[28,[35,2],[[30,7],\"Ok\"],null]]],[]]]]],[1,\"\\n\"]],[]]]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[2]]]],[\"@buttonComponent\",\"Button\",\"&attrs\",\"@onSubmit\",\"@submitTitle\",\"@onClose\",\"@closeTitle\",\"@submitDisabled\",\"@submitButtonType\",\"&default\"],false,[\"let\",\"ensure-safe-component\",\"bs-default\",\"component\",\"on\",\"bs-noop\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-modal/footer.hbs",
    "isStrictMode": false
  });
  /**
  
   Modal footer element used within [Components.Modal](Components.Modal.html) components. See there for examples.
  
   @class ModalFooter
   @namespace Components
   @extends Glimmer.Component
   @public
   */

  /**
   * The title of the default close button. Will be ignored (i.e. no close button) if you provide your own block
   * template.
   *
   * @property closeTitle
   * @type string
   * @default 'Ok'
   * @public
   */

  /**
   * The title of the submit button (primary button). Will be ignored (i.e. no button) if set to `null` or if you provide
   * your own block template.
   *
   * @property submitTitle
   * @type string
   * @default null
   * @public
   */

  /**
   * Set to `true` to disable the submit button. If you bind this to some property that indicates if submitting is allowed
   * (form validation for example) this can be used to prevent the user from pressing the submit button.
   *
   * @property submitDisabled
   * @type boolean
   * @default false
   * @public
   */

  /**
   * The type of the submit button (primary button).
   *
   * @property submitButtonType
   * @type string
   * @default 'primary'
   * @public
   */

  /**
   * @property buttonComponent
   * @type {String}
   * @private
   */

  /**
   * The action to send to the parent modal component when the modal footer's form is submitted
   *
   * @event onSubmit
   * @public
   */

  /**
   * @event onClose
   * @public
   */


  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});