define("itarp-components/components/ui/table", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='table-wrapper'>
    <div class='{{if @scroll 'table-scroll'}}'>
      <table
        class='table table--{{this.theme}} {{unless @hover 'table--no-hover'}}'
      >
        {{yield
          (hash head=(component 'ui/table/head') body=(component 'ui/table/body'))
        }}
      </table>
    </div>
  </div>
  
  */
  {
    "id": "5Gav/0P7",
    "block": "[[[10,0],[14,0,\"table-wrapper\"],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[52,[30,1],\"table-scroll\"]]]],[12],[1,\"\\n    \"],[10,\"table\"],[15,0,[29,[\"table table--\",[30,0,[\"theme\"]],\" \",[52,[51,[30,2]],\"table--no-hover\"]]]],[12],[1,\"\\n      \"],[18,3,[[28,[37,3],null,[[\"head\",\"body\"],[[50,\"ui/table/head\",0,null,null],[50,\"ui/table/body\",0,null,null]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@scroll\",\"@hover\",\"&default\"],false,[\"if\",\"unless\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-components/components/ui/table.hbs",
    "isStrictMode": false
  });

  class UiTableComponent extends _component2.default {
    get theme() {
      const defaultTheme = 'dark';
      return this.args.theme || defaultTheme;
    }

  }

  _exports.default = UiTableComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiTableComponent);
});