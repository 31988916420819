define("itarp-expert-preview/services/ad-token-service", ["exports", "itarp-auth-addon/services/ad-token-service", "itarp-expert-preview/config/environment"], function (_exports, _adTokenService, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AdTokenService extends _adTokenService.default {
    getEnv() {
      return _environment.default['itarp-auth-addon'];
    }

  }

  _exports.default = AdTokenService;
});