define("itarp-workflow-designer/adapters/action-group", ["exports", "itarp-auth-addon/adapters/ad-token-adapter"], function (_exports, _adTokenAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ActionGroupAdapter extends _adTokenAdapter.default {// namespace = '/routing/workflow/api/v1/actions';
    // pathForType() {
    //   return 'templates';
    // }
  }

  _exports.default = ActionGroupAdapter;
});