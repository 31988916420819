define("ember-power-calendar/components/power-calendar/days", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object", "@ember/runloop", "@ember/service", "@ember/debug", "ember-power-calendar/templates/components/power-calendar/days", "ember-power-calendar-utils"], function (_exports, _component, _component2, _object, _runloop, _service, _debug, _days, _emberPowerCalendarUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class2, _class3, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const WEEK_DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  let _class = (_dec = (0, _component.layout)(_days.default), _dec2 = (0, _component.tagName)(''), _dec3 = (0, _service.inject)('power-calendar'), _dec4 = (0, _object.computed)('calendar.locale'), _dec5 = (0, _object.computed)('calendar.locale'), _dec6 = (0, _object.computed)('calendar.locale'), _dec7 = (0, _object.computed)('weekdaysShort', 'startOfWeek'), _dec8 = (0, _object.computed)('localeStartOfWeek', 'weekdayFormat', 'calendar.locale'), _dec9 = (0, _object.computed)('calendar', 'focusedId', 'localeStartOfWeek', 'minDate', 'maxDate', 'disabledDates.[]', 'maxLength'), _dec10 = (0, _object.computed)('showDaysAround', 'days'), _dec11 = (0, _object.computed)('center', 'calendar.center'), _dec(_class2 = _dec2(_class2 = (_class3 = class _class3 extends _component2.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "focusedId", undefined);

      _defineProperty(this, "showDaysAround", true);

      _defineProperty(this, "weekdayFormat", 'short');

      _initializerDefineProperty(this, "powerCalendarService", _descriptor, this);
    }

    // CPs
    get weekdaysMin() {
      return (0, _emberPowerCalendarUtils.withLocale)(this.calendar.locale, _emberPowerCalendarUtils.getWeekdaysMin);
    }

    get weekdaysShort() {
      return (0, _emberPowerCalendarUtils.withLocale)(this.calendar.locale, _emberPowerCalendarUtils.getWeekdaysShort);
    }

    get weekdays() {
      return (0, _emberPowerCalendarUtils.withLocale)(this.calendar.locale, _emberPowerCalendarUtils.getWeekdays);
    }

    get localeStartOfWeek() {
      let forcedStartOfWeek = this.startOfWeek;

      if (forcedStartOfWeek) {
        return parseInt(forcedStartOfWeek, 10);
      }

      return (0, _emberPowerCalendarUtils.localeStartOfWeek)(this.calendar.locale);
    }

    get weekdaysNames() {
      let {
        localeStartOfWeek,
        weekdayFormat
      } = this;
      let format = `weekdays${weekdayFormat === 'long' ? '' : weekdayFormat === 'min' ? 'Min' : 'Short'}`;
      let weekdaysNames = this[format];
      return weekdaysNames.slice(localeStartOfWeek).concat(weekdaysNames.slice(0, localeStartOfWeek));
    }

    get days() {
      let today = this.powerCalendarService.getDate();
      let lastDay = this.lastDay();
      let day = this.firstDay();
      let days = [];

      while ((0, _emberPowerCalendarUtils.isBefore)(day, lastDay)) {
        days.push(this.buildDay(day, today, this.calendar));
        day = (0, _emberPowerCalendarUtils.add)(day, 1, "day");
      }

      return days;
    }

    get weeks() {
      let {
        showDaysAround,
        days
      } = this;
      let weeks = [];
      let i = 0;

      while (days[i]) {
        let daysOfWeek = days.slice(i, i + 7);

        if (!showDaysAround) {
          daysOfWeek = daysOfWeek.filter(d => d.isCurrentMonth);
        }

        weeks.push({
          id: `week-of-${daysOfWeek[0].id}`,
          days: daysOfWeek,
          missingDays: 7 - daysOfWeek.length
        });
        i += 7;
      }

      return weeks;
    }

    get currentCenter() {
      let center = this.center;

      if (!center) {
        center = this.selected || this.calendar.center;
      }

      return (0, _emberPowerCalendarUtils.normalizeDate)(center);
    } // Actions


    handleDayFocus(e) {
      (0, _runloop.scheduleOnce)('actions', this, this._updateFocused, e.target.dataset.date);
    }

    handleDayBlur() {
      (0, _runloop.scheduleOnce)('actions', this, this._updateFocused, null);
    }

    handleKeyDown(e) {
      let {
        focusedId
      } = this;

      if (focusedId) {
        let days = this.days;
        let day, index;

        for (let i = 0; i < days.length; i++) {
          if (days[i].id === focusedId) {
            index = i;
            break;
          }
        }

        if (e.keyCode === 38) {
          e.preventDefault();
          let newIndex = Math.max(index - 7, 0);
          day = days[newIndex];

          if (day.isDisabled) {
            for (let i = newIndex + 1; i <= index; i++) {
              day = days[i];

              if (!day.isDisabled) {
                break;
              }
            }
          }
        } else if (e.keyCode === 40) {
          e.preventDefault();
          let newIndex = Math.min(index + 7, days.length - 1);
          day = days[newIndex];

          if (day.isDisabled) {
            for (let i = newIndex - 1; i >= index; i--) {
              day = days[i];

              if (!day.isDisabled) {
                break;
              }
            }
          }
        } else if (e.keyCode === 37) {
          day = days[Math.max(index - 1, 0)];

          if (day.isDisabled) {
            return;
          }
        } else if (e.keyCode === 39) {
          day = days[Math.min(index + 1, days.length - 1)];

          if (day.isDisabled) {
            return;
          }
        } else {
          return;
        }

        this.set('focusedId', day.id);
        (0, _runloop.scheduleOnce)('afterRender', this, '_focusDate', day.id);
      }
    } // Methods


    buildDay(date, today, calendar) {
      let id = (0, _emberPowerCalendarUtils.formatDate)(date, 'YYYY-MM-DD');
      return (0, _emberPowerCalendarUtils.normalizeCalendarDay)({
        id,
        number: date.getDate(),
        date: new Date(date),
        isDisabled: this.dayIsDisabled(date),
        isFocused: this.focusedId === id,
        isCurrentMonth: date.getMonth() === this.currentCenter.getMonth(),
        isToday: (0, _emberPowerCalendarUtils.isSame)(date, today, 'day'),
        isSelected: this.dayIsSelected(date, calendar)
      });
    }

    buildonSelectValue(day) {
      return day;
    }

    dayIsSelected(date, calendar = this.calendar) {
      return calendar.selected ? (0, _emberPowerCalendarUtils.isSame)(date, calendar.selected, 'day') : false;
    }

    dayIsDisabled(date) {
      let isDisabled = !this.calendar.actions.select;

      if (isDisabled) {
        return true;
      }

      if (this.minDate && (0, _emberPowerCalendarUtils.isBefore)(date, (0, _emberPowerCalendarUtils.startOf)(this.minDate, 'day'))) {
        return true;
      }

      if (this.maxDate && (0, _emberPowerCalendarUtils.isAfter)(date, (0, _emberPowerCalendarUtils.endOf)(this.maxDate, 'day'))) {
        return true;
      }

      if (this.disabledDates) {
        let disabledInRange = this.disabledDates.some(d => {
          let isSameDay = (0, _emberPowerCalendarUtils.isSame)(date, d, 'day');
          let isWeekDayIncludes = WEEK_DAYS.indexOf(d) !== -1 && (0, _emberPowerCalendarUtils.formatDate)(date, 'ddd') === d;
          return isSameDay || isWeekDayIncludes;
        });

        if (disabledInRange) {
          return true;
        }
      }

      return false;
    }

    firstDay() {
      let firstDay = (0, _emberPowerCalendarUtils.startOf)(this.currentCenter, 'month');
      return (0, _emberPowerCalendarUtils.startOfWeek)(firstDay, this.localeStartOfWeek);
    }

    lastDay() {
      let localeStartOfWeek = this.localeStartOfWeek;
      (false && !(!isNaN(this.currentCenter.getTime())) && (0, _debug.assert)("The center of the calendar is an invalid date.", !isNaN(this.currentCenter.getTime())));
      let lastDay = (0, _emberPowerCalendarUtils.endOf)(this.currentCenter, 'month');
      return (0, _emberPowerCalendarUtils.endOfWeek)(lastDay, localeStartOfWeek);
    }

    _updateFocused(id) {
      this.set('focusedId', id);
    }

    _focusDate(id) {
      let dayElement = document.querySelector(`[data-power-calendar-id="${this.calendar.uniqueId}"] [data-date="${id}"]`);

      if (dayElement) {
        dayElement.focus();
      }
    }

    handleClick(e) {
      let dayEl = e.target.closest('[data-date]');

      if (dayEl) {
        let dateStr = dayEl.dataset.date;
        let day = this.days.find(d => d.id === dateStr);

        if (day) {
          if (this.calendar.actions.select) {
            this.calendar.actions.select(day, this.calendar, e);
          }
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class3.prototype, "powerCalendarService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "weekdaysMin", [_dec4], Object.getOwnPropertyDescriptor(_class3.prototype, "weekdaysMin"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "weekdaysShort", [_dec5], Object.getOwnPropertyDescriptor(_class3.prototype, "weekdaysShort"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "weekdays", [_dec6], Object.getOwnPropertyDescriptor(_class3.prototype, "weekdays"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "localeStartOfWeek", [_dec7], Object.getOwnPropertyDescriptor(_class3.prototype, "localeStartOfWeek"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "weekdaysNames", [_dec8], Object.getOwnPropertyDescriptor(_class3.prototype, "weekdaysNames"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "days", [_dec9], Object.getOwnPropertyDescriptor(_class3.prototype, "days"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "weeks", [_dec10], Object.getOwnPropertyDescriptor(_class3.prototype, "weeks"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "currentCenter", [_dec11], Object.getOwnPropertyDescriptor(_class3.prototype, "currentCenter"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "handleDayFocus", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "handleDayFocus"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "handleDayBlur", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "handleDayBlur"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "handleKeyDown", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "handleKeyDown"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "handleClick", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "handleClick"), _class3.prototype)), _class3)) || _class2) || _class2);

  _exports.default = _class;
});