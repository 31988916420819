define("itarp-components/components/table/candidates/column/match-score", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let @row (array 1 2 3 4 5) as |row scores|}}
    <row.cell ...attributes>
      <Ui::Wrapper>
        <div class='card-block--match-score'>
          {{#each scores}}
            <span class='card-block--match-score__item is-filled'></span>
          {{/each}}
        </div>
      </Ui::Wrapper>
    </row.cell>
  {{/let}}
  
  */
  {
    "id": "xRPXAxQJ",
    "block": "[[[44,[[30,1],[28,[37,1],[1,2,3,4,5],null]],[[[1,\"  \"],[8,[30,2,[\"cell\"]],[[17,4]],null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"card-block--match-score\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,3]],null]],null],null,[[[1,\"          \"],[10,1],[14,0,\"card-block--match-score__item is-filled\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2,3]]]],[\"@row\",\"row\",\"scores\",\"&attrs\"],false,[\"let\",\"array\",\"ui/wrapper\",\"each\",\"-track-array\"]]",
    "moduleName": "itarp-components/components/table/candidates/column/match-score.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});