define("itarp-business-application/utils/observable", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let Observable = (_class = class Observable {
    constructor() {
      this.subscribers = {};
    }

    subscribe(event, callback) {
      if (!this.subscribers[event]) {
        this.subscribers[event] = [];
      }

      this.subscribers[event].push(callback);
    }

    publish(event, ...rest) {
      if (!this.subscribers[event]) {
        return;
      }

      this.subscribers[event].forEach(callback => {
        callback(...rest);
      });
    }

    unsubscribe(event, callback) {
      if (!this.subscribers[event]) {
        return;
      }

      this.subscribers[event] = this.subscribers[event].filter(subscriber => subscriber !== callback);
    }

    eventSubscribers(event) {
      return this.subscribers[event];
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "subscribe", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "subscribe"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "publish", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "publish"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unsubscribe", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "unsubscribe"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "eventSubscribers", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "eventSubscribers"), _class.prototype)), _class);
  _exports.default = Observable;
});