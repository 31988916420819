define("shared/serializers/meta-model", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MetaModelSerializer extends _jsonApi.default {
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var _payload$records;

      return super.normalizeResponse(store, primaryModelClass, {
        data: (_payload$records = payload.records) === null || _payload$records === void 0 ? void 0 : _payload$records.map(this.normalizeModel)
      }, id, requestType);
    }

    normalizeModel(data) {
      return {
        attributes: {
          name: data.name.toLowerCase(),
          'collection-name': data.model_collection_name
        },
        id: data._id['$oid'] || data._id,
        type: 'meta-model'
      };
    }

  }

  _exports.default = MetaModelSerializer;
});