define("itarp-business-application/components/section", ["exports", "@glimmer/component", "itarp-business-application/utils/observable"], function (_exports, _component, _observable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#unless @config.attributes.hidden}}
    {{yield (hash observer=this.observer)}}
  {{/unless}}
  */
  {
    "id": "QrjST/Hr",
    "block": "[[[41,[51,[30,1,[\"attributes\",\"hidden\"]]],[[[1,\"  \"],[18,2,[[28,[37,2],null,[[\"observer\"],[[30,0,[\"observer\"]]]]]]],[1,\"\\n\"]],[]],null]],[\"@config\",\"&default\"],false,[\"unless\",\"yield\",\"hash\"]]",
    "moduleName": "itarp-business-application/components/section.hbs",
    "isStrictMode": false
  });

  class SectionComponent extends _component.default {
    constructor() {
      super(...arguments);
      this.observer = new _observable.default();
    }

  }

  _exports.default = SectionComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SectionComponent);
});