define("itarp-opportunity-ui/adapters/offer", ["exports", "itarp-auth-addon/adapters/ad-token-data"], function (_exports, _adTokenData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class OfferAdapter extends _adTokenData.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", '/opportunity/data_service/');
    }

    urlForUpdateRecord(id, modelName, snapshot) {
      var _snapshot$adapterOpti;

      const mode = (_snapshot$adapterOpti = snapshot.adapterOptions) === null || _snapshot$adapterOpti === void 0 ? void 0 : _snapshot$adapterOpti.mode;

      if (mode) {
        return `/opportunity/offers/${id}/${mode}`;
      }

      return super.urlForUpdateRecord(id, modelName, snapshot);
    }

  }

  _exports.default = OfferAdapter;
});