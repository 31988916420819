define("itarp-template-designer/mirage/helpers/paginate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(request, context, resource) {
    const page = parseInt(request.queryParams['page']);
    const size = parseInt(request.queryParams['per_page']);

    if (!page && !size) {
      return resource;
    }

    const slice = resource.slice((page - 1) * size, (page - 1) * size + size);
    const response = context.serializerOrRegistry.serialize(slice, request);
    response.meta = {
      page: {
        total: resource.length,
        per_page: size,
        page: page
      }
    };
    return response;
  }
});