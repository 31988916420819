define("shared/utils/schema/opportunity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const schema = {
    keywords: {
      refreshModel: true,
      type: 'keyword',
      hidden: true
    },
    min_hourly_rate: {
      refreshModel: true,
      type: 'number',
      key: 'min_rate',
      valueKey: 'gte',
      label: '${value} Min Hourly Rate'
    },
    max_hourly_rate: {
      refreshModel: true,
      type: 'number',
      key: 'max_rate',
      valueKey: 'lte',
      label: '${value} Max Hourly Rate'
    },
    min_annualy_rate: {
      refreshModel: true,
      type: 'number',
      key: 'min_rate',
      valueKey: 'gte',
      label: '${value} Min Annual Salary'
    },
    max_annualy_rate: {
      refreshModel: true,
      type: 'number',
      key: 'max_rate',
      valueKey: 'lte',
      label: '${value} Max Annual Salary'
    },
    min_monthly_rate: {
      refreshModel: true,
      type: 'number',
      key: 'min_rate',
      valueKey: 'gte',
      label: '${value} Min Monthly Salary'
    },
    max_monthly_rate: {
      refreshModel: true,
      type: 'number',
      key: 'max_rate',
      valueKey: 'lte',
      label: '${value} Max Monthly Salary'
    },
    min_fixed_rate: {
      refreshModel: true,
      type: 'number',
      key: 'min_rate',
      valueKey: 'gte',
      label: '${value} Min Fixed Price'
    },
    max_fixed_rate: {
      refreshModel: true,
      type: 'number',
      key: 'max_rate',
      valueKey: 'lte',
      label: '${value} Max Fixed Price'
    },
    remuneration: {
      refreshModel: true,
      type: 'text',
      key: 'remunerations.raw',
      label: '{value}',
      hidden: true
    },
    job_type: {
      refreshModel: true,
      type: 'text',
      key: 'job_type.raw',
      label: '{value}'
    },
    travel: {
      refreshModel: true,
      type: 'array',
      key: 'travel_requirement',
      valueKey: 'raw',
      multiple: true,
      label: '{value} Travel Requirement'
    },
    location: {
      refreshModel: true,
      type: 'geopoint',
      hidden: true,
      key: 'location_coordinates'
    },
    skills: {
      refreshModel: true,
      type: 'keyword',
      key: 'skills',
      multiple: true,
      object: true,
      objectValue: 'value',
      label: '{value}'
    },
    functional_skills: {
      refreshModel: true,
      type: 'array',
      key: 'skills.taxonomy_id.raw',
      multiple: true,
      label: '{value}',
      model: true,
      modelType: 'taxonomies-category',
      modelAttribute: 'name'
    },
    tools: {
      refreshModel: true,
      type: 'array',
      key: 'tools.taxonomy_id.raw',
      multiple: true,
      label: '{value}',
      model: true,
      modelType: 'taxonomies-category',
      modelAttribute: 'name'
    },
    open_to_remote: {
      refreshModel: true,
      type: 'boolean',
      key: 'open_to_remote',
      label: 'Open to Remote'
    },
    status: {
      refreshModel: true,
      type: 'array',
      key: 'status',
      label: '{value}'
    }
  };

  var _default = Object.freeze(schema);

  _exports.default = _default;
});