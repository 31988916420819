define("itarp-template-designer/controllers/settings", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TemplatesSettingsController extends _controller.default {}

  _exports.default = TemplatesSettingsController;
});