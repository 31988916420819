define("itarp-business-application/util/schema-manager/schemas/relation", ["exports", "itarp-business-application/util/schema-manager/schemas/relation-types", "@ember/object"], function (_exports, _relationTypes, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = Relationship;

  function Relationship(relation, storeManager) {
    const relationType = relation.is_array ? _relationTypes.relationTypes.collection : _relationTypes.relationTypes.single;
    return new relationType(relation, storeManager);
  }
});