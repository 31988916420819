define("itarp-business-application/components/taxonomy/panel", ["exports", "@glimmer/component", "@ember/object", "@ember/runloop", "@glimmer/tracking"], function (_exports, _component, _object, _runloop, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (and (not @searchTerm) (not @disableSelectAll))}}
    <div class="recommendation-row">
      <div class="checkbox-group">
        <Input
          @type="checkbox"
          class="checkbox-group__field"
          id={{@category.id}}
          @checked={{this.checked}}
        />
        <label class="checkbox-group__label" for={{@category.id}}>
          Select All
        </label>
      </div>
    </div>
  {{/if}}
  {{#each this.list as |taxonomy|}}
    {{#if (and taxonomy.isCategory taxonomy.children.length)}}
      <Taxonomy::Group
        @add={{@add}}
        @remove={{@remove}}
        @taxonomy={{taxonomy}}
        @type={{@type}}
        @childrenType={{@childrenType}}
        @registerGroup={{this.registerGroup}}
        @searchTerm={{@searchTerm}}
        @getSelected={{this.getSelected}}
        @depth="2"
      />
    {{else if (and (eq taxonomy.type @type) (not taxonomy.isCategory))}}
      <Taxonomy::Option
        @option={{taxonomy}}
        @select={{@add}}
        @deselect={{@remove}}
        @register={{this.register}}
        @isSelected={{is-any @selection "taxonomyId" taxonomy.id}}
      />
    {{/if}}
  {{/each}}
  
  */
  {
    "id": "FZTXfuxU",
    "block": "[[[41,[28,[37,1],[[28,[37,2],[[30,1]],null],[28,[37,2],[[30,2]],null]],null],[[[1,\"  \"],[10,0],[14,0,\"recommendation-row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"checkbox-group\"],[12],[1,\"\\n      \"],[8,[39,3],[[24,0,\"checkbox-group__field\"],[16,1,[30,3,[\"id\"]]]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"checked\"]]]],null],[1,\"\\n      \"],[10,\"label\"],[14,0,\"checkbox-group__label\"],[15,\"for\",[30,3,[\"id\"]]],[12],[1,\"\\n        Select All\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"list\"]]],null]],null],null,[[[41,[28,[37,1],[[30,4,[\"isCategory\"]],[30,4,[\"children\",\"length\"]]],null],[[[1,\"    \"],[8,[39,6],null,[[\"@add\",\"@remove\",\"@taxonomy\",\"@type\",\"@childrenType\",\"@registerGroup\",\"@searchTerm\",\"@getSelected\",\"@depth\"],[[30,5],[30,6],[30,4],[30,7],[30,8],[30,0,[\"registerGroup\"]],[30,1],[30,0,[\"getSelected\"]],\"2\"]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[28,[37,7],[[30,4,[\"type\"]],[30,7]],null],[28,[37,2],[[30,4,[\"isCategory\"]]],null]],null],[[[1,\"    \"],[8,[39,8],null,[[\"@option\",\"@select\",\"@deselect\",\"@register\",\"@isSelected\"],[[30,4],[30,5],[30,6],[30,0,[\"register\"]],[28,[37,9],[[30,9],\"taxonomyId\",[30,4,[\"id\"]]],null]]],null],[1,\"\\n  \"]],[]],null]],[]]]],[4]],null]],[\"@searchTerm\",\"@disableSelectAll\",\"@category\",\"taxonomy\",\"@add\",\"@remove\",\"@type\",\"@childrenType\",\"@selection\"],false,[\"if\",\"and\",\"not\",\"input\",\"each\",\"-track-array\",\"taxonomy/group\",\"eq\",\"taxonomy/option\",\"is-any\"]]",
    "moduleName": "itarp-business-application/components/taxonomy/panel.hbs",
    "isStrictMode": false
  });

  let TaxonomyPanelComponent = (_class = class TaxonomyPanelComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "optionGroups", _descriptor, this);

      _initializerDefineProperty(this, "options", _descriptor2, this);

      _initializerDefineProperty(this, "groupSet", _descriptor3, this);

      if (this.args.register) this.args.register(this.optionGroups);
    }

    getSelected(taxonomy) {
      return taxonomy.children.filter(child => this.args.selection.isAny('taxonomyId', child.id)).map(child => child.id);
      this.args.selection.filterBy('parent.id', id).map(i => i.id);
    }

    register(option) {
      (0, _runloop.next)(this, () => {
        this.options.pushObject(option);
      });
    }

    registerGroup(group) {
      (0, _runloop.next)(this, () => {
        this.optionGroups.pushObject(group);
      });
    }

    get list() {
      const {
        list,
        searchTerm,
        type,
        childrenType
      } = this.args;

      if (searchTerm) {
        return list.filter(child => {
          if (child.isCategory) return child;
          const matchingType = child.type === type;
          return matchingType && child.name.toLowerCase().includes(searchTerm.toLowerCase());
        });
      }

      return list.filter(child => {
        const hasChildren = child.get(childrenType).length;
        const hasChildCategories = child.categories.length;
        const matchingType = child.type === type;
        const isChild = !hasChildren && !hasChildCategories && matchingType;
        return hasChildren || hasChildCategories || isChild;
      });
    }

    get checked() {
      return this.optionGroups.isEvery('allSelected') && this.options.isEvery('checked');
    }

    set checked(value) {
      this.optionGroups.invoke('update', value);
      this.options.invoke('update', value);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "optionGroups", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "options", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "groupSet", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "getSelected", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "register", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "register"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerGroup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerGroup"), _class.prototype)), _class);
  _exports.default = TaxonomyPanelComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TaxonomyPanelComponent);
});