define("itarp-shared-assets-addon/helpers/isSelected", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class isSelected extends _helper.default {
    compute([array, fields]) {
      return array && array.includes(fields);
    }

  }

  _exports.default = isSelected;
});