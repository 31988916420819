define("itarp-talentcloud-ui/adapters/talentcloud-invitation", ["exports", "itarp-auth-addon/adapters/ad-token-adapter"], function (_exports, _adTokenAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class TalentcloudInvitationAdapter extends _adTokenAdapter.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", '/api/expert/api/talentcloud/');
    }

    get headers() {
      const result = super.headers;
      return result;
    }

    urlForQuery(query) {
      if (query.adapterOptions.filtering) {
        delete query.adapterOptions.filtering;
        return '/api/expert/api/talentcloud/routing/data_service/expert/invited_talent_clouds';
      } else if (query.adapterOptions.invitation) {
        delete query.adapterOptions.invitation;
        return '/api/expert/routing/data/invitations';
      }

      return super.urlForFindAll(...arguments);
    }

  }

  _exports.default = TalentcloudInvitationAdapter;
});