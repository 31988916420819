define("itarp-opportunity-designer/services/opportunity/authorization", ["exports", "@ember/service", "@ember/object", "itarp-opportunity-designer/utils/permissions/opportunity", "itarp-opportunity-designer/utils/permissions/expert"], function (_exports, _service, _object, _opportunity, _expert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OpportunityAuthorizationService = (_dec = (0, _service.inject)(), (_class = class OpportunityAuthorizationService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "permissionChecker", _descriptor, this);
    }

    get permissions() {
      return _opportunity.default.PERMISSIONS;
    }

    get permissionIdentifier() {
      return _opportunity.default.IDENTIFIER;
    }

    get expertPermissions() {
      return _expert.default.PERMISSIONS;
    }

    get expertPermissionIdentifier() {
      return _expert.default.IDENTIFIER;
    }

    get editPermissions() {
      return [this.permissions.edit.own, this.permissions.edit.all];
    }

    async isPermitted(action) {
      const result = await this.permissionChecker.isPermitted(action, this.permissionIdentifier);
      return !!result;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permissionChecker", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isPermitted", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "isPermitted"), _class.prototype)), _class));
  _exports.default = OpportunityAuthorizationService;
});