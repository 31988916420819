define("itarp-query-filters/utils/schema/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const schema = {
    keywords: {
      refreshModel: true,
      type: 'keyword',
      hidden: true
    },
    contractor: {
      refreshModel: true,
      type: 'boolean',
      key: 'profiles.contractor',
      label: 'Contractor'
    },
    full_time: {
      refreshModel: true,
      type: 'boolean',
      key: 'profiles.full_time_preference',
      label: 'Full Time'
    },
    part_time: {
      refreshModel: true,
      type: 'boolean',
      key: 'profiles.part_time_preference',
      label: 'Part Time'
    },
    work_available: {
      refreshModel: true,
      type: 'boolean',
      key: 'profiles.work_available',
      label: 'Available'
    },
    graduation_start: {
      refreshModel: true,
      type: 'year',
      key: 'profiles.educations.end_date',
      valueKey: 'from',
      combined: true,
      label: '{from} - {to} Graduation Year'
    },
    graduation_end: {
      refreshModel: true,
      type: 'year',
      key: 'profiles.educations.end_date',
      valueKey: 'to',
      combined: true,
      label: '{from} - {to} Graduation Year'
    },
    rate_start: {
      refreshModel: true,
      type: 'number',
      key: 'profiles.hourly_rate',
      valueKey: 'gte',
      label: '${value} Min Rate'
    },
    rate_end: {
      refreshModel: true,
      type: 'number',
      key: 'profiles.hourly_rate',
      valueKey: 'lte',
      label: '${value} Max Rate'
    },
    job_title: {
      refreshModel: true,
      type: 'text',
      key: 'profiles.professional_role',
      label: 'Job Title: {value}'
    },
    min_experience: {
      refreshModel: true,
      type: 'number',
      key: 'profiles.years_of_experience',
      valueKey: 'gte',
      combined: true,
      label: '{gte} - {lte} Years of Experience'
    },
    max_experience: {
      refreshModel: true,
      type: 'number',
      key: 'profiles.years_of_experience',
      valueKey: 'lte',
      combined: true,
      label: '{gte} - {lte} Years of Experience'
    },
    part_time_available: {
      refreshModel: true,
      type: 'boolean',
      key: 'profiles.part_time_available',
      label: '0 - 20 Hours'
    },
    between_time_available: {
      refreshModel: true,
      type: 'boolean',
      key: 'profiles.between_part_full_time_available',
      label: '20 - 40 Hours'
    },
    full_time_available: {
      refreshModel: true,
      type: 'boolean',
      key: 'profiles.full_time_available',
      label: '40+ Hours'
    },
    remote: {
      refreshModel: true,
      type: 'boolean',
      key: 'profiles.remote',
      label: 'Remote preference'
    },
    local_commute_only: {
      refreshModel: true,
      type: 'boolean',
      key: 'profiles.local_commute_only',
      label: 'Local Commute Only'
    },
    willing_to_travel: {
      refreshModel: true,
      type: 'boolean',
      key: 'profiles.willing_to_travel',
      label: 'Willing to Travel'
    },
    willing_to_relocate: {
      refreshModel: true,
      type: 'boolean',
      key: 'profiles.willing_to_relocate',
      label: 'Willing to Relocate'
    },
    salary_min: {
      refreshModel: true,
      type: 'number',
      key: 'profiles.salary_expectation',
      valueKey: 'gte',
      combined: true,
      label: '{gte} - {lte} Annual Salary Range'
    },
    salary_max: {
      refreshModel: true,
      type: 'number',
      key: 'profiles.salary_expectation',
      valueKey: 'lte',
      combined: true,
      label: '{gte} - {lte} Annual Salary Range'
    },
    location: {
      refreshModel: true,
      type: 'geopoint',
      hidden: true,
      key: 'profiles.location_coordinates'
    },
    skills: {
      refreshModel: true,
      type: 'keyword',
      key: 'skills',
      multiple: true,
      object: true,
      objectValue: 'value',
      label: '{value}'
    },
    functional_skills: {
      refreshModel: true,
      type: 'array',
      key: 'profiles.skills.taxonomy_id.raw',
      multiple: true,
      label: '{value}',
      model: true,
      modelType: 'taxonomies-category',
      modelAttribute: 'name'
    },
    tools: {
      refreshModel: true,
      type: 'array',
      key: 'profiles.tools.taxonomy_id.raw',
      multiple: true,
      label: '{value}',
      model: true,
      modelType: 'taxonomies-category',
      modelAttribute: 'name'
    },
    industries: {
      refreshModel: true,
      type: 'array',
      key: 'profiles.industries.name.keyword',
      multiple: true,
      label: '{value}'
    }
  };

  var _default = Object.freeze(schema);

  _exports.default = _default;
});