define("itarp-workflow-designer/mirage/endpoints/action-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(context) {
    context.get('actions/templates', 'action-groups');
    context.get('actions/templates/:id', 'action-groups', 'action-groups/:id');
    context.post('actions/templates', 'action-groups');
    context.patch('actions/templates/:id', 'action-groups', 'action-groups/:id');
    context.del('actions/templates/:id', 'action-groups', 'action-groups/:id');
  }
});