define("itarp-shared-assets-addon/utils/presenter", ["exports", "@ember/object/proxy"], function (_exports, _proxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Presenter is used as a model wrapper providing formatted/derived properties
  // for simplified access in template/components
  // caveat: avoid naming properties on the presenter which are defined on the model itself
  // properties which are not defined on the presenter are proxied to the model
  // accessing and modifiying model properties in presenter have to be done
  // using this.get() and this.set() methods, to modify from outside or call methods
  // on the model use presenter.content (i.e. presenter.content.save())
  class Presenter extends _proxy.default {
    constructor(data) {
      super(...arguments);
      this.content = data;
    }

  }

  _exports.default = Presenter;
});