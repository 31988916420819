define("itarp-opportunity-designer/components/slider", ["exports", "@ember/component", "@ember/template-factory", "@love-open-source/ember-slider/components/ember-slider", "@ember/object"], function (_exports, _component, _templateFactory, _emberSlider, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <EmberSlider
    @config={{@config}}
    @sliderValue={{@sliderValue}}
    @initialValue={{@initialValue}}
    @onChange={{@onChange}}
  />
  */
  {
    "id": "+cgXS8QQ",
    "block": "[[[8,[39,0],null,[[\"@config\",\"@sliderValue\",\"@initialValue\",\"@onChange\"],[[30,1],[30,2],[30,3],[30,4]]],null]],[\"@config\",\"@sliderValue\",\"@initialValue\",\"@onChange\"],false,[\"ember-slider\"]]",
    "moduleName": "itarp-opportunity-designer/components/slider.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _emberSlider.default.extend({
    init() {
      this._super(...arguments);
    },

    // eslint-disable-next-line
    setValueObs: (0, _object.observer)('sliderValue', function () {
      this.moveToPercentage(this.get('sliderValue'));
    })
  }));

  _exports.default = _default;
});