define("itarp-opportunity-designer/controllers/publishing-options/free-boards", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/service", "itarp-opportunity-designer/config/environment", "itarp-opportunity-designer/utils/constants/opportunity"], function (_exports, _controller, _object, _tracking, _service, _environment, _opportunity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PublishingOptionsFreeBoardsController = (_dec = (0, _service.inject)('appRouter'), _dec2 = (0, _service.inject)('opportunity/authorization'), (_class = class PublishingOptionsFreeBoardsController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "workflows", _descriptor2, this);

      _initializerDefineProperty(this, "authorization", _descriptor3, this);

      _initializerDefineProperty(this, "opportunityStatus", _descriptor4, this);
    }

    get isPendingApproval() {
      return this.opportunityStatus === _opportunity.PENDING_APPROVAL_STATUS;
    }

    get isOnHold() {
      return this.opportunity.status === _opportunity.ON_HOLD_STATUS;
    }

    setOnHold(dropdown) {
      this.opportunity.actionFlag = 'setOnHold';
      this.opportunity.save().then(() => dropdown.actions.close());
    }

    async approve(dropdown) {
      if (this.opportunityStatus === _opportunity.PENDING_APPROVAL_STATUS) {
        const workflow = await this.workflows.businessWorkflow(this.opportunity.workflowApprovalId);
        const stageGroup = workflow.stageGroups.findBy('displayOrder', workflow.stageGroups.length);
        const stage = stageGroup.stages.findBy('stageType', 'open');
        this.opportunity.workflowApprovalStageId = stage.id;
      }

      this.opportunity.save().then(() => dropdown.actions.close());
    }

    async reject(dropdown) {
      if (this.opportunityStatus === _opportunity.PENDING_APPROVAL_STATUS) {
        const workflow = await this.workflows.businessWorkflow(this.opportunity.workflowApprovalId);
        const stageGroup = workflow.stageGroups.findBy('displayOrder', workflow.stageGroups.length);
        const stage = stageGroup.stages.findBy('stageType', 'declined');
        this.opportunity.workflowApprovalStageId = stage.id;
      }

      this.opportunity.save().then(() => dropdown.actions.close());
    }

    publishSettings() {
      const forRemoval = this.posts.filterBy('selected', false).filterBy('isNew', false);
      const forCreate = this.posts.filterBy('selected', true).filterBy('isNew');

      for (const post of forCreate) {
        post.save();
      }

      for (const post of forRemoval) {
        post.destroyRecord();
      }

      this.router.transitionTo(`${_environment.default.engineName}.publishing-options.settings`, this.opportunity);
    }

    get status() {
      var _this$opportunity$get;

      const workflowApprovalId = this.opportunity.get('workflowApprovalId');
      const workflowApprovalStageId = (_this$opportunity$get = this.opportunity.get('workflowApprovalStageId')) === null || _this$opportunity$get === void 0 ? void 0 : _this$opportunity$get.toString();

      if (this.opportunity.status !== 'draft' && workflowApprovalStageId && !this.isStatusResolved) {
        this.workflows.findStageByStageId(workflowApprovalId, workflowApprovalStageId).then(stage => {
          if (stage) {
            this.opportunityStatus = stage.stageType;
            this.isStatusResolved = true;
          }
        });
      }

      if (this.opportunity.status === 'draft') {
        this.opportunityStatus = this.opportunity.statusName;
      }

      this.isStatusResolved = false;
      return this.opportunityStatus;
    }

    get permissionIdentifier() {
      return this.authorization.permissionIdentifier;
    }

    get permissions() {
      return this.authorization.permissions;
    }

    cloneOpportunity(opportunity, dropdown) {
      const cloneOpportunity = this.store.createRecord('opportunity', {
        jobTitle: opportunity.jobTitle + ' (Clone)',
        cloneId: opportunity.id
      });
      cloneOpportunity.save().then(() => {
        dropdown.actions.close();
        this.router.transitionTo(`${_environment.default.modulePrefix}.details`, cloneOpportunity.id);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "workflows", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "authorization", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "opportunityStatus", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setOnHold", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setOnHold"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "approve", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "approve"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reject", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "reject"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "publishSettings", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "publishSettings"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cloneOpportunity", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cloneOpportunity"), _class.prototype)), _class));
  _exports.default = PublishingOptionsFreeBoardsController;
});