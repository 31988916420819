define("itarp-opportunity-designer/routes/edit-opportunity/workflows", ["exports", "@ember/routing/route", "rsvp", "@ember/service"], function (_exports, _route, _rsvp, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EditOpportunityWorkflowsRoute = (_dec = (0, _service.inject)('opportunity/wizard'), _dec2 = (0, _service.inject)('opportunity/authorization'), (_class = class EditOpportunityWorkflowsRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "templateName", 'opportunity.workflows');

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "collaborators", _descriptor2, this);

      _initializerDefineProperty(this, "adTokenService", _descriptor3, this);

      _initializerDefineProperty(this, "workflows", _descriptor4, this);

      _initializerDefineProperty(this, "wizard", _descriptor5, this);

      _initializerDefineProperty(this, "authorization", _descriptor6, this);

      _defineProperty(this, "workflow", null);
    }

    async model() {
      const project = this.modelFor('edit-opportunity');
      project.workflowId ? this.workflow = this.store.findRecord('business-workflow', project.workflowId) : null;
      return (0, _rsvp.hash)({
        project: project,
        workflow: this.workflow,
        businessWorkflows: this.workflows.businessWorkflows({
          'category.slug': 'opportunity_recruiting'
        }).then(data => ({
          data,
          meta: data.meta
        })),
        approvalBusinessWorkflows: this.workflows.businessWorkflows({
          'category.slug': 'opportunity_approval'
        }).then(data => ({
          data,
          meta: data.meta
        }))
      });
    }

    setupController(controller, model) {
      var _model$project$workfl;

      super.setupController(controller, model);
      controller.set('project', model.project);
      controller.set('workflow', model.workflow || model.businessWorkflows.data.findBy('isDefault', true));
      controller.set('workflows', model.businessWorkflows.data);
      controller.set('approvalWorkflows', model.approvalBusinessWorkflows.data);
      controller.set('approvalWorkflow', model.approvalBusinessWorkflows.data.findBy('id', (_model$project$workfl = model.project.workflowApprovalId) === null || _model$project$workfl === void 0 ? void 0 : _model$project$workfl.toString()));
      const parentController = this.controllerFor('edit-opportunity');
      parentController.set('beforeSave', controller.beforeSave);
      parentController.set('afterSave', controller.afterSave);
    }

    async beforeModel(transition) {
      this.wizard.assureModelExists(transition);
      const permitted = await this.checkWorkflowPermissions();

      if (!permitted) {
        this.transitionTo('opportunity.job-details');
      }
    }

    async checkWorkflowPermissions() {
      const actions = [this.authorization.permissions.action.linkApprovalWorkflow, this.authorization.permissions.action.linkRecruitingWorkflow];
      const permissionResult = await Promise.all(actions.map(action => this.authorization.isPermitted(action, this.authorization.permissionIdentifier)));
      return permissionResult.some(isPermitted => isPermitted);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "collaborators", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "adTokenService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "workflows", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "wizard", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "authorization", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = EditOpportunityWorkflowsRoute;
});