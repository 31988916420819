define("itarp-business-application/util/schema-manager/manager", ["exports", "itarp-business-application/util/schema-manager/schemas/model", "itarp-business-application/util/schema-manager/schemas/api"], function (_exports, _model, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class SchemaManager {
    constructor(storeManager, store) {
      _defineProperty(this, "schemas", {});

      _defineProperty(this, "storeManager", void 0);

      _defineProperty(this, "store", void 0);

      this.storeManager = storeManager;
      this.store = store;

      if (!SchemaManager.manager) {
        SchemaManager.manager = this;
      }

      return SchemaManager.manager;
    }

    async get(modelName) {
      if (this.schemas[modelName]) return this.schemas[modelName];
      return false;
    }

    register(modelName, modelData) {
      const model = new _model.default(modelData, this.storeManager);

      const api = _api.default.generate(model, this.store);

      this.schemas[modelName] = {
        model,
        api
      };
      return this.schemas[modelName];
    }

    static get manager() {
      return this._manager;
    }

    static set manager(manager) {
      this._manager = manager;
    }

  }

  _exports.default = SchemaManager;
});