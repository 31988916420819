define("itarp-opportunity-designer/services/-gestures", ["exports", "@ember/polyfills", "itarp-opportunity-designer/config/environment", "ember-gestures/services/-gestures"], function (_exports, _polyfills, _environment, _gestures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const assign = _polyfills.assign || _polyfills.merge;
  let gestures = assign({}, {
    useCapture: false
  });
  gestures = assign(gestures, _environment.default.gestures);

  var _default = _gestures.default.extend({
    useCapture: gestures.useCapture
  });

  _exports.default = _default;
});