define("itarp-business-application/components/wrapper", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/object/computed"], function (_exports, _component, _tracking, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="component-wrapper {{this.attributeClasses}} {{this.classList}}">
    {{yield this.classList this.setClassList}}
  </div>
  */
  {
    "id": "VSRKHp/F",
    "block": "[[[10,0],[15,0,[29,[\"component-wrapper \",[30,0,[\"attributeClasses\"]],\" \",[30,0,[\"classList\"]]]]],[12],[1,\"\\n  \"],[18,1,[[30,0,[\"classList\"]],[30,0,[\"setClassList\"]]]],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "itarp-business-application/components/wrapper.hbs",
    "isStrictMode": false
  });

  const LAYOUT_CLASS = {
    fixed: 'is-limited',
    scroll: 'is-scrollable'
  };
  let WrapperComponent = (_dec = (0, _computed.alias)('args.config.attributes'), (_class = class WrapperComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "attributes", _descriptor, this);

      _initializerDefineProperty(this, "classList", _descriptor2, this);
    }

    get attributeClasses() {
      return `${this.layoutClass} ${this.paddingClass}`;
    }

    get layoutClass() {
      return LAYOUT_CLASS[this.attributes.layout] || '';
    }

    get paddingClass() {
      var _this$attributes;

      if ((_this$attributes = this.attributes) !== null && _this$attributes !== void 0 && _this$attributes.padding) {
        let classes = '';
        ['top', 'left', 'right', 'bottom'].forEach(pos => {
          if (this.attributes.padding[pos]) {
            classes += `pad-${pos.charAt(0)}--${this.attributes.padding[pos]} `;
          }
        });
        return classes;
      }

      return '';
    }

    setClassList(classes) {
      this.classList += classes;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "attributes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "classList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setClassList", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setClassList"), _class.prototype)), _class));
  _exports.default = WrapperComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, WrapperComponent);
});