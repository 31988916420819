define("ember-a11y-accordion/components/accordion-list", ["exports", "@ember/component", "@ember/template-factory", "ember-a11y-accordion/utils/dom", "@ember/runloop", "@ember/array", "@glimmer/component", "@ember/object", "@ember/test-waiters", "@ember/utils", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _dom, _runloop, _array, _component2, _object, _testWaiters, _utils, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{this.className}} ...attributes>
    {{yield (hash
      item=(component "accordion-item"
        register=this.registerItem
        toggle=this.toggleItem
      )
    )}}
  </div>
  */
  {
    "id": "uHQsYMa6",
    "block": "[[[11,0],[16,0,[30,0,[\"className\"]]],[17,1],[12],[1,\"\\n  \"],[18,2,[[28,[37,1],null,[[\"item\"],[[50,\"accordion-item\",0,null,[[\"register\",\"toggle\"],[[30,0,[\"registerItem\"]],[30,0,[\"toggleItem\"]]]]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-a11y-accordion/components/accordion-list.hbs",
    "isStrictMode": false
  });

  const waiter = (0, _testWaiters.buildWaiter)('ember-a11y-accordion:accordion-list');
  /**
   * The accordion-list component is the top-most component and is responsible
   * for registering accordion items and toggling their panels.
   *
   * @param {Function} [onShow] Action to execute when a panel is being shown
   * @param {Function} [onAfterShow] Action to execute when a panel is fully shown
   * @param {String} [class] Any CSS classes to be added to the component's element
   * @param {Boolean} [animation] Whether or not it should animate items
   *
   * @example
   * {{#accordion-list as |accordion|}}
   *   {{#accordion.item as |item|}}
   *     {{#item.header}}Lorem Ipsum{{/item.header}}
   *     {{#item.panel}}Lorem ipsum dolor{{/item.panel}}
   *   {{/accordion.item}}
   * {{/accordion-list}}
   */

  let AccordionListClass = (_class = class AccordionListClass extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "className", _dom.CLASS_NAMES.list);

      _defineProperty(this, "animatedHideWaitToken", void 0);

      _defineProperty(this, "animatedShowWaitToken", void 0);

      _initializerDefineProperty(this, "_activeItem", _descriptor, this);

      _initializerDefineProperty(this, "_currentHideTimeout", _descriptor2, this);

      _defineProperty(this, "_items", (0, _array.A)());
    }

    get animation() {
      return (0, _utils.isPresent)(this.args.animation) ? this.args.animation : true;
    }

    /**
     * Action for item components to register themselves.
     *
     * @param {Object} item
     * @public
     */
    registerItem(item) {
      if (!(0, _utils.isPresent)(item)) {
        return;
      }

      this._items.push(item); // At register time close respective items


      if (item.isExpanded) {
        this._activeItem = item;
      } else {
        this.animation ? (0, _dom.setClosedHeight)(item) : this._simpleHide(item);
      }
    }
    /**
     * Action to toggle accordion items.
     *
     * @param {Object} item
     * @public
     */


    toggleItem(item) {
      if (!(0, _utils.isPresent)(item) || item.isDisabled || item.isExpanded) {
        return;
      } // If no items have expandOnInit, then there isn't an active one yet.


      if (this._activeItem) {
        // Hide active item
        this.animation ? this._animatedHide(this._activeItem) : this._simpleHide(this._activeItem);
      } // Show this one


      if (this.animation) {
        this._animatedShow(item);

        this._triggerEvent('onShow', item);
      } else {
        this._simpleShow(item);

        this._triggerEvent('onShow', item);

        this._triggerEvent('onAfterShow', item);
      }

      this._activeItem = item;
    }

    willDestroy() {
      super.willDestroy(...arguments);
      (0, _runloop.cancel)(this._currentHideTimeout);

      this._items.splice(0, this._items.length);
    }
    /**
     * Handles hiding an item with animation.
     *
     * CSS transitions don't transition from "no value" to a value, so
     * before we set the element's height to close it, first we
     * calculate its current open height and set it.
     *
     * @param {Object} item
     * @private
     */


    _animatedHide(item) {
      this.animatedHideWaitToken = waiter.beginAsync();

      if (this._activeItem) {
        // From open height
        (0, _dom.setOpenHeight)(this._activeItem);
      }

      (0, _runloop.cancel)(this._currentHideTimeout); // Set close height

      this._currentHideTimeout = (0, _runloop.next)(() => {
        (0, _dom.setClosedHeight)(item);
        item.isExpanded = false;
        waiter.endAsync(this.animatedHideWaitToken);
      });
    }
    /**
     * Handles showing an item with animation.
     *
     * When the CSS transition has ended, we clear the inline height so the
     * component's contents don't get cutt off in responsive layouts.
     *
     * @param {Object} item
     * @private
     */


    _animatedShow(item) {
      this.animatedShowWaitToken = waiter.beginAsync();
      (0, _dom.setOpenHeight)(item);
      item.isExpanded = true; // Remove the inline height after the transition so contents don't
      // get cut off when resizing the browser window.

      (0, _dom.addEventListenerOnce)(item.panelWrapper, 'transitionend', () => {
        if (item.isExpanded) {
          item.panelWrapper.style.height = null;

          this._triggerEvent('onAfterShow', item);
        }

        waiter.endAsync(this.animatedShowWaitToken);
      });
    }
    /**
     * Handles hiding an item without animation.
     *
     * @param {Object} item
     * @private
     */


    _simpleHide(item) {
      item.isExpanded = false;
      item.panelWrapper.style.display = 'none';
    }
    /**
     * Handles showing an item without animation.
     *
     * @param {Object} item
     * @private
     */


    _simpleShow(item) {
      item.isExpanded = true;
      item.panelWrapper.style.display = null;
    }
    /**
     * Triggers an event
     *
     * @param {string} eventName
     * @param {Object} item
     * @private
     */


    _triggerEvent(eventName, item) {
      this.args[eventName] && this.args[eventName]({
        name: item.name
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_activeItem", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_currentHideTimeout", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "registerItem", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleItem", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleItem"), _class.prototype)), _class);
  _exports.default = AccordionListClass;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AccordionListClass);
});