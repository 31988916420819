define("itarp-components/components/ui/accordion", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{@class}} ...attributes>
    {{yield
      (hash
        item=(component
          'ui/accordion/item' selectedIndex=this.selected toggle=this.toggle
        )
      )
    }}
  </div>
  
  */
  {
    "id": "CNE7gd5P",
    "block": "[[[11,0],[16,0,[30,1]],[17,2],[12],[1,\"\\n  \"],[18,3,[[28,[37,1],null,[[\"item\"],[[50,\"ui/accordion/item\",0,null,[[\"selectedIndex\",\"toggle\"],[[30,0,[\"selected\"]],[30,0,[\"toggle\"]]]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-components/components/ui/accordion.hbs",
    "isStrictMode": false
  });

  let UiAccordionComponent = (_class = class UiAccordionComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "selected", _descriptor, this);
    }

    toggle(index, element) {
      const isSelected = this.selected === index;
      this.selected = isSelected ? null : index;
    }

    preventPropagation(event) {
      event.stopPropagation();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "preventPropagation", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "preventPropagation"), _class.prototype)), _class);
  _exports.default = UiAccordionComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiAccordionComponent);
});