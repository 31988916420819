define("itarp-workflow-designer/components/cancel-modal", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @modalVisible}}
    <ModalDialog
      {{on 'close' @toggleCancelModal}}
      @targetAttachment='center'
      @translucentOverlay={{true}}
      @overlayClass='app-modal-overlay'
      @containerClass='app-modal-wrapper'
    >
      <div class='app-modal app-modal--md mar-t--12'>
        <div class='app-modal-content'>
          <div class='app-modal-content__header'>
            <h4 class='app-modal-content__header__title'>
              You have unsaved changes. Are you sure you want to discard them?
            </h4>
            <a
              class='app-modal-content__header__close'
              {{on 'click' @cancel}}
            ></a>
          </div>
          <div class='app-modal-content__body'>
            <div class='app-modal-group__panel'>
              <div class='row'>
                <div class='col--12-12'>
                  <div class='component-wrapper'>
                    <div class='app-modal-group__panel__controls'>
                      <button
                        {{on 'click' @cancel}}
                        type='button'
                        class='app-btn app-btn--sm'
                      >Cancel</button>
                      <button
                        type='button'
                        class='app-btn app-btn--primary app-btn--sm'
                        {{on 'click' @confirm}}
                      >Discard Changes</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalDialog>
  {{/if}}
  */
  {
    "id": "oXawY5A6",
    "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],[[4,[38,2],[\"close\",[30,2]],null]],[[\"@targetAttachment\",\"@translucentOverlay\",\"@overlayClass\",\"@containerClass\"],[\"center\",true,\"app-modal-overlay\",\"app-modal-wrapper\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"app-modal app-modal--md mar-t--12\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"app-modal-content\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"app-modal-content__header\"],[12],[1,\"\\n          \"],[10,\"h4\"],[14,0,\"app-modal-content__header__title\"],[12],[1,\"\\n            You have unsaved changes. Are you sure you want to discard them?\\n          \"],[13],[1,\"\\n          \"],[11,3],[24,0,\"app-modal-content__header__close\"],[4,[38,2],[\"click\",[30,3]],null],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"app-modal-content__body\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"app-modal-group__panel\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n              \"],[10,0],[14,0,\"col--12-12\"],[12],[1,\"\\n                \"],[10,0],[14,0,\"component-wrapper\"],[12],[1,\"\\n                  \"],[10,0],[14,0,\"app-modal-group__panel__controls\"],[12],[1,\"\\n                    \"],[11,\"button\"],[24,0,\"app-btn app-btn--sm\"],[24,4,\"button\"],[4,[38,2],[\"click\",[30,3]],null],[12],[1,\"Cancel\"],[13],[1,\"\\n                    \"],[11,\"button\"],[24,0,\"app-btn app-btn--primary app-btn--sm\"],[24,4,\"button\"],[4,[38,2],[\"click\",[30,4]],null],[12],[1,\"Discard Changes\"],[13],[1,\"\\n                  \"],[13],[1,\"\\n                \"],[13],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@modalVisible\",\"@toggleCancelModal\",\"@cancel\",\"@confirm\"],false,[\"if\",\"modal-dialog\",\"on\"]]",
    "moduleName": "itarp-workflow-designer/components/cancel-modal.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});