define("itarp-business-application/components/cards/list-card", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object/computed"], function (_exports, _component, _tracking, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="card-wrapper">
    <div class="card-tc">
      <div class="card-tc__header">
        <h4>
          {{this.attributes.title}}
        </h4>
        <span>{{this.attributes.subtitle}}</span>
      </div>
    </div>
  </div>
  */
  {
    "id": "dQaw/d3w",
    "block": "[[[10,0],[14,0,\"card-wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"card-tc\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"card-tc__header\"],[12],[1,\"\\n      \"],[10,\"h4\"],[12],[1,\"\\n        \"],[1,[30,0,[\"attributes\",\"title\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[12],[1,[30,0,[\"attributes\",\"subtitle\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "itarp-business-application/components/cards/list-card.hbs",
    "isStrictMode": false
  });

  let ListCardComponent = (_dec = (0, _computed.alias)('args.config.attributes'), (_class = class ListCardComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "classList", _descriptor, this);

      _initializerDefineProperty(this, "attributes", _descriptor2, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "classList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "attributes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ListCardComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ListCardComponent);
});