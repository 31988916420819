define("itarp-components/components/ui/input/rate-range", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='component-wrapper has-padding'>
    <div class='input-group'>
      <label class='input-group__label'>
        {{yield}}
      </label>
      <div class='input-group__elements for-range {{this.display}}'>
        <div class='input-group__add-on'>
          <span class='input-group__add-on__text'>$</span>
          <input
            type='text'
            value={{this.start}}
            class='input-group__field'
            placeholder={{if @minPlaceholder @minPlaceholder 'Min'}}
            {{on 'change' this.setStart}}
          />
        </div>
        <span class='input-group__range__divider'></span>
        <div class='input-group__add-on'>
          {{#if this.isVertical}}
            <span class='input-group__add-on__text'>$</span>
          {{/if}}
          <input
            type='text'
            value={{this.end}}
            class='input-group__field'
            placeholder={{if @maxPlaceholder @maxPlaceholder 'Max'}}
            {{on 'change' this.setEnd}}
          />
        </div>
      </div>
    </div>
  </div>
  
  */
  {
    "id": "DkaqWoS6",
    "block": "[[[10,0],[14,0,\"component-wrapper has-padding\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"input-group\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"input-group__label\"],[12],[1,\"\\n      \"],[18,3,null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[15,0,[29,[\"input-group__elements for-range \",[30,0,[\"display\"]]]]],[12],[1,\"\\n      \"],[10,0],[14,0,\"input-group__add-on\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"input-group__add-on__text\"],[12],[1,\"$\"],[13],[1,\"\\n        \"],[11,\"input\"],[16,2,[30,0,[\"start\"]]],[24,0,\"input-group__field\"],[16,\"placeholder\",[52,[30,1],[30,1],\"Min\"]],[24,4,\"text\"],[4,[38,2],[\"change\",[30,0,[\"setStart\"]]],null],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[14,0,\"input-group__range__divider\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"input-group__add-on\"],[12],[1,\"\\n\"],[41,[30,0,[\"isVertical\"]],[[[1,\"          \"],[10,1],[14,0,\"input-group__add-on__text\"],[12],[1,\"$\"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[11,\"input\"],[16,2,[30,0,[\"end\"]]],[24,0,\"input-group__field\"],[16,\"placeholder\",[52,[30,2],[30,2],\"Max\"]],[24,4,\"text\"],[4,[38,2],[\"change\",[30,0,[\"setEnd\"]]],null],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@minPlaceholder\",\"@maxPlaceholder\",\"&default\"],false,[\"yield\",\"if\",\"on\"]]",
    "moduleName": "itarp-components/components/ui/input/rate-range.hbs",
    "isStrictMode": false
  });

  let UiInputYearRangeComponent = (_class = class UiInputYearRangeComponent extends _component2.default {
    get display() {
      const options = {
        horizontal: 'is-horizontal',
        vertical: 'is-vertical'
      };
      const defaultOption = options.horizontal;

      if (this.args.display) {
        return options[this.args.display] || defaultOption;
      }

      return defaultOption;
    }

    isVertical() {
      return this.display === 'is-vertical';
    }

    get start() {
      if (this.args.from) return this.args.from;
    }

    get end() {
      if (this.args.to) return this.args.to;
    }

    setStart(event) {
      const value = Number(event.target.value);

      if (value !== NaN) {
        const end = this.end ? this.end : value;
        const isValid = end ? value <= end && value > 0 : value > 0;

        if (isValid) {
          this.args.setStart(value);
          return this.args.onChange && this.args.onChange(value);
        } else {
          this.args.setStart(null);
          this.args.onChange && this.args.onChange(null);
        }
      }

      event.target.value = this.start || '';
    }

    setEnd(event) {
      const value = Number(event.target.value);

      if (value !== NaN) {
        const start = this.start ? this.start : value;
        const isValid = start ? value >= start && value > 0 : value > 0;

        if (isValid) {
          this.args.setEnd(value);
          return this.args.onChange && this.args.onChange(value);
        } else {
          this.args.setEnd(null);
          this.args.onChange && this.args.onChange(null);
        }
      }

      event.target.value = this.end || '';
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "setStart", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setStart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setEnd", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setEnd"), _class.prototype)), _class);
  _exports.default = UiInputYearRangeComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiInputYearRangeComponent);
});