define("shared/helpers/compare-numbers", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function compareNumbers(params
  /*, hash*/
  ) {
    return params[0] == params[1];
  });

  _exports.default = _default;
});