define("itarp-components/components/ui/input/radio", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let (unique-id) as |uid|}}
    <div class='radio-group' ...attributes>
      <input
        class='radio-group__field'
        id={{uid}}
        name={{@name}}
        type='radio'
        checked={{this.isSelected}}
        {{on 'change' (fn @onChange @value)}}
      />
      <label class='radio-group__label' for={{uid}}>
        {{yield}}
      </label>
    </div>
  {{/let}}
  */
  {
    "id": "1rlRVB1n",
    "block": "[[[44,[[28,[37,1],null,null]],[[[1,\"  \"],[11,0],[24,0,\"radio-group\"],[17,2],[12],[1,\"\\n    \"],[11,\"input\"],[24,0,\"radio-group__field\"],[16,1,[30,1]],[16,3,[30,3]],[16,\"checked\",[30,0,[\"isSelected\"]]],[24,4,\"radio\"],[4,[38,2],[\"change\",[28,[37,3],[[30,4],[30,5]],null]],null],[12],[13],[1,\"\\n    \"],[10,\"label\"],[14,0,\"radio-group__label\"],[15,\"for\",[30,1]],[12],[1,\"\\n      \"],[18,6,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]]]],[\"uid\",\"&attrs\",\"@name\",\"@onChange\",\"@value\",\"&default\"],false,[\"let\",\"unique-id\",\"on\",\"fn\",\"yield\"]]",
    "moduleName": "itarp-components/components/ui/input/radio.hbs",
    "isStrictMode": false
  });

  class UiInputRadioComponent extends _component2.default {
    get selected() {
      return this.args.selected;
    }

    get value() {
      return this.args.value;
    }

    get isSelected() {
      return this.selected === this.value || `${this.selected}` === `${this.value}`;
    }

  }

  _exports.default = UiInputRadioComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiInputRadioComponent);
});