define("itarp-shared-assets-addon/serializers/data", ["exports", "@ember-data/serializer/json", "@ember/string", "ember-inflector"], function (_exports, _json, _string, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class DataSerializer extends _json.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", '_id');
    }

    keyForAttribute(attr) {
      return (0, _string.underscore)(attr);
    }

    extractId(modelClass, resourceHash) {
      const id = resourceHash[this.primaryKey];
      return typeof id === 'object' ? id['$oid'] : id;
    }

    normalizeFindAllResponse(store, modelClass, payload, ...rest) {
      const data = payload ? payload.records : [];
      return super.normalizeFindAllResponse(store, modelClass, data, ...rest);
    }

    normalize(modelClass, data) {
      var _result$data;

      data.type = modelClass.modelName;
      const result = super.normalize(modelClass, data);
      if (result !== null && result !== void 0 && (_result$data = result.data) !== null && _result$data !== void 0 && _result$data.attributes) this.parseAttributes(result.data.attributes);
      return result;
    }

    parseAttributes(attributes) {
      for (const attr in attributes) {
        try {
          const result = JSON.parse(attributes[attr]);
          attributes[attr] = result;
        } catch (e) {}
      }
    }

    normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      const data = payload ? payload.records : [];
      data.meta = payload.meta;
      return super.normalizeQueryResponse(store, primaryModelClass, data, id, requestType);
    }

    normalizeFindHasManyResponse(store, primaryModelClass, payload, id, requestType) {
      const data = payload ? payload.records : [];
      data.meta = payload.meta;
      return super.normalizeFindHasManyResponse(store, primaryModelClass, data, id, requestType);
    }

    serialize(snapshot, options) {
      const json = super.serialize(...arguments);
      const collectionName = (0, _string.underscore)((0, _emberInflector.pluralize)(snapshot.modelName));
      return {
        [collectionName]: { ...json,
          tenant_id: this.tenantId
        }
      };
    }

    get token() {
      const encoded = localStorage.getItem('itarp_token');
      return encoded ? JSON.parse(atob(encoded.split('.')[1])) : null;
    }

    get tenantId() {
      if (this.token && this.token.extension_tenant_id) {
        return this.token.extension_tenant_id;
      }
    }

  }

  _exports.default = DataSerializer;
});