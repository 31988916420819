define("itarp-business-application/routes/dynamic-route", ["exports", "itarp-auth-addon/routes/application", "@ember/service", "@ember/object", "@ember/runloop", "@glimmer/tracking", "itarp-business-application/util/parser", "itarp-business-application/utils/observable"], function (_exports, _application, _service, _object, _runloop, _tracking, _parser, _observable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DynamicRouteRoute = (_dec = (0, _service.inject)('context-tree'), _dec2 = (0, _service.inject)(), _dec3 = (0, _service.inject)('ad-token-service'), (_class = class DynamicRouteRoute extends _application.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "contextTree", _descriptor3, this);

      _defineProperty(this, "templateName", 'dynamic-route');

      _defineProperty(this, "storeManager", undefined);

      _defineProperty(this, "queryParams", {
        filter: {
          refreshModel: true
        }
      });

      _initializerDefineProperty(this, "hyperlinks", _descriptor4, this);

      _initializerDefineProperty(this, "adTokenService", _descriptor5, this);

      _initializerDefineProperty(this, "title", _descriptor6, this);

      _defineProperty(this, "sidePanelConfig", {
        attributes: {
          menuItems: [{
            label: 'Autofill',
            icon: 'autofill',
            value: 'autofill'
          }, {
            label: 'Basic Info',
            icon: 'basic-info',
            value: 'basic-info'
          }, {
            label: 'Expertise',
            value: 'expertise'
          }, {
            label: 'Work Arrangement',
            icon: 'work-arrangement',
            value: 'work-arrangement'
          }, {
            label: 'Preview',
            icon: 'preview',
            value: 'profile-preview'
          }]
        }
      });
    }

    async model(params, transition) {
      const slug = params.path;
      const options = {
        store: this.store,
        router: this.router,
        ids: this.getParamIds(transition),
        transition: this.handleTransition,
        slug: slug
      };
      let page = await this.store.find('page', slug);
      const context = this.contextTree.generate(page, true);
      page = await (0, _parser.PageParser)(page, { ...options,
        context
      });

      if (page.save) {
        this.save = page.save.bind(page);
      } else {
        this.save = null;
      }

      this.templateName = page.template;
      this.title = page.title;
      return page;
    }

    getParamIds(transition) {
      var _transition$to;

      const params = transition === null || transition === void 0 ? void 0 : (_transition$to = transition.to) === null || _transition$to === void 0 ? void 0 : _transition$to.queryParams;
      const ids = {
        userId: this.adTokenService.tokenInformation.sub
      };
      if (!params) return ids;

      for (const paramName in params) {
        if (paramName.substring(paramName.length - 3) === '_id') {
          const modelName = paramName.substring(0, paramName.length - 3);
          ids[modelName] = params[paramName];
        }
      }

      return ids;
    }

    async submit(callback) {
      let isSuccess = false;
      if (!this.save) return;
      await this.save().then(() => {
        isSuccess = true;
      });
      if (callback) return callback(isSuccess);
    }

    reset() {
      for (const modelName in this.models) {
        this.models[modelName].record.rollback();
      }
    }

    activate() {
      (0, _runloop.scheduleOnce)('afterRender', this, () => {
        document.addEventListener('click', this.handleTransition);
      });
    }

    handleTransition(event) {
      const elements = ['button', 'a'];
      const elementName = event.target.nodeName.toLowerCase();
      if (elements.indexOf(elementName) === -1) return;
      event.preventDefault();
      event.stopPropagation();
      this.hyperlinks.transition(event, elementName);
    }

    setupController(controller) {
      super.setupController(...arguments);
      controller.set('submit', this.submit);
      controller.set('reset', this.reset);
      controller.set('title', this.title); // controller.set('observer', this.setObserver());

      controller.set('sidePanelConfig', this.sidePanelConfig);
    }

    setObserver() {
      const observer = new _observable.default();
      observer.subscribe('submit', this.submit);
      observer.subscribe('cancel', this.reset);
      return observer;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "contextTree", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "hyperlinks", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "adTokenService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "title", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "submit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reset", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "reset"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "activate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "activate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleTransition", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleTransition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setObserver", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setObserver"), _class.prototype)), _class));
  _exports.default = DynamicRouteRoute;
});