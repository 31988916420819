define("itarp-opportunity-designer/services/integrations", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const BASE_ENDPOINT = '/opportunity/connector_service/integrations/';
  let IntegrationsService = (_dec = (0, _service.inject)('ad-token-service'), _dec2 = (0, _service.inject)(), (_class = class IntegrationsService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "auth", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);
    }

    get tenant() {
      return this.auth.tokenInformation.extension_tenant_id;
    }

    get authHeader() {
      return 'Bearer ' + this.auth.token;
    }

    getWithType(type) {
      return fetch(`${BASE_ENDPOINT}/${this.tenant}?type=${type}`, {
        headers: {
          Authorization: this.authHeader
        }
      }).then(async response => response.json()).then(success => success.integrations, () => []);
    }

    initializePosts(id, enabled, created) {
      const result = [];

      for (const board of enabled) {
        const existing = created.findBy('jobBoardType', board);

        if (existing) {
          result.pushObject(existing);
        } else {
          result.pushObject(this.store.createRecord('publish-setting', {
            jobBoardType: board,
            recordId: id,
            recordType: 'opportunity'
          }));
        }
      }

      return result;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = IntegrationsService;
});