define("itarp-business-application/util/schema-manager/schemas/relation-types/index", ["exports", "itarp-business-application/util/schema-manager/schemas/relation-types/single", "itarp-business-application/util/schema-manager/schemas/relation-types/collection"], function (_exports, _single, _collection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.relationTypes = void 0;
  const relationTypes = {
    single: _single.Single,
    collection: _collection.Collection
  };
  _exports.relationTypes = relationTypes;
});