define("itarp-business-application/components/divider", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#unless @config.attributes.hidden}}
    <div class="component-wrapper">
      <div class="divider" data-test-divider-component></div>
    </div>
  {{/unless}}
  */
  {
    "id": "CEfApfic",
    "block": "[[[41,[51,[30,1,[\"attributes\",\"hidden\"]]],[[[1,\"  \"],[10,0],[14,0,\"component-wrapper\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"divider\"],[14,\"data-test-divider-component\",\"\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@config\"],false,[\"unless\"]]",
    "moduleName": "itarp-business-application/components/divider.hbs",
    "isStrictMode": false
  });

  class DividerComponent extends _component.default {}

  _exports.default = DividerComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DividerComponent);
});