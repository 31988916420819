define("itarp-business-application/components/forms/checkbox", ["exports", "itarp-business-application/components/forms/base", "@ember/object", "@ember/object/computed"], function (_exports, _base, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#unless @config.attributes.hidden}}
    <div class="checkbox-group {{this.wrapperClass}}" data-test-checkbox>
      {{#let (unique-id) as |checkboxID|}}
        <Input
          id={{checkboxID}}
          name={{this.label}}
          class="checkbox-group__field"
          @type="checkbox"
          @checked={{this.value}}
          {{on "input" this.updateValue}}
          data-test-checkbox-input
        />
        <label
          for={{checkboxID}}
          class="checkbox-group__label {{this.labelClass}}"
          data-test-checkbox-label
        >
          {{this.label}}
        </label>
      {{/let}}
    </div>
  {{/unless}}
  */
  {
    "id": "MgTSHV5v",
    "block": "[[[41,[51,[30,1,[\"attributes\",\"hidden\"]]],[[[1,\"  \"],[10,0],[15,0,[29,[\"checkbox-group \",[30,0,[\"wrapperClass\"]]]]],[14,\"data-test-checkbox\",\"\"],[12],[1,\"\\n\"],[44,[[28,[37,2],null,null]],[[[1,\"      \"],[8,[39,3],[[16,1,[30,2]],[16,3,[30,0,[\"label\"]]],[24,0,\"checkbox-group__field\"],[24,\"data-test-checkbox-input\",\"\"],[4,[38,4],[\"input\",[30,0,[\"updateValue\"]]],null]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"value\"]]]],null],[1,\"\\n      \"],[10,\"label\"],[15,\"for\",[30,2]],[15,0,[29,[\"checkbox-group__label \",[30,0,[\"labelClass\"]]]]],[14,\"data-test-checkbox-label\",\"\"],[12],[1,\"\\n        \"],[1,[30,0,[\"label\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@config\",\"checkboxID\"],false,[\"unless\",\"let\",\"unique-id\",\"input\",\"on\"]]",
    "moduleName": "itarp-business-application/components/forms/checkbox.hbs",
    "isStrictMode": false
  });

  let FormsCheckboxComponent = (_dec = (0, _computed.alias)('args.config.attributes'), _dec2 = (0, _object.computed)('attributes.label'), _dec3 = (0, _object.computed)('attributes.value'), _dec4 = (0, _object.computed)('attributes.required'), (_class = class FormsCheckboxComponent extends _base.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "attributes", _descriptor, this);
    }

    get label() {
      return this.attributes.label;
    }

    get value() {
      return this.attributes.value;
    }

    get required() {
      return this.attributes.required || false;
    }

    get wrapperClass() {
      if (this.required) return 'is-required';
    }

    get labelClass() {
      if (this.required) return 'label-note--required';
    }

    updateValue() {
      if (this.value !== undefined && this.value !== null) {
        return (0, _object.set)(this.attributes, 'value', !this.value);
      }

      return (0, _object.set)(this.attributes, 'value', true);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "attributes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "label", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "label"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "value", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "value"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "required", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "required"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateValue"), _class.prototype)), _class));
  _exports.default = FormsCheckboxComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormsCheckboxComponent);
});