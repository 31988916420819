define("itarp-query-filters/helpers/year-options", ["exports", "@ember/component/helper", "itarp-query-filters/utils/options/year-range"], function (_exports, _helper, _yearRange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(() => _yearRange.default);

  _exports.default = _default;
});