define("itarp-shared-assets-addon/utils/string/first-letter-capitalize", ["exports", "@ember/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = firstLetterCapitalize;

  /**
   * capitalize first letter of each owrd
   * Input: pending approval
   * Output: Pending Approval 
  
   */
  function firstLetterCapitalize(value) {
    if (typeof value !== 'string') return value;
    value = value.replaceAll('_', ' ');
    const words = value.split(' ');
    return words.map(word => {
      return (0, _string.capitalize)(word);
    }).join(' ');
  }
});