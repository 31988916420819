define("itarp-opportunity-designer/routes/publishing-options", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PublishingOptionsRoute extends _route.default {
    async model(params) {
      return this.store.findRecord('opportunity', params.id);
    }

  }

  _exports.default = PublishingOptionsRoute;
});