define("itarp-business-application/util/parser/attribute/attributes/model-collection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = parseModelCollection;

  function parseModelCollection(data) {
    let modelName = data; // const [record] = await store.getRecord(modelName);

    return {
      get: () => modelName,
      set: val => modelName = val
    };
  }
});