define("itarp-query-filters/utils/parse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = parse;

  function parse(value) {
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }
});