define("itarp-opportunity-designer/serializers/publish-setting", ["exports", "itarp-shared-assets-addon/serializers/data", "@ember/utils"], function (_exports, _data, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class PublishSettingSerializer extends _data.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        selected: {
          serialize: false
        },
        isPosted: {
          serialize: false
        },
        recordType: {
          serialize: false
        },
        jobBoardType: {
          serialize: false
        }
      });
    }

    normalize(modelClass, data) {
      data.selected = true;
      return super.normalize(...arguments);
    }

  }

  _exports.default = PublishSettingSerializer;
});