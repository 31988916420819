define("shared/components/ui/offscreens/base/header", ["exports", "@ember/component/template-only"], function (_exports, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="offscreen__header" ...attributes>
    <h3 class="offscreen__header__title">{{yield}}</h3>
    <a class="offscreen__header__close" {{on "click" @toggle}}></a>
  </div>
  */
  {
    "id": "Dd9ViU/V",
    "block": "[[[11,0],[24,0,\"offscreen__header\"],[17,1],[12],[1,\"\\n  \"],[10,\"h3\"],[14,0,\"offscreen__header__title\"],[12],[18,3,null],[13],[1,\"\\n  \"],[11,3],[24,0,\"offscreen__header__close\"],[4,[38,1],[\"click\",[30,2]],null],[12],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@toggle\",\"&default\"],false,[\"yield\",\"on\"]]",
    "moduleName": "shared/components/ui/offscreens/base/header.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});