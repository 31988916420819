define("itarp-components/components/table/candidates/column/opportunity", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let @row as |row|}}
    <row.cell ...attributes>
      <Ui::Wrapper>
        <div class='card-block--paragraph'>
          <LinkTo
            @route='details'
            class='card-block--job-role__title__link'
            @model={{@application.opportunity.id}}
          >
            {{@application.opportunity.jobTitle}}
          </LinkTo>
        </div>
      </Ui::Wrapper>
    </row.cell>
  {{/let}}
  */
  {
    "id": "a4UbP//H",
    "block": "[[[44,[[30,1]],[[[1,\"  \"],[8,[30,2,[\"cell\"]],[[17,3]],null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"card-block--paragraph\"],[12],[1,\"\\n        \"],[8,[39,2],[[24,0,\"card-block--job-role__title__link\"]],[[\"@route\",\"@model\"],[\"details\",[30,4,[\"opportunity\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,4,[\"opportunity\",\"jobTitle\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]],[\"@row\",\"row\",\"&attrs\",\"@application\"],false,[\"let\",\"ui/wrapper\",\"link-to\"]]",
    "moduleName": "itarp-components/components/table/candidates/column/opportunity.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});