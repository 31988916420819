define("ember-bootstrap/components/bs-dropdown/menu/divider", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _templateFactory, _component2, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{if (macroCondition (macroGetOwnConfig "isNotBS3")) "dropdown-divider" "divider"}} ...attributes>
    {{yield}}
  </div>
  */
  {
    "id": "2Kbf3b4d",
    "block": "[[[11,0],[24,0,\"dropdown-divider\"],[17,1],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-dropdown/menu/divider.hbs",
    "isStrictMode": false
  });

  let DropdownMenuDivider = (
  /**
   Component for a dropdown menu divider.
  
   See [Components.Dropdown](Components.Dropdown.html) for examples.
  
   @class DropdownMenuDivider
   @namespace Components
   @extends Ember.Component
   @public
   */
  _dec = (0, _component2.tagName)(''), _dec(_class = (0, _deprecateSubclassing.default)(_class = class DropdownMenuDivider extends _component.default {}) || _class) || _class);
  _exports.default = DropdownMenuDivider;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DropdownMenuDivider);
});