define("itarp-expert-preview/components/rate-display", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @application}}
    {{#if @application.displayRate}}
      <li class='card-block--info__list__item'>
        $<span
          class='card-block--info__bolded'
        >{{@application.displayRate}}</span>/hr
      </li>
    {{/if}}
  {{else}}
    {{#if @expert.profiles.hourlyRate}}
      <PermissionContent @action='view_hourly_rate' @identifier='expert_info'>
        <li class='card-block--info__list__item'>
          $<span
            class='card-block--info__bolded'
          >{{@expert.profiles.hourlyRate}}</span>/hr
        </li>
      </PermissionContent>
    {{/if}}
  {{/if}}
  */
  {
    "id": "8J1T7vy8",
    "block": "[[[41,[30,1],[[[41,[30,1,[\"displayRate\"]],[[[1,\"    \"],[10,\"li\"],[14,0,\"card-block--info__list__item\"],[12],[1,\"\\n      $\"],[10,1],[14,0,\"card-block--info__bolded\"],[12],[1,[30,1,[\"displayRate\"]]],[13],[1,\"/hr\\n    \"],[13],[1,\"\\n\"]],[]],null]],[]],[[[41,[30,2,[\"profiles\",\"hourlyRate\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@action\",\"@identifier\"],[\"view_hourly_rate\",\"expert_info\"]],[[\"default\"],[[[[1,\"\\n      \"],[10,\"li\"],[14,0,\"card-block--info__list__item\"],[12],[1,\"\\n        $\"],[10,1],[14,0,\"card-block--info__bolded\"],[12],[1,[30,2,[\"profiles\",\"hourlyRate\"]]],[13],[1,\"/hr\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[]]]],[\"@application\",\"@expert\"],false,[\"if\",\"permission-content\"]]",
    "moduleName": "itarp-expert-preview/components/rate-display.hbs",
    "isStrictMode": false
  });

  let RateDisplayComponent = (_class = class RateDisplayComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "markupRate", _descriptor, this);

      this.loadDisplayedRate();
    }

    loadDisplayedRate() {
      const application = this.args.application;

      if (application && !application.get('displayRate')) {
        const isPresenter = !!application.content;

        if (isPresenter) {
          return this.markupRate.getApplicationRates([application.content]).then(rates => {
            if (this.args.onRateLoaded) {
              this.args.onRateLoaded(rates[0]);
            }
          });
        }

        this.markupRate.getApplicationRates([application]).then(rates => {
          if (this.args.onRateLoaded) {
            this.args.onRateLoaded(rates[0]);
          }
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "markupRate", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadDisplayedRate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadDisplayedRate"), _class.prototype)), _class);
  _exports.default = RateDisplayComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RateDisplayComponent);
});