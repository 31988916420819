define("itarp-business-application/components/forms/submit-button", ["exports", "@glimmer/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="component-wrapper">
    <button
      data-test-submit-button
      class="app-btn {{this.btnClass}}"
      type="button"
      {{on "click" this.submit}}
      data-location={{this.link}}
    >
      {{this.attributes.label}}
    </button>
  </div>
  
  */
  {
    "id": "40rUmZWG",
    "block": "[[[10,0],[14,0,\"component-wrapper\"],[12],[1,\"\\n  \"],[11,\"button\"],[24,\"data-test-submit-button\",\"\"],[16,0,[29,[\"app-btn \",[30,0,[\"btnClass\"]]]]],[16,\"data-location\",[30,0,[\"link\"]]],[24,4,\"button\"],[4,[38,0],[\"click\",[30,0,[\"submit\"]]],null],[12],[1,\"\\n    \"],[1,[30,0,[\"attributes\",\"label\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"on\"]]",
    "moduleName": "itarp-business-application/components/forms/submit-button.hbs",
    "isStrictMode": false
  });

  let FormsSubmitButtonComponent = (_dec = (0, _computed.alias)('args.config.attributes'), _dec2 = (0, _object.computed)('attributes.{location,param,paramValue}'), (_class = class FormsSubmitButtonComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "attributes", _descriptor, this);
    }

    submit(event) {
      event.stopPropagation();
      this.args.submit && this.args.submit(event);
    }

    onSuccess(event) {
      return function (isSuccess) {
        if (isSuccess === false) event.stopPropagation();
      };
    }

    get link() {
      if (this.attributes.param && this.attributes.paramValue) {
        const {
          location,
          param,
          paramValue
        } = this.attributes;
        return `${location}?${param}=${paramValue}`;
      }

      return this.attributes.location;
    }

    get btnClass() {
      if (this.attributes.type) {
        switch (this.attributes.type) {
          case 'secondary':
            return 'btn--mw';
            break;

          case 'primary':
            return 'btn--mw app-btn--primary';
            break;

          case 'secondary-icon':
            return 'btn--mw btn-icon btn-icon--prev';
            break;

          case 'primary-icon':
            return 'btn--mw btn-icon btn-icon--next app-btn--primary';
            break;

          default:
            return 'btn--mw';
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "attributes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "submit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSuccess", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSuccess"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "link", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "link"), _class.prototype)), _class));
  _exports.default = FormsSubmitButtonComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormsSubmitButtonComponent);
});