define("itarp-business-application/components/forms/dropdown", ["exports", "itarp-business-application/components/forms/base", "@ember/object", "@ember/object/computed"], function (_exports, _base, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#unless @config.attributes.hidden}}
    {{#let (unique-id) as |inputId|}}
      <div class="component-wrapper" data-test-dropdown-component>
        <div
          class="select-group
            {{if this.isRequired "is-required"}}
            {{if this.tip "has-tip"}}"
        >
          <label
            class="select-group__label"
            for={{inputId}}
            data-test-dropdown-label
          >
            {{#if this.isRequired}}
              <span
                class="label-note label-note--required"
                data-test-dropdown-required-label
              ></span>
            {{/if}}
            {{this.attributes.label}}
          </label>
          <PowerSelect
            @options={{this.sortedOptions}}
            @selected={{find-by "value" this.options this.value}}
            @placeholder={{this.placeholder}}
            @onChange={{fn this.select}}
            @searchEnabled={{this.searchEnabled}}
            @searchField="label"
            id={{inputId}}
            as |option|
          >
            {{option.label}}
          </PowerSelect>
          {{#if this.attributes.tip}}
            <span class="form-tip" data-test-dropdown-tip>
              Tip
              <EmberPopover
                @event="hover"
                @side="left"
                @tooltipClass="form-tip__tooltip"
              >
                {{this.attributes.tip}}
              </EmberPopover>
            </span>
          {{/if}}
        </div>
      </div>
    {{/let}}
  {{/unless}}
  */
  {
    "id": "b9P/PTfb",
    "block": "[[[41,[51,[30,1,[\"attributes\",\"hidden\"]]],[[[44,[[28,[37,2],null,null]],[[[1,\"    \"],[10,0],[14,0,\"component-wrapper\"],[14,\"data-test-dropdown-component\",\"\"],[12],[1,\"\\n      \"],[10,0],[15,0,[29,[\"select-group\\n          \",[52,[30,0,[\"isRequired\"]],\"is-required\"],\"\\n          \",[52,[30,0,[\"tip\"]],\"has-tip\"]]]],[12],[1,\"\\n        \"],[10,\"label\"],[14,0,\"select-group__label\"],[15,\"for\",[30,2]],[14,\"data-test-dropdown-label\",\"\"],[12],[1,\"\\n\"],[41,[30,0,[\"isRequired\"]],[[[1,\"            \"],[10,1],[14,0,\"label-note label-note--required\"],[14,\"data-test-dropdown-required-label\",\"\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"          \"],[1,[30,0,[\"attributes\",\"label\"]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[39,4],[[16,1,[30,2]]],[[\"@options\",\"@selected\",\"@placeholder\",\"@onChange\",\"@searchEnabled\",\"@searchField\"],[[30,0,[\"sortedOptions\"]],[28,[37,5],[\"value\",[30,0,[\"options\"]],[30,0,[\"value\"]]],null],[30,0,[\"placeholder\"]],[28,[37,6],[[30,0,[\"select\"]]],null],[30,0,[\"searchEnabled\"]],\"label\"]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,3,[\"label\"]]],[1,\"\\n        \"]],[3]]]]],[1,\"\\n\"],[41,[30,0,[\"attributes\",\"tip\"]],[[[1,\"          \"],[10,1],[14,0,\"form-tip\"],[14,\"data-test-dropdown-tip\",\"\"],[12],[1,\"\\n            Tip\\n            \"],[8,[39,7],null,[[\"@event\",\"@side\",\"@tooltipClass\"],[\"hover\",\"left\",\"form-tip__tooltip\"]],[[\"default\"],[[[[1,\"\\n              \"],[1,[30,0,[\"attributes\",\"tip\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2]]]],[]],null]],[\"@config\",\"inputId\",\"option\"],false,[\"unless\",\"let\",\"unique-id\",\"if\",\"power-select\",\"find-by\",\"fn\",\"ember-popover\"]]",
    "moduleName": "itarp-business-application/components/forms/dropdown.hbs",
    "isStrictMode": false
  });

  let FormsDropdownComponent = (_dec = (0, _computed.alias)('args.config.attributes'), _dec2 = (0, _computed.sort)('options', 'sortDirection'), _dec3 = (0, _object.computed)('attributes.options.[]'), _dec4 = (0, _object.computed)('attributes.value'), (_class = class FormsDropdownComponent extends _base.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "attributes", _descriptor, this);

      _initializerDefineProperty(this, "sortedOptions", _descriptor2, this);
    }

    get placeholder() {
      return this.attributes.placeholder || '';
    }

    get isRequired() {
      return this.attributes.required || false;
    }

    get searchEnabled() {
      return this.attributes.search || false;
    }

    get sortDirection() {
      return this.attributes.sort === 'desc' ? ['label:desc'] : ['label'];
    }

    get options() {
      return this.attributes.options;
    }

    get value() {
      return this.attributes.value;
    }

    select(option) {
      (0, _object.set)(this.attributes, 'value', option.value);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "attributes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sortedOptions", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "options", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "options"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "value", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "value"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype)), _class));
  _exports.default = FormsDropdownComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormsDropdownComponent);
});