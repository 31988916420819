define("itarp-components/components/ui/grid/row", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='row' ...attributes>
    {{yield (hash column=(component 'ui/grid/column'))}}
  </div>
  
  */
  {
    "id": "K0gWSeis",
    "block": "[[[11,0],[24,0,\"row\"],[17,1],[12],[1,\"\\n  \"],[18,2,[[28,[37,1],null,[[\"column\"],[[50,\"ui/grid/column\",0,null,null]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-components/components/ui/grid/row.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});