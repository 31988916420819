define("itarp-opportunity-designer/components/board/settings-group", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='row'>
    <div class='col--12-12'>
      <div class='component-wrapper has-padding'>
        <h4 class="ts--18">
          {{@group.label}}
        </h4>
        {{#each @group.options as |option|}}
          {{#if (eq option.setting.id @settingId)}}
            <Board::SettingsOption @option={{option}} />
          {{/if}}
        {{/each}}
      </div>
    </div>
  </div>
  
  */
  {
    "id": "ZXFA7V8P",
    "block": "[[[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"col--12-12\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"component-wrapper has-padding\"],[12],[1,\"\\n      \"],[10,\"h4\"],[14,0,\"ts--18\"],[12],[1,\"\\n        \"],[1,[30,1,[\"label\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"options\"]]],null]],null],null,[[[41,[28,[37,3],[[30,2,[\"setting\",\"id\"]],[30,3]],null],[[[1,\"          \"],[8,[39,4],null,[[\"@option\"],[[30,2]]],null],[1,\"\\n\"]],[]],null]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@group\",\"option\",\"@settingId\"],false,[\"each\",\"-track-array\",\"if\",\"eq\",\"board/settings-option\"]]",
    "moduleName": "itarp-opportunity-designer/components/board/settings-group.hbs",
    "isStrictMode": false
  });

  class BoardSettingsGroupComponent extends _component2.default {}

  _exports.default = BoardSettingsGroupComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BoardSettingsGroupComponent);
});