define("ember-a11y-accordion/components/accordion-header", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-a11y-accordion/utils/dom"], function (_exports, _component, _templateFactory, _component2, _dom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <header class={{this.className}} ...attributes>
    {{#if @isCollapsible}}
      <button
        type="button"
        id={{@triggerId}}
        class="a11y-accordion-header__trigger"
        aria-controls={{@panelId}}
        aria-expanded={{this.ariaExpanded}}
        aria-disabled={{this.ariaDisabled}}
        {{on "click" @toggle}}>
        {{yield}}
      </button>
    {{else}}
      <button
        type="button"
        id={{@triggerId}}
        class="a11y-accordion-header__trigger"
        aria-controls={{@panelId}}
        aria-expanded={{this.ariaExpanded}}
        aria-disabled={{if @isDisabled this.ariaDisabled this.ariaExpanded}}
        {{on "click" @toggle}}>
        {{yield}}
      </button>
    {{/if}}
  </header>
  */
  {
    "id": "UCsXjhS2",
    "block": "[[[11,\"header\"],[16,0,[30,0,[\"className\"]]],[17,1],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[11,\"button\"],[16,1,[30,3]],[24,0,\"a11y-accordion-header__trigger\"],[16,\"aria-controls\",[30,4]],[16,\"aria-expanded\",[30,0,[\"ariaExpanded\"]]],[16,\"aria-disabled\",[30,0,[\"ariaDisabled\"]]],[24,4,\"button\"],[4,[38,1],[\"click\",[30,5]],null],[12],[1,\"\\n      \"],[18,7,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[11,\"button\"],[16,1,[30,3]],[24,0,\"a11y-accordion-header__trigger\"],[16,\"aria-controls\",[30,4]],[16,\"aria-expanded\",[30,0,[\"ariaExpanded\"]]],[16,\"aria-disabled\",[52,[30,6],[30,0,[\"ariaDisabled\"]],[30,0,[\"ariaExpanded\"]]]],[24,4,\"button\"],[4,[38,1],[\"click\",[30,5]],null],[12],[1,\"\\n      \"],[18,7,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"&attrs\",\"@isCollapsible\",\"@triggerId\",\"@panelId\",\"@toggle\",\"@isDisabled\",\"&default\"],false,[\"if\",\"on\",\"yield\"]]",
    "moduleName": "ember-a11y-accordion/components/accordion-header.hbs",
    "isStrictMode": false
  });

  const DEFAULT_ARIA_LEVEL = '3';
  /**
   * The accordion-header receives the toggle action and handles expanding
   * or collapsing the respective panel component.
   *
   * @param {String} [aria-level] The ARIA heading level (defaults to "3")
   * @param {String} [class] Any CSS classes to be added to the component's element
   */

  class AccordionHeaderComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "className", _dom.CLASS_NAMES.header);
    }

    get ariaDisabled() {
      return this.args.isDisabled ? 'true' : 'false';
    }

    get ariaExpanded() {
      return this.args.isExpanded ? 'true' : 'false';
    }

    get ariaLevel() {
      return this.args.ariaLevel ?? DEFAULT_ARIA_LEVEL;
    }

  }

  _exports.default = AccordionHeaderComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AccordionHeaderComponent);
});