define("itarp-workflow-designer/mirage/scenarios/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    const actionTemplates1 = [{
      name: 'if/then Condition',
      icon: 'condition'
    }, {
      name: 'Enter another Workflow',
      icon: 'enter'
    }, {
      name: 'Advance Stage',
      icon: 'advance'
    }, {
      name: 'Update Stage',
      icon: 'update'
    }, {
      name: 'Trigger Webhook',
      icon: 'trigger'
    }].map((name, icon) => server.create('action-template', name, icon));
    const actionTemplates2 = [{
      name: 'Send Email Message',
      icon: 'email'
    }, {
      name: 'Send Text Message',
      icon: 'text-message'
    }, {
      name: 'Send In-app Notification',
      icon: 'notification'
    }, {
      name: 'Send Push Notification',
      icon: 'notification'
    } // { name: 'Post a Comment', icon: 'comment' },
    // { name: 'Notify Stakeholders', icon: 'notify' },
    ].map((name, icon) => server.create('action-template', name, icon));
    const actionTemplates3 = [{
      name: 'Send Email',
      icon: 'email'
    }].map((name, icon) => server.create('action-template', name, icon));
    const actionTemplates4 = [{
      name: 'Create Task',
      icon: 'task'
    }].map((name, icon) => server.create('action-template', name, icon));
    const actionTemplates5 = [{
      name: 'Embed Interview Scorecard',
      icon: 'interview-scorecard'
    }, {
      name: 'Embed Interview Guideline',
      icon: 'guideline'
    }, {
      name: 'Embed Video Interview',
      icon: 'video'
    }].map((name, icon) => server.create('action-template', name, icon));
    const actionTemplates6 = [{
      name: 'Send Assessment',
      icon: 'assessment'
    }].map((name, icon) => server.create('action-template', name, icon));
    const actionTemplates7 = [{
      name: 'Send Questionarie',
      icon: 'questionarie'
    }].map((name, icon) => server.create('action-template', name, icon));
    const actionTemplates8 = [{
      name: 'Reject Candidate',
      icon: 'reject'
    }, {
      name: 'Add Tags',
      icon: 'add-tag'
    }, {
      name: 'Remove Tags',
      icon: 'remove-tag'
    }, {
      name: 'TalentCloud Prospect',
      icon: 'talentcloud'
    }, {
      name: 'Make TalentCloud Member',
      icon: 'talentcloud'
    }].map((name, icon) => server.create('action-template', name, icon));
    const actionTemplates9 = [{
      name: 'Update Owner',
      icon: 'update-owner'
    }, {
      name: 'Remove Owner',
      icon: 'remove-owner'
    }].map((name, icon) => server.create('action-template', name, icon));
    const actionTemplates10 = [{
      name: 'Update Source Category',
      icon: 'source'
    }, {
      name: 'Add Feedback',
      icon: 'feedback'
    }].map((name, icon) => server.create('action-template', name, icon)); // const actionGroups1 = [
    //   {
    //     name: 'Workflow',
    //     icon: 'workflow',
    //     actionTemplates: actionTemplates1,
    //   },
    // ].map((name, icon, actionTemplates) =>
    //   server.create('action-group', name, icon, actionTemplates)
    // );

    const actionGroups2 = [{
      name: 'Internal Communication',
      icon: 'communication',
      actionTemplates: actionTemplates2
    }].map((name, icon, actionTemplates) => server.create('action-group', name, icon, actionTemplates)); // const actionGroups3 = [
    //   {
    //     name: 'External Communication',
    //     icon: 'communication',
    //     actionTemplates: actionTemplates3,
    //   },
    // ].map((name, icon, actionTemplates) =>
    //   server.create('action-group', name, icon, actionTemplates)
    // );
    // const actionGroups4 = [
    //   {
    //     name: 'Task',
    //     icon: 'task',
    //     actionTemplates: actionTemplates4,
    //   },
    // ].map((name, icon, actionTemplates) =>
    //   server.create('action-group', name, icon, actionTemplates)
    // );
    // const actionGroups5 = [
    //   {
    //     name: 'Interview',
    //     icon: 'interview',
    //     actionTemplates: actionTemplates5,
    //   },
    // ].map((name, icon, actionTemplates) =>
    //   server.create('action-group', name, icon, actionTemplates)
    // );
    // const actionGroups6 = [
    //   {
    //     name: 'Assessment',
    //     icon: 'assessment',
    //     actionTemplates: actionTemplates6,
    //   },
    // ].map((name, icon, actionTemplates) =>
    //   server.create('action-group', name, icon, actionTemplates)
    // );
    // const actionGroups7 = [
    //   {
    //     name: 'Questionarie',
    //     icon: 'questionarie',
    //     actionTemplates: actionTemplates7,
    //   },
    // ].map((name, icon, actionTemplates) =>
    //   server.create('action-group', name, icon, actionTemplates)
    // );
    // const actionGroups8 = [
    //   {
    //     name: 'Candidate actions',
    //     icon: 'candidate',
    //     actionTemplates: actionTemplates8,
    //   },
    // ].map((name, icon, actionTemplates) =>
    //   server.create('action-group', name, icon, actionTemplates)
    // );
    // const actionGroups9 = [
    //   {
    //     name: 'Opportunity Owner',
    //     icon: 'owner',
    //     actionTemplates: actionTemplates9,
    //   },
    // ].map((name, icon, actionTemplates) =>
    //   server.create('action-group', name, icon, actionTemplates)
    // );
    // const actionGroups10 = [
    //   {
    //     name: 'Other actions',
    //     icon: 'other',
    //     actionTemplates: actionTemplates10,
    //   },
    // ].map((name, icon, actionTemplates) =>
    //   server.create('action-group', name, icon, actionTemplates)
    // );

    const workflowTemplate1 = [{
      metadata: {
        name: 'Custom',
        stage_groups: []
      }
    }].map((name, templates) => server.create('workflow-template', name, templates));
    const workflowTemplate2 = [{
      metadata: {
        name: 'Job',
        stage_groups: [{
          name: 'Applied',
          stageGroup: 'Applied',
          displayOrder: 1
        }, {
          name: 'Screening',
          stageGroup: 'Screening',
          displayOrder: 2
        }, {
          name: 'Assessment',
          stageGroup: 'Assessment',
          displayOrder: 3
        }, {
          name: 'Shortlisted',
          stageGroup: 'Shortlisted',
          displayOrder: 4
        }, {
          name: 'Interview',
          stageGroup: 'Interview',
          displayOrder: 5
        }, {
          name: 'Offer',
          stageGroup: 'Offer',
          displayOrder: 6
        }, {
          name: 'Onboarding',
          stageGroup: 'Onboarding',
          displayOrder: 7
        }, {
          name: 'Hired',
          stageGroup: 'Hired',
          displayOrder: 8
        }, {
          name: 'Job Completed',
          stageGroup: 'Job Completed',
          displayOrder: 9
        }]
      }
    }].map((name, templates) => server.create('workflow-template', name, templates));
    const workflowTemplate3 = [{
      metadata: {
        name: 'TalentCloud',
        stage_groups: [{
          name: 'Applied',
          stageGroup: 'Applied',
          displayOrder: 1
        }, {
          name: 'Screening',
          stageGroup: 'Screening',
          displayOrder: 2
        }, {
          name: 'Assessment',
          stageGroup: 'Assessment',
          displayOrder: 3
        }, {
          name: 'Shortlisted',
          stageGroup: 'Shortlisted',
          displayOrder: 4
        }, {
          name: 'Interview',
          stageGroup: 'Interview',
          displayOrder: 5
        }, {
          name: 'Offer',
          stageGroup: 'Offer',
          displayOrder: 6
        }, {
          name: 'Onboarding',
          stageGroup: 'Onboarding',
          displayOrder: 7
        }, {
          name: 'Hired',
          stageGroup: 'Hired',
          displayOrder: 8
        }, {
          name: 'TalendCloud Completed',
          stageGroup: 'Job Completed',
          displayOrder: 9
        }]
      }
    }].map((name, templates) => server.create('workflow-template', name, templates));
    server.create('workflow', 3);
    server.create('stage-group', 3);
    server.create('stage', 3);
    server.create('action', 3);
  }
});