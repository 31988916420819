define("itarp-workflow-designer/services/trigger", ["exports", "@ember/service", "ember-inflector", "@ember/string"], function (_exports, _service, _emberInflector, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TriggerService = (_dec = (0, _service.inject)('workflow-constants'), (_class = class TriggerService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "constants", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _defineProperty(this, "filters", [{
        stage: 'invitation_pending',
        stageGroup: 'invited',
        metadata: stage => ({
          runtime_params: {
            resource_id: 'main_trigger_event.payload.attributes.resource_id',
            workflow_id: `service:data_service.[/${this.resourceForStage(stage)}/{{runtime_params.resource_id}}].workflow_id`
          },
          filter_old: {},
          filter_new: {
            '{{runtime_params.workflow_id}}': this.workflowIdentifier(stage)
          }
        })
      }, {
        stage: 'invitation_declined',
        stageGroup: 'invited',
        metadata: stage => ({
          runtime_params: {
            resource_id: 'main_trigger_event.payload.attributes.resource_id',
            workflow_id: `service:data_service.[/${this.resourceForStage(stage)}/{{runtime_params.resource_id}}].workflow_id`
          },
          filter_old: {},
          filter_new: {
            '{{runtime_params.workflow_id}}': this.workflowIdentifier(stage),
            status: 'declined'
          }
        })
      }, {
        stage: 'applied',
        stageGroup: 'applied',
        metadata: stage => ({
          filter_old: {},
          filter_new: {
            is_invited: false,
            [this.stageIdentifierAttribute(stage)]: stage.get('identifier')
          }
        })
      }, {
        stage: 'applied',
        stageGroup: 'invited',
        metadata: stage => ({
          filter_old: {},
          filter_new: {
            is_invited: true,
            [this.stageIdentifierAttribute(stage)]: stage.get('identifier')
          }
        })
      }]);

      _defineProperty(this, "collections", [{
        workflowTypes: ['opportunity_recruiting', 'talentcloud_recruiting'],
        stages: ['applied'],
        collection: 'applications'
      }, {
        workflowTypes: ['opportunity_recruiting', 'talentcloud_recruiting'],
        stages: ['invitation_pending', 'invitation_declined'],
        collection: 'invitations'
      }, {
        workflowTypes: ['opportunity_approval'],
        stages: [],
        collection: 'opportunities'
      }, {
        workflowTypes: ['talentcloud_approval'],
        stages: [],
        collection: 'talent_clouds'
      }]);
    }

    createTrigger(stage) {
      const collection = this.collectionForStage(stage);
      const eventType = this.eventTypeForStage(stage);
      const filter = this.filterForStage(stage);
      const metadata = filter ? filter.metadata(stage) : this.defaultMetadata(stage);
      return this.store.createRecord('trigger', {
        collectionName: collection,
        eventType: eventType,
        metaData: metadata,
        eventName: `${collection}.${eventType}`,
        name: this.triggerName(collection, eventType)
      });
    }

    triggerName(collection, eventType) {
      return `${(0, _emberInflector.singularize)((0, _string.capitalize)(collection))} ${(0, _string.capitalize)(eventType)}`;
    }

    collectionForStage(stage) {
      const stageType = stage.get('stageType');
      const workflow = stage.get('stageGroup.workflow');
      const workflowType = workflow.get('type');
      const workflowCollections = this.collections.filter(collection => {
        return collection.workflowTypes.includes(workflowType);
      });
      const collection = workflowCollections.find(collection => {
        return collection.stages.includes(stageType);
      });
      return collection ? collection.collection : workflowCollections[0].collection;
    }

    eventTypeForStage(stage) {
      const stageType = stage.get('stageType');
      const createdTypes = ['applied', 'invitation_pending'];
      return createdTypes.includes(stageType) ? 'created' : 'updated';
    }

    filterForStage(stage) {
      const stageType = stage.get('stageType');
      const stageGroupType = stage.get('stageGroup.stageGroupType');
      return this.filters.find(filter => {
        return filter.stage === stageType && filter.stageGroup === stageGroupType;
      });
    }

    resourceForStage(stage) {
      const workflow = stage.get('stageGroup.workflow');
      const workflowType = workflow.get('type');

      if (workflowType.indexOf('opportunity') !== -1) {
        return 'opportunities';
      }

      return 'talent_clouds';
    }

    defaultMetadata(stage) {
      return {
        filter_old: {},
        filter_new: {
          [this.stageIdentifierAttribute(stage)]: stage.get('identifier')
        }
      };
    }

    stageIdentifierAttribute(stage) {
      const workflow = stage.get('stageGroup.workflow');
      const workflowType = workflow.get('type');
      return this.constants.STAGE_IDENTIFIERS[workflowType.toUpperCase()];
    }

    workflowIdentifier(stage) {
      const workflow = stage.get('stageGroup.workflow');
      return workflow.get('identifier');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TriggerService;
});