define("shared/serializers/taxonomy", ["exports", "shared/serializers/business-application"], function (_exports, _businessApplication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TaxonomySerializer extends _businessApplication.default {}

  _exports.default = TaxonomySerializer;
});