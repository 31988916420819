define("itarp-opportunity-designer/routes/shared-rosters", ["exports", "@ember/routing/route", "rsvp", "@ember/service"], function (_exports, _route, _rsvp, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SharedRostersRoute = (_class = class SharedRostersRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "adTokenService", _descriptor, this);

      _defineProperty(this, "queryParams", {
        page: {
          refreshModel: true
        }
      });
    }

    async model(params) {
      const ownerId = this.adTokenService.tokenInformation.sub;
      return (0, _rsvp.hash)({
        sharedRosters: this.store.query('roster-snapshot', {
          order_by: 'created_at',
          per_page: 5,
          page: params.page,
          advance_filter: {
            member_ids: {
              $in: [ownerId]
            }
          },
          user_object: true
        }).then(results => {
          return {
            data: results,
            meta: results.meta
          };
        })
      });
    }

    setupController(controller, model) {
      var _model$rosters, _model$rosters$meta, _model$rosters$meta$p;

      super.setupController(...arguments);
      let rosterSnapshots = model.sharedRosters.data.toArray();
      const rosterHasMultiplePages = ((_model$rosters = model.rosters) === null || _model$rosters === void 0 ? void 0 : (_model$rosters$meta = _model$rosters.meta) === null || _model$rosters$meta === void 0 ? void 0 : (_model$rosters$meta$p = _model$rosters$meta.page) === null || _model$rosters$meta$p === void 0 ? void 0 : _model$rosters$meta$p.total) > 5;
      controller.set('rosterHasMultiplePages', rosterHasMultiplePages);
      controller.set('sharedRosters', rosterSnapshots);
      controller.set('pageMeta', model.sharedRosters.meta.page);

      if (model.sharedRosters.meta.page && model.sharedRosters.meta.page.total) {
        controller.set('total', model.sharedRosters.meta.page.total);
      }
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('page', 1);
        controller.set('usersPageMeta.page', 1);
        controller.set('consultants', null);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "adTokenService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = SharedRostersRoute;
});