define("itarp-shared-assets-addon/helpers/findby", ["exports", "@ember/component/helper", "@ember/utils", "@ember/array"], function (_exports, _helper, _utils, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FindBy extends _helper.default {
    compute([array, attr, value]) {
      if ((0, _utils.isEmpty)(attr)) {
        return [];
      }

      if ((0, _utils.typeOf)(value) === 'object') {
        return (0, _array.A)(array).find(item => {
          const itemValue = JSON.stringify(item[attr]);
          return itemValue === JSON.stringify(value);
        });
      }

      return (0, _array.A)(array).findBy(attr, value);
    }

  }

  _exports.default = FindBy;
});