define("itarp-workflow-designer/serializers/action", ["exports", "@ember-data/serializer/json-api", "@ember/string", "@ember/object"], function (_exports, _jsonApi, _string, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let ActionSerializer = (_class = class ActionSerializer extends _jsonApi.default {
    serialize(snapshot) {
      const serializeMethods = {
        send_email: this.serializeSendEmailAction,
        action: this.serializeAction
      };
      const type = snapshot.record.actionType;
      const serialize = serializeMethods[type];
      return serialize ? serialize(snapshot) : serializeMethods.action(snapshot);
    }

    normalize(modelClass, resourceHash) {
      var _attributes$actionFo;

      const attributes = resourceHash.attributes;
      const actionType = attributes['action-type'];
      const isEmailType = actionType === 'send_email';
      const hasActionFormType = (_attributes$actionFo = attributes['action-form-data']) === null || _attributes$actionFo === void 0 ? void 0 : _attributes$actionFo.actionFormType;

      if (isEmailType && !hasActionFormType) {
        const emailFormType = 'send_email_with_sender_and_recipients';
        attributes['action-form-data'].actionFormType = emailFormType;
        attributes['action-form-data'].recipients = [];
        attributes['action-form-data'].templateId = this.extractTemplateId(attributes);
      }

      return super.normalize(modelClass, resourceHash);
    }

    extractTemplateId(attributes) {
      return this.recursiveKeyFind(attributes.metadata, 'notificationTemplateID');
    }

    recursiveKeyFind(obj, key) {
      let result;

      for (const prop in obj) {
        if (prop === key) {
          return obj[prop];
        }

        if (typeof obj[prop] === 'object') {
          result = this.recursiveKeyFind(obj[prop], key);

          if (result) {
            return result;
          }
        }
      }
    }

    transformAttributes(snapshot) {
      const result = {};
      snapshot.eachAttribute(attribute => {
        result[(0, _string.underscore)(attribute)] = snapshot.record.get(attribute);
      });
      return result;
    }

    serializeAction(snapshot) {
      const serialized = this.transformAttributes(snapshot);
      const stage_id = snapshot.belongsTo('stage').id;
      return {
        actions: { ...serialized,
          stage_id
        }
      };
    }

    serializeSendEmailAction(snapshot) {
      const serialized = this.transformAttributes(snapshot);
      const stage_id = snapshot.belongsTo('stage').id;
      return {
        actions: { ...serialized,
          stage_id,
          metadata: snapshot.record.metadata
        }
      };
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "extractTemplateId", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "extractTemplateId"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transformAttributes", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "transformAttributes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "serializeAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "serializeAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "serializeSendEmailAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "serializeSendEmailAction"), _class.prototype)), _class);
  _exports.default = ActionSerializer;
});