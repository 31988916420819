define("itarp-workflow-designer/mirage/endpoints/stage-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(context) {
    context.get('stage_groups', 'stage-groups', function (schema, request) {
      let data;

      if (request.queryParams) {
        const workflow = request.queryParams.workflow_id;
        data = schema.stageGroups.where(group => group.workflowId === workflow);
      } else {
        data = schema.stageGroups.all();
      }

      return this.serialize(data);
    });
    context.get('stage_groups/:id', 'stage-groups', 'stage-groups/:id');
    context.post('stage_groups', 'stage-groups');
    context.patch('stage_groups/:id', 'stage-groups', 'stage-groups/:id');
    context.del('stage_groups/:id', 'stage-groups', 'stage-groups/:id');
  }
});