define("itarp-business-application/components/collections/tabs", ["exports", "@glimmer/component", "@ember/object", "@ember/object/computed", "@glimmer/tracking"], function (_exports, _component, _object, _computed, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#unless @config.attributes.hidden}}
    <div class="component-wrapper">
      <div class="form-tabs">
        <AriaTabs data-test-tabs-component class="form-tabs__wrapper" as |at|>
          <div data-test-tabs-list class="form-tabs__list">
            <at.tabList as |tl|>
              {{#each this.children as |child index|}}
                <tl.tab data-test-tabs-item>
                  {{child.attributes.title}}
                  <span id="form-tabs-{{index}}"></span>
                </tl.tab>
              {{/each}}
            </at.tabList>
          </div>
          <div data-test-tabs-panels class="form-tabs__panels">
            {{#each @config.children as |child index|}}
              <at.tabPanel data-test-tabs-panel-item>
                {{#each child.children as |nestedChild|}}
                  <RenderElement
                    @element={{nestedChild}}
                    @observer={{@observer}}
                    @submit={{@submit}}
                    @reset={{@reset}}
                    @onChange={{@onChange}}
                    @index={{index}}
                  >
                    {{yield}}
                  </RenderElement>
                {{/each}}
              </at.tabPanel>
            {{/each}}
          </div>
        </AriaTabs>
      </div>
    </div>
  {{/unless}}
  */
  {
    "id": "yDjimJSm",
    "block": "[[[41,[51,[30,1,[\"attributes\",\"hidden\"]]],[[[1,\"  \"],[10,0],[14,0,\"component-wrapper\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"form-tabs\"],[12],[1,\"\\n      \"],[8,[39,1],[[24,\"data-test-tabs-component\",\"\"],[24,0,\"form-tabs__wrapper\"]],null,[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,\"data-test-tabs-list\",\"\"],[14,0,\"form-tabs__list\"],[12],[1,\"\\n          \"],[8,[30,2,[\"tabList\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"children\"]]],null]],null],null,[[[1,\"              \"],[8,[30,3,[\"tab\"]],[[24,\"data-test-tabs-item\",\"\"]],null,[[\"default\"],[[[[1,\"\\n                \"],[1,[30,4,[\"attributes\",\"title\"]]],[1,\"\\n                \"],[10,1],[15,1,[29,[\"form-tabs-\",[30,5]]]],[12],[13],[1,\"\\n              \"]],[]]]]],[1,\"\\n\"]],[4,5]],null],[1,\"          \"]],[3]]]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,\"data-test-tabs-panels\",\"\"],[14,0,\"form-tabs__panels\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,1,[\"children\"]]],null]],null],null,[[[1,\"            \"],[8,[30,2,[\"tabPanel\"]],[[24,\"data-test-tabs-panel-item\",\"\"]],null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,6,[\"children\"]]],null]],null],null,[[[1,\"                \"],[8,[39,4],null,[[\"@element\",\"@observer\",\"@submit\",\"@reset\",\"@onChange\",\"@index\"],[[30,8],[30,9],[30,10],[30,11],[30,12],[30,7]]],[[\"default\"],[[[[1,\"\\n                  \"],[18,13,null],[1,\"\\n                \"]],[]]]]],[1,\"\\n\"]],[8]],null],[1,\"            \"]],[]]]]],[1,\"\\n\"]],[6,7]],null],[1,\"        \"],[13],[1,\"\\n      \"]],[2]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@config\",\"at\",\"tl\",\"child\",\"index\",\"child\",\"index\",\"nestedChild\",\"@observer\",\"@submit\",\"@reset\",\"@onChange\",\"&default\"],false,[\"unless\",\"aria-tabs\",\"each\",\"-track-array\",\"render-element\",\"yield\"]]",
    "moduleName": "itarp-business-application/components/collections/tabs.hbs",
    "isStrictMode": false
  });

  let CollectionsTabsComponent = (_dec = (0, _computed.alias)('args.config.attributes'), _dec2 = (0, _computed.alias)('args.config'), _dec3 = (0, _object.computed)('config.children'), (_class = class CollectionsTabsComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "attributes", _descriptor, this);

      _initializerDefineProperty(this, "config", _descriptor2, this);

      _initializerDefineProperty(this, "selectedIndex", _descriptor3, this);
    }

    get children() {
      return this.config.children;
    }

    selectTab(index) {
      this.selectedIndex = index;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "attributes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "config", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectedIndex", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "children", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "children"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectTab", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectTab"), _class.prototype)), _class));
  _exports.default = CollectionsTabsComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CollectionsTabsComponent);
});