define("itarp-business-application/components/image", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object/computed", "@ember/object"], function (_exports, _component, _tracking, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="component-wrapper">
    <div class={{concat "image-group shape--" this.border " align--" this.align}}>
      <img
        src={{or
          this.attributes.source
          "assets/images/cards/private_profile.svg"
        }}
        alt={{this.attributes.alt}}
        width="{{this.width}}"
        height="{{this.height}}"
        class="image-group__item {{this.fitClass}}"
      />
    </div>
  </div>
  */
  {
    "id": "IoMpjiWK",
    "block": "[[[10,0],[14,0,\"component-wrapper\"],[12],[1,\"\\n  \"],[10,0],[15,0,[28,[37,0],[\"image-group shape--\",[30,0,[\"border\"]],\" align--\",[30,0,[\"align\"]]],null]],[12],[1,\"\\n    \"],[10,\"img\"],[15,\"src\",[28,[37,1],[[30,0,[\"attributes\",\"source\"]],\"assets/images/cards/private_profile.svg\"],null]],[15,\"alt\",[30,0,[\"attributes\",\"alt\"]]],[15,\"width\",[29,[[30,0,[\"width\"]]]]],[15,\"height\",[29,[[30,0,[\"height\"]]]]],[15,0,[29,[\"image-group__item \",[30,0,[\"fitClass\"]]]]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"concat\",\"or\"]]",
    "moduleName": "itarp-business-application/components/image.hbs",
    "isStrictMode": false
  });

  let ImageComponent = (_dec = (0, _computed.alias)('args.config.attributes'), _dec2 = (0, _object.computed)('attributes.width'), _dec3 = (0, _object.computed)('attributes.height'), _dec4 = (0, _object.computed)('attributes.align'), _dec5 = (0, _object.computed)('attributes.border'), _dec6 = (0, _object.computed)('attributes.size'), (_class = class ImageComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "classList", _descriptor, this);

      _initializerDefineProperty(this, "attributes", _descriptor2, this);
    }

    get width() {
      return this.attributes.width || '';
    }

    get height() {
      return this.attributes.height || '';
    }

    get align() {
      return this.attributes.align || '';
    }

    get border() {
      return this.attributes.border || '';
    }

    get fitClass() {
      return this.attributes.size || '';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "classList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "attributes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "width", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "width"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "height", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "height"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "align", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "align"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "border", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "border"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fitClass", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "fitClass"), _class.prototype)), _class));
  _exports.default = ImageComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ImageComponent);
});