define("itarp-template-designer/mirage/endpoints/template-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(context) {
    context.get('template-settings', function (schema, request) {
      const tenantId = request.requestHeaders.TenantID;
      return schema.templateSettings.where({
        tenantId
      });
    });
    context.get('template-settings/:id');
    context.patch('template-settings/:id');
  }
});