define("itarp-components/components/ui/filters/block/header", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='filter-block__header'>
    <h5 class='filter-block__header__title'>
      <a
        class='filter-block__header__link {{if @isCollapsed 'is-collapsed'}}'
        {{on 'click' @toggle}}
      >{{yield}}</a>
    </h5>
  </div>
  */
  {
    "id": "arH6Tjx/",
    "block": "[[[10,0],[14,0,\"filter-block__header\"],[12],[1,\"\\n  \"],[10,\"h5\"],[14,0,\"filter-block__header__title\"],[12],[1,\"\\n    \"],[11,3],[16,0,[29,[\"filter-block__header__link \",[52,[30,1],\"is-collapsed\"]]]],[4,[38,1],[\"click\",[30,2]],null],[12],[18,3,null],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@isCollapsed\",\"@toggle\",\"&default\"],false,[\"if\",\"on\",\"yield\"]]",
    "moduleName": "itarp-components/components/ui/filters/block/header.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});