define("itarp-opportunity-designer/controllers/edit-opportunity", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@ember/template", "@ember/utils", "@glimmer/tracking", "itarp-opportunity-designer/utils/constants/opportunity"], function (_exports, _controller, _object, _service, _template, _utils, _tracking, _opportunity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EditOpportunityController = (_dec = (0, _service.inject)('opportunity/wizard'), _dec2 = (0, _service.inject)('appRouter'), _dec3 = (0, _service.inject)('opportunity/authorization'), _dec4 = (0, _service.inject)('workflows'), _dec5 = (0, _object.computed)('model.errors.[]'), (_class = class EditOpportunityController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "showPreview", _descriptor, this);

      _initializerDefineProperty(this, "wizard", _descriptor2, this);

      _initializerDefineProperty(this, "appRouter", _descriptor3, this);

      _initializerDefineProperty(this, "authorization", _descriptor4, this);

      _initializerDefineProperty(this, "workflowsService", _descriptor5, this);

      _initializerDefineProperty(this, "toast", _descriptor6, this);
    }

    get permissions() {
      return this.authorization.permissions;
    }

    get permissionIdentifier() {
      return this.authorization.permissionIdentifier;
    }

    get isCompleteOpportunity() {
      return this.model.isJobDetailsComplete && this.model.isWorkArrangementComplete;
    }

    get progressPercentage() {
      if (!this.wizard.currentStep) return 0;
      const step = this.wizard.currentStep;
      const totalSteps = this.wizard.totalSteps;
      const stepNumber = this.wizard.currentStepNumber;
      const hasFields = step && step.fields.length > 0;

      if (hasFields) {
        const declaredFields = step.fields.length;
        const fieldsWithValues = this.getNumberOfFieldsWithValues();
        const fieldsPercentage = fieldsWithValues / declaredFields;
        const stepProgression = (stepNumber - 1 + fieldsPercentage) / totalSteps;
        return stepProgression * 100;
      } else {
        const stepProgression = stepNumber / totalSteps;
        return stepProgression * 100;
      }
    }

    get progressWidthStyle() {
      return (0, _template.htmlSafe)(`width: ${this.progressPercentage}%`);
    }

    get sortedErrors() {
      const attrOrder = {
        jobTitle: 0,
        description: 1,
        skills: 2,
        tools: 3,
        jobType: 4,
        timeCommitment: 5,
        startDate: 6,
        remunerations: 7,
        maxRate: 8,
        idealCandidateLocation: 9,
        travelRequirement: 10
      };
      return this.model.errors.toArray().sort((a, b) => {
        if (attrOrder[a.attribute] < attrOrder[b.attribute]) {
          return -1;
        } else if (attrOrder[a.attribute] > attrOrder[b.attribute]) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    getNumberOfFieldsWithValues() {
      const attributes = this.model.constructor.attributes;
      const fields = this.model.constructor.fields;
      const currentStep = this.wizard.currentStep;
      let count = 0;
      currentStep.fields.forEach(field => {
        const attribute = attributes.get(field);
        const association = fields.get(field);
        let value = null;

        if (attribute) {
          value = this.model.get(field);
          const isBoolean = attribute.type === 'boolean';

          if (isBoolean && typeof value === 'boolean' || (0, _utils.isPresent)(value)) {
            count += 1;
          }
        } else if (association === 'belongsTo') {
          value = this.model.belongsTo(field);

          if ((0, _utils.isPresent)(value)) {
            count += 1;
          }
        } else if (association === 'hasMany') {
          value = this.model.hasMany(field);

          if (value.length > 0) {
            count += 1;
          }
        }
      });
      return count;
    }

    async save() {
      const {
        validate,
        name
      } = this.wizard.currentStep;
      this.model.page = validate ? `opportunities/${name}` : null;
      this.model.status = this.isCompleteOpportunity && this.model.workflowApprovalStageId ? _opportunity.IN_APPROVAL_WORKFLOW_STATUS : _opportunity.DRAFT_STATUS;
      if (this.beforeSave) await this.beforeSave();
      this.model.save().then(async () => {
        if (this.afterSave) await this.afterSave();
        return this.transitionToNext();
      }, errors => {
        console.log(errors);
      });
    }

    transitionToNext() {
      if (this.wizard.isLastStep) {
        if (this.wizard.currentStep.name !== 'workflows') {
          this.addDefaultWorkflows().then(() => {
            this.notifyOpportunityApproval();
          });
        } else {
          this.notifyOpportunityApproval();
        }

        return this.appRouter.transitionTo(`/opportunities/${this.model.id}`);
      } else {
        return this.appRouter.transitionTo(this.wizard.nextRoute);
      }
    }

    clearErrors() {
      this.model.errors.clear();
    }

    togglePreview() {
      this.showPreview = !this.showPreview;
    }

    async addDefaultWorkflows() {
      if (this.model.workflowApprovalId && this.model.workflowId) return;
      const defaultHiringWorkflow = await this.workflowsService.defaultWorkflowFor('opportunity_recruiting');
      const defaultApprovalWorkflow = await this.workflowsService.defaultWorkflowFor('opportunity_approval');
      const stageGroup = defaultApprovalWorkflow.stageGroups.findBy('stageGroupType', 'created');
      const stage = stageGroup.stages.findBy('stageType', 'pending_approval');

      if (!this.model.workflowApprovalId) {
        this.model.workflowApprovalId = defaultApprovalWorkflow.id;
        this.model.workflowApprovalStageId = stage === null || stage === void 0 ? void 0 : stage.id;
      }

      if (!this.model.workflowId) {
        this.model.workflowId = defaultHiringWorkflow.id;
      }

      this.model.status = 'in_approval_workflow';
      this.model.save().then(() => {}, errors => {
        console.log(errors);
      });
    }

    findApprovalStage(workflow) {
      if (!workflow) return;
      const groups = workflow.stageGroups;
      const stageType = 'pending_approval';
      const group = groups.findBy('stageGroupType', 'created');

      const findByStageType = sg => sg.stages.findBy('stageType', stageType);

      if (group) {
        const stage = findByStageType(group);
        if (stage) return stage;
      } else {
        return groups.reduce((stageFound, group) => {
          if (stageFound) return stageFound;
          const stage = findByStageType(group);
          if (stage) return stage;
          return stageFound;
        }, null);
      }
    }

    async notifyOpportunityApproval() {
      const opportunityApprovalId = this.model.workflowApprovalId;
      const opportunityApprovalStageId = this.model.workflowApprovalStageId;
      const workflow = await this.workflowsService.businessWorkflow(opportunityApprovalId);
      const stage = this.findApprovalStage(workflow);

      if (stage && stage.stageType === 'pending_approval' && stage.id === opportunityApprovalStageId) {
        this.toast.show('Thank you for submitting your job opportunity. The job will appear on the marketplace as soon as it is approved by our team.');
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showPreview", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "wizard", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "appRouter", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "authorization", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "workflowsService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "toast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "sortedErrors", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "sortedErrors"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getNumberOfFieldsWithValues", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getNumberOfFieldsWithValues"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionToNext", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToNext"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearErrors", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearErrors"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "togglePreview", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "togglePreview"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addDefaultWorkflows", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addDefaultWorkflows"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "findApprovalStage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "findApprovalStage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "notifyOpportunityApproval", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "notifyOpportunityApproval"), _class.prototype)), _class));
  _exports.default = EditOpportunityController;
});