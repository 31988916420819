define("itarp-opportunity-designer/controllers/shared-rosters", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/service"], function (_exports, _controller, _object, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SharedRostersController = (_class = class SharedRostersController extends _controller.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['page']);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "page", _descriptor2, this);

      _initializerDefineProperty(this, "adTokenService", _descriptor3, this);

      _defineProperty(this, "ownerId", this.adTokenService.tokenInformation.sub);

      _initializerDefineProperty(this, "rosterId", _descriptor4, this);

      _initializerDefineProperty(this, "isExpanded", _descriptor5, this);

      _initializerDefineProperty(this, "expandingItem", _descriptor6, this);

      _initializerDefineProperty(this, "consultants", _descriptor7, this);

      _initializerDefineProperty(this, "showRosterModal", _descriptor8, this);

      _initializerDefineProperty(this, "selectedRoster", _descriptor9, this);

      _initializerDefineProperty(this, "clients", _descriptor10, this);

      _initializerDefineProperty(this, "usersPageMeta", _descriptor11, this);

      _initializerDefineProperty(this, "expert", _descriptor12, this);

      _initializerDefineProperty(this, "tab", _descriptor13, this);
    }

    get hasMultiplePages() {
      var _this$usersPageMeta, _this$usersPageMeta2;

      return ((_this$usersPageMeta = this.usersPageMeta) === null || _this$usersPageMeta === void 0 ? void 0 : _this$usersPageMeta.total) > ((_this$usersPageMeta2 = this.usersPageMeta) === null || _this$usersPageMeta2 === void 0 ? void 0 : _this$usersPageMeta2.per_page);
    }

    toggleRosterAccordion(roster, index) {
      if (this.usersPageMeta !== 1) (0, _object.set)(this.usersPageMeta, 'page', 1);

      if (this.expandingItem == index) {
        this.isExpanded = !this.isExpanded;
      } else {
        this.isExpanded = true; // if (roster.rosterUserIds.length) this.loadConsultants(roster.id);
      }

      this.rosterId = roster.id;
      this.expandingItem = index;
    }

    async loadConsultants(rosterId, page) {
      let results = await this.store.query('user', {
        rosterId: rosterId,
        page: page,
        member_only: true
      }).then(users => {
        this.usersPageMeta = users.meta;
        return users;
      }, response => {
        console.log(response);
      });
      results.content.splice(results.content.findIndex(i => i.id === this.ownerId), 1);
      this.consultants = results;
    }

    createRoster(title) {
      return this.selectedRoster.save().then(roster => {
        if (this.sortedRosters && this.sortedRosters.length >= 5) this.sortedRosters.pop();
        if (this.sortedRosters) this.sortedRosters.unshiftObject(roster);
        this.toggleRosterModal();
        return roster;
      }, response => {
        return response;
      });
    }

    saveRoster() {
      if (this.selectedRoster.isNew) return this.createRoster();
      return this.selectedRoster.save().then(roster => {
        this.toggleRosterModal();
        return roster;
      }, response => {
        return response;
      });
    }

    newRoster() {
      this.selectedRoster = this.store.createRecord('roster', {
        ownerId: this.ownerId
      });
      this.toggleRosterModal();
    }

    toggleRosterModal() {
      this.showRosterModal = !this.showRosterModal;

      if (!this.showRosterModal && this.selectedRoster.hasDirtyAttributes) {
        if (this.selectedRoster.isNew) return this.selectedRoster.unloadRecord();
        this.selectedRoster.rollbackAttributes();
      }
    }

    deleteRoster(roster) {
      roster.destroyRecord();
      this.sharedRosters.removeObject(roster);
      this.appRouter.refresh;
    }

    async loadClients() {
      this.clients = this.store.query('user', {
        per_page: 'infinity',
        filter: {
          user_type: 'tod_client'
        }
      });
    }

    removeClient(roster, index) {
      roster.userIds.splice(index - 1, 1);
      roster.save();
    }

    showExpertPreview(expert) {
      this.store.findRecord('user', expert._id).then(user => {
        this.expert = user;
      });
    }

    hideExpertPreview() {
      this.expert = null;
      this.tab = null;
    }

    get isShowingExpertPreview() {
      return this.expert !== null;
    }

    get expertPreviewConfig() {
      return {
        expert: this.expert,
        isOpen: this.isShowingExpertPreview,
        close: this.hideExpertPreview,
        activeTab: this.tab,
        selectTab: val => this.tab = val
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "page", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "adTokenService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "rosterId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isExpanded", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "expandingItem", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "consultants", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showRosterModal", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "selectedRoster", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "clients", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "usersPageMeta", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        page: 1,
        per_page: 15
      };
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "expert", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "tab", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleRosterAccordion", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleRosterAccordion"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadConsultants", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadConsultants"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createRoster", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "createRoster"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveRoster", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveRoster"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newRoster", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "newRoster"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleRosterModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleRosterModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteRoster", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteRoster"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadClients", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadClients"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeClient", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeClient"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showExpertPreview", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showExpertPreview"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hideExpertPreview", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "hideExpertPreview"), _class.prototype)), _class);
  _exports.default = SharedRostersController;
});