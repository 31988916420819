define("itarp-components/components/ui/input/tag", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PowerSelectMultiple
    @selected={{this.tags}}
    @placeholder={{@placeholder}}
    @onChange={{this.setTags}}
    @buildSelection={{this.buildSelection}}
    @searchEnabled={{true}}
    @onOpen={{this.onOpen}}
    @onKeydown={{this.onKeydown}}
    @searchField='label'
    ...attributes
    as |option|
  >
    {{option.label}}
  </PowerSelectMultiple>
  */
  {
    "id": "2lTG1760",
    "block": "[[[8,[39,0],[[17,1]],[[\"@selected\",\"@placeholder\",\"@onChange\",\"@buildSelection\",\"@searchEnabled\",\"@onOpen\",\"@onKeydown\",\"@searchField\"],[[30,0,[\"tags\"]],[30,2],[30,0,[\"setTags\"]],[30,0,[\"buildSelection\"]],true,[30,0,[\"onOpen\"]],[30,0,[\"onKeydown\"]],\"label\"]],[[\"default\"],[[[[1,\"\\n  \"],[1,[30,3,[\"label\"]]],[1,\"\\n\"]],[3]]]]]],[\"&attrs\",\"@placeholder\",\"option\"],false,[\"power-select-multiple\"]]",
    "moduleName": "itarp-components/components/ui/input/tag.hbs",
    "isStrictMode": false
  });

  const selectEventCodes = ['Comma', 'Enter'];
  let UiInputTagComponent = (_class = class UiInputTagComponent extends _component2.default {
    get tags() {
      return this.args.value ? this.args.value : [];
    }

    setTags(tag) {
      const tags = [...this.tags];

      if (tag.value) {
        tags.removeObject(tag);
      } else {
        tags.pushObject({
          label: tag,
          value: tag
        });
      }

      this.args.setValue(tags);
      this.args.onChange && this.args.onChange(tags);
    }

    buildSelection(option) {
      return option;
    }

    onOpen() {
      return false; // disable dropdown open
    }

    onKeydown(dropdown, event) {
      let value = event.target.value;
      value = value.trim();
      const isDuplicate = this.tags.isAny('value', value);
      const isSelectEvent = selectEventCodes.includes(event.code);
      if (isDuplicate || isSelectEvent === false || !value) return;
      dropdown.actions.choose(value, event);
      event.stopPropagation();
      event.preventDefault();
      dropdown.actions.search('');
      return false;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "setTags", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setTags"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buildSelection", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "buildSelection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onOpen", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onOpen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeydown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onKeydown"), _class.prototype)), _class);
  _exports.default = UiInputTagComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiInputTagComponent);
});