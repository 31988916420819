define("@love-open-source/ember-slider/templates/components/ember-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Aiq9kiFU",
    "block": "[[[10,0],[14,0,\"slider-path\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"slider-pseudo-path\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"slider-color-filler\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,0],null,[[\"@value\",\"@onStart\"],[[30,0,[\"value\"]],[28,[37,1],[[30,0,[\"actions\",\"handleMoveStart\"]],[30,0]],null]]],null],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"slider-pseudo-path-closed\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"filler-guide\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"slider-color-filler-closed\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"likert-points\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"likertPoints\"]]],null]],null],null,[[[1,\"        \"],[10,0],[15,0,[29,[\"likert-point \",[52,[28,[37,5],[[30,1,[\"left\"]],[30,0,[\"_percentage\"]]],null],\"active\"]]]],[15,5,[29,[\"left: \",[30,1,[\"left\"]],\"%;\"]]],[12],[1,\"\\n\"],[1,\"            \"],[10,0],[14,0,\"likert-label\"],[15,5,[29,[\"min-width: \",[28,[37,6],[[30,0,[\"likertDistance\"]],3],null],\"px;\"]]],[12],[1,[30,1,[\"label\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[13]],[\"point\"],false,[\"slider-handle\",\"fn\",\"each\",\"-track-array\",\"if\",\"lte\",\"mult\"]]",
    "moduleName": "@love-open-source/ember-slider/templates/components/ember-slider.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});