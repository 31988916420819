define("ember-file-upload/computed/sum-by", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(collectionKey, itemKey) {
    return (0, _object.computed)(`${collectionKey}.@each.${itemKey}`, function () {
      let collection = (0, _object.get)(this, collectionKey);
      return collection.reduce(function (sum, item) {
        return sum + (0, _object.get)(item, itemKey);
      }, 0);
    });
  }
});