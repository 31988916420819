define("ember-bootstrap/mixins/component-child", ["exports", "@ember/object/mixin", "@ember/object", "ember-bootstrap/mixins/component-parent"], function (_exports, _mixin, _object, _componentParent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Mixin for components that act as a child component in a parent-child relationship of components
   *
   * @class ComponentChild
   * @namespace Mixins
   * @private
   */
  // eslint-disable-next-line ember/no-new-mixins
  var _default = _mixin.default.create({
    /**
     * The parent component
     *
     * @property _parent
     * @private
     */
    _parent: (0, _object.computed)(function () {
      return this.nearestOfType(_componentParent.default);
    }),

    /**
     * flag to check if component has already been registered
     * @property _didRegister
     * @type boolean
     * @private
     */
    _didRegister: false,

    /**
     * Register ourself as a child at the parent component
     * We use the `willRender` event here to also support the fastboot environment, where there is no `didInsertElement`
     *
     * @method _registerWithParent
     * @private
     */
    _registerWithParent() {
      if (!this._didRegister) {
        let parent = this._parent;

        if (parent) {
          parent.registerChild(this);
          this._didRegister = true;
        }
      }
    },

    /**
     * Unregister from the parent component
     *
     * @method _unregisterFromParent
     * @private
     */
    _unregisterFromParent() {
      let parent = this._parent;

      if (this._didRegister && parent) {
        parent.removeChild(this);
        this._didRegister = false;
      }
    },

    didReceiveAttrs() {
      this._super(...arguments);

      this._registerWithParent();
    },

    willRender() {
      this._super(...arguments);

      this._registerWithParent();
    },

    willDestroyElement() {
      this._super(...arguments);

      this._unregisterFromParent();
    }

  });

  _exports.default = _default;
});