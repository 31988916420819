define("itarp-components/components/ui/input/editor-wrapper", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='textarea-group__wrapper' ...attributes>
    {{yield (component 'tinymce-editor' required=@required id=@id)}}
  </div>
  
  */
  {
    "id": "ISfyO9m6",
    "block": "[[[11,0],[24,0,\"textarea-group__wrapper\"],[17,1],[12],[1,\"\\n  \"],[18,4,[[50,\"tinymce-editor\",0,null,[[\"required\",\"id\"],[[30,2],[30,3]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@required\",\"@id\",\"&default\"],false,[\"yield\",\"component\"]]",
    "moduleName": "itarp-components/components/ui/input/editor-wrapper.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});