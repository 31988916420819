define("itarp-opportunity-designer/utils/string/humanize", ["exports", "@ember/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = humanize;

  function humanize(value) {
    if (typeof value !== 'string') return value;
    return value.split('_').map(v => (0, _string.capitalize)(v)).join(' ');
  }
});