define("itarp-business-application/util/parser/page/pages/base", ["exports", "itarp-business-application/util/parser/page/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class BasePage {
    constructor(data) {
      _defineProperty(this, "children", []);

      _defineProperty(this, "store", null);

      _defineProperty(this, "title", null);

      _defineProperty(this, "template", null);

      this.title = (0, _helpers.title)(data);
      this.template = (0, _helpers.template)(data, 'dynamic-route');
    }

  }

  _exports.default = BasePage;
});