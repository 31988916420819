define("itarp-opportunity-designer/templates/opportunity/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "1vo5IYwS",
    "block": "[[[10,0],[14,0,\"multistep-form pad-l--1-5 pad-r--1-5\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col--12-6\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"form-options in-boxes\"],[12],[1,\"\\n        \"],[10,\"label\"],[14,0,\"form-options__label\"],[12],[1,\"\\n          Please select the kind of opportunity you are posting\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"form-options__elements\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"radio-group\"],[12],[1,\"\\n            \"],[11,\"input\"],[24,0,\"radio-group__field\"],[24,3,\"opportunityType\"],[16,\"checked\",[28,[37,0],[[30,0,[\"project\",\"isProject\"]],false],null]],[24,1,\"is_job\"],[24,4,\"radio\"],[4,[38,1],[\"click\",[28,[37,2],[[30,0,[\"setProjectType\"]],false],null]],null],[12],[13],[1,\"\\n            \"],[10,\"label\"],[14,0,\"radio-group__label\"],[14,\"for\",\"is_job\"],[12],[1,\"Job\"],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"radio-group\"],[12],[1,\"\\n            \"],[11,\"input\"],[24,0,\"radio-group__field\"],[24,3,\"opportunityType\"],[16,\"checked\",[28,[37,0],[[30,0,[\"project\",\"isProject\"]],true],null]],[24,1,\"is_project\"],[24,4,\"radio\"],[4,[38,1],[\"click\",[28,[37,2],[[30,0,[\"setProjectType\"]],true],null]],null],[12],[13],[1,\"\\n            \"],[10,\"label\"],[14,0,\"radio-group__label\"],[14,\"for\",\"is_project\"],[12],[1,\"Project\"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"eq\",\"on\",\"fn\"]]",
    "moduleName": "itarp-opportunity-designer/templates/opportunity/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});