define("itarp-workflow-designer/controllers/workflows", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/object", "@ember/service"], function (_exports, _controller, _tracking, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let WorkflowsController = (_class = class WorkflowsController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "appRouter", _descriptor2, this);

      _initializerDefineProperty(this, "toast", _descriptor3, this);

      _initializerDefineProperty(this, "deleteModalVisible", _descriptor4, this);

      _initializerDefineProperty(this, "workflow", _descriptor5, this);

      _initializerDefineProperty(this, "expandedItem", _descriptor6, this);
    }

    toggleDeleteModal(workflow) {
      this.deleteModalVisible = !this.deleteModalVisible;

      if (workflow.id) {
        this.workflow = workflow;
      }
    }

    deleteWorkflow() {
      const adapter = this.store.adapterFor('workflow');
      const headers = adapter.get('headers');
      const namespace = adapter.get('namespace');
      const endpoint = `${namespace}/workflows/${this.workflow.id}`;
      this.deleteModalVisible = false;
      fetch(endpoint, {
        method: 'DELETE',
        headers
      }).then(response => {
        if (response.status === 204) {
          this.store.unloadRecord(this.workflow);
          return null;
        } else {
          return response.json();
        }
      }).then(result => {
        if (result) {
          this.workflow.versionStatus = 'pending_delete';
          this.toast.show(`The deletion process of the workflow, ${this.workflow.name}, has started. You will be notified once it is complete.`, {
            autoHide: false,
            size: 'lg',
            heading: 'Deletion in Progress'
          });
        } else {
          this.toast.show('Workflow deleted successfully.');
        }

        this.deleteModalVisible = false;
        this.workflow = null;
      });
    }

    editWorkflow(workflow, event) {
      event.preventDefault();
      event.stopPropagation();
      const editRoute = 'itarp-workflow-designer.workflow.edit-stages';
      const editableStatus = ['inactive', 'rejected'];

      if (editableStatus.includes(workflow.versionStatus)) {
        return this.appRouter.transitionTo(editRoute, workflow.id);
      }

      const adapter = this.store.adapterFor('workflow');
      const id = workflow.id;
      const endpoint = adapter.urlForUnpublishedRevision(id);
      const method = 'PATCH';
      const headers = adapter.headers;
      const body = JSON.stringify(workflow.serialize());
      return fetch(endpoint, {
        method,
        headers,
        body
      }).then(response => response.json()).then(result => {
        this.appRouter.transitionTo(editRoute, result.data.id);
      });
    }

    get customWorkflows() {
      return this.model.filterBy('type', 'custom');
    }

    get opportunityWorkflows() {
      return this.model.filter(opportunity => {
        return opportunity.type === 'opportunity_approval' || opportunity.type === 'opportunity_recruiting';
      });
    }

    get talentCloudWorkflows() {
      return this.model.filter(talentcloud => {
        return talentcloud.type === 'talentcloud_approval' || talentcloud.type === 'talentcloud_recruiting';
      });
    }

    setExpandedItem(item) {
      if (this.expandedItem === item) {
        this.expandedItem = null;
        return;
      }

      this.expandedItem = item;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "appRouter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "toast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "deleteModalVisible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "workflow", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "expandedItem", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleDeleteModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleDeleteModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteWorkflow", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteWorkflow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editWorkflow", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "editWorkflow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setExpandedItem", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setExpandedItem"), _class.prototype)), _class);
  _exports.default = WorkflowsController;
});