define("ember-bootstrap/components/bs-form/group", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-bootstrap/config", "@ember/utils", "ember-bootstrap/utils/decorators/arg"], function (_exports, _component, _templateFactory, _component2, _config, _utils, _arg) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="{{if (macroCondition (macroGetOwnConfig "isNotBS5")) "form-group"}} {{if (macroCondition (macroGetOwnConfig "isBS3")) this.validationClass}} {{if (macroCondition (macroGetOwnConfig "isBS3")) (if this.hasFeedback "has-feedback")}} {{if (macroCondition (macroGetOwnConfig "isBS3")) (bs-size-class "form-group" @size)}} {{if (macroCondition (macroGetOwnConfig "isNotBS3")) (if (bs-eq @formLayout "horizontal") "row")}}"
    ...attributes
  >
    {{yield}}
    {{#if this.hasFeedback}}
      <span class="form-control-feedback {{this.iconName}}" aria-hidden="true"></span>
    {{/if}}
  </div>
  */
  {
    "id": "5LpPN9wi",
    "block": "[[[11,0],[16,0,[29,[\"form-group\",\" \",[27],\" \",[27],\" \",[27],\" \",[52,[28,[37,1],[[30,1],\"horizontal\"],null],\"row\"]]]],[17,2],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[41,[30,0,[\"hasFeedback\"]],[[[1,\"    \"],[10,1],[15,0,[29,[\"form-control-feedback \",[30,0,[\"iconName\"]]]]],[14,\"aria-hidden\",\"true\"],[12],[13],[1,\"\\n\"]],[]],null],[13]],[\"@formLayout\",\"&attrs\",\"&default\"],false,[\"if\",\"bs-eq\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-form/group.hbs",
    "isStrictMode": false
  });

  /**
    This component renders a `<div class="form-group">` element, with support for validation states and feedback icons (only for BS3).
    You can use it as a block level component. The following shows Bootstrap 3 usage for the internal markup.
  
    ```hbs
    <BsForm as |form|>
      <form.group @validation={{this.firstNameValidation}}>
        <label class="control-label">First name</label>
        <input value={{this.firstname}} class="form-control" oninput={{action (mut this.firstname) value="target.value"}} type="text">
      </form.group>
    </bs-form>
    ```
  
    If the `validation` property is set to some state (usually Bootstrap's predefined states "success",
    "warning" or "error"), the appropriate styles will be added, together with a feedback icon.
    See http://getbootstrap.com/css/#forms-control-validation
  
    @class FormGroup
    @namespace Components
    @extends Ember.Component
    @public
  */
  let FormGroup = (_class = class FormGroup extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "validation", _descriptor, this);

      _initializerDefineProperty(this, "useIcons", _descriptor2, this);

      _initializerDefineProperty(this, "successIcon", _descriptor3, this);

      _initializerDefineProperty(this, "errorIcon", _descriptor4, this);

      _initializerDefineProperty(this, "warningIcon", _descriptor5, this);

      _initializerDefineProperty(this, "infoIcon", _descriptor6, this);
    }

    /**
     * @property classTypePrefix
     * @type String
     * @default 'form-group' (BS3) or 'form-control' (BS4)
     * @private
     */

    /**
     * Computed property which is true if the form group is in a validation state
     *
     * @property hasValidation
     * @type boolean
     * @private
     * @readonly
     */
    get hasValidation() {
      return (0, _utils.isPresent)(this.validation);
    }
    /**
     * [BS3 only] Set to a validation state to render the form-group with a validation style.
     * See http://getbootstrap.com/css/#forms-control-validation
     *
     * The default states of "success", "warning" and "error" are supported by Bootstrap out-of-the-box.
     * But you can use custom states as well. This will set a has-<state> class, and (if `useIcons`is true)
     * a feedback whose class is taken from the <state>Icon property
     *
     * @property validation
     * @type string
     * @public
     */


    /**
     * [BS3 only] Computed property which is true if the form group is showing a validation icon
     *
     * @property hasFeedback
     * @type boolean
     * @private
     * @readonly
     */
    get hasFeedback() {
      return this.hasValidation && this.useIcons && this.hasIconForValidationState;
    }
    /**
     * [BS3 only] The icon classes to be used for a feedback icon in a "success" validation state.
     * Defaults to the usual glyphicon classes. This is ignored, and no feedback icon is
     * rendered if `useIcons` is false.
     *
     * You can change this globally by setting the `formValidationSuccessIcon` property of
     * the ember-bootstrap configuration in your config/environment.js file. If your are
     * using FontAwesome for example:
     *
     * ```js
     * ENV['ember-bootstrap'] = {
     *   formValidationSuccessIcon: 'fas fa-check'
     * }
     * ```
     *
     * @property successIcon
     * @type string
     * @default 'glyphicon glyphicon-ok'
     * @public
     */


    /**
     * [BS3 only]
     *
     * @property iconName
     * @type string
     * @readonly
     * @private
     */
    get iconName() {
      let validation = this.validation || 'success';
      return this[`${validation}Icon`];
    }
    /**
     * [BS3 only]
     *
     * @property hasIconForValidationState
     * @type boolean
     * @readonly
     * @private
     */


    get hasIconForValidationState() {
      return (0, _utils.isPresent)(this.iconName);
    }
    /**
     * [BS3 only]
     *
     * @property validationClass
     * @type string
     * @readonly
     * @private
     */


    get validationClass() {
      let validation = this.validation;
      return !(0, _utils.isBlank)(validation) ? `has-${validation}` : undefined;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "validation", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "useIcons", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "successIcon", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _config.default.formValidationSuccessIcon;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "errorIcon", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _config.default.formValidationErrorIcon;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "warningIcon", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _config.default.formValidationWarningIcon;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "infoIcon", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _config.default.formValidationInfoIcon;
    }
  })), _class);
  _exports.default = FormGroup;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormGroup);
});