define("itarp-template-designer/services/placeholders", ["exports", "@ember/service", "@glimmer/tracking", "@ember/object", "itarp-shared-assets-addon/helpers/color-class"], function (_exports, _service, _tracking, _object, _colorClass) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PlaceholdersService = (_class = class PlaceholdersService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "activeEditor", _descriptor, this);

      _initializerDefineProperty(this, "selection", _descriptor2, this);

      _initializerDefineProperty(this, "placeholders", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _defineProperty(this, "factories", {
        link: this.createLink,
        default: this.createSpan,
        collection: this.createLoop
      });
    }

    get tokens() {
      if (this.placeholders.length) return this.placeholders;
      this.loadPlaceholders();
      return this.placeholders;
    }

    loadPlaceholders() {
      this.store.findAll('token', {
        include: 'fields'
      }).then(data => {
        this.placeholders = data;
      });
    }

    setActiveEditor(editor) {
      this.activeEditor = editor;
      this.selection = [];
    }

    select(field) {
      if (this.selection.includes(field)) {
        this.selection.removeObject(field);
      } else {
        this.selection.pushObject(field);
      }
    }

    insertPlaceholders(generateHtml, onSave) {
      if (generateHtml) {
        this.selection.map(field => field.value).forEach(value => {
          onSave(`{{${value}}}`);
        });
      } else {
        for (const field of this.selection) {
          const type = field.tokenType;
          const factory = this.factories[type] || this.factories.default;
          const placeholder = factory(field);
          this.activeEditor.insertContent(placeholder, {
            format: 'html'
          });
        }
      }

      this.selection = [];
      this.activeEditor = null;
    }

    createLink(field) {
      const node = document.createElement('a');
      node.setAttribute('href', `{{${field.value}}}`);
      node.setAttribute('style', 'color:#4ba4da;font-size:16px;font-family:"Lato",Arial,sans-serif;text-decoration:none;');
      node.textContent = field.displayName || field.fieldName;
      return node.outerHTML;
    }

    createSpan(field) {
      const node = document.createElement('span');
      const tokenOrder = this.placeholders.indexOf(field.token);
      const displayName = field.placeholderDisplayName;
      const tagClass = (0, _colorClass.colorClass)('tag-', tokenOrder);
      node.setAttribute('class', `mceNonEditable ${tagClass}`);
      node.setAttribute('data-display-name', displayName);
      node.textContent = `{{${field.value}}}`;
      return node.outerHTML;
    }

    createLoop(field) {
      const node = document.createElement('span');
      const tokenOrder = this.placeholders.indexOf(field.token);
      const displayName = field.placeholderDisplayName;
      let value = field.value.split('.').lastObject;
      let collection = field.value;
      let collectionItem = collection.split('.').lastObject;

      if (collection.split('.').length > 2) {
        collection = collection.substring(0, collection.length - value.length - 1);
        collectionItem = collection.split('.').lastObject;
        value = `${collectionItem}.${value}`;
      }

      const tagClass = (0, _colorClass.colorClass)('tag-', tokenOrder);
      node.setAttribute('class', `mceNonEditable ${tagClass}`);
      node.setAttribute('data-display-name', displayName);
      node.textContent = `{{${value}}}`;
      return `{{#each ${collection} ${collectionItem}}}${node.outerHTML}{{/each}}`;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "activeEditor", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selection", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "placeholders", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadPlaceholders", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadPlaceholders"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setActiveEditor", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setActiveEditor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "insertPlaceholders", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "insertPlaceholders"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createLink", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "createLink"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createSpan", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "createSpan"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createLoop", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "createLoop"), _class.prototype)), _class);
  _exports.default = PlaceholdersService;
});