define("itarp-talentcloud-ui/serializers/applied-talentcloud", ["exports", "@ember-data/serializer/json", "@ember/string"], function (_exports, _json, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class AppliedTalentcloudSerializer extends _json.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", 'application_id');
    }

    keyForAttribute(attr, method) {
      return (0, _string.underscore)(attr);
    }

    normalizeQueryResponse(store, modelClass, payload, ...rest) {
      let data = payload ? payload.talent_cloud_applications : [];
      data = data.map(d => {
        var _d$talent_cloud$;

        return {
          application_id: d._id,
          ...d.talent_cloud[0],
          talentcloud_id: (_d$talent_cloud$ = d.talent_cloud[0]) === null || _d$talent_cloud$ === void 0 ? void 0 : _d$talent_cloud$._id
        };
      });
      data.meta = payload.meta || {};
      return super.normalizeQueryResponse(store, modelClass, data, ...rest);
    }

    normalize(modelClass, data) {
      var _result$data;

      data.type = 'talent-cloud';
      const result = super.normalize(modelClass, data);
      if (result !== null && result !== void 0 && (_result$data = result.data) !== null && _result$data !== void 0 && _result$data.attributes) this.parseAttributes(result.data.attributes);

      if (result.data.attributes.owner_id) {
        result.data.relationships.owner = {
          data: {
            id: result.data.attributes.owner_id,
            type: 'user'
          },
          links: {
            related: `/api/expert/api/talentcloud/routing/data_service/users/${result.data.attributes.owner_id}`
          }
        };
      }

      return result;
    }

    parseAttributes(attributes) {
      for (const attr in attributes) {
        try {
          const result = JSON.parse(attributes[attr]);
          attributes[attr] = result;
        } catch (e) {}
      }
    }

  }

  _exports.default = AppliedTalentcloudSerializer;
});