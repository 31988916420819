define("itarp-components/components/ui/form", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <form {{on 'submit' this.submit}} ...attributes>
    {{yield
      (hash
        row=(component 'ui/grid/row')
        inputGroup=(component 'ui/input/input-group')
        switchGroup=(component 'ui/input/switch-group')
        selectGroup=(component 'ui/input/select-group')
        editorGroup=(component 'ui/input/editor-group')
      )
    }}
  </form>
  
  */
  {
    "id": "pshM6+Gt",
    "block": "[[[11,\"form\"],[17,1],[4,[38,0],[\"submit\",[30,0,[\"submit\"]]],null],[12],[1,\"\\n  \"],[18,2,[[28,[37,2],null,[[\"row\",\"inputGroup\",\"switchGroup\",\"selectGroup\",\"editorGroup\"],[[50,\"ui/grid/row\",0,null,null],[50,\"ui/input/input-group\",0,null,null],[50,\"ui/input/switch-group\",0,null,null],[50,\"ui/input/select-group\",0,null,null],[50,\"ui/input/editor-group\",0,null,null]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"on\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-components/components/ui/form.hbs",
    "isStrictMode": false
  });

  let UiFormComponent = (_class = class UiFormComponent extends _component2.default {
    submit(event) {
      event.preventDefault();
      if (this.args.onSubmit) this.args.onSubmit();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "submit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype)), _class);
  _exports.default = UiFormComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiFormComponent);
});