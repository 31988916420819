define("itarp-talentcloud-ui/serializers/user-favorite", ["exports", "itarp-talentcloud-ui/serializers/business-application"], function (_exports, _businessApplication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class UserFavoriteSerializer extends _businessApplication.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", '_id');
    }

    serialize(snapshot, options) {
      let json = super.serialize(...arguments);
      const {
        resource_ids,
        resource_type
      } = json['user-favorites'];
      json['ids'] = resource_ids;
      json['resource_type'] = resource_type;
      delete json['user-favorites'];
      return json;
    }

    serializeBelongsTo(snapshot, json, relationship) {
      let key = relationship.key;
      let belongsTo = snapshot.belongsTo(key);
      key = this.keyForRelationship ? this.keyForRelationship(key, 'belongsTo', 'serialize') : key;
      return json[key];
    }

  }

  _exports.default = UserFavoriteSerializer;
});