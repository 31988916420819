define("itarp-opportunity-designer/helpers/resource-number", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function resourceNumber([times]) {
    if (Math.sign(times) === -1 || Math.sign(times) === 0) {
      times = 1;
    }

    if (times <= 10) {
      return [...Array(Number(times)).keys()];
    } else {
      return [...Array(Number(10)).keys()];
    }
  });

  _exports.default = _default;
});