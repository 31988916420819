define("itarp-opportunity-designer/components/loader", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='loading-animation'>
    {{#if this.title}}
      <h5 class='loading-animation__title'>
        {{this.title}}
      </h5>
    {{/if}}
    <div class='loading-animation__svg'>
      <Icons::Loader />
    </div>
  </div>
  
  */
  {
    "id": "9+1YmWSD",
    "block": "[[[10,0],[14,0,\"loading-animation\"],[12],[1,\"\\n\"],[41,[30,0,[\"title\"]],[[[1,\"    \"],[10,\"h5\"],[14,0,\"loading-animation__title\"],[12],[1,\"\\n      \"],[1,[30,0,[\"title\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"loading-animation__svg\"],[12],[1,\"\\n    \"],[8,[39,1],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"icons/loader\"]]",
    "moduleName": "itarp-opportunity-designer/components/loader.hbs",
    "isStrictMode": false
  });

  class LoaderComponent extends _component2.default {
    get title() {
      const {
        title
      } = this.args;
      return title;
    }

  }

  _exports.default = LoaderComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LoaderComponent);
});