define("itarp-template-designer/controllers/settings/edit", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/object"], function (_exports, _controller, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TemplatesSettingsEditController = (_class = class TemplatesSettingsEditController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "textVisible", _descriptor, this);

      _defineProperty(this, "editorOptions", Object.freeze({
        menubar: false,
        statusbar: false,
        plugins: ['link lists paste noneditable code fullscreen'],
        toolbar: ['numlist bullist alignleft aligncenter bold italic underline link | fullscreen'],
        branding: false,
        inline_styles: false,
        height: 300,
        content_css: '/itarp-shared-assets-addon/styles/tinymce.css'
      }));
    }

    saveSettings() {
      this.templateSetting.save().then(() => {
        this.transitionToRoute('settings');
      }).catch(err => {
        console.log(err);
      });
    }

    cancelSettings() {
      this.transitionToRoute('settings');
    }

    toggleTextVisibility() {
      this.textVisible = !this.textVisible;
    }

    get filterEditorConfig() {
      return { ...this.editorOptions,
        toolbar: ['fullscreen']
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "textVisible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "saveSettings", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveSettings"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelSettings", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancelSettings"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleTextVisibility", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleTextVisibility"), _class.prototype)), _class);
  _exports.default = TemplatesSettingsEditController;
});