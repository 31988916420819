define("itarp-template-designer/controllers/templates/categories/edit", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/object", "@ember/service", "itarp-template-designer/config/environment"], function (_exports, _controller, _tracking, _object, _service, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TemplatesCategoriesNewController = (_class = class TemplatesCategoriesNewController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "appRouter", _descriptor, this);

      _initializerDefineProperty(this, "deleteModalVisible", _descriptor2, this);

      _initializerDefineProperty(this, "categoryModalVisible", _descriptor3, this);

      _initializerDefineProperty(this, "error", _descriptor4, this);
    }

    closeCategoryModal() {
      this.category.templates.filterBy('hasDirtyAttributes').forEach(template => template.rollbackAttributes());
      this.appRouter.transitionTo(`${_environment.default.modulePrefix}`);
    }

    saveCategory(e) {
      e.preventDefault();
      this.category.name = this.category.name.trim();
      this.category.templates.filterBy('hasDirtyAttributes').forEach(template => template.save());
      const notUnique = this.store.peekAll('category').filter(category => category.id !== this.category.id).filterBy('hasDirtyAttributes', false).isAny('name', this.category.name);

      if (notUnique) {
        return this.error = 'Name must be unique';
      } else {
        this.error = '';
        this.category.categoryType = this.selectedCategoryType.value;
        this.category.save().then(() => {
          this.appRouter.transitionTo(`${_environment.default.modulePrefix}`);
        }, errors => {
          console.log(errors);
        });
      }
    }

    toggleDeleteCategoryModal() {
      this.categoryModalVisible = !this.categoryModalVisible;
      this.deleteModalVisible = !this.deleteModalVisible;
    }

    deleteCategory() {
      this.category.destroyRecord().then(() => {
        this.appRouter.transitionTo(`${_environment.default.modulePrefix}`);
      });
      this.deleteModalVisible = false;
      this.categoryModalVisible = true;
    }

    toggleIsVisibleTemplate(template) {
      template.isVisible = !template.isVisible;
    }

    get categoryTypeOptions() {
      return [{
        name: 'TalentCloud Templates',
        value: 'talent_cloud'
      }, {
        name: 'Opportunity Templates',
        value: 'opportunity'
      }, {
        name: 'Custom',
        value: 'custom'
      }];
    }

    get selectedCategoryType() {
      const typeOfCategoryType = typeof this.category.categoryType;

      if (typeOfCategoryType === 'object') {
        return this.category.categoryType;
      } else if (typeOfCategoryType === 'string') {
        return this.categoryTypeOptions.findBy('value', this.category.categoryType) ? this.categoryTypeOptions.findBy('value', this.category.categoryType) : {
          name: this.category.categoryType,
          value: this.category.categoryType
        };
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appRouter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "deleteModalVisible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "categoryModalVisible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "error", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "closeCategoryModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeCategoryModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveCategory", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveCategory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleDeleteCategoryModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleDeleteCategoryModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteCategory", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteCategory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleIsVisibleTemplate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleIsVisibleTemplate"), _class.prototype)), _class);
  _exports.default = TemplatesCategoriesNewController;
});