define("itarp-components/components/filters/taxonomy-list/group", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let (get @group @type) as |taxonomies|}}
    {{#if (get taxonomies 'length')}}
      <div class='component-wrapper has-padding' ...attributes>
        <div class='form-options'>
          <div class='form-options__elements'>
            {{#each taxonomies as |taxonomy|}}
              <Filters::TaxonomyList::Options
                @taxonomy={{taxonomy}}
                @type={{@type}}
                @selected={{@selected}}
                @onChange={{@onChange}}
              />
            {{/each}}
          </div>
        </div>
      </div>
    {{/if}}
  {{/let}}
  
  */
  {
    "id": "eoobLboK",
    "block": "[[[44,[[28,[37,1],[[30,1],[30,2]],null]],[[[41,[28,[37,1],[[30,3],\"length\"],null],[[[1,\"    \"],[11,0],[24,0,\"component-wrapper has-padding\"],[17,4],[12],[1,\"\\n      \"],[10,0],[14,0,\"form-options\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"form-options__elements\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,3]],null]],null],null,[[[1,\"            \"],[8,[39,5],null,[[\"@taxonomy\",\"@type\",\"@selected\",\"@onChange\"],[[30,5],[30,2],[30,6],[30,7]]],null],[1,\"\\n\"]],[5]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[3]]]],[\"@group\",\"@type\",\"taxonomies\",\"&attrs\",\"taxonomy\",\"@selected\",\"@onChange\"],false,[\"let\",\"get\",\"if\",\"each\",\"-track-array\",\"filters/taxonomy-list/options\"]]",
    "moduleName": "itarp-components/components/filters/taxonomy-list/group.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});