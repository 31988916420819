define("itarp-opportunity-designer/adapters/publish-setting", ["exports", "itarp-auth-addon/adapters/ad-token-data"], function (_exports, _adTokenData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class PublishSettingAdapter extends _adTokenData.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", '/opportunity/data_service');
    }

    urlForCreateRecord(modelName, snapshot) {
      const record = snapshot.record;
      const id = record.recordId;
      const identifier = record.jobBoardType;
      return `${this.namespace}/${id}/publish/${identifier}`;
    }

  }

  _exports.default = PublishSettingAdapter;
});