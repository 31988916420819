define("itarp-opportunity-designer/serializers/roster", ["exports", "itarp-shared-assets-addon/serializers/data"], function (_exports, _data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RosterSerializer extends _data.default {
    shouldSerializeHasMany(snapshot, key, relationship) {
      return false;
    }

    normalize(modelClass, payload) {
      if (payload.data) {
        return super.normalize(modelClass, payload.data);
      }

      return super.normalize(...arguments);
    }

    serialize() {
      const data = super.serialize(...arguments);

      if ('include_ids' in data.rosters && data.rosters.include_ids.length > 0 || 'exclude_ids' in data.rosters && data.rosters.exclude_ids.length > 0) {
        return data.rosters;
      }

      return data;
    }

  }

  _exports.default = RosterSerializer;
});