define("itarp-components/components/table/candidates/menu-row", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let @table as |body|}}
    <body.row @menu='true' ...attributes as |row|>
      {{yield row}}
    </body.row>
  {{/let}}
  */
  {
    "id": "rkR66N1n",
    "block": "[[[44,[[30,1]],[[[1,\"  \"],[8,[30,2,[\"row\"]],[[17,3]],[[\"@menu\"],[\"true\"]],[[\"default\"],[[[[1,\"\\n    \"],[18,5,[[30,4]]],[1,\"\\n  \"]],[4]]]]],[1,\"\\n\"]],[2]]]],[\"@table\",\"body\",\"&attrs\",\"row\",\"&default\"],false,[\"let\",\"yield\"]]",
    "moduleName": "itarp-components/components/table/candidates/menu-row.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});