define("ember-engines-router-service/initializers/ember-engines-router-service", ["exports", "ember-engines-router-service/-private/engine-ext"], function (_exports, _engineExt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  // Load extensions to Ember
  // TODO: Move to ensure they run prior to instantiating Ember.Application
  function initialize() {}

  var _default = {
    name: 'ember-engines-router-service',
    initialize
  };
  _exports.default = _default;
});