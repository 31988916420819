define("itarp-template-designer/templates/templates/new", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "8JmHf9m0",
    "block": "[[[10,\"header\"],[14,0,\"designer__content__header as-popup\"],[12],[1,\"\\n\"],[41,[30,0,[\"isPreviewing\"]],[[[1,\"    \"],[11,3],[24,6,\"#\"],[24,0,\"designer__content__header__title with-return\"],[4,[38,1],[\"click\",[30,0,[\"togglePreview\"]]],null],[12],[1,\"\\n      \"],[10,1],[14,0,\"designer__content__header__title__return\"],[12],[13],[1,\"\\n      Back to Template Editor\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,2],[[24,0,\"designer__content__header__title with-return\"]],[[\"@route\"],[\"templates\"]],[[\"default\"],[[[[1,\"\\n      \"],[10,1],[14,0,\"designer__content__header__title__return\"],[12],[13],[1,\"\\n      Back to Templates\\n    \"]],[]]]]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isPreviewing\"]],[[[1,\"  \"],[8,[39,3],null,[[\"@route\",\"@subject\",\"@body\",\"@togglePreview\"],[\"templates.new.form\",[30,0,[\"template\",\"emailSubject\"]],[30,0,[\"template\",\"htmlSafeMessage\"]],[30,0,[\"togglePreview\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,4],null,[[\"@template\",\"@categories\",\"@save\",\"@cancel\",\"@openPreview\",\"@workflows\",\"@error\"],[[30,0,[\"template\"]],[30,0,[\"categories\"]],[30,0,[\"saveTemplate\"]],[30,0,[\"toggleCancelModal\"]],[30,0,[\"togglePreview\"]],[30,0,[\"workflows\"]],[30,0,[\"error\"]]]],null],[1,\"\\n\"]],[]]],[1,\"\\n\"],[8,[39,5],null,[[\"@modalVisible\",\"@toggleModal\",\"@cancelModal\",\"@saveModal\"],[[30,0,[\"cancelModalVisible\"]],[30,0,[\"toggleCancelModal\"]],[30,0,[\"cancelSaveModal\"]],[30,0,[\"toggleCancelModal\"]]]],null]],[],false,[\"if\",\"on\",\"link-to\",\"preview\",\"template-form\",\"template-modal\"]]",
    "moduleName": "itarp-template-designer/templates/templates/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});