define("itarp-components/components/filters/taxonomy/industry", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each @industries as |industry|}}
    <div class='checkbox-group'>
      <Input
        @type='checkbox'
        class='checkbox-group__field'
        id={{industry.id}}
        @checked={{any @selected industry.name}}
        {{on 'change' (fn this.selectIndustry industry.name)}}
      />
      <label
        class='checkbox-group__label'
        for={{industry.id}}
      >{{industry.name}}</label>
    </div>
  {{/each}}
  */
  {
    "id": "TEheRONJ",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"  \"],[10,0],[14,0,\"checkbox-group\"],[12],[1,\"\\n    \"],[8,[39,2],[[24,0,\"checkbox-group__field\"],[16,1,[30,2,[\"id\"]]],[4,[38,4],[\"change\",[28,[37,5],[[30,0,[\"selectIndustry\"]],[30,2,[\"name\"]]],null]],null]],[[\"@type\",\"@checked\"],[\"checkbox\",[28,[37,3],[[30,3],[30,2,[\"name\"]]],null]]],null],[1,\"\\n    \"],[10,\"label\"],[14,0,\"checkbox-group__label\"],[15,\"for\",[30,2,[\"id\"]]],[12],[1,[30,2,[\"name\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[2]],null]],[\"@industries\",\"industry\",\"@selected\"],false,[\"each\",\"-track-array\",\"input\",\"any\",\"on\",\"fn\"]]",
    "moduleName": "itarp-components/components/filters/taxonomy/industry.hbs",
    "isStrictMode": false
  });

  let FiltersTaxonomyIndustryComponent = (_class = class FiltersTaxonomyIndustryComponent extends _component2.default {
    selectIndustry(industry) {
      if (this.args.selected.includes(industry)) {
        this.args.selected.removeObject(industry);
      } else {
        this.args.selected.pushObject(industry);
      }

      this.args.onChange();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "selectIndustry", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectIndustry"), _class.prototype)), _class);
  _exports.default = FiltersTaxonomyIndustryComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FiltersTaxonomyIndustryComponent);
});