define("itarp-components/components/table/candidates/column/application-status", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/runloop", "@ember/service"], function (_exports, _component, _templateFactory, _component2, _object, _tracking, _runloop, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let @row as |row|}}
    <row.cell ...attributes>
      <Ui::Wrapper>
        <div class='card-block--select dropdown'>
          {{#if @changeStagePermitted}}
            <BasicDropdown
              @horizontalPosition='left'
              @onOpen={{this.loadOptions}}
              as |dd|
            >
              <dd.Trigger>
                <span class='card-block--select__label'>
                  {{#if this.selectedOption}}
                    {{this.selectedOption.label}}
                  {{else}}
                    Applied
                  {{/if}}
                </span>
              </dd.Trigger>
              <dd.Content class='dropdown__menu'>
                {{#each this.options as |option|}}
                  <div
                    class='dropdown__menu__link is-dark
                      {{if (eq option.value this.selectedOption.value) 'active'}}
                      {{if option.disabled 'disabled'}}'
                    role='button'
                    {{on 'click' (fn this.updateStage option dd)}}
                  >{{option.label}}</div>
                {{/each}}
              </dd.Content>
            </BasicDropdown>
          {{else}}
            <span class='card-block--select__label'>
              {{#if this.selectedOption}}
                {{this.selectedOption.label}}
              {{else}}
                Applied
              {{/if}}
            </span>
          {{/if}}
        </div>
      </Ui::Wrapper>
    </row.cell>
  {{/let}}
  */
  {
    "id": "sjM/exMS",
    "block": "[[[44,[[30,1]],[[[1,\"  \"],[8,[30,2,[\"cell\"]],[[17,3]],null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"card-block--select dropdown\"],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"          \"],[8,[39,3],null,[[\"@horizontalPosition\",\"@onOpen\"],[\"left\",[30,0,[\"loadOptions\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[30,5,[\"Trigger\"]],null,null,[[\"default\"],[[[[1,\"\\n              \"],[10,1],[14,0,\"card-block--select__label\"],[12],[1,\"\\n\"],[41,[30,0,[\"selectedOption\"]],[[[1,\"                  \"],[1,[30,0,[\"selectedOption\",\"label\"]]],[1,\"\\n\"]],[]],[[[1,\"                  Applied\\n\"]],[]]],[1,\"              \"],[13],[1,\"\\n            \"]],[]]]]],[1,\"\\n            \"],[8,[30,5,[\"Content\"]],[[24,0,\"dropdown__menu\"]],null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"options\"]]],null]],null],null,[[[1,\"                \"],[11,0],[16,0,[29,[\"dropdown__menu__link is-dark\\n                    \",[52,[28,[37,6],[[30,6,[\"value\"]],[30,0,[\"selectedOption\",\"value\"]]],null],\"active\"],\"\\n                    \",[52,[30,6,[\"disabled\"]],\"disabled\"]]]],[24,\"role\",\"button\"],[4,[38,7],[\"click\",[28,[37,8],[[30,0,[\"updateStage\"]],[30,6],[30,5]],null]],null],[12],[1,[30,6,[\"label\"]]],[13],[1,\"\\n\"]],[6]],null],[1,\"            \"]],[]]]]],[1,\"\\n          \"]],[5]]]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,1],[14,0,\"card-block--select__label\"],[12],[1,\"\\n\"],[41,[30,0,[\"selectedOption\"]],[[[1,\"              \"],[1,[30,0,[\"selectedOption\",\"label\"]]],[1,\"\\n\"]],[]],[[[1,\"              Applied\\n\"]],[]]],[1,\"          \"],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]],[\"@row\",\"row\",\"&attrs\",\"@changeStagePermitted\",\"dd\",\"option\"],false,[\"let\",\"ui/wrapper\",\"if\",\"basic-dropdown\",\"each\",\"-track-array\",\"eq\",\"on\",\"fn\"]]",
    "moduleName": "itarp-components/components/table/candidates/column/application-status.hbs",
    "isStrictMode": false
  });

  let TableCandidateColumnApplicationStatusComponent = (_dec = (0, _object.computed)('_options'), _dec2 = (0, _object.computed)('_options', 'args.application.workflowStageId'), (_class = class TableCandidateColumnApplicationStatusComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "optionsLoaded", _descriptor, this);

      _initializerDefineProperty(this, "_options", _descriptor2, this);

      _initializerDefineProperty(this, "stageRules", _descriptor3, this);

      if (this.workflowStageId && this.args.asyncOptions) {
        this.loadOptions();
      }
    }

    get workflowStageId() {
      return this.args.application.workflowStageId;
    }

    get options() {
      var _this$args$options;

      const options = (_this$args$options = this.args.options) !== null && _this$args$options !== void 0 && _this$args$options.length ? this.args.options : this._options;
      return this.stageRules.applyRules(options, this.args.application.workflowStageId);
    }

    get selectedOption() {
      const defaultOption = this.options.findBy('label', 'Applied');
      return this.options.findBy('value', `${this.workflowStageId}`) || defaultOption;
    }

    updateStage(option, dropdown) {
      if (!this.args.changeStagePermitted) return;
      if (option.disabled) return;
      this.args.application.workflowStageId = option.value;

      if (option.clientVisible && this.args.application.clientVisible !== true) {
        this.args.application.clientVisible = true;
      }

      this.args.application.save().then(() => {
        this.args.resetButtonsLoaded();
      });
      dropdown.actions.close();
    }

    loadOptions() {
      if (this.args.asyncOptions & !this.optionsLoaded) {
        (0, _runloop.next)(this, () => {
          this.args.loadOptions().then(options => {
            this.optionsLoaded = true;
            this._options = options;
          });
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "optionsLoaded", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_options", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stageRules", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "options", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "options"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedOption", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "selectedOption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateStage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateStage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadOptions", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadOptions"), _class.prototype)), _class));
  _exports.default = TableCandidateColumnApplicationStatusComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TableCandidateColumnApplicationStatusComponent);
});