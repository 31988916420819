define("itarp-opportunity-designer/controllers/details/overview", ["exports", "@ember/controller", "itarp-components/utils/constants/questions"], function (_exports, _controller, _questions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DetailsOverviewController extends _controller.default {
    get questionTypes() {
      return _questions.TYPES;
    }

    get status() {
      return this.parentController.opportunityStatus;
    }

    get statusClass() {
      return this.parentController.statusClass;
    }

    get prferredWorkingHours() {
      let hours = [];

      if (this.project.officeHours) {
        hours.push('Office Hours');
      }

      if (this.project.outsideOfficeHours) {
        hours.push('Outside Office Hours');
      }

      if (this.project.flexible) {
        hours.push('Flexible');
      }

      return hours.join(', ');
    }

  }

  _exports.default = DetailsOverviewController;
});