define("itarp-opportunity-ui/components/ui/offscreens/base", ["exports", "itarp-opportunity-ui/components/ui/togglable"], function (_exports, _togglable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class="offscreen
      {{this.stateClass}}
      {{this.sizeClass}}
      {{this.positionClass}}
      {{this.additionalClasses}}"
  >
    <div class="offscreen-content" ...attributes>
      {{#if this.isOpen}}
        {{yield
          (hash
            header=(component "ui/offscreens/base/header" toggle=this.toggle)
            body=(component "ui/offscreens/base/body" showSignature=true)
            actions=(hash toggle=this.toggle close=this.close open=this.open)
          )
          to="content"
        }}
      {{/if}}
    </div>
  </div>
  {{yield
    (hash actions=(hash toggle=this.toggle open=this.open close=this.close))
  }}
  */
  {
    "id": "xjJmQiG9",
    "block": "[[[10,0],[15,0,[29,[\"offscreen\\n    \",[30,0,[\"stateClass\"]],\"\\n    \",[30,0,[\"sizeClass\"]],\"\\n    \",[30,0,[\"positionClass\"]],\"\\n    \",[30,0,[\"additionalClasses\"]]]]],[12],[1,\"\\n  \"],[11,0],[24,0,\"offscreen-content\"],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"isOpen\"]],[[[1,\"      \"],[18,2,[[28,[37,2],null,[[\"header\",\"body\",\"actions\"],[[50,\"ui/offscreens/base/header\",0,null,[[\"toggle\"],[[30,0,[\"toggle\"]]]]],[50,\"ui/offscreens/base/body\",0,null,[[\"showSignature\"],[true]]],[28,[37,2],null,[[\"toggle\",\"close\",\"open\"],[[30,0,[\"toggle\"]],[30,0,[\"close\"]],[30,0,[\"open\"]]]]]]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[18,3,[[28,[37,2],null,[[\"actions\"],[[28,[37,2],null,[[\"toggle\",\"open\",\"close\"],[[30,0,[\"toggle\"]],[30,0,[\"open\"]],[30,0,[\"close\"]]]]]]]]]]],[\"&attrs\",\"&content\",\"&default\"],false,[\"if\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-opportunity-ui/components/ui/offscreens/base.hbs",
    "isStrictMode": false
  });

  class UiOffscreensBaseComponent extends _togglable.default {
    get position() {
      return this.args.position || 'right';
    }

    get size() {
      return this.args.size || 'medium';
    }

    get positionClass() {
      const classes = {
        left: 'offscreen--left',
        right: 'offscreen--right',
        top: 'offscreen--top',
        bottom: 'offscreen--bottom'
      };
      return classes[this.position] || '';
    }

    get sizeClass() {
      const classes = {
        small: 'offscreen--sm',
        medium: 'offscreen--md',
        large: 'offscreen--lg'
      };
      return classes[this.size] || '';
    }

    get additionalClasses() {
      return this.args.additionalClasses || '';
    }

    get stateClass() {
      return this.isOpen ? 'is-open' : 'is-closed';
    }

  }

  _exports.default = UiOffscreensBaseComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UiOffscreensBaseComponent);
});