define("itarp-opportunity-designer/components/taxonomy/option", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let (concat @option.name '-' @option.id) as |identifier|}}
    <div class='recommendation-row'>
      <div class='checkbox-group'>
        <Input
          @type='checkbox'
          class='checkbox-group__field'
          id={{identifier}}
          @checked={{this.checked}}
        />
  
        {{#if @allCategories}}
          {{#if @searchTerm}}
            <label class='checkbox-group__label' for={{identifier}}>
              {{@option.parent.name}}
              -
              {{@option.name}}
            </label>
          {{else}}
            <label class='checkbox-group__label' for={{identifier}}>
  
              {{@option.name}}
            </label>
          {{/if}}
        {{else}}
          <label class='checkbox-group__label' for={{identifier}}>
  
            {{@option.name}}
          </label>
  
        {{/if}}
  
      </div>
    </div>
  {{/let}}
  */
  {
    "id": "RkcbPo1N",
    "block": "[[[44,[[28,[37,1],[[30,1,[\"name\"]],\"-\",[30,1,[\"id\"]]],null]],[[[1,\"  \"],[10,0],[14,0,\"recommendation-row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"checkbox-group\"],[12],[1,\"\\n      \"],[8,[39,2],[[24,0,\"checkbox-group__field\"],[16,1,[30,2]]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"checked\"]]]],null],[1,\"\\n\\n\"],[41,[30,3],[[[41,[30,4],[[[1,\"          \"],[10,\"label\"],[14,0,\"checkbox-group__label\"],[15,\"for\",[30,2]],[12],[1,\"\\n            \"],[1,[30,1,[\"parent\",\"name\"]]],[1,\"\\n            -\\n            \"],[1,[30,1,[\"name\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,\"label\"],[14,0,\"checkbox-group__label\"],[15,\"for\",[30,2]],[12],[1,\"\\n\\n            \"],[1,[30,1,[\"name\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]]]],[]],[[[1,\"        \"],[10,\"label\"],[14,0,\"checkbox-group__label\"],[15,\"for\",[30,2]],[12],[1,\"\\n\\n          \"],[1,[30,1,[\"name\"]]],[1,\"\\n        \"],[13],[1,\"\\n\\n\"]],[]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[2]]]],[\"@option\",\"identifier\",\"@allCategories\",\"@searchTerm\"],false,[\"let\",\"concat\",\"input\",\"if\"]]",
    "moduleName": "itarp-opportunity-designer/components/taxonomy/option.hbs",
    "isStrictMode": false
  });

  let TaxonomyOptionComponent = (_class = class TaxonomyOptionComponent extends _component2.default {
    constructor() {
      super(...arguments);
      if (this.args.register) this.args.register(this);
    }

    get checked() {
      return this.args.isSelected;
    }

    set checked(isChecked) {
      if (isChecked) {
        return this.args.select(this.args.option);
      }

      return this.args.deselect(this.args.option);
    }

    update(isChecked) {
      this.checked = isChecked;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "update", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype)), _class);
  _exports.default = TaxonomyOptionComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TaxonomyOptionComponent);
});