define("itarp-shared-assets-addon/components/permission-content", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/array", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _service, _array, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isPermitted}}
    {{yield}}
  {{/if}}
  */
  {
    "id": "l3CKZKF9",
    "block": "[[[41,[30,0,[\"isPermitted\"]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "itarp-shared-assets-addon/components/permission-content.hbs",
    "isStrictMode": false
  });

  let PermissionContentComponent = (_dec = (0, _service.inject)('permission-checker'), (_class = class PermissionContentComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "check", _descriptor, this);

      _initializerDefineProperty(this, "isResolved", _descriptor2, this);
    }

    get isPermitted() {
      const {
        action,
        identifier
      } = this.args;
      if (this.isResolved) return this.result;

      if (action && (0, _array.isArray)(action)) {
        const checks = action.map((act, index) => {
          if ((0, _array.isArray)(identifier)) {
            return this.check.isPermitted(act, identifier[index]);
          }

          return this.check.isPermitted(act, identifier);
        });
        Promise.all(checks).then(results => {
          this.isResolved = true;
          this.result = results.any(res => res === true);
        });
      } else {
        this.check.isPermitted(action, identifier).then(result => {
          this.isResolved = true;
          this.result = result;
        });
      }

      return false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "check", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isResolved", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class));
  _exports.default = PermissionContentComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PermissionContentComponent);
});