define("itarp-workflow-designer/helpers/find-name", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function ([itemId, list]) {
    if (itemId) {
      var _list$findBy;

      const templateName = (_list$findBy = list.findBy('id', itemId)) === null || _list$findBy === void 0 ? void 0 : _list$findBy.name;
      return `: ` + templateName;
    }
  });

  _exports.default = _default;
});