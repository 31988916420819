define("itarp-workflow-designer/mirage/endpoints/workflows", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(context) {
    context.get('workflows', function (schema, request) {
      const tenantId = request.requestHeaders.TenantID;
      return schema.workflows.where({
        tenantId
      });
    });
    context.get('workflows/:id', function (schema, request) {
      request.url = request.url.replace('stage-groups', 'stagegroups');
      const id = request.params.id;
      return schema.workflows.find(id);
    });
    context.post('workflows', function (schema, request) {
      const tenantId = request.requestHeaders.TenantID;
      const attrs = JSON.parse(request.requestBody);
      const workflowTemplates = schema.workflowTemplates.find(attrs.data.attributes.template);
      const stageGroups = workflowTemplates.metadata.stage_groups.map(stageGroup => {
        return schema.stageGroups.create({ ...stageGroup
        });
      });
      const workflow = schema.workflows.create({ ...attrs,
        tenantId,
        stageGroups
      });
      return workflow;
    });
    context.patch('workflows/:id');
    context.del('workflows/:id');
  }
});