define("itarp-opportunity-designer/controllers/shared-rosters/show", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/service"], function (_exports, _controller, _object, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SharedRostersShowController = (_dec = (0, _service.inject)('ad-token-service'), (_class = class SharedRostersShowController extends _controller.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['page', 'userId']);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "toast", _descriptor2, this);

      _initializerDefineProperty(this, "favorites", _descriptor3, this);

      _initializerDefineProperty(this, "auth", _descriptor4, this);

      _initializerDefineProperty(this, "page", _descriptor5, this);

      _initializerDefineProperty(this, "retryCount", _descriptor6, this);

      _initializerDefineProperty(this, "expert", _descriptor7, this);

      _initializerDefineProperty(this, "userId", _descriptor8, this);

      _initializerDefineProperty(this, "tab", _descriptor9, this);

      _initializerDefineProperty(this, "userIsFavorite", _descriptor10, this);
    }

    get isShowingExpertPreview() {
      return this.expert !== null;
    }

    get expertPreviewConfig() {
      return {
        expert: this.expert,
        isOpen: this.isShowingExpertPreview,
        close: this.hideExpertPreview,
        activeTab: this.tab,
        selectTab: val => this.tab = val,
        addToFavorite: this.addToFavorite,
        alreadyFavorite: this.userIsFavorite
      };
    }

    deleteRoster() {
      this.roster.memberIds.removeObject(this.auth.userId);
      this.roster.save().then(() => {
        this.transitionToRoute('shared-rosters');
      });
    }

    removeClient(roster, index) {
      roster.userIds.splice(index - 1, 1);
      roster.save();
    }

    showExpertPreview(expertId) {
      if (this.userId !== expertId) {
        this.retryCount = 0;
      }

      this.userId = expertId;
      this.userIsFavorite = this.isAlreadyFavorite([expertId]);
      this.store.findRecord('user', expertId, {
        reload: true
      }).then(user => {
        this.expert = user;
      }).catch(() => {
        this.retryCount = this.retryCount + 1;

        if (this.retryCount < 3) {
          this.showExpertPreview(expertId);
        }
      });
    }

    hideExpertPreview() {
      this.expert = null;
      this.tab = null;
      this.userId = null;
    }

    isAlreadyFavorite(expertIds) {
      const ids = this.userFavorites.map(fav => fav.id);
      return expertIds.every(ex => ids.some(id => id === ex));
    }

    async addToFavorite(expertIds) {
      let message = '';
      const markedAsFavorite = this.isAlreadyFavorite(expertIds);

      if (markedAsFavorite) {
        const res = await this.favorites.deleteFavorite(expertIds);

        if (res.ok) {
          message = 'Expert removed from favorites';
          this.userFavorites = this.userFavorites.filter(fav => !expertIds.includes(fav.id));
          this.userIsFavorite = false;
        }
      } else {
        const res = await this.favorites.markAsFavorite(expertIds);

        if (res && res.id) {
          message = 'Expert added to favorites';
          this.userFavorites.pushObject(this.expert);
          this.userIsFavorite = true;
        }
      }

      if (message) {
        this.toast.show(message);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "favorites", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "page", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "retryCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "expert", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "userId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "tab", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "userIsFavorite", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "deleteRoster", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteRoster"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeClient", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeClient"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showExpertPreview", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showExpertPreview"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hideExpertPreview", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "hideExpertPreview"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isAlreadyFavorite", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "isAlreadyFavorite"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addToFavorite", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addToFavorite"), _class.prototype)), _class));
  _exports.default = SharedRostersShowController;
});