define("ember-engines/components/link-to-external", ["exports", "@ember/routing/link-component", "@ember/application", "@ember/object"], function (_exports, _linkComponent, _application, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let LinkToExternal;
  {
    LinkToExternal = class LinkToExternal extends _linkComponent.default {
      _namespaceRoute(targetRouteName) {
        const owner = (0, _application.getOwner)(this);

        const externalRoute = owner._getExternalRoute(targetRouteName);

        return externalRoute;
      } // override LinkTo's assertLinkToOrigin method to noop. In LinkTo, this assertion
      // checks to make sure LinkTo is not being used inside a routeless engine
      // See this PR here for more details: https://github.com/emberjs/ember.js/pull/19477


      assertLinkToOrigin() {}

    };
  }
  var _default = LinkToExternal;
  _exports.default = _default;
});