define("itarp-talentcloud-ui/adapters/talentcloud-opportunity", ["exports", "itarp-auth-addon/adapters/ad-token-adapter"], function (_exports, _adTokenAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TalentcloudOpportunityAdapter extends _adTokenAdapter.default {
    get headers() {
      const result = super.headers;
      result['Content-Type'] = 'application/json';
      result['Accept'] = 'application/json';
      return result;
    }

    urlForQuery(query) {
      if (query.adapterOptions.talentCloudId) {
        const talentCloudId = query.adapterOptions.talentCloudId;
        delete query.adapterOptions.talentCloudId;
        return `/api/expert/api/talentcloud/routing/data_service/talent_clouds/${talentCloudId}/opportunities`;
      }

      return super.urlForFindAll(...arguments);
    }

  }

  _exports.default = TalentcloudOpportunityAdapter;
});