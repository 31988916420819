define("itarp-components/components/ui/accordion/body", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @isOpen}}
    <div class='filter-group__body' ...attributes>
      {{yield}}
    </div>
  {{/if}}
  
  */
  {
    "id": "ek9ME2ng",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,0],[24,0,\"filter-group__body\"],[17,2],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@isOpen\",\"&attrs\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "itarp-components/components/ui/accordion/body.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});