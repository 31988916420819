define("itarp-business-application/components/card-collection", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="component-wrapper">
    <div
      class="component component-cards component-cards--multiline component-cards--3-columns"
    >
      {{yield}}
    </div>
  </div>
  */
  {
    "id": "q79+Z4Oj",
    "block": "[[[10,0],[14,0,\"component-wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"component component-cards component-cards--multiline component-cards--3-columns\"],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "itarp-business-application/components/card-collection.hbs",
    "isStrictMode": false
  });

  class CardCollectionComponent extends _component.default {}

  _exports.default = CardCollectionComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CardCollectionComponent);
});