define("itarp-shared-assets-addon/presenters/expert", ["exports", "itarp-shared-assets-addon/utils/presenter", "@ember/string"], function (_exports, _presenter, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ExpertPresenter extends _presenter.default {
    get fullName() {
      const firstName = this.get('firstName');
      const lastName = this.get('lastName');
      return [firstName, lastName].compact().join(' ');
    }

    get initials() {
      return this.fullName.split(' ').map(v => v[0]).join('').toUpperCase();
    }

    get professionalRole() {
      return this.get('profile.professionalRole');
    }

    get yearsOfExperience() {
      return this.get('profile.yearsOfExperience');
    }

    get address() {
      return this.get('profile.fullAddress');
    }

    get linkedinUrl() {
      return this.get('profile.linkedinUrl');
    }

    get email() {
      return this.get('content.email');
    }

    get experfyExpert() {
      return this.get('profile.experfyExpert');
    }

    get highlights() {
      const highlights = this.get('profile.highlightsSanitized');
      if (highlights) return highlights;
    }

    get hourlyRate() {
      const rate = this.get('rate');
      const profileRate = this.get('profile.hourlyRate');
      return rate ? rate : profileRate;
    }

    get hoursAvailable() {
      return this.get('profile.hoursPerWeek');
    }

    get isAvailable() {
      return this.get('profile.workAvailable');
    }

    get pitchVideo() {
      return this.get('profile.pitchVideo');
    }

    get pitch() {
      return this.get('profile.pitchSanitized');
    }

    get skills() {
      return this.get('profile.skills');
    }

    get tools() {
      return this.get('profile.tools');
    }

    get employment() {
      return this.get('profile.experiences');
    }

    get educations() {
      return this.get('profile.educations');
    }

    get certifications() {
      return this.get('profile.certifications');
    }

    get industry() {
      return this.get('profile.industry');
    }

    get industries() {
      return this.get('profile.industries');
    }

    get practiceArea() {
      return this.get('profile.practiceArea');
    }

    get practiceAreas() {
      return this.get('profile.practiceAreas');
    }

    get mentorshipInterest() {
      return this.get('profile.mentorshipInterest');
    }

    get usWorkAuthorized() {
      return this.get('profile.usWorkAuthorized');
    }

    get requiresUsVisaSponsorship() {
      return this.get('profile.requiresUsVisaSponsorship');
    }

    get hasBusiness() {
      return this.get('profile.hasBusiness');
    }

    get diversity() {
      const gender = this.get('profile.gender');
      const race = this.get('profile.race');
      const disability = this.get('profile.disability');
      const veteran = this.get('profile.veteran');
      const disabled = disability ? 'disabled' : 'no disability';
      const isVeteran = veteran ? 'veteran' : 'not a veteran';
      return [gender, race, disabled, isVeteran].compact().without('').map(v => (0, _string.capitalize)(v)).join(', ');
    }

    get dob() {
      return this.get('profile.bornDate');
    }

    get workPreference() {
      const fullTime = this.get('profile.fullTimePreference');
      const partTime = this.get('profile.partTimePreference');
      const contractor = this.get('profile.contractor');
      const result = [];
      if (contractor) result.pushObject('Contractor');
      if (fullTime) result.pushObject('Full-time Permanent');
      if (partTime) result.pushObject('Part-time Permanent');
      return result.join(', ');
    }

    get locationPreference() {
      const commute = this.get('profile.localCommuteOnly');
      const remote = this.get('profile.remote');
      const relocate = this.get('profile.willingToRelocate');
      const travel = this.get('profile.willingToTravel');
      const travelAvailability = this.get('profile.travelAvailability');
      const result = [];
      if (commute) result.pushObject('I am open to local commute only');
      if (remote) result.pushObject('I am interested in remote work only');
      relocate ? result.pushObject('I am willing to relocate') : result.pushObject('I am not willing to relocate');
      travel ? result.pushObject(`I am willing to travel ${travelAvailability}`) : result.pushObject('I am not willing to travel');
      return result.join(', ');
    }

    get approvedAt() {
      return this.get('profile.approvedAt');
    }

  }

  _exports.default = ExpertPresenter;
});