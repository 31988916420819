define("itarp-components/components/ui/table/cell", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <td class={{this.className}} ...attributes>
    {{yield @options}}
  </td>
  */
  {
    "id": "FT+nL0C0",
    "block": "[[[11,\"td\"],[16,0,[30,0,[\"className\"]]],[17,1],[12],[1,\"\\n  \"],[18,3,[[30,2]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@options\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "itarp-components/components/ui/table/cell.hbs",
    "isStrictMode": false
  });

  class UiTableCellComponent extends _component2.default {
    get className() {
      const className = this.args.className || 'table__body';
      return className + '__cell';
    }

  }

  _exports.default = UiTableCellComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiTableCellComponent);
});