define("ember-gestures/templates/components/fast-async", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qfhlh5QM",
    "block": "[[[18,1,[[33,1]]],[1,[34,2]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"isPending\",\"text\"]]",
    "moduleName": "ember-gestures/templates/components/fast-async.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});