define("itarp-components/components/templates/filter-layout/filters", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class='designer__body__filters
      {{if @expanded 'is-expanded' 'is-collapsed'}}
      {{if (and @fixed @expanded) 'is-fixed'}}
      {{if (and @fixed (and @sidebar @expanded)) 'is-fixed-collapsed'}}
      {{if @sidebarHidden 'is-left'}}'
    ...attributes
  >
    <Ui::Filters @toggle={{@toggle}} as |filters|>
      {{yield filters}}
    </Ui::Filters>
  </div>
  */
  {
    "id": "WK7qZfW1",
    "block": "[[[11,0],[16,0,[29,[\"designer__body__filters\\n    \",[52,[30,1],\"is-expanded\",\"is-collapsed\"],\"\\n    \",[52,[28,[37,1],[[30,2],[30,1]],null],\"is-fixed\"],\"\\n    \",[52,[28,[37,1],[[30,2],[28,[37,1],[[30,3],[30,1]],null]],null],\"is-fixed-collapsed\"],\"\\n    \",[52,[30,4],\"is-left\"]]]],[17,5],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@toggle\"],[[30,6]]],[[\"default\"],[[[[1,\"\\n    \"],[18,8,[[30,7]]],[1,\"\\n  \"]],[7]]]]],[1,\"\\n\"],[13]],[\"@expanded\",\"@fixed\",\"@sidebar\",\"@sidebarHidden\",\"&attrs\",\"@toggle\",\"filters\",\"&default\"],false,[\"if\",\"and\",\"ui/filters\",\"yield\"]]",
    "moduleName": "itarp-components/components/templates/filter-layout/filters.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});