define("itarp-opportunity-designer/routes/my-opportunities-loading", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MyOpportunitiesLoadingRoute extends _route.default {}

  _exports.default = MyOpportunitiesLoadingRoute;
});