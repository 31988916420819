define("itarp-shared-assets-addon/components/ui/offscreen", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/object/observers"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service, _observers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.open}}
    {{#in-element this.element}}
      <div class='offscreen-preview__content'>
        {{yield
          (hash
            header=(component 'ui/offscreen/header' close=@close)
            body=(component 'ui/offscreen/body')
          )
        }}
      </div>
    {{/in-element}}
  {{/if}}
  */
  {
    "id": "M3SO/+g3",
    "block": "[[[41,[30,0,[\"open\"]],[[[40,[[[1,\"    \"],[10,0],[14,0,\"offscreen-preview__content\"],[12],[1,\"\\n      \"],[18,2,[[28,[37,3],null,[[\"header\",\"body\"],[[50,\"ui/offscreen/header\",0,null,[[\"close\"],[[30,1]]]],[50,\"ui/offscreen/body\",0,null,null]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],\"%cursor:0%\",[30,0,[\"element\"]]]],[]],null]],[\"@close\",\"&default\"],false,[\"if\",\"in-element\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-shared-assets-addon/components/ui/offscreen.hbs",
    "isStrictMode": false
  });

  let UiOffscreenComponent = (_class = class UiOffscreenComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "open", _descriptor, this);

      _initializerDefineProperty(this, "element", _descriptor2, this);

      _initializerDefineProperty(this, "layout", _descriptor3, this);

      this.element = document.querySelector('.offscreen-preview');
      if (this.args.isOpen) this.handleOpenState();
      (0, _observers.addObserver)(this, 'args.isOpen', this, 'observeOpenState');
    }

    observeOpenState() {
      const open = this.args.isOpen;
      const hasChanged = open !== this.open;
      if (!hasChanged) return;
      open ? this.handleOpenState() : this.handleCloseState();
    }

    handleOpenState() {
      this.open = true;
      this.layout.renderOffscreen();
    }

    handleCloseState() {
      this.layout.renderMain().then(() => {
        this.open = false;
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "open", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "element", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "layout", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "observeOpenState", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "observeOpenState"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleOpenState", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleOpenState"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleCloseState", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleCloseState"), _class.prototype)), _class);
  _exports.default = UiOffscreenComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiOffscreenComponent);
});