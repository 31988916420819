define("itarp-talentcloud-ui/components/ui/modals/base", ["exports", "itarp-talentcloud-ui/components/ui/togglable", "@glimmer/tracking", "@ember/object"], function (_exports, _togglable, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.isOpen}}
    <ModalDialog
      @onClose={{this.toggle}}
      @targetAttachment={{this.position}}
      @translucentOverlay={{this.hasOverlay}}
      @overlayClass={{this.overlayClass}}
      @containerClass={{this.containerClass}}
    >
      <div class="app-modal {{this.sizeClass}} {{this.additionalClasses}}">
        <div class="app-modal-content" ...attributes>
          {{yield
            (hash
              header=(component "ui/modals/base/header" toggle=this.toggle)
              body=(component "ui/modals/base/body")
              actions=(hash toggle=this.toggle close=this.close open=this.open)
            )
            to="dialog"
          }}
        </div>
      </div>
    </ModalDialog>
  {{/if}}
  {{yield
    (hash actions=(hash toggle=this.toggle open=this.open close=this.close))
  }}
  */
  {
    "id": "OtcPaZTh",
    "block": "[[[41,[30,0,[\"isOpen\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@onClose\",\"@targetAttachment\",\"@translucentOverlay\",\"@overlayClass\",\"@containerClass\"],[[30,0,[\"toggle\"]],[30,0,[\"position\"]],[30,0,[\"hasOverlay\"]],[30,0,[\"overlayClass\"]],[30,0,[\"containerClass\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[15,0,[29,[\"app-modal \",[30,0,[\"sizeClass\"]],\" \",[30,0,[\"additionalClasses\"]]]]],[12],[1,\"\\n      \"],[11,0],[24,0,\"app-modal-content\"],[17,1],[12],[1,\"\\n        \"],[18,2,[[28,[37,3],null,[[\"header\",\"body\",\"actions\"],[[50,\"ui/modals/base/header\",0,null,[[\"toggle\"],[[30,0,[\"toggle\"]]]]],[50,\"ui/modals/base/body\",0,null,null],[28,[37,3],null,[[\"toggle\",\"close\",\"open\"],[[30,0,[\"toggle\"]],[30,0,[\"close\"]],[30,0,[\"open\"]]]]]]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null],[18,3,[[28,[37,3],null,[[\"actions\"],[[28,[37,3],null,[[\"toggle\",\"open\",\"close\"],[[30,0,[\"toggle\"]],[30,0,[\"open\"]],[30,0,[\"close\"]]]]]]]]]]],[\"&attrs\",\"&dialog\",\"&default\"],false,[\"if\",\"modal-dialog\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-talentcloud-ui/components/ui/modals/base.hbs",
    "isStrictMode": false
  });

  class UiModalsBaseComponent extends _togglable.default {
    get containerClass() {
      return this.args.containerClass || 'app-modal-wrapper';
    }

    get overlayClass() {
      return this.args.overlayClass || 'app-modal-overlay';
    }

    get hasOverlay() {
      return this.args.hasOverlay !== false;
    }

    get position() {
      return this.args.position || 'center';
    }

    get size() {
      return this.args.size || 'medium';
    }

    get additionalClasses() {
      return this.args.additionalClasses || '';
    }

    get sizeClass() {
      const classes = {
        small: 'app-modal--sm',
        medium: 'app-modal--md',
        large: 'app-modal--lg'
      };
      return classes[this.size] || '';
    }

  }

  _exports.default = UiModalsBaseComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UiModalsBaseComponent);
});