define("itarp-components/components/table/candidates/body", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let @table as |table|}}
    <table.body @group={{or @group 'true'}} as |body|>
      {{yield
        (hash
          row=(component
            'table/candidates/row'
            candidate=@candidate
            application=@application
            project=@project
            stagesWithButtons=@stagesWithButtons
            showPreview=@showPreview
            table=body
            wide=true
            showOffer=@showOffer
          )
          menuRow=(component 'table/candidates/menu-row' table=body)
        )
        body
      }}
    </table.body>
  {{/let}}
  */
  {
    "id": "vjShzBl0",
    "block": "[[[44,[[30,1]],[[[1,\"  \"],[8,[30,2,[\"body\"]],null,[[\"@group\"],[[28,[37,1],[[30,3],\"true\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[18,11,[[28,[37,3],null,[[\"row\",\"menuRow\"],[[50,\"table/candidates/row\",0,null,[[\"candidate\",\"application\",\"project\",\"stagesWithButtons\",\"showPreview\",\"table\",\"wide\",\"showOffer\"],[[30,5],[30,6],[30,7],[30,8],[30,9],[30,4],true,[30,10]]]],[50,\"table/candidates/menu-row\",0,null,[[\"table\"],[[30,4]]]]]]],[30,4]]],[1,\"\\n  \"]],[4]]]]],[1,\"\\n\"]],[2]]]],[\"@table\",\"table\",\"@group\",\"body\",\"@candidate\",\"@application\",\"@project\",\"@stagesWithButtons\",\"@showPreview\",\"@showOffer\",\"&default\"],false,[\"let\",\"or\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-components/components/table/candidates/body.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});