define("shared/adapters/record", ["exports", "itarp-auth-addon/adapters/ad-token-adapter"], function (_exports, _adTokenAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class RecordAdapter extends _adTokenAdapter.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", '/api/expert/routing/data');
    }

    get headers() {
      const result = super.headers;
      result['Content-Type'] = 'application/json';
      return result;
    }

    updateRecord(record, modelName) {
      const data = record.toJSON();
      let url = this.buildURL(modelName, record.get('id'), undefined, 'updateRecord');
      return super.ajax(url, 'PATCH', {
        data
      });
    }

    createRecord(record, modelName) {
      const data = record.toJSON();
      let url = this.buildURL(modelName, undefined, undefined, 'createRecord');
      return super.ajax(url, 'POST', {
        data
      });
    }

    deleteRecord(record, modelName) {
      return super.ajax(this.buildURL(modelName, record.get('id'), record, 'deleteRecord'), 'DELETE');
    }

  }

  _exports.default = RecordAdapter;
});