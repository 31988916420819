define('ember-hammertime/mixins/touch-action', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const defaults = _emberGetConfig.default.EmberHammertime || {};

  const FocusableInputTypes = ['button', 'submit', 'text', 'file'];
  // Set this to `false` to not apply the styles automatically to elements with an `action`
  const TouchActionOnAction = defaults.touchActionOnAction === undefined ? true : defaults.touchActionOnAction;
  // Remove 'onclick' if you do not want the styles automatically applied to elements with an `onclick`
  const TouchActionAttributes = defaults.touchActionAttributes || ['onclick'];
  // Remove whichever element types you do not want automatically getting styles applied to them
  const TouchActionSelectors = defaults.touchActionSelectors || ['button', 'input', 'a', 'textarea'];
  // The actual style string that is applied to the elements. You can tweak this if you want something different.
  const TouchActionProperties = defaults.touchActionProperties || 'touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;';

  function touchActionStyle() {
    let style = Ember.get(this, 'touchActionProperties');
    let otherStyleKey = Ember.get(this, 'otherStyleKey');

    if (otherStyleKey) {
      let otherStyle = Ember.get(this, otherStyleKey);

      if (otherStyle) {
        if (Ember.String.isHTMLSafe(otherStyle)) {
          otherStyle = otherStyle.string;
        }
        style += otherStyle;
      }
    }

    return Ember.String.htmlSafe(style);
  }

  exports.default = Ember.Mixin.create({
    touchActionOnAction: TouchActionOnAction,
    touchActionAttributes: TouchActionAttributes,
    touchActionSelectors: TouchActionSelectors,
    touchActionProperties: TouchActionProperties,
    ignoreTouchAction: false,

    init() {
      this._super(...arguments);

      const {
        tagName,
        ignoreTouchAction,
        click
      } = this;

      const hasClick = click && click.apply;
      const hasTag = tagName !== '' || tagName === null && hasClick;
      if (!hasTag) {
        return;
      }

      let maybeApplyStyle = ignoreTouchAction === false;
      let hasClickHandler = ignoreTouchAction === false && hasClick;
      let shouldApplyStyle = false;

      if (maybeApplyStyle) {
        let isFocusable = this.touchActionSelectors.indexOf(tagName) !== -1;

        if (isFocusable && tagName === 'input') {
          isFocusable = FocusableInputTypes.indexOf(this.type) !== -1;
        }

        shouldApplyStyle = isFocusable;
      }

      if (hasClickHandler || shouldApplyStyle) {
        let newAttributeBindings = [];
        const bindings = Ember.get(this, 'attributeBindings');

        // don't override other style bindings if present
        if (Array.isArray(bindings)) {
          bindings.forEach(binding => {
            if (binding === 'style') {
              this.otherStyleKey = binding;
            } else {
              let end = binding.length - 6;

              if (end > 0 && ':style' === binding.substring(end)) {
                this.otherStyleKey = binding.substring(0, end);
              }
            }
          });
          newAttributeBindings = newAttributeBindings.concat(bindings);
        }

        newAttributeBindings.push('touchActionStyle:style');
        this.set('attributeBindings', newAttributeBindings);

        let desc = this.otherStyleKey ? Ember.computed(this.otherStyleKey, touchActionStyle) : Ember.computed(touchActionStyle);
        Ember.defineProperty(this, 'touchActionStyle', desc);
      }
    }
  });
});