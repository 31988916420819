define("itarp-talentcloud-ui/components/question/factory", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking"], function (_exports, _component, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield
    (component
      this.componentName
      question=@question
      onChange=@onChange
      value=@value
      disabled=@disabled
    )
  }}
  */
  {
    "id": "/Sky3ZG+",
    "block": "[[[18,5,[[50,[30,0,[\"componentName\"]],0,null,[[\"question\",\"onChange\",\"value\",\"disabled\"],[[30,1],[30,2],[30,3],[30,4]]]]]]],[\"@question\",\"@onChange\",\"@value\",\"@disabled\",\"&default\"],false,[\"yield\",\"component\"]]",
    "moduleName": "itarp-talentcloud-ui/components/question/factory.hbs",
    "isStrictMode": false
  });

  class QuestionFactoryComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "types", {
        certification: 'CERTIFICATION',
        custom: 'FREE_FORM',
        travel: 'TRAVEL',
        visa: 'VISA_STATUS',
        authorization: 'WORK_AUTHORIZATION',
        experience: 'WORK_EXPERIENCE'
      });
    }

    get type() {
      const question = this.args.question;
      return Object.entries(this.types).find(([k, v]) => question.type === k)[0];
    }

    get componentName() {
      return `question/types/${this.type}`;
    }

  }

  _exports.default = QuestionFactoryComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, QuestionFactoryComponent);
});