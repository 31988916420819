define("itarp-opportunity-designer/serializers/collaborator", ["exports", "itarp-shared-assets-addon/serializers/data"], function (_exports, _data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CollaboratorSerializer extends _data.default {
    normalize(modelClass, data) {
      const user = data.collaborator_user_id;
      const result = super.normalize(...arguments);

      if (user) {
        result.data.relationships.user = {
          data: {
            id: user,
            type: 'user'
          }
        };
      }

      return result;
    }

  }

  _exports.default = CollaboratorSerializer;
});