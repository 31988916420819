define("itarp-business-application/components/panel-sidebar", ["exports", "@glimmer/component", "@ember/object/computed", "@ember/object", "@ember/service"], function (_exports, _component, _computed, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="panel-sidebar com-w--11" data-test-panel-sidebar>
    <div class="wrapper">
      <ul class="panel-sidebar__links">
        {{#each this.menuItems as |item|}}
          <LinkTo
            @route={{item.route}}
            class="panel-sidebar__link"
            @activeClass="is-active"
            @disabled={{item.disabled}}
          >
            <span class="panel-sidebar__link__icon {{item.icon}}"></span>
            <span class="panel-sidebar__link__text">
              {{item.label}}
            </span>
          </LinkTo>
        {{/each}}
      </ul>
    </div>
  </div>
  
  */
  {
    "id": "/6eq/Cj4",
    "block": "[[[10,0],[14,0,\"panel-sidebar com-w--11\"],[14,\"data-test-panel-sidebar\",\"\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"wrapper\"],[12],[1,\"\\n    \"],[10,\"ul\"],[14,0,\"panel-sidebar__links\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"menuItems\"]]],null]],null],null,[[[1,\"        \"],[8,[39,2],[[24,0,\"panel-sidebar__link\"]],[[\"@route\",\"@activeClass\",\"@disabled\"],[[30,1,[\"route\"]],\"is-active\",[30,1,[\"disabled\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[10,1],[15,0,[29,[\"panel-sidebar__link__icon \",[30,1,[\"icon\"]]]]],[12],[13],[1,\"\\n          \"],[10,1],[14,0,\"panel-sidebar__link__text\"],[12],[1,\"\\n            \"],[1,[30,1,[\"label\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"item\"],false,[\"each\",\"-track-array\",\"link-to\"]]",
    "moduleName": "itarp-business-application/components/panel-sidebar.hbs",
    "isStrictMode": false
  });

  let PanelSidebarComponent = (_dec = (0, _computed.alias)('args.config.attributes'), _dec2 = (0, _service.inject)(), _dec3 = (0, _object.computed)('attributes.hidden'), (_class = class PanelSidebarComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "attributes", _descriptor, this);

      _initializerDefineProperty(this, "hyperlinks", _descriptor2, this);
    }

    get isHidden() {
      return this.attributes.hidden;
    }

    get menuItems() {
      return [{
        label: 'Autofill',
        icon: 'autofill',
        route: 'profile.autofill'
      }, {
        label: 'Basic Info',
        icon: 'basic-info',
        route: 'profile.basic-info',
        disabled: !this.args.user.profile.resume && !this.args.user.profile.linkedinUrl
      }, {
        label: 'Expertise',
        icon: 'expertise',
        route: 'profile.expertise',
        disabled: !this.args.user.profile.basicInfoComplete
      }, {
        label: 'Work Arrangement',
        icon: 'work-arrangement',
        route: 'profile.work-arrangement',
        disabled: !this.args.user.profile.expertiseComplete
      }, {
        label: 'Preview',
        icon: 'preview',
        route: 'profile-preview'
      }];
    }

    transition(sidebarId, itemId, event) {
      event.preventDefault();
      const tagName = 'a';
      const elementId = `panel-sidebar__link-${sidebarId}-${itemId}`;
      const element = document.getElementById(elementId);
      this.hyperlinks.transition({
        target: element,
        preventDefault: () => {}
      }, tagName);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "attributes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hyperlinks", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isHidden", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "isHidden"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transition", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "transition"), _class.prototype)), _class));
  _exports.default = PanelSidebarComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PanelSidebarComponent);
});