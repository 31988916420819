define("shared/components/tag", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking"], function (_exports, _component, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PowerSelectMultiple
    @placeholder={{@placeholder}}
    @onChange={{this.setTags}}
    @buildSelection={{this.buildSelection}}
    @searchEnabled={{true}}
    @onOpen={{this.onOpen}}
    @onKeydown={{this.onKeydown}}
    @registerAPI={{this.registerPowerSelectAPI}}
    @searchField="label"
    @onBlur={{this.onBlur}}
    ...attributes
    as |option|
  >
    {{option.label}}
  </PowerSelectMultiple>
  */
  {
    "id": "Xq01aYT0",
    "block": "[[[8,[39,0],[[17,1]],[[\"@placeholder\",\"@onChange\",\"@buildSelection\",\"@searchEnabled\",\"@onOpen\",\"@onKeydown\",\"@registerAPI\",\"@searchField\",\"@onBlur\"],[[30,2],[30,0,[\"setTags\"]],[30,0,[\"buildSelection\"]],true,[30,0,[\"onOpen\"]],[30,0,[\"onKeydown\"]],[30,0,[\"registerPowerSelectAPI\"]],\"label\",[30,0,[\"onBlur\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[1,[30,3,[\"label\"]]],[1,\"\\n\"]],[3]]]]]],[\"&attrs\",\"@placeholder\",\"option\"],false,[\"power-select-multiple\"]]",
    "moduleName": "shared/components/tag.hbs",
    "isStrictMode": false
  });

  const selectEventCodes = ['Comma', 'Enter'];
  let TagComponent = (_class = class TagComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "powerSelect", _descriptor, this);
    }

    get tags() {
      return this.args.value ? this.args.value : [];
    }

    setTags(tag) {
      const tags = [...this.tags];

      if (tag.value) {
        tags.removeObject(tag);
      } else {
        tags.pushObject({
          label: tag,
          value: tag
        });
      }

      this.args.setValue(tags);
      this.args.onChange && this.args.onChange(tags);
    }

    buildSelection(option) {
      return option;
    }

    onOpen() {
      return false; // disable dropdown open
    }

    onKeydown(dropdown, event) {
      const value = event.target.value;
      const isDuplicate = this.tags.isAny('value', value);
      const isSelectEvent = selectEventCodes.includes(event.code);
      if (isDuplicate || isSelectEvent === false) return;
      dropdown.actions.choose(value, event);
      event.stopPropagation();
      event.preventDefault();
      dropdown.actions.search('');
      return false;
    }

    onBlur(event) {
      const {
        searchText
      } = event;
      const trimmedSearchText = searchText ? searchText.trim() : '';
      const tags = [...this.tags];

      if (trimmedSearchText) {
        const isDuplicate = tags.isAny('value', trimmedSearchText);

        if (!isDuplicate) {
          tags.pushObject({
            label: trimmedSearchText,
            value: trimmedSearchText
          });
          this.args.setValue(tags);
          this.args.registerPowerSelectAPI(this.powerSelect);
        }
      }
    }

    registerPowerSelectAPI(api) {
      this.powerSelect = api;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "powerSelect", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setTags", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setTags"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buildSelection", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "buildSelection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onOpen", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onOpen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeydown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onKeydown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onBlur", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onBlur"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerPowerSelectAPI", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerPowerSelectAPI"), _class.prototype)), _class);
  _exports.default = TagComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TagComponent);
});