define("itarp-business-application/controllers/list-elements", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ListElementsController extends _controller.default {}

  _exports.default = ListElementsController;
});