define("itarp-components/components/ui/table/filters", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Ui::Table::Row @class='table__body__filters' as |row|>
    <row.cell colspan={{@colspan}}>
      <div class='table-row-filters'>
        {{yield (hash group=(component 'ui/table/filters/group'))}}
      </div>
    </row.cell>
  </Ui::Table::Row>
  */
  {
    "id": "wy1pFZLv",
    "block": "[[[8,[39,0],null,[[\"@class\"],[\"table__body__filters\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"cell\"]],[[16,\"colspan\",[30,2]]],null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"table-row-filters\"],[12],[1,\"\\n      \"],[18,3,[[28,[37,2],null,[[\"group\"],[[50,\"ui/table/filters/group\",0,null,null]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"row\",\"@colspan\",\"&default\"],false,[\"ui/table/row\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-components/components/ui/table/filters.hbs",
    "isStrictMode": false
  });

  class UiTableFiltersComponent extends _component2.default {}

  _exports.default = UiTableFiltersComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiTableFiltersComponent);
});