define("shared/services/filters", ["exports", "@ember/service", "@ember/object", "@ember/utils", "@ember/array", "shared/utils/factory", "shared/utils/parse"], function (_exports, _service, _object, _utils, _array, _factory, _parse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FiltersService = (_dec = (0, _service.inject)('route-adapters'), _dec2 = (0, _service.inject)('filter-chips'), (_class = class FiltersService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "routeAdapters", _descriptor, this);

      _initializerDefineProperty(this, "chips", _descriptor2, this);

      _defineProperty(this, "factory", new _factory.default());
    }

    setupFilters(route) {
      const adapter = this.routeAdapters.get(route);
      const filters = {};
      adapter.defaultParamValues.forEach((value, param) => {
        const type = adapter.filterTypeFor(param);

        if (type) {
          filters[param] = this.factory.create(type, value);
        }
      });
      return filters;
    }

    serializeFilters(filters, route) {
      const adapter = this.routeAdapters.get(route);
      const params = Object.entries(filters).reduce((result, [name, filter]) => {
        const value = filter.serializeQuery();
        result[name] = isPresent(value) ? value : adapter.defaultValueFor(name);
        return result;
      }, {});
      adapter.controller.setProperties(params);
    }

    deserializeFilters(params, filtersSet, route) {
      if (!params || !filtersSet) return;
      Object.entries(filtersSet).forEach(([paramName, filter]) => {
        filter.deserialize(params[paramName]);
      });
      return this.chips.generate(route);
    }

    buildQuery(route, params) {
      const adapter = this.routeAdapters.get(route);
      const schema = adapter.paramsSchema;
      const result = [];

      for (const [paramName, value] of Object.entries(params)) {
        const definition = schema[paramName];
        if (!definition) continue;
        const filter = adapter.filters[paramName];

        if (definition.key) {
          const previous = result.filter(f => f[definition.key])[0];
          const queryValue = filter.serializeSearch(value);
          const query = paramObject(definition, queryValue);

          if (isPresent(value)) {
            if (previous && definition.valueKey) {
              Object.assign(previous[definition.key], query[definition.key]);
            } else {
              result.pushObject(query);
            }
          }
        }
      }

      return result;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "routeAdapters", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chips", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupFilters", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "serializeFilters", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "serializeFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deserializeFilters", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deserializeFilters"), _class.prototype)), _class));
  _exports.default = FiltersService;

  function isPresent(value) {
    if ((0, _array.isArray)(value)) {
      return value.length > 0;
    }

    return ![null, undefined].includes(value);
  }

  function paramObject(schema, value) {
    const parsed = (0, _parse.default)(value);
    const isObject = (0, _utils.typeOf)(parsed) === 'object';

    if (schema.key && schema.valueKey) {
      return {
        [schema.key]: {
          [schema.valueKey]: value
        }
      };
    }

    return {
      [schema.key]: parsed
    };
    return isObject ? JSON.stringify(filter) : filter;
  }
});