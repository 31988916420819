define("itarp-opportunity-designer/serializers/global-questionnaire", ["exports", "@ember-data/serializer/json"], function (_exports, _json) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class GlobalQuestionnaireSerializer extends _json.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", 'id');
    }

    normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      const data = payload ? payload.records ? payload.records : payload : [];
      data.meta = payload.meta;
      return super.normalizeQueryResponse(store, primaryModelClass, data, id, requestType);
    }

  }

  _exports.default = GlobalQuestionnaireSerializer;
});