define("itarp-opportunity-designer/templates/details/unsuccessful-loading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "WxadCCGi",
    "block": "[[[8,[39,0],null,[[\"@items\"],[[28,[37,1],[[28,[37,2],null,[[\"value\",\"label\"],[\"...\",\"Declined\"]]],[28,[37,2],null,[[\"value\",\"label\"],[\"...\",\"Not Interested\"]]],[28,[37,2],null,[[\"value\",\"label\"],[\"...\",\"Unavailable\"]]],[28,[37,2],null,[[\"value\",\"label\"],[\"...\",\"Withdrawn\"]]]],null]]],null],[1,\"\\n\"],[10,0],[14,0,\"designer__content__body no-sp has-filters filters-collapsed\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"designer__body__listing\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col--12-12\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"component-wrapper has-padding\"],[12],[1,\"\\n          \"],[10,\"h3\"],[14,0,\"ts--20 mar-b--0\"],[12],[1,\"\\n            ... People Matching Your Criteria\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col--12-12\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"component-wrapper has-padding\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"table-wrapper\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"table-scroll\"],[12],[1,\"\\n              \"],[8,[39,3],null,[[\"@title\"],[\"Loading\"]],null],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"ui/candidate-stats\",\"array\",\"hash\",\"loader\"]]",
    "moduleName": "itarp-opportunity-designer/templates/details/unsuccessful-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});