define("itarp-business-application/util/parser/index", ["exports", "itarp-business-application/util/parser/component", "itarp-business-application/util/parser/page"], function (_exports, _component, _page) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "parseComponents", {
    enumerable: true,
    get: function () {
      return _component.parseComponents;
    }
  });
  Object.defineProperty(_exports, "parseComponent", {
    enumerable: true,
    get: function () {
      return _component.parseComponent;
    }
  });
  Object.defineProperty(_exports, "PageParser", {
    enumerable: true,
    get: function () {
      return _page.PageParser;
    }
  });
});