define("itarp-business-application/components/tabs/basic-tab", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div>
    {{yield}}
  </div>
  */
  {
    "id": "wAKI9T+p",
    "block": "[[[10,0],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "itarp-business-application/components/tabs/basic-tab.hbs",
    "isStrictMode": false
  });

  class TabsBasicTabComponent extends _component.default {}

  _exports.default = TabsBasicTabComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TabsBasicTabComponent);
});