define("itarp-opportunity-designer/serializers/proposal", ["exports", "itarp-shared-assets-addon/serializers/data", "@ember-data/serializer/rest", "@ember/object", "@ember/string"], function (_exports, _data, _rest, _object, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let ProposalSerializer = (_class = class ProposalSerializer extends _data.default.extend(_rest.EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        user: {
          embedded: 'always'
        },
        talentCloud: {
          serialize: false
        },
        selectedForBulk: {
          serialize: false
        },
        opportunity: {
          serialize: false
        },
        answers: {
          serialize: false
        }
      });
    }

    normalize(modelClass, data) {
      this.normalizeUser(data);
      const resourceRecord = this.normalizeResource(data);
      const answers = this.normalizeAnswers(data);
      const resource = data.resource_type;
      const resourceID = data.resource_id;
      const resourceData = {
        data: {
          type: resource,
          id: resourceID
        }
      };
      const result = super.normalize(...arguments);
      if (!result.included) result.included = [];
      if (resourceRecord) result.included.push(resourceRecord.data);

      if (answers && answers.length) {
        result.included.pushObjects(answers.compact().map(a => a.data));
        result.data.relationships.answers = {
          data: answers.map(answer => ({
            type: 'answer',
            id: answer.data.id
          }))
        };
      }

      if (resource && resourceID) {
        result.data.relationships[(0, _string.camelize)(resource)] = resourceData;
      }

      return result;
    }

    serialize(snapshot) {
      const json = super.serialize(...arguments);
      return {
        applications: json.proposals
      };
    }

    normalizeUser(data) {
      if (data.user) {
        if (Array.isArray(data.user)) {
          data.user = data.user.length ? data.user[0] : null;
        }

        if (data.user && data.user.id) {
          data.user._id = data.user.id;
          delete data.user.id;
        }

        if (!data.user_id && data.user && data.user._id) {
          data.user_id = data.user._id;
        }
      }
    }

    normalizeResource(data) {
      const resources = ['talent_cloud', 'opportunity'];
      let resource;
      let resourceType;
      let modelName;

      for (const option of resources) {
        if (data[option]) {
          resource = data[option];
          resourceType = option;
          modelName = (0, _string.dasherize)(resourceType);
        }
      }

      if (resource) {
        var _resource;

        if (Array.isArray(resource)) {
          data[resourceType] = resource[0];
          resource = resource[0];
        }

        if (!((_resource = resource) !== null && _resource !== void 0 && _resource._id)) return;
        data.resource_id = resource._id;
        data.resource_type = modelName;
        delete data[resourceType];
        const serializer = this.store.serializerFor(modelName);
        const model = this.store.modelFor(modelName);
        return serializer.normalize(model, resource);
      }
    }

    normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      ['opportunity_applications', 'talent_cloud_applications'].forEach(key => {
        if (key in payload) {
          const records = payload[key] && payload[key].records ? payload[key].records : payload[key];
          payload.records = records;
        }
      });
      return super.normalizeQueryResponse(store, primaryModelClass, payload, id, requestType);
    }

    normalizeAnswers(data) {
      let answers = data.answers;

      if (answers && answers.length) {
        delete data.answers;
        return answers.map(this.normalizeAnswer).filter(a => !!a);
      }
    }

    normalizeAnswer(answer) {
      const type = answer.type;
      const serializer = this.store.serializerFor('answer');
      const model = this.store.modelFor('answer');
      const result = serializer.normalize(model, answer);
      result.data.attributes.type = type;
      result.data.relationships.question = {
        data: {
          type: 'questionnaire',
          id: answer.question_id
        }
      };
      return result;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "normalizeUser", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "normalizeUser"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "normalizeResource", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "normalizeResource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "normalizeAnswers", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "normalizeAnswers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "normalizeAnswer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "normalizeAnswer"), _class.prototype)), _class);
  _exports.default = ProposalSerializer;
});