define("ember-bootstrap/components/bs-form/element/control/input", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "@ember/object", "ember-bootstrap/components/bs-form/element/control", "@ember/utils", "ember-bootstrap/utils/cp/size-class", "ember-bootstrap/utils/default-decorator", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _templateFactory, _component2, _object, _control, _utils, _sizeClass, _defaultDecorator, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <input
    type={{this.type}}
    id={{@id}}
    disabled={{this.disabled}}
    readonly={{this.readonly}}
    aria-describedby={{this.ariaDescribedBy}}
    value={{this.value}}
    class="form-control {{if (macroCondition (macroGetOwnConfig "isNotBS3")) this.formValidationClass}} {{if (macroCondition (macroGetOwnConfig "isNotBS3")) this.sizeClass}}"
    ...attributes
    {{on "change" this.handleChange}}
    {{on "input" this.handleInput}}
  >
  */
  {
    "id": "us957q/i",
    "block": "[[[11,\"input\"],[16,4,[30,0,[\"type\"]]],[16,1,[30,1]],[16,\"disabled\",[30,0,[\"disabled\"]]],[16,\"readonly\",[30,0,[\"readonly\"]]],[16,\"aria-describedby\",[30,0,[\"ariaDescribedBy\"]]],[16,2,[30,0,[\"value\"]]],[16,0,[29,[\"form-control \",[30,0,[\"formValidationClass\"]],\" \",[30,0,[\"sizeClass\"]]]]],[17,2],[4,[38,0],[\"change\",[30,0,[\"handleChange\"]]],null],[4,[38,0],[\"input\",[30,0,[\"handleInput\"]]],null],[12],[13]],[\"@id\",\"&attrs\"],false,[\"on\"]]",
    "moduleName": "ember-bootstrap/components/bs-form/element/control/input.hbs",
    "isStrictMode": false
  });

  const allowedTypes = new Map();

  function canUseType(type) {
    if (typeof document !== 'object' || typeof document.createElement !== 'function') {
      // consider all types as supported if running in an
      // environment that doesn't support DOM
      return true;
    }

    if (!allowedTypes.has(type)) {
      try {
        let inputElement = document.createElement('input');
        inputElement.type = type;
        allowedTypes.set(type, true);
      } catch (error) {
        allowedTypes.set(type, false);
      }
    }

    return allowedTypes.get(type);
  }
  /**
  
   @class FormElementControlInput
   @namespace Components
   @extends Components.FormElementControl
   @private
   */


  let FormElementControlInput = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _sizeClass.default)('form-control', 'size'), _dec(_class = (0, _deprecateSubclassing.default)(_class = (_class2 = class FormElementControlInput extends _control.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "size", _descriptor, this);

      _initializerDefineProperty(this, "sizeClass", _descriptor2, this);
    }

    /**
     * @property type
     * @type {String}
     * @public
     */
    get type() {
      return 'text';
    }

    set type(value) {
      // fallback to 'text' if value is empty
      if ((0, _utils.isEmpty)(value)) {
        return 'text';
      } // IE 11 throws if setting an unsupported type via DOM.
      // We guard against that behaviour by testing if user
      // agent throws on setting the provided type.
      // This is inspired by input helper shipped with Ember.js:
      // https://github.com/emberjs/ember.js/blob/30137796af42c63b28ead127cba0e43e45a773c1/packages/%40ember/-internals/glimmer/lib/components/text_field.ts#L93-L115


      if (!canUseType(value)) {
        return 'text';
      }

      return value;
    }

    handleChange(event) {
      this.onChange(event.target.value);
    }

    handleInput(event) {
      this.onChange(event.target.value);
    }
    /**
     * [BS4 only] Property for size styling, set to 'lg', 'sm' or 'xs'
     *
     * Also see the [Bootstrap docs](https://getbootstrap.com/docs/4.3/components/forms/#sizing)
     *
     * @property size
     * @type String
     * @public
     */


  }, (_applyDecoratedDescriptor(_class2.prototype, "type", [_object.computed], Object.getOwnPropertyDescriptor(_class2.prototype, "type"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleChange", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleChange"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleInput", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleInput"), _class2.prototype), _descriptor = _applyDecoratedDescriptor(_class2.prototype, "size", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "sizeClass", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class);
  _exports.default = FormElementControlInput;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormElementControlInput);
});