define("ember-bootstrap/components/bs-accordion/item/title", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _templateFactory, _object, _component2, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- template-lint-disable no-nested-interactive --}}
  {{!-- @todo fix this, see https://github.com/kaliber5/ember-bootstrap/issues/999 --}}
  <div
    class="{{if @collapsed "collapsed" "expanded"}} {{if (macroCondition (macroGetOwnConfig "isNotBS3")) "card-header"}} {{if (macroCondition (macroGetOwnConfig "isBS3")) "panel-heading"}}"
    role="tab"
    ...attributes
    {{on "click" this.handleClick}}
  >
    {{#if (macroCondition (macroGetOwnConfig "isNotBS3"))}}
      <h5 class="mb-0">
        <button class="btn btn-link {{if @disabled "disabled"}}" type="button" disabled={{@disabled}}>
          {{yield}}
        </button>
      </h5>
    {{/if}}
    {{#if (macroCondition (macroGetOwnConfig "isBS3"))}}
      <h4 class="panel-title">
        <a href="#" class={{if @disabled "disabled"}}>
          {{yield}}
        </a>
      </h4>
    {{/if}}
  </div>
  */
  {
    "id": "eIEYDWJ/",
    "block": "[[[11,0],[16,0,[29,[[52,[30,1],\"collapsed\",\"expanded\"],\" \",\"card-header\",\" \",[27]]]],[24,\"role\",\"tab\"],[17,2],[4,[38,1],[\"click\",[30,0,[\"handleClick\"]]],null],[12],[1,\"\\n\"],[1,\"    \"],[10,\"h5\"],[14,0,\"mb-0\"],[12],[1,\"\\n      \"],[10,\"button\"],[15,0,[29,[\"btn btn-link \",[52,[30,3],\"disabled\"]]]],[15,\"disabled\",[30,3]],[14,4,\"button\"],[12],[1,\"\\n        \"],[18,4,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"@collapsed\",\"&attrs\",\"@disabled\",\"&default\"],false,[\"if\",\"on\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-accordion/item/title.hbs",
    "isStrictMode": false
  });

  let AccordionItemTitle =
  /**
   Component for an accordion item title.
  
   See [Components.Accordion](Components.Accordion.html) for examples.
  
   @class AccordionItemTitle
   @namespace Components
   @extends Glimmer.Component
   @public
   */
  (0, _deprecateSubclassing.default)(_class = (_class2 = class AccordionItemTitle extends _component2.default {
    /**
     * @property collapsed
     * @type boolean
     * @public
     */

    /**
     * @property disabled
     * @type boolean
     * @private
     */

    /**
     * @event onClick
     * @public
     */
    handleClick(e) {
      e.preventDefault();

      if (!this.args.disabled) {
        var _this$args$onClick, _this$args;

        (_this$args$onClick = (_this$args = this.args).onClick) === null || _this$args$onClick === void 0 ? void 0 : _this$args$onClick.call(_this$args);
      }
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "handleClick", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleClick"), _class2.prototype)), _class2)) || _class;

  _exports.default = AccordionItemTitle;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AccordionItemTitle);
});