define("ember-modal-dialog/components/liquid-tether-dialog", ["exports", "@ember/object", "@ember/string", "ember-modal-dialog/components/basic-dialog", "ember-modal-dialog/templates/components/liquid-tether-dialog"], function (_exports, _object, _string, _basicDialog, _liquidTetherDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basicDialog.default.extend({
    layout: _liquidTetherDialog.default,
    targetAttachmentClass: (0, _object.computed)('targetAttachment', function () {
      let targetAttachment = this.get('targetAttachment') || '';
      return `ember-modal-dialog-target-attachment-${(0, _string.dasherize)(targetAttachment)}`;
    }),
    targetAttachment: null,
    attachment: null,

    didReceiveAttrs() {
      this._super(...arguments);

      if (!this.get('attachment')) {
        this.set('attachment', 'middle center');
      }

      if (!this.get('targetAttachment')) {
        this.set('targetAttachment', 'middle center');
      }
    },

    tetherClassPrefix: (0, _object.computed)({
      get() {
        return 'liquid-tether';
      },

      set(key, val) {
        if (val) {
          return val;
        }

        return 'liquid-tether';
      }

    }),
    hasOverlay: true,
    tetherTarget: null // element, css selector, view instance, 'viewport', or 'scroll-handle'
    // offset - passed in
    // targetOffset - passed in
    // targetModifier - passed in

  });

  _exports.default = _default;
});