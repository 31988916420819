define("itarp-business-application/services/taxonomy", ["exports", "@ember/service", "@glimmer/tracking", "@ember/object", "@ember/object/computed"], function (_exports, _service, _tracking, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TaxonomyService = (_dec = (0, _computed.sort)('_categories', 'sortProperty'), _dec2 = (0, _computed.sort)('_skills', 'sortProperty'), _dec3 = (0, _computed.sort)('_tools', 'sortProperty'), (_class = class TaxonomyService extends _service.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "sortProperty", ['name']);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "taxonomy", _descriptor2, this);

      _initializerDefineProperty(this, "_categories", _descriptor3, this);

      _initializerDefineProperty(this, "_skills", _descriptor4, this);

      _initializerDefineProperty(this, "_tools", _descriptor5, this);

      _initializerDefineProperty(this, "categories", _descriptor6, this);

      _initializerDefineProperty(this, "skills", _descriptor7, this);

      _initializerDefineProperty(this, "tools", _descriptor8, this);
    }

    getTaxonomies() {
      if (!!this.taxonomy.length) return;

      const selectable = t => opt => opt.type === t && !opt.isCategory;

      this.store.query('taxonomies-category', {
        per_page: 'infinity'
      }).then(taxonomy => {
        this.set('taxonomy', taxonomy);
        this.set('_categories', taxonomy.filterBy('type', 'main_category'));
        this.set('_skills', taxonomy.filter(selectable('skill')));
        this.set('_tools', taxonomy.filter(selectable('tool')));
      });
    }

    create(option) {
      const {
        type,
        name
      } = option;

      if (option.id) {
        return this.store.createRecord('taxonomy', {
          name,
          type,
          taxonomyId: option.id,
          parent: option.get('parent.name')
        });
      }

      return this.store.createRecord('taxonomy', {
        name,
        type
      });
    }

    select(selected, option) {
      if (!option) return;
      const identifier = option.id ? 'taxonomyId' : 'name';
      const selectedIdentifier = option.id ? 'taxonomyId' : 'name';
      const taxonomy = selected.findBy(selectedIdentifier, option[identifier]);

      if (taxonomy) {
        selected.removeObject(taxonomy);
      } else {
        selected.pushObject(this.create(option));
      }
    }

    add(selected, taxonomy) {
      if (selected.isAny('taxonomyId', taxonomy.id)) return;
      selected.pushObject(this.create(taxonomy));
    }

    remove(selected, taxonomy) {
      const option = selected.findBy('taxonomyId', taxonomy.id);
      if (option) selected.removeObject(option);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "taxonomy", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_categories", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_skills", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_tools", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "categories", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "skills", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "tools", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getTaxonomies", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getTaxonomies"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "create", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "create"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "add", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype)), _class));
  _exports.default = TaxonomyService;
});