define("itarp-query-filters/utils/options/year-range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ranges = [[0, 2], [2, 4], [4, 6], [6, 8], [8, 10], [10, 15], [15, 20], [20]];

  const optValue = (gte, lte = null) => {
    return Object.freeze({
      gte,
      lte
    });
  };

  const defined = v => ![null, undefined].includes(v);

  const optLabel = ({
    gte,
    lte
  }) => {
    if (defined(gte) && defined(lte)) return `${gte}-${lte} Years`;
    return `${gte}+ Years`;
  };

  const option = item => {
    const value = optValue(...item);
    return Object.freeze({
      value,
      label: optLabel(value)
    });
  };

  const options = Object.freeze(ranges.map(option));
  var _default = options;
  _exports.default = _default;
});