define("itarp-components/components/ui/input/switch-group", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='form-switch-group {{if @oposite 'is-opposite'}}' ...attributes>
    {{#let (unique-id) as |uid|}}
      <div class='form-switch {{@class}}'>
        {{yield
          (hash
            input=(component 'input' type='checkbox' id=uid)
            label=(component 'ui/input/label' class='form-switch__label' for=uid)
          )
        }}
      </div>
      <span class='form-switch-group__label'>{{@label}}</span>
    {{/let}}
  </div>
  
  */
  {
    "id": "ALOoYCdo",
    "block": "[[[11,0],[16,0,[29,[\"form-switch-group \",[52,[30,1],\"is-opposite\"]]]],[17,2],[12],[1,\"\\n\"],[44,[[28,[37,2],null,null]],[[[1,\"    \"],[10,0],[15,0,[29,[\"form-switch \",[30,4]]]],[12],[1,\"\\n      \"],[18,6,[[28,[37,4],null,[[\"input\",\"label\"],[[50,\"input\",0,null,[[\"type\",\"id\"],[\"checkbox\",[30,3]]]],[50,\"ui/input/label\",0,null,[[\"class\",\"for\"],[\"form-switch__label\",[30,3]]]]]]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,1],[14,0,\"form-switch-group__label\"],[12],[1,[30,5]],[13],[1,\"\\n\"]],[3]]],[13],[1,\"\\n\"]],[\"@oposite\",\"&attrs\",\"uid\",\"@class\",\"@label\",\"&default\"],false,[\"if\",\"let\",\"unique-id\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-components/components/ui/input/switch-group.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});