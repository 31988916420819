define("itarp-talentcloud-ui/components/ui/modals/base/header", ["exports", "@ember/component/template-only"], function (_exports, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="app-modal-content__header" ...attributes>
    <h4 class="app-modal-content__header__title {{if @titleClass @titleClass}}">
      {{yield}}
    </h4>
    <a class="app-modal-content__header__close" {{on "click" @toggle}}></a>
  </div>
  */
  {
    "id": "B9CrX7JV",
    "block": "[[[11,0],[24,0,\"app-modal-content__header\"],[17,1],[12],[1,\"\\n  \"],[10,\"h4\"],[15,0,[29,[\"app-modal-content__header__title \",[52,[30,2],[30,2]]]]],[12],[1,\"\\n    \"],[18,4,null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[11,3],[24,0,\"app-modal-content__header__close\"],[4,[38,2],[\"click\",[30,3]],null],[12],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@titleClass\",\"@toggle\",\"&default\"],false,[\"if\",\"yield\",\"on\"]]",
    "moduleName": "itarp-talentcloud-ui/components/ui/modals/base/header.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});