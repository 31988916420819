define("itarp-business-application/routes/my-favorite-talentclouds", ["exports", "@ember/routing/route", "@ember/service", "rsvp"], function (_exports, _route, _service, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MyFavoritesRoute = (_dec = (0, _service.inject)(), _dec2 = (0, _service.inject)('ad-token-service'), (_class = class MyFavoritesRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        page: {
          refreshModel: true
        },
        talentCloudId: {
          refreshModel: true
        }
      });

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "adTokenService", _descriptor2, this);
    }

    async model(params) {
      const pageNumber = params.page;
      const id = this.adTokenService.tokenInformation.sub;
      let favorites = await this.store.query('user-favorite', {
        user_id: id,
        resource_type: 'talent_cloud',
        page: pageNumber ? pageNumber : 1,
        per_page: 12,
        adapterOptions: {
          talentCloudFavorites: true
        }
      });
      const applications = await this.store.query('application', {
        filter: {
          resource_type: 'talent_cloud',
          user_id: id
        },
        per_page: 'infinity'
      });
      const selected = params.talentCloudId ? favorites.findBy('id', params.talentCloudId) : null;
      return (0, _rsvp.hash)({
        favorites,
        selectedProject: selected ? selected : favorites.firstObject,
        applications
      });
    }

    setupController(controller, model) {
      const favorites = model.favorites;
      super.setupController(...arguments); // jobTitle is undefined for selected project. (opportunity). Setting jobTitle of favorite item

      const favoriteItem = model.selectedProject ? model.favorites.findBy('id', model.selectedProject.id) : null;

      if (favoriteItem) {
        model.selectedProject.jobTitle = favoriteItem.jobTitle;
      }

      const meta = model.favorites.get('meta');
      let hasMultiplePages = false;

      if (meta) {
        controller.set('pageMeta', meta);
        controller.set('total', meta.total);
        hasMultiplePages = meta.total > 12;
      }

      controller.set('hasMultiplePages', hasMultiplePages);
      controller.set('favorites', favorites);
      controller.set('noRecords', favorites.length === 0);
      controller.set('selectedProject', model.selectedProject);
      controller.set('talentCloudId', model.selectedProject ? model.selectedProject.id : null);
      controller.set('applications', model.applications);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "adTokenService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = MyFavoritesRoute;
});