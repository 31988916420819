define("itarp-opportunity-designer/components/opportunity-status", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/service", "itarp-shared-assets-addon/utils/string/first-letter-capitalize", "itarp-opportunity-designer/utils/constants/opportunity", "@ember/runloop", "itarp-opportunity-designer/utils/string/humanize"], function (_exports, _component, _templateFactory, _component2, _object, _tracking, _service, _firstLetterCapitalize, _opportunity, _runloop, _humanize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{this.status}}
  */
  {
    "id": "h0+RbPnu",
    "block": "[[[1,[30,0,[\"status\"]]]],[],false,[]]",
    "moduleName": "itarp-opportunity-designer/components/opportunity-status.hbs",
    "isStrictMode": false
  });

  let OpportunityStatusComponent = (_class = class OpportunityStatusComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "workflows", _descriptor, this);

      _initializerDefineProperty(this, "status", _descriptor2, this);

      this.loadOpportunityStatus();
    }

    get opportunity() {
      return this.args.opportunity;
    }

    get workflowId() {
      return this.opportunity.workflowApprovalId;
    }

    get stageId() {
      return this.opportunity.workflowApprovalStageId.toString();
    }

    loadOpportunityStatus() {
      var _this$opportunity$wor, _this$opportunity$wor2;

      const workflowApprovalId = this.opportunity.workflowApprovalId;
      const workflowApprovalStageId = (_this$opportunity$wor = this.opportunity.workflowApprovalStageId) === null || _this$opportunity$wor === void 0 ? void 0 : _this$opportunity$wor.toString();
      const workflowId = this.opportunity.workflowId;
      const workflowStageId = (_this$opportunity$wor2 = this.opportunity.workflowStageId) === null || _this$opportunity$wor2 === void 0 ? void 0 : _this$opportunity$wor2.toString();
      const isInRecruitmentWorkflow = this.opportunity.status === 'in_hiring_workflow';
      const isDraft = this.opportunity.status === _opportunity.DRAFT_STATUS;

      if (isDraft) {
        (0, _runloop.next)(() => {
          this.status = (0, _humanize.default)(this.opportunity.status);
        });
        this.isStatusResolved = true;
      } else if (workflowApprovalId && !this.isStatusResolved) {
        if (isInRecruitmentWorkflow) {
          this.workflows.findStageByStageId(workflowId, workflowStageId).then(stage => {
            if (stage) {
              (0, _runloop.next)(() => {
                this.status = (0, _firstLetterCapitalize.default)(stage.label);
                this.isStatusResolved = true;
              });
            }
          });
        } else {
          this.workflows.findStageByStageId(workflowApprovalId, workflowApprovalStageId).then(stage => {
            if (stage) {
              (0, _runloop.next)(() => {
                this.status = (0, _firstLetterCapitalize.default)(stage.stageType);
                this.isStatusResolved = true;
              });
            }
          });
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "workflows", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "status", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "loadOpportunityStatus", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadOpportunityStatus"), _class.prototype)), _class);
  _exports.default = OpportunityStatusComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, OpportunityStatusComponent);
});