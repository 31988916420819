define("itarp-opportunity-designer/controllers/opportunity/workflows", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/service", "itarp-opportunity-designer/utils/constants/opportunity"], function (_exports, _controller, _object, _tracking, _service, _opportunity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let WorkflowsController = (_dec = (0, _service.inject)('workflows'), (_class = class WorkflowsController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "workflowsService", _descriptor, this);

      _initializerDefineProperty(this, "workflow", _descriptor2, this);

      _initializerDefineProperty(this, "approvalWorkflow", _descriptor3, this);

      _initializerDefineProperty(this, "approvalWorkflowError", _descriptor4, this);
    }

    get isCompleteOpportunity() {
      return this.project.isJobDetailsComplete && this.project.isWorkArrangementComplete;
    }

    setWorkflow(workflow) {
      this.workflow = workflow;
      this.project.workflowId = workflow.id;
    }

    setApprovalWorkflow(workflow) {
      this.setApprovalStage(workflow);

      if (!this.approvalWorkflowError) {
        this.approvalWorkflow = workflow;
        this.project.workflowApprovalId = workflow.id;
      }
    }

    get approvalWorkflowEditable() {
      const status = this.project.status;
      const changes = this.project.changedAttributes();

      if (status === _opportunity.IN_APPROVAL_WORKFLOW_STATUS) {
        if (!changes.status) return false;
        return changes.status[0] === _opportunity.DRAFT_STATUS;
      }

      return status === _opportunity.DRAFT_STATUS;
    }

    get recruitingWorkflowEditable() {
      const isApproved = !!this.project.approvedAt || !!this.project.approvedBy;
      return !isApproved;
    }

    async beforeSave() {
      if (this.isCompleteOpportunity) {
        const opportunityApprovalId = this.project.workflowApprovalId;
        const opportunityApprovalStageId = this.project.workflowApprovalStageId;

        if (opportunityApprovalId && !opportunityApprovalStageId) {
          const approvalWorkflow = await this.workflowsService.businessWorkflow(opportunityApprovalId);
          await this.setApprovalStage(approvalWorkflow);
        }

        if (this.project.workflowId) return;
        const recruitingWorkflow = await this.workflowsService.defaultWorkflowFor('opportunity_recruiting');
        const approvalWorkflow = await this.workflowsService.defaultWorkflowFor('opportunity_approval');

        if (recruitingWorkflow) {
          this.project.workflowId = recruitingWorkflow.id;
        }

        if (approvalWorkflow && !opportunityApprovalId) {
          this.project.workflowApprovalId = approvalWorkflow.id;
          await this.setApprovalStage(approvalWorkflow);
        }
      }
    }

    findApprovalStage(workflow) {
      const groups = workflow.stageGroups;
      const stageType = 'pending_approval';
      const group = groups.findBy('stageGroupType', 'created');

      const findByStageType = sg => sg.stages.findBy('stageType', stageType);

      if (group) {
        const stage = findByStageType(group);
        if (stage) return stage;
      } else {
        return groups.reduce((stageFound, group) => {
          if (stageFound) return stageFound;
          const stage = findByStageType(group);
          if (stage) return stage;
          return stageFound;
        }, null);
      }
    }

    async setApprovalStage(workflow) {
      this.approvalWorkflowError = null;
      const stage = this.findApprovalStage(workflow);

      if (!stage) {
        this.approvalWorkflowError = 'No approval stage found';
        return;
      }

      this.project.workflowApprovalStageId = stage.id;
      this.project.status = _opportunity.IN_APPROVAL_WORKFLOW_STATUS;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "workflowsService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "workflow", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "approvalWorkflow", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "approvalWorkflowError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setWorkflow", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setWorkflow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setApprovalWorkflow", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setApprovalWorkflow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "beforeSave", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "beforeSave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "findApprovalStage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "findApprovalStage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setApprovalStage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setApprovalStage"), _class.prototype)), _class));
  _exports.default = WorkflowsController;
});