define("ember-a11y-accordion/components/accordion-panel", ["exports", "@ember/component", "@ember/template-factory", "ember-a11y-accordion/utils/dom", "@glimmer/component"], function (_exports, _component, _templateFactory, _dom, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <section id={{@id}} aria-hidden={{this.ariaHidden}} aria-labelledby={{this.ariaLabelledBy}} class={{this.className}} ...attributes>
    <div class="a11y-accordion-panel-content">
      {{yield}}
    </div>
  </section>
  
  */
  {
    "id": "EHb1quEr",
    "block": "[[[11,\"section\"],[16,1,[30,1]],[16,\"aria-hidden\",[30,0,[\"ariaHidden\"]]],[16,\"aria-labelledby\",[30,0,[\"ariaLabelledBy\"]]],[16,0,[30,0,[\"className\"]]],[17,2],[12],[1,\"\\n  \"],[10,0],[14,0,\"a11y-accordion-panel-content\"],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@id\",\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-a11y-accordion/components/accordion-panel.hbs",
    "isStrictMode": false
  });

  /**
   * The accordion-panel component contains the contents to be shown at any
   * given point. This component is toggled by the accordion-header.
   *
   * @param {String} [class] Any CSS classes to be added to the component's element
   */
  class AccordionPanelComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "className", _dom.CLASS_NAMES.panelWrapper);
    }

    get ariaHidden() {
      return this.args.isExpanded ? 'false' : 'true';
    }

    get ariaLabelledBy() {
      return this.args.triggerId || '';
    }

  }

  _exports.default = AccordionPanelComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AccordionPanelComponent);
});