define("itarp-opportunity-ui/utils/sortable-questions", ["exports", "@ember/array/proxy", "@ember/object/proxy", "@ember/object", "@ember/object/internals", "@glimmer/tracking"], function (_exports, _proxy, _proxy2, _object, _internals, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj, _init, _dec2, _obj2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const sortableMember = _proxy2.default.extend((_dec = (0, _object.computed)('questionnaire'), (_obj = {
    sortable: null,
    questionnaire: null,

    get loaded() {
      return this.get('questionnaire') !== null;
    },

    get uid() {
      return (0, _internals.guidFor)(this);
    },

    get index() {
      return this.sortable.indexOf(this);
    },

    get order() {
      return this.index;
    },

    next() {
      return this.sortable.objectAt(this.index + 1);
    },

    previous() {
      return this.sortable.objectAt(this.index - 1);
    }

  }, (_applyDecoratedDescriptor(_obj, "questionnaire", [_tracking.tracked], (_init = Object.getOwnPropertyDescriptor(_obj, "questionnaire"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function () {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "loaded", [_dec], Object.getOwnPropertyDescriptor(_obj, "loaded"), _obj)), _obj)));

  const sortableArray = _proxy.default.extend((_dec2 = (0, _object.computed)('content.@each.loaded'), (_obj2 = {
    content: [],

    init() {
      this._super(...arguments);

      this.content = this.content.sortBy('order').map((item, index) => {
        return sortableMember.create({
          sortable: this,
          content: item
        });
      });
    },

    get loaded() {
      return this.content.every(item => item.loaded);
    },

    moveUp: function (object) {
      const previous = object.previous();
      if (!previous) return;
      const index = previous.index;
      this.removeObject(object);
      this.insertAt(index, object);
    },
    moveDown: function (object) {
      const next = object.next();
      if (!next) return;
      const index = next.index;
      this.removeObject(object);
      this.insertAt(index, object);
    },

    add(object, questionnaire) {
      const member = sortableMember.create({
        sortable: this,
        content: object,
        questionnaire: questionnaire
      });
      this.pushObject(member);
    },

    remove(object) {
      this.removeObject(object);
    }

  }, (_applyDecoratedDescriptor(_obj2, "loaded", [_dec2], Object.getOwnPropertyDescriptor(_obj2, "loaded"), _obj2), _applyDecoratedDescriptor(_obj2, "add", [_object.action], Object.getOwnPropertyDescriptor(_obj2, "add"), _obj2), _applyDecoratedDescriptor(_obj2, "remove", [_object.action], Object.getOwnPropertyDescriptor(_obj2, "remove"), _obj2)), _obj2)));

  var _default = sortableArray;
  _exports.default = _default;
});