define("itarp-opportunity-designer/controllers/details-public/overview-loading", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DetailsPublicOverviewLoadingController extends _controller.default {}

  _exports.default = DetailsPublicOverviewLoadingController;
});