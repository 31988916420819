define("itarp-query-filters/services/route-adapters", ["exports", "@ember/service", "itarp-query-filters/utils/adapter/route"], function (_exports, _service, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class AdapterService extends _service.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "adapters", new Map());
    }

    get(route) {
      if (this.has(route)) return this.adapters.get(route.fullRouteName);
      return this.add(route);
    }

    has(route) {
      return this.adapters.has(route.fullRouteName);
    }

    add(route) {
      this.adapters.set(route.fullRouteName, new _route.default(route));
      return this.get(route);
    }

  }

  _exports.default = AdapterService;
});