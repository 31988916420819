define("itarp-opportunity-designer/routes/edit-opportunity/work-arrangement", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class EditOpportunityWorkArrangementRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "templateName", 'opportunity.work-arrangement');
    }

    model() {
      return this.modelFor('edit-opportunity');
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.set('project', model);
      const parentController = this.controllerFor('edit-opportunity');
      parentController.set('beforeSave', controller.save);
      parentController.set('afterSave', controller.afterSave);
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('targetDaysToFillError', null);
      }
    }

  }

  _exports.default = EditOpportunityWorkArrangementRoute;
});