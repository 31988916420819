define("itarp-business-application/components/sidebar", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/object/computed", "@ember/runloop"], function (_exports, _component, _tracking, _object, _computed, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class="sidebar {{if this.isCollapsed "is-collapsed"}}">
    <div class="sidebar__content">
      <a
        data-test-collapse-toggle
        class="sidebar__collapse"
        type="button"
        {{on "click" this.collapse}}
      ></a>
      <div class="sidebar__component">
        <Sidebar::List @items={{this.items}} @isCollapsed={{this.isCollapsed}} />
      </div>
    </div>
  </section>
  */
  {
    "id": "dVa6Z6Vx",
    "block": "[[[10,\"section\"],[15,0,[29,[\"sidebar \",[52,[30,0,[\"isCollapsed\"]],\"is-collapsed\"]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"sidebar__content\"],[12],[1,\"\\n    \"],[11,3],[24,\"data-test-collapse-toggle\",\"\"],[24,0,\"sidebar__collapse\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"collapse\"]]],null],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"sidebar__component\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@items\",\"@isCollapsed\"],[[30,0,[\"items\"]],[30,0,[\"isCollapsed\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"if\",\"on\",\"sidebar/list\"]]",
    "moduleName": "itarp-business-application/components/sidebar.hbs",
    "isStrictMode": false
  });

  let SidebarComponent = (_class = class SidebarComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "isCollapsed", _descriptor, this);

      _defineProperty(this, "items", [{
        label: 'Profile',
        icon: 'for-profile',
        path: '',
        items: [{
          label: 'Edit Profile',
          icon: '',
          path: 'profile.autofill'
        }, {
          label: 'View Profile',
          icon: '',
          path: 'profile-preview'
        }]
      }, {
        label: 'TalentClouds',
        icon: 'for-talentclouds',
        path: '',
        items: [{
          label: 'Browse TalentClouds',
          icon: '',
          path: 'itarp-talentcloud-ui'
        }, {
          label: 'My TalentClouds',
          icon: '',
          path: 'itarp-talentcloud-ui.my-clouds'
        }, {
          label: 'Invites',
          icon: '',
          path: 'itarp-talentcloud-ui.invites'
        }]
      }, {
        label: 'Opportunities',
        icon: 'for-opportunities',
        path: '',
        items: [{
          label: 'Browse Opportunities',
          icon: '',
          path: 'itarp-opportunity-ui.browse'
        }, {
          label: 'My Opportunities',
          icon: '',
          path: 'itarp-opportunity-ui.my-opportunities'
        }, {
          label: 'Invites',
          icon: '',
          path: 'itarp-opportunity-ui.invites'
        }, {
          label: 'Scheduled Interviews',
          icon: '',
          path: ''
        }]
      }]);

      if (this.args.sidebarCollapsed) {
        this.isCollapsed = true;
      }
    }

    collapse() {
      this.args.toggleSidebar();
      this.isCollapsed = !this.isCollapsed;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isCollapsed", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "collapse", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "collapse"), _class.prototype)), _class);
  _exports.default = SidebarComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SidebarComponent);
});