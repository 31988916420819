define("itarp-workflow-designer/templates/workflow/create", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "lO9UKJGj",
    "block": "[[[10,\"header\"],[14,0,\"designer__content__header\"],[12],[1,\"\\n  \"],[10,\"h3\"],[14,0,\"designer__content__header__title\"],[12],[1,\"Create New Workflow\"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"designer__content__body\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@workflow\",\"@onBlur\",\"@workflowValidationResult\",\"@workflowTemplates\",\"@saveWorkflow\",\"@cancelEditWorkflow\",\"@workflowCategories\",\"@selectWorkflowType\",\"@configurableWorkflowType\",\"@colWidth\"],[[30,0,[\"workflow\"]],[30,0,[\"validateWorkflow\"]],[30,0,[\"workflowValidationResult\"]],[30,0,[\"filteredTemplate\"]],[30,0,[\"saveWorkflow\"]],[30,0,[\"cancelEditWorkflow\"]],[30,0,[\"workflowCategories\"]],[30,0,[\"selectWorkflowType\"]],true,\"9\"]],null],[1,\"\\n\"],[13]],[],false,[\"workflow-editor\"]]",
    "moduleName": "itarp-workflow-designer/templates/workflow/create.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});