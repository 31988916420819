define("itarp-opportunity-ui/transforms/sortable", ["exports", "@ember-data/serializer/transform", "itarp-opportunity-ui/utils/sortable-questions"], function (_exports, _transform, _sortableQuestions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SortableTransform extends _transform.default {
    deserialize(serialized, options) {
      const content = serialized || [];
      return _sortableQuestions.default.create({
        content: content
      });
    }

    serialize(deserialized, options) {
      return deserialized.serialize();
    }

  }

  _exports.default = SortableTransform;
});