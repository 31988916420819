define("itarp-template-designer/mirage/endpoints/tokens", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(context) {
    context.get('tokens');
    context.get('tokens/:id');
  }
});