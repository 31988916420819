define("shared/components/icons/loader", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    class="whole"
    width={{this.width}}
    height={{this.height}}
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="loader">
      <animateTransform
        xlink:href="#loader"
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        from="0 50 50"
        to="360 50 50"
        dur="1s"
        begin="0s"
        repeatCount="indefinite"
        restart="always"
      ></animateTransform>
      <path
        class="a"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100ZM50 90C72.0914 90 90 72.0914 90 50C90 27.9086 72.0914 10 50 10C27.9086 10 10 27.9086 10 50C10 72.0914 27.9086 90 50 90Z"
        fill="#d1dbe3"
      ></path>
      <path
        class="b"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M100 50C100 22.3858 77.6142 0 50 0V10C72.0914 10 90 27.9086 90 50H100Z"
        fill="#4ba4da"
      ></path>
    </g>
  </svg>
  */
  {
    "id": "To1ugI38",
    "block": "[[[10,\"svg\"],[14,0,\"whole\"],[15,\"width\",[30,0,[\"width\"]]],[15,\"height\",[30,0,[\"height\"]]],[14,\"viewBox\",\"0 0 100 100\"],[14,\"fill\",\"none\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"g\"],[14,1,\"loader\"],[12],[1,\"\\n    \"],[10,\"animateTransform\"],[14,\"xlink:href\",\"#loader\",\"http://www.w3.org/1999/xlink\"],[14,\"attributeName\",\"transform\"],[14,\"attributeType\",\"XML\"],[14,\"from\",\"0 50 50\"],[14,\"to\",\"360 50 50\"],[14,\"dur\",\"1s\"],[14,\"begin\",\"0s\"],[14,\"repeatCount\",\"indefinite\"],[14,\"restart\",\"always\"],[14,4,\"rotate\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,0,\"a\"],[14,\"fill-rule\",\"evenodd\"],[14,\"clip-rule\",\"evenodd\"],[14,\"d\",\"M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100ZM50 90C72.0914 90 90 72.0914 90 50C90 27.9086 72.0914 10 50 10C27.9086 10 10 27.9086 10 50C10 72.0914 27.9086 90 50 90Z\"],[14,\"fill\",\"#d1dbe3\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,0,\"b\"],[14,\"fill-rule\",\"evenodd\"],[14,\"clip-rule\",\"evenodd\"],[14,\"d\",\"M100 50C100 22.3858 77.6142 0 50 0V10C72.0914 10 90 27.9086 90 50H100Z\"],[14,\"fill\",\"#4ba4da\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "shared/components/icons/loader.hbs",
    "isStrictMode": false
  });

  class IconsLoaderComponent extends _component.default {
    get height() {
      return this.args.height || '100';
    }

    get width() {
      return this.args.width || '100';
    }

  }

  _exports.default = IconsLoaderComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, IconsLoaderComponent);
});