define("itarp-opportunity-designer/helpers/opportunity/educations-list", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const educations = ['Bachelors', 'Masters', 'Ph.D.', 'J.D.', 'M.D.', 'D.O', 'MBA', 'PharmaD', 'High School', 'Associate'];

  var _default = (0, _helper.helper)(function opportunityEducationsList() {
    return educations;
  });

  _exports.default = _default;
});