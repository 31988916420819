define("itarp-talentcloud-ui/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    // Define your engine's route map here
    this.route('browse', {
      path: ''
    });
    this.route('my-clouds', {
      path: '/my-talentclouds'
    });
    this.route('invites', {
      path: '/invites'
    });
    this.route('details', {
      path: '/:id/details'
    });
  });

  _exports.default = _default;
});