define("itarp-opportunity-ui/components/ui/offscreens/base/body", ["exports", "@ember/component/template-only"], function (_exports, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="offscreen__body" ...attributes>
    {{yield}}
    {{#if @showSignature}}
      <div class="offscreen__body__signature">
        <span class="offscreen__body__signature__text">Powered by Experfy</span>
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "C7B0wpqE",
    "block": "[[[11,0],[24,0,\"offscreen__body\"],[17,1],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[10,0],[14,0,\"offscreen__body__signature\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"offscreen__body__signature__text\"],[12],[1,\"Powered by Experfy\"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"&attrs\",\"@showSignature\",\"&default\"],false,[\"yield\",\"if\"]]",
    "moduleName": "itarp-opportunity-ui/components/ui/offscreens/base/body.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});