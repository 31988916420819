define("itarp-business-application/components/sidebar/list", ["exports", "@ember/component/template-only"], function (_exports, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ul class="sidebar__links">
    {{#each @items as |item|}}
      <Sidebar::ListItem @item={{item}} @isCollapsed={{@isCollapsed}} />
    {{/each}}
  </ul>
  */
  {
    "id": "ewKbWAJh",
    "block": "[[[10,\"ul\"],[14,0,\"sidebar__links\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@item\",\"@isCollapsed\"],[[30,2],[30,3]]],null],[1,\"\\n\"]],[2]],null],[13]],[\"@items\",\"item\",\"@isCollapsed\"],false,[\"each\",\"-track-array\",\"sidebar/list-item\"]]",
    "moduleName": "itarp-business-application/components/sidebar/list.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});