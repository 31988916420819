define("itarp-opportunity-ui/controllers/my-opportunities", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/object", "@ember/service", "itarp-opportunity-ui/utils/opportunity"], function (_exports, _controller, _tracking, _object, _service, _opportunity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MyOpportunitiesController = (_dec = (0, _service.inject)('ad-token-service'), (_class = class MyOpportunitiesController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "layout", _descriptor2, this);

      _initializerDefineProperty(this, "adTokenService", _descriptor3, this);

      _initializerDefineProperty(this, "practice_areas", _descriptor4, this);

      _initializerDefineProperty(this, "isApplied", _descriptor5, this);

      _initializerDefineProperty(this, "opportunityId", _descriptor6, this);

      _initializerDefineProperty(this, "applications", _descriptor7, this);

      _initializerDefineProperty(this, "questions", _descriptor8, this);

      _initializerDefineProperty(this, "fetchingApplications", _descriptor9, this);

      _initializerDefineProperty(this, "newHourlyRate", _descriptor10, this);

      _initializerDefineProperty(this, "applyOffscreen", _descriptor11, this);

      _initializerDefineProperty(this, "fetchingQuestions", _descriptor12, this);

      _initializerDefineProperty(this, "errors", _descriptor13, this);

      _initializerDefineProperty(this, "keywordTagComponent", _descriptor14, this);

      _initializerDefineProperty(this, "type", _descriptor15, this);

      _defineProperty(this, "queryParams", ['expertApplies', 'practice_areas', 'keywords', 'page', 'open_to_remote', 'job_type', 'location', 'type']);

      _defineProperty(this, "page", _opportunity.DEFAULT_PAGE_NUMBER);

      _defineProperty(this, "expertApplies", null);

      _defineProperty(this, "job_type", null);

      _defineProperty(this, "keywords", null);

      _defineProperty(this, "location", null);

      _defineProperty(this, "open_to_remote", null);

      _defineProperty(this, "skills", null);
    }

    updateHourlyRate(val) {
      this.newHourlyRate = val;
    }

    registerOffscreenAPI(options) {
      this.applyOffscreen = options;
    }

    apply(submitModal) {
      const project = this.projects.findBy('id', this.opportunityId);
      this.application.resourceId = project.id;
      this.application.resourceType = 'opportunity';
      this.application.name = this.user.fullName;
      this.application.email = this.user.email;
      this.application.userId = this.user.id;
      this.application.companyId = this.companyId;

      if (this.newHourlyRate) {
        this.user.profile.hourlyRate = this.newHourlyRate;
        this.user.save();
      }

      this.application.save().then(() => {
        this.applyOffscreen.close();
        this.getApplications(this.opportunityId);
        submitModal.open();
        this.application = this.store.createRecord('application');

        if (this.user.profiles.get('resume')) {
          this.application.set('resume', this.user.profiles.get('resume'));
        }

        if (this.user.profiles.get('linkedinUrl')) {
          this.application.set('linkedinUrl', this.user.profiles.get('linkedinUrl'));
        }
      });
    }

    getApplications(opportunityId) {
      if (opportunityId && !this.fetchingApplications) {
        this.fetchingApplications = true;
        const userId = this.user && this.user.get('id') ? this.user.get('id') : this.adTokenService.tokenInformation.sub;
        this.store.query('application', {
          filter: {
            resource_id: opportunityId,
            resource_type: 'opportunity',
            user_id: userId
          }
        }).then(applications => {
          this.applications = applications;
          this.fetchingApplications = false;
        });
      }
    }

    async getQuestions(opportunityId) {
      if (opportunityId && !this.fetchingQuestions) {
        this.fetchingQuestions = true;
        await this.store.query('questionnaire', {
          filter: {
            resource_id: opportunityId,
            resource_type: 'opportunity'
          }
        }).then(questions => {
          for (const item of this.selectedProject.questions.toArray()) {
            const question = questions.findBy('id', item.get('questionnaire_id'));
            if (question) item.questionnaire = question;
          }

          this.questions = this.selectedProject.questions || [];
          this.fetchingQuestions = false;
        });
      }
    }

    changeOpportunity(opportunityId) {
      if (this.opportunityId === opportunityId) return;
      this.opportunityId = opportunityId;
      (0, _object.set)(this, 'selectedProject', this.projects.findBy('opportunityId', opportunityId));

      if (this.selectedProject) {
        this.getQuestions(opportunityId);
        this.getApplications(opportunityId);
      }

      if (this.applyOffscreen && this.applyOffscreen.isOpen) {
        this.applyOffscreen.close();
      }

      this.applicationErrors = [];
    }

    get isApplied() {
      if (this.opportunityId && this.applications) {
        return this.applications.length > 0;
      }

      return false;
    }

    onSelectPracticeAreas(practiceAreas) {
      this.selectedPracticeAreas = practiceAreas;
      this.practice_areas = practiceAreas.map(pa => pa.id);
    }

    updateUserFavorites(updatedUserFavorites) {
      (0, _object.set)(this, 'userFavorites', updatedUserFavorites);
    }

    listOpportunities(event) {
      event.preventDefault();
      this.type = 'opportunity';
    }

    listProjects(event) {
      event.preventDefault();
      this.type = 'project';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "layout", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "adTokenService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "practice_areas", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isApplied", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "opportunityId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "applications", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "questions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "fetchingApplications", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "newHourlyRate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "applyOffscreen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "fetchingQuestions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "errors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "keywordTagComponent", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "type", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'opportunity';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateHourlyRate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateHourlyRate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerOffscreenAPI", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerOffscreenAPI"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "apply", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "apply"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getApplications", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getApplications"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getQuestions", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getQuestions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeOpportunity", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeOpportunity"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectPracticeAreas", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectPracticeAreas"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateUserFavorites", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateUserFavorites"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "listOpportunities", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "listOpportunities"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "listProjects", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "listProjects"), _class.prototype)), _class));
  _exports.default = MyOpportunitiesController;
});