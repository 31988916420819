define("shared/adapters/page", ["exports", "itarp-auth-addon/adapters/ad-token-adapter"], function (_exports, _adTokenAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class PageAdapter extends _adTokenAdapter.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", '/api/expert/routing/config');
    }

  }

  _exports.default = PageAdapter;
});