define("@love-open-source/ember-slider/templates/components/slider-handle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dhpcc/cZ",
    "block": "[[[10,0],[14,0,\"handle-cover\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"slider-value\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"value-mover\"],[12],[1,\"\\n        \"],[1,[30,1]],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"@value\"],false,[]]",
    "moduleName": "@love-open-source/ember-slider/templates/components/slider-handle.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});