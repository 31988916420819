define('ember-ignore-children-helper/helpers/ignore-children', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ignoreChildren = ignoreChildren;
  function ignoreChildren([nextHandler]) {
    return function (...args) {
      let event = args[args.length - 1];
      if (event && event.target === event.currentTarget) {
        nextHandler.apply(this, args);
      }
    };
  }

  exports.default = Ember.Helper.helper(ignoreChildren);
});