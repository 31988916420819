define("itarp-workflow-designer/components/error-message", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showError}}
      <span class="form-alert__content no-left-padding">{{this.validationError}}</span>
  {{/if}}
  
  */
  {
    "id": "0s0ag60K",
    "block": "[[[41,[30,0,[\"showError\"]],[[[1,\"    \"],[10,1],[14,0,\"form-alert__content no-left-padding\"],[12],[1,[30,0,[\"validationError\"]]],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\"]]",
    "moduleName": "itarp-workflow-designer/components/error-message.hbs",
    "isStrictMode": false
  });

  class ErrorMessageComponent extends _component2.default {
    get showError() {
      const validationResult = this.args.validationResult;
      const attribute = this.args.attribute;
      return validationResult && validationResult.errors && !!validationResult.errors[attribute];
    }

    get validationError() {
      const validationResult = this.args.validationResult;
      const attribute = this.args.attribute;
      return validationResult.errors[attribute];
    }

  }

  _exports.default = ErrorMessageComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ErrorMessageComponent);
});