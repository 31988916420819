define("ember-engines-router-service/utils/resembles-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.resemblesURL = resemblesURL;

  /**
   * Check if a routeName resembles a url.
   *
   * @public
   * @method namespaceEngineRouteName
   * @param {String} str
   * @return {boolean}
   */
  function resemblesURL(str) {
    return typeof str === 'string' && (str === '' || str[0] === '/');
  }
});