define("itarp-business-application/components/heading", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/object/computed", "@ember/template"], function (_exports, _component, _tracking, _object, _computed, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (element this.elementName) as |Heading|}}
    <div class="component-wrapper">
      {{#if (not (eq this.icon "none"))}}
        <Heading
          class="text--{{this.textTransform}}
            {{if this.icon "text-icon text-icon--{{this.icon}}"}}"
          style="color: {{this.textColor}}"
        >
          {{this.attributes.content}}
          {{yield}}
        </Heading>
      {{else}}
        <Heading
          class="text--{{this.textTransform}}"
          style="color: {{this.textColor}}"
        >
          {{this.attributes.content}}
          {{yield}}
        </Heading>
      {{/if}}
    </div>
  {{/let}}
  */
  {
    "id": "buEzKQht",
    "block": "[[[44,[[28,[37,1],[[30,0,[\"elementName\"]]],null]],[[[1,\"  \"],[10,0],[14,0,\"component-wrapper\"],[12],[1,\"\\n\"],[41,[28,[37,3],[[28,[37,4],[[30,0,[\"icon\"]],\"none\"],null]],null],[[[1,\"      \"],[8,[30,1],[[16,0,[29,[\"text--\",[30,0,[\"textTransform\"]],\"\\n          \",[52,[30,0,[\"icon\"]],\"text-icon text-icon--{{this.icon}}\"]]]],[16,5,[29,[\"color: \",[30,0,[\"textColor\"]]]]]],null,[[\"default\"],[[[[1,\"\\n        \"],[1,[30,0,[\"attributes\",\"content\"]]],[1,\"\\n        \"],[18,2,null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[30,1],[[16,0,[29,[\"text--\",[30,0,[\"textTransform\"]]]]],[16,5,[29,[\"color: \",[30,0,[\"textColor\"]]]]]],null,[[\"default\"],[[[[1,\"\\n        \"],[1,[30,0,[\"attributes\",\"content\"]]],[1,\"\\n        \"],[18,2,null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[1]]]],[\"Heading\",\"&default\"],false,[\"let\",\"element\",\"if\",\"not\",\"eq\",\"yield\"]]",
    "moduleName": "itarp-business-application/components/heading.hbs",
    "isStrictMode": false
  });

  let HeadingComponent = (_dec = (0, _computed.alias)('args.config.attributes'), _dec2 = (0, _object.computed)('attributes.content'), _dec3 = (0, _object.computed)('attributes.transform'), _dec4 = (0, _object.computed)('attributes.color'), _dec5 = (0, _object.computed)('attributes.icon'), (_class = class HeadingComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "attributes", _descriptor, this);

      _initializerDefineProperty(this, "classList", _descriptor2, this);
    }

    get elementName() {
      return this.attributes.size ? `h${this.attributes.size}` : 'h6';
    }

    get content() {
      return (0, _template.htmlSafe)(this.attributes.content);
    }

    get textTransform() {
      return this.attributes.transform || '';
    }

    get textColor() {
      return this.attributes.color || '';
    }

    get icon() {
      return this.attributes.icon || '';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "attributes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "classList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "content", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "content"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "textTransform", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "textTransform"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "textColor", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "textColor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "icon", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "icon"), _class.prototype)), _class));
  _exports.default = HeadingComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, HeadingComponent);
});