define("ember-bootstrap/components/bs-form/element/layout/inline", ["exports", "ember-bootstrap/components/bs-form/element/layout/vertical"], function (_exports, _vertical) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
   @class FormElementLayoutInline
   @namespace Components
   @extends Components.FormElementLayout
   @private
   */
  class FormElementLayoutInline extends _vertical.default {}

  _exports.default = FormElementLayoutInline;
});