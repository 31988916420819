define("itarp-expert-preview/components/scorecard-dropdown", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@glimmer/tracking", "@ember/object", "@ember/template"], function (_exports, _component, _templateFactory, _component2, _service, _tracking, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (and this.url this.scope this.params this.scorecardParams.resourceId)}}
    <RemoteFrontend
      @url={{this.settings.url}}
      @scope={{this.settings.scope}}
      @params={{this.settings.params}}
      @moduleName={{'./appContainer'}}
      @fallback={{this.fallbackPlaceholder}}
    />
  {{/if}}
  */
  {
    "id": "zg3+HhUD",
    "block": "[[[41,[28,[37,1],[[30,0,[\"url\"]],[30,0,[\"scope\"]],[30,0,[\"params\"]],[30,0,[\"scorecardParams\",\"resourceId\"]]],null],[[[1,\"  \"],[8,[39,2],null,[[\"@url\",\"@scope\",\"@params\",\"@moduleName\",\"@fallback\"],[[30,0,[\"settings\",\"url\"]],[30,0,[\"settings\",\"scope\"]],[30,0,[\"settings\",\"params\"]],\"./appContainer\",[30,0,[\"fallbackPlaceholder\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"and\",\"remote-frontend\"]]",
    "moduleName": "itarp-expert-preview/components/scorecard-dropdown.hbs",
    "isStrictMode": false
  });

  let ScorecardDropdownComponent = (_dec = (0, _service.inject)('micro-frontend'), (_class = class ScorecardDropdownComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "microFrontend", _descriptor, this);

      _initializerDefineProperty(this, "settings", _descriptor2, this);

      this.setupDropdownComponent();
    }

    get url() {
      return this.settings.url;
    }

    get scope() {
      return this.settings.scope;
    }

    get params() {
      return this.settings.params;
    }

    get scorecardParams() {
      return this.args.scorecardParams;
    }

    get fallbackPlaceholder() {
      return (0, _template.htmlSafe)('<a class="btn-square btn-square--dark btn-square--score"></a>');
    }

    setupDropdownComponent() {
      const name = 'scorecards';
      const path = '';
      this.settings = this.microFrontend.configFor({
        name,
        path
      });
      this.params.add({
        exactRoute: 'evaluate',
        candidateId: this.scorecardParams.candidateId,
        resourceType: this.scorecardParams.resourceType,
        resourceName: this.scorecardParams.resourceName,
        scorecardIds: this.scorecardParams.scorecardIds,
        resourceId: this.scorecardParams.resourceId
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "microFrontend", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "settings", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupDropdownComponent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupDropdownComponent"), _class.prototype)), _class));
  _exports.default = ScorecardDropdownComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ScorecardDropdownComponent);
});