define("shared/utils/transform/range", ["exports", "shared/utils/transform/query", "@glimmer/tracking", "@ember/object", "@ember/utils", "shared/utils/parse"], function (_exports, _query, _tracking, _object, _utils, _parse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // range value: { start: number, end: number }
  // range serialized: { gte: range.value.start, lte: range.value.end }
  let RangeTransform = (_class = class RangeTransform extends _query.default {
    constructor(value) {
      super(...arguments);

      _initializerDefineProperty(this, "value", _descriptor, this);

      if (this.validFormat(value)) this.value = value;
    }

    validFormat(value) {
      return (0, _utils.typeOf)(value) === 'object' && 'gte' in value;
    }

    serializeQuery() {
      if ((0, _utils.typeOf)(this.value) === 'object') {
        const lt = this.value.lte ? `l:${this.value.lte}` : null;
        const gt = this.value.gte ? `g:${this.value.gte}` : null;

        if (this.value.gte || this.value.lte) {
          return [gt, lt].compact().join(',');
        }
      }
    }

    serializeSearch(value) {
      this.deserialize(value);

      if ((0, _utils.typeOf)(this.value) === 'object') {
        if (this.value.gte || this.value.lte) {
          return JSON.stringify({
            gte: this.value.gte,
            lte: this.value.lte
          });
        }
      }
    }

    deserialize(value) {
      if (value) {
        const res = {};
        value.split(',').forEach(v => {
          const val = v.split(':');

          if (val[0] === 'g') {
            res.gte = val[1];
          } else if (val[0] === 'l') {
            res.lte = val[1];
          }
        });
        if (res.gte || res.lte) this.value = res;
      }
    } // @action
    // serialize() {
    //   if (typeOf(this.value) === 'object') {
    //     if (this.value.gte || this.value.lte) {
    //       return JSON.stringify({ gte: this.value.gte, lte: this.value.lte });
    //     }
    //   }
    // }
    // @action
    // deserialize(value) {
    //   const parsed = parse(value);
    //   if (typeOf(parsed) === 'object' && ('gte' in parsed || 'lte' in parsed)) {
    //     const { gte, lte } = parsed;
    //     this.value = {
    //       gte,
    //       lte,
    //     };
    //   }
    // }


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "validFormat", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validFormat"), _class.prototype)), _class); // serializeQuery() {
  //   if (typeOf(this.value) === 'object') {
  //     const lt = this.value.lte ? `l:${this.value.lte}` : null;
  //     const gt = this.value.gte ? `g:${this.value.gte}` : null;
  //     if (this.value.gte || this.value.lte) {
  //       return [gt, lt].compact().join(',')
  //     }
  //   }
  // }
  // serializeSearch() {
  //   if (typeOf(this.value) === 'object') {
  //     if (this.value.gte || this.value.lte) {
  //       return JSON.stringify({ gte: this.value.gte, lte: this.value.lte });
  //     }
  //   }
  // }
  // deserialize(value) {
  //   if(value) {
  //     const res = {};
  //     value.split(',').forEach(((v)=> {
  //       const val = v.split(':');
  //       if(val[0] === 'g') {
  //         res.gte = val[1]
  //       } else if(val[0] === 'l') {
  //         res.lte = val[1]
  //       }
  //     }))
  //   }
  // }

  _exports.default = RangeTransform;
});