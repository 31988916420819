define("shared/components/templates/filter-layout/filters", ["exports", "@ember/component/template-only"], function (_exports, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class="designer__body__filters
      {{if @expanded "is-expanded" "is-collapsed"}}
      {{if (and @fixed @expanded) "is-fixed"}}
      {{if (and @fixed (and @sidebar @expanded)) "is-fixed-collapsed"}}"
    ...attributes
  >
    <Ui::Filters @toggle={{@toggle}} as |filters|>
      {{yield filters}}
    </Ui::Filters>
  </div>
  */
  {
    "id": "FCGGWVmG",
    "block": "[[[11,0],[16,0,[29,[\"designer__body__filters\\n    \",[52,[30,1],\"is-expanded\",\"is-collapsed\"],\"\\n    \",[52,[28,[37,1],[[30,2],[30,1]],null],\"is-fixed\"],\"\\n    \",[52,[28,[37,1],[[30,2],[28,[37,1],[[30,3],[30,1]],null]],null],\"is-fixed-collapsed\"]]]],[17,4],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@toggle\"],[[30,5]]],[[\"default\"],[[[[1,\"\\n    \"],[18,7,[[30,6]]],[1,\"\\n  \"]],[6]]]]],[1,\"\\n\"],[13]],[\"@expanded\",\"@fixed\",\"@sidebar\",\"&attrs\",\"@toggle\",\"filters\",\"&default\"],false,[\"if\",\"and\",\"ui/filters\",\"yield\"]]",
    "moduleName": "shared/components/templates/filter-layout/filters.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});