define("itarp-components/components/ui/filters", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='filters-collapse'>
    <span class='filters-collapse__icon'></span>
    <a class='filters-collapse__link' {{on 'click' this.toggle}}></a>
  </div>
  <div class='filter-blocks'>
    {{yield (hash block=(component 'ui/filters/block'))}}
  </div>
  
  */
  {
    "id": "6yC4x2FV",
    "block": "[[[10,0],[14,0,\"filters-collapse\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"filters-collapse__icon\"],[12],[13],[1,\"\\n  \"],[11,3],[24,0,\"filters-collapse__link\"],[4,[38,0],[\"click\",[30,0,[\"toggle\"]]],null],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"filter-blocks\"],[12],[1,\"\\n  \"],[18,1,[[28,[37,2],null,[[\"block\"],[[50,\"ui/filters/block\",0,null,null]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"on\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-components/components/ui/filters.hbs",
    "isStrictMode": false
  });

  let UiFiltersComponent = (_class = class UiFiltersComponent extends _component2.default {
    toggle() {
      if (this.args.toggle) this.args.toggle();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "toggle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype)), _class);
  _exports.default = UiFiltersComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiFiltersComponent);
});