define("itarp-components/components/ui/input/input-group", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='input-group {{if @required 'is-required'}}' ...attributes>
    {{#let (unique-id) as |uid|}}
      {{yield
        (hash
          label=(component
            'ui/input/label' class='input-group__label' for=uid required=@required
          )
          input=(component 'input' id=uid class='input-group__field')
        )
      }}
    {{/let}}
  </div>
  
  */
  {
    "id": "ZPl0LAHx",
    "block": "[[[11,0],[16,0,[29,[\"input-group \",[52,[30,1],\"is-required\"]]]],[17,2],[12],[1,\"\\n\"],[44,[[28,[37,2],null,null]],[[[1,\"    \"],[18,4,[[28,[37,4],null,[[\"label\",\"input\"],[[50,\"ui/input/label\",0,null,[[\"class\",\"for\",\"required\"],[\"input-group__label\",[30,3],[30,1]]]],[50,\"input\",0,null,[[\"id\",\"class\"],[[30,3],\"input-group__field\"]]]]]]]],[1,\"\\n\"]],[3]]],[13],[1,\"\\n\"]],[\"@required\",\"&attrs\",\"uid\",\"&default\"],false,[\"if\",\"let\",\"unique-id\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-components/components/ui/input/input-group.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});