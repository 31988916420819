define("itarp-template-designer/models/template", ["exports", "@ember-data/model", "@ember/template", "@ember/array"], function (_exports, _model, _template, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TemplateModel = (_dec = (0, _model.attr)(), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('boolean', {
    defaultValue: true
  }), _dec12 = (0, _model.attr)('boolean', {
    defaultValue: true
  }), _dec13 = (0, _model.attr)('number'), _dec14 = (0, _model.attr)('string', {
    defaultValue: 'inactive'
  }), _dec15 = (0, _model.attr)('number', {
    defaultValue: 1
  }), _dec16 = (0, _model.hasMany)('category'), _dec17 = (0, _model.hasMany)('template-workflow', {
    async: false
  }), _dec18 = (0, _model.attr)(), _dec19 = (0, _model.attr)(), _dec20 = (0, _model.attr)({
    defaultValue: () => (0, _array.A)([])
  }), _dec21 = (0, _model.belongsTo)('template', {
    inverse: 'templates'
  }), _dec22 = (0, _model.hasMany)('template', {
    inverse: 'parentTemplate'
  }), _dec23 = (0, _model.hasMany)('attachment', {
    async: false
  }), _dec24 = (0, _model.attr)(), _dec25 = (0, _model.attr)(), (_class = class TemplateModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "cloneId", _descriptor, this);

      _initializerDefineProperty(this, "name", _descriptor2, this);

      _initializerDefineProperty(this, "description", _descriptor3, this);

      _initializerDefineProperty(this, "emailSubject", _descriptor4, this);

      _initializerDefineProperty(this, "emailBody", _descriptor5, this);

      _initializerDefineProperty(this, "emailText", _descriptor6, this);

      _initializerDefineProperty(this, "smsMessage", _descriptor7, this);

      _initializerDefineProperty(this, "inAppTitle", _descriptor8, this);

      _initializerDefineProperty(this, "inAppMessage", _descriptor9, this);

      _initializerDefineProperty(this, "pushMessage", _descriptor10, this);

      _initializerDefineProperty(this, "isPublished", _descriptor11, this);

      _initializerDefineProperty(this, "isVisible", _descriptor12, this);

      _initializerDefineProperty(this, "templateOrder", _descriptor13, this);

      _initializerDefineProperty(this, "versionStatus", _descriptor14, this);

      _initializerDefineProperty(this, "version", _descriptor15, this);

      _initializerDefineProperty(this, "categories", _descriptor16, this);

      _initializerDefineProperty(this, "templateWorkflows", _descriptor17, this);

      _initializerDefineProperty(this, "payload", _descriptor18, this);

      _initializerDefineProperty(this, "publishMeta", _descriptor19, this);

      _initializerDefineProperty(this, "workflowStageGroups", _descriptor20, this);

      _initializerDefineProperty(this, "parentTemplate", _descriptor21, this);

      _initializerDefineProperty(this, "templates", _descriptor22, this);

      _initializerDefineProperty(this, "attachments", _descriptor23, this);

      _initializerDefineProperty(this, "metadata", _descriptor24, this);

      _initializerDefineProperty(this, "stageRelevantMeta", _descriptor25, this);
    }

    get htmlSafeMessage() {
      return (0, _template.htmlSafe)(this.emailBody);
    }

    get statusName() {
      const statuses = {
        inactive: 'Inactive',
        active: 'Published',
        draft: 'Publishing in progress',
        deleted: 'Deletion in progress',
        rejected: 'Rejected',
        deletion_rejected: 'Rejected'
      };
      return statuses[this.versionStatus] || '';
    }

    get isEditable() {
      const statuses = ['draft', 'deleted'];
      return !statuses.includes(this.versionStatus);
    }

    get isRejected() {
      return ['rejected', 'deletion_rejected'].includes(this.versionStatus);
    }

    get isPublishInProgress() {
      return this.versionStatus === 'draft';
    }

    get publishErrors() {
      const errors = [];
      if (!this.isRejected) return errors;

      if (this.publishMeta && this.publishMeta.errors) {
        this.publishMeta.errors.forEach(error => {
          if (typeof error === 'string') {
            errors.push(error);
          } else if (typeof error === 'object') {
            errors.push(error.message);
          }
        });
      }

      return errors;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "cloneId", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "emailSubject", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "emailBody", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "emailText", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "smsMessage", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "inAppTitle", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "inAppMessage", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "pushMessage", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isPublished", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isVisible", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "templateOrder", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "versionStatus", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "version", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "categories", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "templateWorkflows", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "payload", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "publishMeta", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "workflowStageGroups", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "parentTemplate", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "templates", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "attachments", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "metadata", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "stageRelevantMeta", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TemplateModel;
});