define("shared/services/taxonomy", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TaxonomyService extends _service.default {}

  _exports.default = TaxonomyService;
});