define("itarp-opportunity-ui/components/ui/modals/reasons/button", ["exports", "@ember/component/template-only"], function (_exports, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button
    type="button"
    class="app-btn {{if @className @className}}"
    {{on "click" @action}}
    ...attributes
  >{{yield}}</button>
  */
  {
    "id": "uTS+4qFJ",
    "block": "[[[11,\"button\"],[24,4,\"button\"],[16,0,[29,[\"app-btn \",[52,[30,1],[30,1]]]]],[17,2],[4,[38,1],[\"click\",[30,3]],null],[12],[18,4,null],[13]],[\"@className\",\"&attrs\",\"@action\",\"&default\"],false,[\"if\",\"on\",\"yield\"]]",
    "moduleName": "itarp-opportunity-ui/components/ui/modals/reasons/button.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});