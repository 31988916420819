define("ember-bootstrap/components/bs-link-to", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "@ember/service", "@ember/debug", "ember-bootstrap/mixins/component-child", "@ember/object/compat"], function (_exports, _component, _templateFactory, _component2, _service, _debug, _componentChild, _compat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo
    @route={{this.route}}
    @models={{this._models}}
    @query={{this._query}}
    @disabled={{@disabled}}
    class={{@class}}
    ...attributes
  >
    {{yield}}
  </LinkTo>
  */
  {
    "id": "EdjAorPD",
    "block": "[[[8,[39,0],[[16,0,[30,1]],[17,2]],[[\"@route\",\"@models\",\"@query\",\"@disabled\"],[[30,0,[\"route\"]],[30,0,[\"_models\"]],[30,0,[\"_query\"]],[30,3]]],[[\"default\"],[[[[1,\"\\n  \"],[18,4,null],[1,\"\\n\"]],[]]]]]],[\"@class\",\"&attrs\",\"@disabled\",\"&default\"],false,[\"link-to\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-link-to.hbs",
    "isStrictMode": false
  });
  /* eslint-disable ember/classic-decorator-no-classic-methods */


  /**
   This is largely copied from Ember.LinkComponent. It is used as extending from Ember.LinkComponent has been deprecated.
   We need this to
   * register ourselves to a parent component that needs to know `active` state due to Bootstrap markup requirements, see Nav/LinkTo
   * continue supporting positional params until we can remove support
  
   @class LinkComponent
   @namespace Components
   @extends Component
   @private
  */
  let LinkComponent = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _service.inject)('router'), _dec(_class = (_class2 = class LinkComponent extends _component.default.extend(_componentChild.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);
    }

    get active() {
      if (!this.route) {
        return false;
      } // Ember < 3.22 does not correctly entangle autotracking with routing state changes, so we manually do that here
      // See https://github.com/emberjs/ember.js/issues/19004
      // shamelessly stolen from https://github.com/rwjblue/ember-router-helpers/blob/master/addon/utils/track-active-route.js
      // ensure we recompute anytime `router.currentURL` changes


      this.router.currentURL; // ensure we recompute whenever the `router.currentRouteName` changes
      // this is slightly overlapping with router.currentURL but there are
      // cases where route.currentURL doesn't change but the
      // router.currentRouteName has (e.g. loading and error states)

      this.router.currentRouteName;
      return this.router.isActive(this.route, ...this._models, {
        queryParams: this._query
      });
    }

    get _models() {
      let {
        model,
        models
      } = this;

      if (model !== undefined) {
        return [model];
      } else if (models !== undefined) {
        (false && !(Array.isArray(models)) && (0, _debug.assert)('The `@models` argument must be an array.', Array.isArray(models)));
        return models;
      } else {
        return [];
      }
    }

    get _query() {
      return this.query ?? {};
    } // eslint-disable-next-line ember/no-component-lifecycle-hooks


    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      let {
        params
      } = this;

      if (!params || params.length === 0) {
        return;
      }

      (false && !(false) && (0, _debug.deprecate)(`Positional arguments for ember-bootstrap's link-to components are deprecated. Switch to angle bracket invocation and named arguments.`, false, {
        id: `ember-bootstrap.link-to.positional-args`,
        until: '5.0.0',
        since: '4.7.0',
        for: 'ember-bootstrap'
      }));
      params = params.slice(); // taken from original Ember.LnkComponent
      // Process the positional arguments, in order.
      // Skipping this, as we don't support this
      // 1. Inline link title comes first, if present.
      // if (!hasBlock) {
      //   this.set('linkTitle', params.shift());
      // }
      // 2. The last argument is possibly the `query` object.

      let queryParams = params[params.length - 1];

      if (queryParams && queryParams.isQueryParams) {
        this.set('query', params.pop().values);
      } else {
        this.set('query', undefined);
      } // 3. If there is a `route`, it is now at index 0.


      if (params.length === 0) {
        this.set('route', undefined);
      } else {
        this.set('route', params.shift());
      } // 4. Any remaining indices (if any) are `models`.


      this.set('model', undefined);
      this.set('models', params);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "active", [_compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class2.prototype, "active"), _class2.prototype)), _class2)) || _class);
  LinkComponent.reopenClass({
    positionalParams: 'params'
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LinkComponent);

  _exports.default = _default;
});