define("itarp-components/utils/constants/questions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SINGLETONS = _exports.TYPES = _exports.default = void 0;
  const AUTHORIZATION = 'WORK_AUTHORIZATION';
  const CERTIFICATION = 'CERTIFICATION';
  const EXPERIENCE = 'WORK_EXPERIENCE';
  const VISA = 'VISA_STATUS';
  const CUSTOM = 'FREE_FORM';
  const TRAVEL = 'TRAVEL';
  const TYPES = {
    AUTHORIZATION,
    CERTIFICATION,
    EXPERIENCE,
    VISA,
    CUSTOM,
    TRAVEL
  };
  _exports.TYPES = TYPES;
  const SINGLETONS = [AUTHORIZATION, TRAVEL, VISA];
  _exports.SINGLETONS = SINGLETONS;
  var _default = TYPES;
  _exports.default = _default;
});