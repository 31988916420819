define("itarp-opportunity-designer/components/ui/card/option", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='card-holder'>
    <div class='card-option'>
      {{#if (eq @type 'switch')}}
        {{#let (unique-id) as |uid|}}
          <div class='card-option__switch'>
            <div class='form-switch form-switch--sm'>
              <Input @type='checkbox' id={{uid}} @checked={{@value}} />
              <label for={{uid}} class='form-switch__label'></label>
            </div>
          </div>
        {{/let}}
      {{/if}}
      <div class='card-option__header'>
        <span class='card-option__header__icon' ...attributes></span>
        <h5 class='ts--18 card-option__header__title'>
          {{yield to='title'}}
        </h5>
      </div>
      <div class='card-option__body'>
        <p>
          {{yield to='body'}}
        </p>
      </div>
    </div>
  </div>
  
  */
  {
    "id": "ET5ScrNC",
    "block": "[[[10,0],[14,0,\"card-holder\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"card-option\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,1],\"switch\"],null],[[[44,[[28,[37,3],null,null]],[[[1,\"        \"],[10,0],[14,0,\"card-option__switch\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"form-switch form-switch--sm\"],[12],[1,\"\\n            \"],[8,[39,4],[[16,1,[30,2]]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,3]]],null],[1,\"\\n            \"],[10,\"label\"],[15,\"for\",[30,2]],[14,0,\"form-switch__label\"],[12],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]]]],[]],null],[1,\"    \"],[10,0],[14,0,\"card-option__header\"],[12],[1,\"\\n      \"],[11,1],[24,0,\"card-option__header__icon\"],[17,4],[12],[13],[1,\"\\n      \"],[10,\"h5\"],[14,0,\"ts--18 card-option__header__title\"],[12],[1,\"\\n        \"],[18,5,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"card-option__body\"],[12],[1,\"\\n      \"],[10,2],[12],[1,\"\\n        \"],[18,6,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@type\",\"uid\",\"@value\",\"&attrs\",\"&title\",\"&body\"],false,[\"if\",\"eq\",\"let\",\"unique-id\",\"input\",\"yield\"]]",
    "moduleName": "itarp-opportunity-designer/components/ui/card/option.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});