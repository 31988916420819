define("itarp-talentcloud-ui/components/ui/modals/invitation", ["exports", "@ember/component/template-only"], function (_exports, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Modals::Base @additionalClasses="mar-t--4">
    <:dialog as |modal|>
      <modal.header>
        Invitation to Apply
      </modal.header>
      <modal.body>
        <div class="row">
          <div class="col--12-12">
            <div class="component-wrapper">
              {{@message}}
            </div>
          </div>
        </div>
      </modal.body>
    </:dialog>
    <:default as |options|>
      {{yield options.actions}}
    </:default>
  </Ui::Modals::Base>
  */
  {
    "id": "BfmMFQ4V",
    "block": "[[[8,[39,0],null,[[\"@additionalClasses\"],[\"mar-t--4\"]],[[\"dialog\",\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"header\"]],null,null,[[\"default\"],[[[[1,\"\\n      Invitation to Apply\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[30,1,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"col--12-12\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"component-wrapper\"],[12],[1,\"\\n            \"],[1,[30,2]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[1]],[[[1,\"\\n    \"],[18,4,[[30,3,[\"actions\"]]]],[1,\"\\n  \"]],[3]]]]]],[\"modal\",\"@message\",\"options\",\"&default\"],false,[\"ui/modals/base\",\"yield\"]]",
    "moduleName": "itarp-talentcloud-ui/components/ui/modals/invitation.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});