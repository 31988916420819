define("shared/utils/transform/query", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * The QueryTransform is an abstract class which provides base implementation for query filter type
   * The subclasses are responsible for implementation of serialize and deserialize
   */
  class QueryTransform {
    constructor() {
      _defineProperty(this, "value", null);

      if (this.constructor == QueryTransform) {
        throw new Error('QueryTransform is considered an abstract class and cannot be instantiated');
      }
    }
    /**
     * filter service calls the serialize method and sets the query parameter to the returned value
     */


    serialize() {
      throw new Error('Method serialize() is considered abstract and must be implemented in sub class');
    }
    /**
     * filter service calls the deserialize method with the value of query parameter.
     * The result of deserialize method must be set on the transform class instance value field.
     * The value field would be accesed by other parts of the application.
     */


    deserialize(value) {
      throw new Error('Method deserialize() is considered abstract and must be implemented in sub class');
    }

  }

  _exports.default = QueryTransform;
});