define("itarp-opportunity-designer/routes/details/sourcing", ["exports", "@ember/routing/route", "rsvp", "@ember/service"], function (_exports, _route, _rsvp, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DetailsSourcingRoute = (_dec = (0, _service.inject)('opportunity/authorization'), (_class = class DetailsSourcingRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        pageNumber: {
          refreshModel: true
        }
      });

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "appRouter", _descriptor2, this);

      _initializerDefineProperty(this, "authorization", _descriptor3, this);
    }

    async model(params) {
      const project = this.modelFor('details').project;
      const ordersSet = [['first_name', 'asc'], ['last_name', 'desc'], ['created_at', 'asc'], ['created_at', 'desc'], ['profiles.state', 'desc'], ['profiles.address', 'asc'], ['email', 'desc'], ['profiles.city', 'asc'], ['profiles.city', 'desc'], ['profiles.zip_code', 'asc'], ['profiles.zip_code', 'desc']];
      let sum = 0;

      for (let i = 0; i < project.jobTitle.length; i++) {
        sum += project.jobTitle.charCodeAt(i);
      }

      const orderCriteria = ordersSet[sum % ordersSet.length];
      let page = params.pageNumber ? params.pageNumber : 1;
      return (0, _rsvp.hash)({
        params,
        project,
        invitations: this.store.query('invitation', {
          filter: {
            resource_type: 'opportunity',
            resource_id: project.id
          }
        }).then(results => {
          return {
            data: results,
            meta: results.meta
          };
        }),
        users: this.store.query('user', {
          per_page: 20,
          page: page,
          filter: {
            user_type: 'expert',
            profiles: {
              work_available: true
            }
          },
          advance_filter: {
            profiles: {
              profile_picture: {
                $exists: true,
                $ne: ''
              },
              professional_role: {
                $exists: true,
                $ne: ''
              },
              years_of_experience: {
                $gte: 1
              },
              city: {
                $exists: true,
                $ne: ''
              },
              state: {
                $exists: true,
                $ne: ''
              },
              country: {
                $exists: true,
                $ne: ''
              }
            }
          },
          order_by: orderCriteria
        }).then(results => {
          return {
            data: results,
            meta: results.meta
          };
        })
      });
    }

    async beforeModel(transition) {
      var _transition$from;

      const permission = this.authorization.permissions.candidates.view.sourcing;
      const isPermitted = await this.authorization.isPermitted(permission);
      const originatedFrom = (_transition$from = transition.from) === null || _transition$from === void 0 ? void 0 : _transition$from.name;

      if (!isPermitted) {
        transition.abort();

        if (originatedFrom) {
          return this.appRouter.transitionTo(originatedFrom);
        }

        this.appRouter.transitionTo('itarp-opportunity-designer.browse');
      }
    }

    setupController(controller, model) {
      super.setupController(...arguments);
      controller.set('project', model.project);
      controller.set('experts', model.users.data);
      controller.set('page', model.users.meta.page);
      controller.set('invitations', model.invitations.data.toArray());

      if (model.invitations.meta.page.total) {
        controller.set('invites', model.invitations.meta.page.total);
      }

      if (model.users.meta.page && model.users.meta.page.total) {
        controller.set('total', model.users.meta.page.total);
      }
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('pageNumber', 1);
        controller.set('expert', null);
        controller.set('tab', null);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "appRouter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "authorization", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = DetailsSourcingRoute;
});