define("tracked-maps-and-sets/-private/map", ["exports", "tracked-maps-and-sets/-private/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TrackedWeakMap = _exports.TrackedMap = void 0;

  class TrackedMap extends Map {
    // **** KEY GETTERS ****
    get(key) {
      (0, _util.consumeKey)(this, key);
      return super.get(key);
    }

    has(key) {
      (0, _util.consumeKey)(this, key);
      return super.has(key);
    } // **** ALL GETTERS ****


    entries() {
      (0, _util.consumeCollection)(this);
      return super.entries();
    }

    keys() {
      (0, _util.consumeCollection)(this);
      return super.keys();
    }

    values() {
      (0, _util.consumeCollection)(this);
      return super.values();
    }

    forEach(fn) {
      (0, _util.consumeCollection)(this);
      super.forEach(fn);
    }

    get size() {
      (0, _util.consumeCollection)(this);
      return super.size;
    } // **** KEY SETTERS ****


    set(key, value) {
      (0, _util.dirtyKey)(this, key);
      (0, _util.dirtyCollection)(this);
      return super.set(key, value);
    }

    delete(key) {
      (0, _util.dirtyKey)(this, key);
      (0, _util.dirtyCollection)(this);
      return super.delete(key);
    } // **** ALL SETTERS ****


    clear() {
      super.forEach((_v, k) => (0, _util.dirtyKey)(this, k));
      (0, _util.dirtyCollection)(this);
      return super.clear();
    }

  }

  _exports.TrackedMap = TrackedMap;

  if (typeof Symbol !== undefined) {
    let originalIterator = TrackedMap.prototype[Symbol.iterator];
    Object.defineProperty(TrackedMap.prototype, Symbol.iterator, {
      get() {
        (0, _util.consumeCollection)(this);
        return originalIterator;
      }

    });
  }

  class TrackedWeakMap extends WeakMap {
    get(key) {
      (0, _util.consumeKey)(this, key);
      return super.get(key);
    }

    has(key) {
      (0, _util.consumeKey)(this, key);
      return super.has(key);
    }

    set(key, value) {
      (0, _util.dirtyKey)(this, key);
      return super.set(key, value);
    }

    delete(key) {
      (0, _util.dirtyKey)(this, key);
      return super.delete(key);
    }

  }

  _exports.TrackedWeakMap = TrackedWeakMap;
});