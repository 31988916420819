define("itarp-components/components/ui/table/body", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tbody class='table__body {{if @group 'table__row__group'}}'>
    {{yield
      (hash filters=(component 'ui/table/filters') row=(component 'ui/table/row'))
    }}
  </tbody>
  */
  {
    "id": "2b39Zdp2",
    "block": "[[[10,\"tbody\"],[15,0,[29,[\"table__body \",[52,[30,1],\"table__row__group\"]]]],[12],[1,\"\\n  \"],[18,2,[[28,[37,2],null,[[\"filters\",\"row\"],[[50,\"ui/table/filters\",0,null,null],[50,\"ui/table/row\",0,null,null]]]]]],[1,\"\\n\"],[13]],[\"@group\",\"&default\"],false,[\"if\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-components/components/ui/table/body.hbs",
    "isStrictMode": false
  });

  class UiTableBodyComponent extends _component2.default {}

  _exports.default = UiTableBodyComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiTableBodyComponent);
});