define("itarp-opportunity-designer/controllers/opportunity/application-requirement", ["exports", "@ember/controller", "@ember/object", "@ember/service", "itarp-components/utils/constants/questions"], function (_exports, _controller, _object, _service, _questions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OpportunityApplicationRequirementController = (_class = class OpportunityApplicationRequirementController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "toast", _descriptor2, this);
    }

    get types() {
      return _questions.TYPES;
    }

    get singletonState() {
      const questions = this.project.questionnaire;
      return _questions.SINGLETONS.reduce((states, type) => {
        states[type] = questions.isAny('type', type);
        return states;
      }, {});
    }

    get typeDefaults() {
      return {
        [_questions.TYPES.AUTHORIZATION]: {
          idealAnswer: 'Yes'
        },
        [_questions.TYPES.VISA]: {
          idealAnswer: 'No'
        }
      };
    }

    async remove(question) {
      const position = question.position;

      try {
        this.project.questionnaire.removeObject(question);
        await question.destroyRecord();
      } catch (err) {
        var _err$errors;

        const message = (_err$errors = err.errors) !== null && _err$errors !== void 0 && _err$errors.length ? err.errors.mapBy('title').join('. ') : err.message;
        this.toast.show(`Couldn't remove the question: ${message}`);
        this.project.questionnaire.insertAt(position, question);
      }
    }

    move(question, direction) {
      const questionnaire = this.project.questionnaire;
      const position = question.get('position');
      const positions = {
        up: position - 1,
        down: position + 1
      };
      const destination = positions[direction];
      if (destination >= questionnaire.length || destination < 0) return;
      questionnaire.removeAt(position, 1);
      questionnaire.insertAt(destination, question);
    }

    add(type) {
      const isSingleton = _questions.SINGLETONS.includes(type);

      const isDisabled = isSingleton ? this.singletonState[type] : false;
      if (isDisabled) return;
      const question = this.store.createRecord('questionnaire', this.defaultAttrs(type));
      this.project.questionnaire.pushObject(question);
    }

    defaultAttrs(type) {
      const resourceId = this.project.id;
      const resourceType = 'opportunity';
      const defaults = this.typeDefaults[type];
      return {
        resourceId,
        resourceType,
        type,
        ...defaults
      };
    }

    async save() {
      const unsaved = this.project.questionnaire.filterBy('hasDirtyAttributes');
      const persistQuestions = unsaved.map(q => q.save());
      await Promise.all(persistQuestions);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "remove", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "move", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "move"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "add", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "defaultAttrs", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "defaultAttrs"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class);
  _exports.default = OpportunityApplicationRequirementController;
});