define("itarp-business-application/templates/dynamic-route-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LLhhjrGX",
    "block": "[[[10,\"h1\"],[12],[1,[30,0,[\"model\",\"message\"]]],[13]],[],false,[]]",
    "moduleName": "itarp-business-application/templates/dynamic-route-error.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});