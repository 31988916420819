define("shared/components/pagination-list", ["exports", "@glimmer/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="pagination">
    <div class="pagination__pages">
      <ul class="pagination-list">
        <li class="pagination-list__item is-prev">
          {{#if (eq this.previousIndex 0)}}
            <a class="pagination-list__link"></a>
          {{else}}
            {{yield this.previousIndex ""}}
          {{/if}}
        </li>
        {{#each this.paginate as |index|}}
          <li
            class="pagination-list__item
              {{if (eq this.args.page.page index) " is-active"}}"
          >
            {{yield index index}}
          </li>
        {{/each}}
        <li class="pagination-list__item is-next">
          {{#if (eq this.nextIndex 0)}}
            <a class="pagination-list__link"></a>
          {{else}}
            {{yield this.nextIndex ""}}
          {{/if}}
        </li>
      </ul>
    </div>
    <div class="pagination__format"></div>
  </div>
  */
  {
    "id": "oDTY0tmy",
    "block": "[[[10,0],[14,0,\"pagination\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"pagination__pages\"],[12],[1,\"\\n    \"],[10,\"ul\"],[14,0,\"pagination-list\"],[12],[1,\"\\n      \"],[10,\"li\"],[14,0,\"pagination-list__item is-prev\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"previousIndex\"]],0],null],[[[1,\"          \"],[10,3],[14,0,\"pagination-list__link\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[18,2,[[30,0,[\"previousIndex\"]],\"\"]],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"paginate\"]]],null]],null],null,[[[1,\"        \"],[10,\"li\"],[15,0,[29,[\"pagination-list__item\\n            \",[52,[28,[37,1],[[30,0,[\"args\",\"page\",\"page\"]],[30,1]],null],\" is-active\"]]]],[12],[1,\"\\n          \"],[18,2,[[30,1],[30,1]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"      \"],[10,\"li\"],[14,0,\"pagination-list__item is-next\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"nextIndex\"]],0],null],[[[1,\"          \"],[10,3],[14,0,\"pagination-list__link\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[18,2,[[30,0,[\"nextIndex\"]],\"\"]],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"pagination__format\"],[12],[13],[1,\"\\n\"],[13]],[\"index\",\"&default\"],false,[\"if\",\"eq\",\"yield\",\"each\",\"-track-array\"]]",
    "moduleName": "shared/components/pagination-list.hbs",
    "isStrictMode": false
  });

  let PaginationListComponent = (_dec = (0, _object.computed)('args.page.page'), _dec2 = (0, _object.computed)('args.page.page,args.page.total'), (_class = class PaginationListComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "delta", 2);
    }

    get previousIndex() {
      const pageNumber = Number(this.args.page.page);
      return pageNumber >= 2 ? pageNumber - 1 : 0;
    }

    get nextIndex() {
      const pageNumber = Number(this.args.page.page);
      const records = Number(this.args.page.total);
      const perPage = Number(this.args.page.per_page);
      const total = Math.ceil(records / perPage);
      return pageNumber < total ? pageNumber + 1 : 0;
    }

    get current() {
      return Number(this.args.page.page);
    }

    get last() {
      return Math.ceil(Number(this.args.page.total) / Number(this.args.page.per_page));
    }

    get left() {
      return this.current - this.delta;
    }

    get right() {
      return this.current + this.delta + 1;
    }

    get paginate() {
      let pagesWithDots = [],
          range = [],
          l;

      for (let i = 1; i <= this.last; i++) {
        if (i == 1 || i == this.last || i >= this.left && i < this.right) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            pagesWithDots.push(l + 1);
          } else if (i - l !== 1) {
            pagesWithDots.push('...');
          }
        }

        pagesWithDots.push(i);
        l = i;
      }

      return pagesWithDots;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "previousIndex", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "previousIndex"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "nextIndex", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "nextIndex"), _class.prototype)), _class));
  _exports.default = PaginationListComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PaginationListComponent);
});