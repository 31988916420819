define("itarp-opportunity-designer/components/power-select/options/taxonomy", ["exports", "@ember/component", "@ember/template-factory", "ember-power-select/components/power-select/options"], function (_exports, _component, _templateFactory, _options) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ul
    role='listbox'
    aria-controls='ember-power-select-trigger-{{@select.uniqueId}}'
    ...attributes
    {{did-insert this.addHandlers}}
  >
    {{#if @select.loading}}
      {{#if @loadingMessage}}
        <li
          class='ember-power-select-option ember-power-select-option--loading-message'
          role='option'
        >
          {{@loadingMessage}}
        </li>
      {{/if}}
    {{/if}}
    {{#each @options as |option index|}}
      <li
        class='ember-power-select-option'
        aria-selected='{{is-any @select.selected 'taxonomy_id' option.id}}'
        aria-disabled={{if option.disabled 'true'}}
        aria-current='{{eq option @select.highlighted}}'
        data-option-index='{{@groupIndex}}{{index}}'
        role='option'
        data-test-select-option
      >
        {{#if option.parent.name}}
          <p>{{option.name}}</p>
          <p>{{option.parent.name}}</p>
        {{else}}
          <p>{{option.name}}</p>
        {{/if}}
      </li>
    {{/each}}
  </ul>
  */
  {
    "id": "HgmD/OX/",
    "block": "[[[11,\"ul\"],[24,\"role\",\"listbox\"],[16,\"aria-controls\",[29,[\"ember-power-select-trigger-\",[30,1,[\"uniqueId\"]]]]],[17,2],[4,[38,0],[[30,0,[\"addHandlers\"]]],null],[12],[1,\"\\n\"],[41,[30,1,[\"loading\"]],[[[41,[30,3],[[[1,\"      \"],[10,\"li\"],[14,0,\"ember-power-select-option ember-power-select-option--loading-message\"],[14,\"role\",\"option\"],[12],[1,\"\\n        \"],[1,[30,3]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[42,[28,[37,3],[[28,[37,3],[[30,4]],null]],null],null,[[[1,\"    \"],[10,\"li\"],[14,0,\"ember-power-select-option\"],[15,\"aria-selected\",[29,[[28,[37,4],[[30,1,[\"selected\"]],\"taxonomy_id\",[30,5,[\"id\"]]],null]]]],[15,\"aria-disabled\",[52,[30,5,[\"disabled\"]],\"true\"]],[15,\"aria-current\",[29,[[28,[37,5],[[30,5],[30,1,[\"highlighted\"]]],null]]]],[15,\"data-option-index\",[29,[[30,7],[30,6]]]],[14,\"role\",\"option\"],[14,\"data-test-select-option\",\"\"],[12],[1,\"\\n\"],[41,[30,5,[\"parent\",\"name\"]],[[[1,\"        \"],[10,2],[12],[1,[30,5,[\"name\"]]],[13],[1,\"\\n        \"],[10,2],[12],[1,[30,5,[\"parent\",\"name\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,2],[12],[1,[30,5,[\"name\"]]],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[5,6]],null],[13]],[\"@select\",\"&attrs\",\"@loadingMessage\",\"@options\",\"option\",\"index\",\"@groupIndex\"],false,[\"did-insert\",\"if\",\"each\",\"-track-array\",\"is-any\",\"eq\"]]",
    "moduleName": "itarp-opportunity-designer/components/power-select/options/taxonomy.hbs",
    "isStrictMode": false
  });

  class PowerSelectOptionsTaxonomyComponent extends _options.default {}

  _exports.default = PowerSelectOptionsTaxonomyComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PowerSelectOptionsTaxonomyComponent);
});