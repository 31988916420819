define("shared/serializers/professional-role", ["exports", "shared/serializers/business-application"], function (_exports, _businessApplication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ProfessionalRoleSerializer extends _businessApplication.default {}

  _exports.default = ProfessionalRoleSerializer;
});