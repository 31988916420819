define("itarp-workflow-designer/services/workflow-constants", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class WorkflowConstants extends _service.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "STAGE_GROUPS", {
        ACTIONS: {
          SAVE_ACTION: {
            TYPE: 'saveAction',
            MESSAGE: 'Do you want to save this action?'
          },
          CANCEL_ACTION: {
            TYPE: 'cancelAction',
            MESSAGE: 'Do you want to cancel this action?'
          },
          MOVE_ACTION: {
            TYPE: 'moveAction',
            MESSAGE: 'You are moving to a different stage. This will discard unsaved changes'
          },
          DELETE_ACTION: {
            TYPE: 'deleteAction',
            MESSAGE: 'Do you want to delete this action?'
          }
        }
      });

      _defineProperty(this, "ACTION_TYPES", {
        SEND_EMAIL: 'send_email',
        SEND_NOTIFICATION: 'send_notification',
        UI_ACTION: 'ui_action'
      });

      _defineProperty(this, "WORKFLOW_TYPES", {
        OPPORTUNITY_APPROVAL: 'opportunities',
        TALENTCLOUD_APPROVAL: 'talent_clouds',
        OPPORTUNITY_RECRUITING: 'applications',
        TALENTCLOUD_RECRUITING: 'applications'
      });

      _defineProperty(this, "NOTIFICATION_TYPES", [this.ACTION_TYPES.SEND_EMAIL, this.ACTION_TYPES.SEND_NOTIFICATION]);

      _defineProperty(this, "STAGE_IDENTIFIERS", {
        OPPORTUNITY_APPROVAL: 'workflow_approval_stage_id',
        TALENTCLOUD_APPROVAL: 'workflow_approval_stage_id',
        OPPORTUNITY_RECRUITING: 'workflow_stage_id',
        TALENTCLOUD_RECRUITING: 'workflow_stage_id'
      });
    }

  }

  _exports.default = WorkflowConstants;
});