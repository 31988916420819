define("itarp-business-application/templates/page-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Dtk1exam",
    "block": "[[[1,[28,[35,0],[[30,0,[\"title\"]]],null]],[1,\"\\n\"],[10,0],[14,0,\"content content--100h content--bg\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"container container--1264\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col--12-12\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"component-wrapper has-padding\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"panel mar-t--3\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"panel__header panel__header--secondary\"],[12],[1,\"\\n              \"],[10,\"h3\"],[14,0,\"panel__header__title\"],[12],[1,\"\\n                Experfy TalentClouds\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,0],[14,0,\"panel__body no-sidebar\"],[12],[1,\"\\n              \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n                \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n                  \"],[10,0],[14,0,\"col--12-12\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"model\",\"children\"]]],null]],null],null,[[[1,\"                      \"],[8,[39,3],null,[[\"@element\",\"@observer\",\"@submit\",\"@reset\",\"@onChange\"],[[30,1],[30,0,[\"observer\"]],[28,[37,4],[[30,0,[\"observer\",\"publish\"]],\"submit\"],null],[28,[37,4],[[30,0,[\"observer\",\"publish\"]],\"cancel\"],null],[28,[37,4],[[30,0,[\"observer\",\"publish\"]],\"change\"],null]]],null],[1,\"\\n\"]],[1]],null],[1,\"                  \"],[13],[1,\"\\n                \"],[13],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"element\"],false,[\"page-title\",\"each\",\"-track-array\",\"render-element\",\"fn\"]]",
    "moduleName": "itarp-business-application/templates/page-layout.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});