define("itarp-components/utils/taxonomy/helpers", ["exports", "itarp-components/utils/taxonomy/taxonomy"], function (_exports, _taxonomy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findChildren = _exports.filterByType = _exports.getChildTaxonomy = void 0;

  const getChildTaxonomy = function (type) {
    if (this.loadedChildren) {
      var _this$children;

      return (_this$children = this.children) === null || _this$children === void 0 ? void 0 : _this$children.filter(child => filterByType(child, type));
    } else {
      return this.store.query('taxonomies-category', {
        filter: {
          left: {
            $gt: this.left
          },
          right: {
            $lt: this.right
          }
        },
        per_page: 'infinity',
        order_by: 'name'
      }).then(data => {
        var _this$children2;

        this.children = data.map(child => new _taxonomy.default(child, this.store, data));
        this.loadedChildren = true;
        return (_this$children2 = this.children) === null || _this$children2 === void 0 ? void 0 : _this$children2.filter(child => filterByType(child, type));
      });
    }
  };

  _exports.getChildTaxonomy = getChildTaxonomy;

  const filterByType = function (child, type) {
    return child.type === type || child.isCategory;
  };

  _exports.filterByType = filterByType;

  const findChildren = function (data, parent) {
    const left = parent.left;
    const right = parent.right;
    if (!data) return [];
    return data.filter(item => item.left > left && item.right < right);
  };

  _exports.findChildren = findChildren;
});