define("itarp-workflow-designer/components/panel", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='workflow-stages__list__form__header'>
    <h4 class='workflow-stages__list__form__title'>
      {{yield to='title'}}</h4>
  </div>
  <div class='workflow-stages__list__form__body'>
    {{yield to='body'}}
  </div>
  <div class='workflow-stages__list__form__footer'>
    {{yield to='footer'}}
  </div>
  */
  {
    "id": "5GVnWLPf",
    "block": "[[[10,0],[14,0,\"workflow-stages__list__form__header\"],[12],[1,\"\\n  \"],[10,\"h4\"],[14,0,\"workflow-stages__list__form__title\"],[12],[1,\"\\n    \"],[18,1,null],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"workflow-stages__list__form__body\"],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"workflow-stages__list__form__footer\"],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13]],[\"&title\",\"&body\",\"&footer\"],false,[\"yield\"]]",
    "moduleName": "itarp-workflow-designer/components/panel.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});