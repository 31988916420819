define("shared/components/location", ["exports", "shared/components/base", "@ember/object", "@glimmer/tracking", "@ember/service", "shared/helpers/countries", "@ember/runloop", "shared/utils/constants/google-api", "shared/utils/string/arr-join", "@ember/object/computed"], function (_exports, _base, _object, _tracking, _service, _countries, _runloop, _googleApi, _arrJoin, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#unless @config.attributes.hidden}}
    <div
      class="form-group form-group--location form-inline--location"
      data-test-location-component
    >
      <div class="form-group__elements">
        <div class="form-group__select country-select" data-test-country-select>
          <PowerSelect
            @tagName="div"
            @dropdownClass="country-select__menu"
            @selected={{this.country}}
            @options={{this.countries}}
            @onChange={{this.selectCountry}}
            @placeholder="Select Country"
            @searchField="value"
            as |country|
          >
            {{country.flag}}
            {{country.value}}
          </PowerSelect>
        </div>
        <input
          type="text"
          class="input-group__field {{if this.error "has-error"}}"
          placeholder="Enter City, State or Zip "
          id="google-search"
          autocomplete="false"
          value={{this.fullLocation}}
          {{did-insert this.attachAutoComplete}}
          {{on "change" this.clearValues}}
          data-test-location-input
        />
      </div>
    </div>
  {{/unless}}
  */
  {
    "id": "mLwfsk1O",
    "block": "[[[41,[51,[30,1,[\"attributes\",\"hidden\"]]],[[[1,\"  \"],[10,0],[14,0,\"form-group form-group--location form-inline--location\"],[14,\"data-test-location-component\",\"\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"form-group__elements\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"form-group__select country-select\"],[14,\"data-test-country-select\",\"\"],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@tagName\",\"@dropdownClass\",\"@selected\",\"@options\",\"@onChange\",\"@placeholder\",\"@searchField\"],[\"div\",\"country-select__menu\",[30,0,[\"country\"]],[30,0,[\"countries\"]],[30,0,[\"selectCountry\"]],\"Select Country\",\"value\"]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,2,[\"flag\"]]],[1,\"\\n          \"],[1,[30,2,[\"value\"]]],[1,\"\\n        \"]],[2]]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[11,\"input\"],[16,0,[29,[\"input-group__field \",[52,[30,0,[\"error\"]],\"has-error\"]]]],[24,\"placeholder\",\"Enter City, State or Zip \"],[24,1,\"google-search\"],[24,\"autocomplete\",\"false\"],[16,2,[30,0,[\"fullLocation\"]]],[24,\"data-test-location-input\",\"\"],[24,4,\"text\"],[4,[38,3],[[30,0,[\"attachAutoComplete\"]]],null],[4,[38,4],[\"change\",[30,0,[\"clearValues\"]]],null],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@config\",\"country\"],false,[\"unless\",\"power-select\",\"if\",\"did-insert\",\"on\"]]",
    "moduleName": "shared/components/location.hbs",
    "isStrictMode": false
  });

  let FormsLocationComponent = (_dec = (0, _computed.alias)('args.config.attributes'), _dec2 = (0, _service.inject)('google-api'), _dec3 = (0, _object.computed)('attributes.country'), (_class = class FormsLocationComponent extends _base.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "attributes", _descriptor, this);

      _initializerDefineProperty(this, "googleApi", _descriptor2, this);

      _defineProperty(this, "countries", _countries.default.compute());

      _initializerDefineProperty(this, "input", _descriptor3, this);

      if (!this.attributes.country) {
        const USA = this.countries.findBy('code', 'US');
        (0, _object.set)(this.attributes, 'country', USA.value);
      }
    }

    get city() {
      return this.attributes.city;
    }

    set city(city) {
      this.attributes.city = city;
    }

    get zipcode() {
      return this.attributes.zipcode;
    }

    set zipcode(zipCode) {
      this.attributes.zipcode = zipCode;
    }

    get state() {
      return this.attributes.state;
    }

    set state(state) {
      this.attributes.state = state;
    }

    get locationCoordinates() {
      return this.attributes.locationCoordinates;
    }

    set locationCoordinates(coordinates) {
      this.attributes.locationCoordinates = coordinates;
    }

    get label() {
      return this.attributes.label;
    }

    get country() {
      const country = this.attributes.country;
      return this.countries.findBy('value', country);
    }

    get fullLocation() {
      return this.city && this.state && this.zipcode ? (0, _arrJoin.default)([this.city, this.state, this.zipcode]) : '';
    }

    attachAutoComplete(input) {
      (0, _runloop.later)(this, () => {
        const options = {
          countries: this.country.code,
          onSelect: this.selectSuggestion,
          components: [_googleApi.ADDR_CPNT.LOC, _googleApi.ADDR_CPNT.ADMIN_AREA, _googleApi.ADDR_CPNT.ZIP]
        };
        this.input = input;
        this.googleApi.initAutocomplete(input, options);
      });
    }

    async selectSuggestion(result) {
      const city = result[_googleApi.ADDR_CPNT.LOC];
      const zipcode = result[_googleApi.ADDR_CPNT.ZIP];
      const state = result[_googleApi.ADDR_CPNT.ADMIN_AREA];
      const coordinates = {
        long: result.geopoint.lng,
        lat: result.geopoint.lat
      };
      (0, _object.set)(this, 'city', city);
      (0, _object.set)(this, 'zipcode', zipcode);
      (0, _object.set)(this, 'state', state);
      (0, _object.set)(this, 'locationCoordinates', coordinates);
      this.setInputValue();
    }

    clearValues() {
      (0, _object.set)(this, 'city', null);
      (0, _object.set)(this, 'zipcode', null);
      (0, _object.set)(this, 'state', null);
      (0, _object.set)(this, 'locationCoordinates', null);
      this.setInputValue();
    }

    setInputValue() {
      this.args.config.attributes.setAddresses({
        country: this.country.value,
        state: this.state,
        zipCode: this.zipcode,
        city: this.city,
        locationCoordinates: this.locationCoordinates
      });
    }

    selectCountry(country) {
      (0, _object.set)(this.attributes, 'country', country.value);
      this.googleApi.setAutocompleteCountry(this.country.code);
      this.clearValues();
    }

    updateAddressValue(type, event) {
      (0, _object.set)(this, type, event.target.value);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "attributes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "googleApi", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "input", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "country", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "country"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "attachAutoComplete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "attachAutoComplete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectSuggestion", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectSuggestion"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearValues", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearValues"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setInputValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setInputValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectCountry", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectCountry"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateAddressValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateAddressValue"), _class.prototype)), _class));
  _exports.default = FormsLocationComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormsLocationComponent);
});