define("itarp-opportunity-designer/transforms/image-url", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const namespace = '/opportunity/storage/show/';
  const urlmatch = ['www', 'http'];

  class ImageUrlTransform extends _transform.default {
    deserialize(serialized, options) {
      if (typeof serialized === 'string') {
        if (urlmatch.any(m => serialized.includes(m))) return serialized;
      }

      return serialized ? namespace + serialized : null;
    }

    serialize(deserialized, options) {
      if (deserialized) {
        if (urlmatch.any(m => deserialized.includes(m))) return deserialized;
        return deserialized.replace(namespace, '');
      }
    }

  }

  _exports.default = ImageUrlTransform;
});