define("itarp-business-application/components/forms/youtube-input", ["exports", "itarp-business-application/components/forms/base", "@ember/object/computed", "@ember/object"], function (_exports, _base, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#unless @config.attributes.hidden}}
    {{#let (unique-id) as |youtube-input-id|}}
      <div class="component-wrapper">
        <div class="input-group is-optional">
          {{#if this.label}}
            <label class="input-group__label" data-test-youtube-label>
              {{this.label}}
            </label>
          {{/if}}
          {{#if this.value}}
  
            <div class="youtube-video-container" data-test-youtube-preview>
              <iframe
                width="1264"
                height="482"
                src={{this.value}}
                frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          {{/if}}
          <input
            class="input-group__field {{if this.error "has-error"}}"
            value={{this.value}}
            type="text"
            placeholder={{this.attributes.placeholder}}
            {{on "focusout" this.updateValue}}
          />
        </div>
      </div>
    {{/let}}
  {{/unless}}
  */
  {
    "id": "XbWZ0ZGV",
    "block": "[[[41,[51,[30,1,[\"attributes\",\"hidden\"]]],[[[44,[[28,[37,2],null,null]],[[[1,\"    \"],[10,0],[14,0,\"component-wrapper\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"input-group is-optional\"],[12],[1,\"\\n\"],[41,[30,0,[\"label\"]],[[[1,\"          \"],[10,\"label\"],[14,0,\"input-group__label\"],[14,\"data-test-youtube-label\",\"\"],[12],[1,\"\\n            \"],[1,[30,0,[\"label\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"value\"]],[[[1,\"\\n          \"],[10,0],[14,0,\"youtube-video-container\"],[14,\"data-test-youtube-preview\",\"\"],[12],[1,\"\\n            \"],[10,\"iframe\"],[14,\"width\",\"1264\"],[14,\"height\",\"482\"],[15,\"src\",[30,0,[\"value\"]]],[14,\"frameborder\",\"0\"],[14,\"allow\",\"accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture\"],[14,\"allowfullscreen\",\"\"],[12],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[11,\"input\"],[16,0,[29,[\"input-group__field \",[52,[30,0,[\"error\"]],\"has-error\"]]]],[16,2,[30,0,[\"value\"]]],[16,\"placeholder\",[30,0,[\"attributes\",\"placeholder\"]]],[24,4,\"text\"],[4,[38,4],[\"focusout\",[30,0,[\"updateValue\"]]],null],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2]]]],[]],null]],[\"@config\",\"youtube-input-id\"],false,[\"unless\",\"let\",\"unique-id\",\"if\",\"on\"]]",
    "moduleName": "itarp-business-application/components/forms/youtube-input.hbs",
    "isStrictMode": false
  });

  let FormsYoutubeInputComponent = (_dec = (0, _computed.alias)('args.config.attributes'), _dec2 = (0, _object.computed)('attributes.label'), _dec3 = (0, _object.computed)('attributes.link'), _dec4 = (0, _object.computed)('attributes.isRequired'), _dec5 = (0, _object.computed)('attributes.value'), (_class = class FormsYoutubeInputComponent extends _base.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "attributes", _descriptor, this);
    }

    get label() {
      return this.attributes.label;
    }

    get youtubeSrc() {
      return this.attributes.youtubeSrc;
    }

    get isRequired() {
      return this.attributes.required;
    }

    get value() {
      return this.attributes.value;
    }

    updateValue(event) {
      const val = event.target.value;
      let embeddedUrl = null;
      const youtubeMatcher = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#]*).*/;
      const linkMatched = val.match(youtubeMatcher);

      if (linkMatched && linkMatched[7].length === 11) {
        embeddedUrl = `https://www.youtube.com/embed/${linkMatched[7]}`;
      }

      (0, _object.set)(this.attributes, 'value', embeddedUrl);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "attributes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "label", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "label"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "youtubeSrc", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "youtubeSrc"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isRequired", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "isRequired"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "value", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "value"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateValue"), _class.prototype)), _class));
  _exports.default = FormsYoutubeInputComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormsYoutubeInputComponent);
});