define("itarp-opportunity-designer/components/question/types/authorization", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='component-wrapper has-padding'>
    <div class='card no-header'>
      <div class='card__body'>
        <p class='ts--18 text--bold text--with-icon for-travel'>
          Are you authorized to work in the United States?
        </p>
        <p class='ts--18 text--flex mar-t--1'>
          Ideal answer:
          <span>
            <Input
              @type='text'
              @value={{@question.idealAnswer}}
              class='input-group__field'
              placeholder='Yes'
              disabled={{@isView}}
            />
          </span>
        </p>
      </div>
      {{yield}}
    </div>
  </div>
  */
  {
    "id": "qfHbe5Mk",
    "block": "[[[10,0],[14,0,\"component-wrapper has-padding\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"card no-header\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"card__body\"],[12],[1,\"\\n      \"],[10,2],[14,0,\"ts--18 text--bold text--with-icon for-travel\"],[12],[1,\"\\n        Are you authorized to work in the United States?\\n      \"],[13],[1,\"\\n      \"],[10,2],[14,0,\"ts--18 text--flex mar-t--1\"],[12],[1,\"\\n        Ideal answer:\\n        \"],[10,1],[12],[1,\"\\n          \"],[8,[39,0],[[24,0,\"input-group__field\"],[24,\"placeholder\",\"Yes\"],[16,\"disabled\",[30,1]]],[[\"@type\",\"@value\"],[\"text\",[30,2,[\"idealAnswer\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@isView\",\"@question\",\"&default\"],false,[\"input\",\"yield\"]]",
    "moduleName": "itarp-opportunity-designer/components/question/types/authorization.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});