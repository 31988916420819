define("itarp-template-designer/mirage/endpoints/templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(context) {
    context.get('templates');
    context.get('templates/:id');
    context.post('templates');
    context.patch('templates/:id');
    context.del('templates/:id');
  }
});