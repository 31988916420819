define("itarp-template-designer/templates/templates/categories/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "gXEvwzRA",
    "block": "[[[8,[39,0],null,[[\"@category\",\"@closeCategoryModal\",\"@saveCategory\",\"@toggleIsVisibleTemplate\",\"@deleteCategory\",\"@deleteModalVisible\",\"@toggleDeleteCategoryModal\",\"@categoryModalVisible\",\"@error\",\"@categoryTypeOptions\",\"@selectedCategoryType\"],[[30,0,[\"category\"]],[30,0,[\"closeCategoryModal\"]],[30,0,[\"saveCategory\"]],[30,0,[\"toggleIsVisibleTemplate\"]],[30,0,[\"deleteCategory\"]],[30,0,[\"deleteModalVisible\"]],[30,0,[\"toggleDeleteCategoryModal\"]],[30,0,[\"categoryModalVisible\"]],[30,0,[\"error\"]],[30,0,[\"categoryTypeOptions\"]],[30,0,[\"selectedCategoryType\"]]]],null]],[],false,[\"category-modal\"]]",
    "moduleName": "itarp-template-designer/templates/templates/categories/edit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});