define("itarp-components/components/ui/input/suggestions-list", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='fieldset-suggestions'>
    <span class='fieldset-suggestions__title'>{{yield}}</span>
    <ul class='fieldset-suggestions__list'>
      {{#each @items as |item|}}
        <li class='fieldset-suggestions__list__item'>
          <a
            {{on 'click' (fn this.select item)}}
            class='fieldset-suggestions__list__link
              {{if (eq item @selected) 'is-added'}}'
          >{{item.label}}</a>
        </li>
      {{/each}}
    </ul>
  </div>
  
  */
  {
    "id": "g80xDyeg",
    "block": "[[[10,0],[14,0,\"fieldset-suggestions\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"fieldset-suggestions__title\"],[12],[18,4,null],[13],[1,\"\\n  \"],[10,\"ul\"],[14,0,\"fieldset-suggestions__list\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[14,0,\"fieldset-suggestions__list__item\"],[12],[1,\"\\n        \"],[11,3],[16,0,[29,[\"fieldset-suggestions__list__link\\n            \",[52,[28,[37,4],[[30,2],[30,3]],null],\"is-added\"]]]],[4,[38,5],[\"click\",[28,[37,6],[[30,0,[\"select\"]],[30,2]],null]],null],[12],[1,[30,2,[\"label\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@items\",\"item\",\"@selected\",\"&default\"],false,[\"yield\",\"each\",\"-track-array\",\"if\",\"eq\",\"on\",\"fn\"]]",
    "moduleName": "itarp-components/components/ui/input/suggestions-list.hbs",
    "isStrictMode": false
  });

  let UiInputSuggestionsListComponent = (_class = class UiInputSuggestionsListComponent extends _component2.default {
    select(suggestion) {
      this.args.setValue && this.args.setValue(suggestion);
      this.args.onChange && this.args.onChange(suggestion);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "select", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype)), _class);
  _exports.default = UiInputSuggestionsListComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiInputSuggestionsListComponent);
});