define("itarp-business-application/util/parser/attribute/attributes/model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = parseModel;

  async function parseModel(data, store) {
    const {
      model_name: modelName,
      attribute_name: attribute,
      attribute_state: state
    } = data;
    const result = await store.getRecordAttribute(modelName, attribute, state);
    return result;
  }
});