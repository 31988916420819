define("itarp-opportunity-ui/helpers/html-safe", ["exports", "@ember/component/helper", "@ember/template"], function (_exports, _helper, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.htmlSafe = htmlSafe;
  _exports.default = void 0;

  function htmlSafe([content]) {
    return (0, _template.htmlSafe)(content);
  }

  var _default = (0, _helper.helper)(htmlSafe);

  _exports.default = _default;
});