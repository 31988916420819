define("itarp-opportunity-ui/serializers/questionnaire", ["exports", "@ember-data/serializer/json", "@ember/string"], function (_exports, _json, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class QuestionnaireSerializer extends _json.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", '_id');
    }

    keyForAttribute(attr, method) {
      return (0, _string.underscore)(attr);
    }

    extractId(modelClass, resourceHash) {
      const id = resourceHash[this.primaryKey];
      return typeof id === 'object' ? id['$oid'] : id;
    }

    normalizeFindAllResponse(store, modelClass, payload, ...rest) {
      const data = payload ? payload.records : [];
      return super.normalizeFindAllResponse(store, modelClass, data, ...rest);
    }

    normalizeQueryResponse(store, modelClass, payload, ...rest) {
      const data = payload ? payload.records : [];
      return super.normalizeQueryResponse(store, modelClass, data, ...rest);
    }

    normalize(modelClass, data) {
      const type = data.type;
      data.type = 'questionnaire';
      const result = super.normalize(modelClass, data);
      result.data.attributes.type = type;
      return result;
    }

  }

  _exports.default = QuestionnaireSerializer;
});