define("itarp-template-designer/serializers/template-workflow", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TemplateWorkflowSerializer extends _jsonApi.default {
    normalize(modelClass, data) {
      data.relationships = {
        workflow: {
          data: {
            id: data.attributes['workflow-id'],
            type: 'business-workflow'
          }
        },
        stages: {
          data: data.attributes['stage-ids'].map(stageId => {
            return {
              id: stageId,
              type: 'business-stage'
            };
          })
        }
      };
      delete data.attributes['workflow-id'];
      delete data.attributes['stage-ids'];
      return super.normalize(...arguments);
    }

  }

  _exports.default = TemplateWorkflowSerializer;
});