define("itarp-components/components/ui/input/radio-group", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='component-wrapper has-padding'>
    <div class='form-options' ...attributes>
      <label class='form-options__label'>
        {{yield to='label'}}
      </label>
      {{#let (unique-id) as |uid|}}
        <div class='form-options__elements'>
          {{yield
            (hash
              radio=(component
                'ui/input/radio' name=uid selected=@value onChange=this.setValue
              )
            )
            to='group'
          }}
        </div>
      {{/let}}
    </div>
  </div>
  */
  {
    "id": "dm0Ww8xR",
    "block": "[[[10,0],[14,0,\"component-wrapper has-padding\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"form-options\"],[17,1],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"form-options__label\"],[12],[1,\"\\n      \"],[18,4,null],[1,\"\\n    \"],[13],[1,\"\\n\"],[44,[[28,[37,2],null,null]],[[[1,\"      \"],[10,0],[14,0,\"form-options__elements\"],[12],[1,\"\\n        \"],[18,5,[[28,[37,3],null,[[\"radio\"],[[50,\"ui/input/radio\",0,null,[[\"name\",\"selected\",\"onChange\"],[[30,2],[30,3],[30,0,[\"setValue\"]]]]]]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"uid\",\"@value\",\"&label\",\"&group\"],false,[\"yield\",\"let\",\"unique-id\",\"hash\",\"component\"]]",
    "moduleName": "itarp-components/components/ui/input/radio-group.hbs",
    "isStrictMode": false
  });

  let UiInputRadioGroupComponent = (_class = class UiInputRadioGroupComponent extends _component2.default {
    setValue(value) {
      if (this.args.setValue) {
        this.args.setValue(value);
      }

      if (this.args.onChange) {
        this.args.onChange(value);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "setValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setValue"), _class.prototype)), _class);
  _exports.default = UiInputRadioGroupComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiInputRadioGroupComponent);
});