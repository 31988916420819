define("ember-cropperjs/templates/components/image-cropper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3PCs7iid",
    "block": "[[[10,\"img\"],[15,1,[28,[37,0],[\"image-cropper-\",[30,0,[\"elementId\"]]],null]],[14,0,\"max-w-full\"],[15,\"src\",[30,0,[\"source\"]]],[15,\"alt\",[30,0,[\"alt\"]]],[12],[13],[1,\"\\n\\n\"],[41,[30,0,[\"_cropper\"]],[[[1,\"  \"],[18,1,[[28,[37,3],null,[[\"cropper\",\"call\",\"on\"],[[30,0,[\"_cropper\"]],[50,\"image-cropper-call\",0,null,[[\"obj\"],[[30,0,[\"_cropper\"]]]]],[50,\"image-cropper-on\",0,null,[[\"cropper\",\"eventSource\"],[[30,0,[\"_cropper\"]],[30,0,[\"_cropper\",\"element\"]]]]]]]]]],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"concat\",\"if\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-cropperjs/templates/components/image-cropper.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});