define("itarp-business-application/components/forms/accordion", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking"], function (_exports, _component, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <BsAccordion class="accordion-list" as |acc|>
    {{#each @items as |item index|}}
      <acc.item @value={{index}} class="accordion-list__item" as |aitem|>
  
        <aitem.title
          @collapsed={{not (eq this.selected index)}}
          class="accordion-list__item__header"
          {{on "click" (fn this.toggle index)}}
          as |title|
        >
          {{yield
            (hash item=item class="accordion-list__item__title")
            to="title"
          }}
          <ul
            class="accordion-list__item__controls"
            {{on "click" this.preventPropagation}}
          >
            <li class="accordion-list__item__controls__item">
              <a
                class="accordion-list__item__controls__link for-edit"
                {{on "click" (fn @edit item index)}}
              ></a>
            </li>
            <li class="accordion-list__item__controls__item">
              <a
                class="accordion-list__item__controls__link for-remove"
                {{on "click" (fn @remove item)}}
              ></a>
            </li>
          </ul>
        </aitem.title>
        <aitem.body
          @class="accordion-list__item__body"
          @collapsed={{not (eq this.selected index)}}
        >
          {{yield item}}
        </aitem.body>
      </acc.item>
    {{/each}}
  </BsAccordion>
  */
  {
    "id": "JJTKfMIx",
    "block": "[[[8,[39,0],[[24,0,\"accordion-list\"]],null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,2]],null]],null],null,[[[1,\"    \"],[8,[30,1,[\"item\"]],[[24,0,\"accordion-list__item\"]],[[\"@value\"],[[30,4]]],[[\"default\"],[[[[1,\"\\n\\n      \"],[8,[30,5,[\"title\"]],[[24,0,\"accordion-list__item__header\"],[4,[38,5],[\"click\",[28,[37,6],[[30,0,[\"toggle\"]],[30,4]],null]],null]],[[\"@collapsed\"],[[28,[37,3],[[28,[37,4],[[30,0,[\"selected\"]],[30,4]],null]],null]]],[[\"default\"],[[[[1,\"\\n        \"],[18,9,[[28,[37,8],null,[[\"item\",\"class\"],[[30,3],\"accordion-list__item__title\"]]]]],[1,\"\\n        \"],[11,\"ul\"],[24,0,\"accordion-list__item__controls\"],[4,[38,5],[\"click\",[30,0,[\"preventPropagation\"]]],null],[12],[1,\"\\n          \"],[10,\"li\"],[14,0,\"accordion-list__item__controls__item\"],[12],[1,\"\\n            \"],[11,3],[24,0,\"accordion-list__item__controls__link for-edit\"],[4,[38,5],[\"click\",[28,[37,6],[[30,7],[30,3],[30,4]],null]],null],[12],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,\"li\"],[14,0,\"accordion-list__item__controls__item\"],[12],[1,\"\\n            \"],[11,3],[24,0,\"accordion-list__item__controls__link for-remove\"],[4,[38,5],[\"click\",[28,[37,6],[[30,8],[30,3]],null]],null],[12],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[6]]]]],[1,\"\\n      \"],[8,[30,5,[\"body\"]],null,[[\"@class\",\"@collapsed\"],[\"accordion-list__item__body\",[28,[37,3],[[28,[37,4],[[30,0,[\"selected\"]],[30,4]],null]],null]]],[[\"default\"],[[[[1,\"\\n        \"],[18,10,[[30,3]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[5]]]]],[1,\"\\n\"]],[3,4]],null]],[1]]]]]],[\"acc\",\"@items\",\"item\",\"index\",\"aitem\",\"title\",\"@edit\",\"@remove\",\"&title\",\"&default\"],false,[\"bs-accordion\",\"each\",\"-track-array\",\"not\",\"eq\",\"on\",\"fn\",\"yield\",\"hash\"]]",
    "moduleName": "itarp-business-application/components/forms/accordion.hbs",
    "isStrictMode": false
  });

  let FormsAccordionComponent = (_class = class FormsAccordionComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "selected", _descriptor, this);
    }

    toggle(index, element) {
      const isSelected = this.selected === index;
      this.selected = isSelected ? null : index;
    }

    preventPropagation(event) {
      event.stopPropagation();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "preventPropagation", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "preventPropagation"), _class.prototype)), _class);
  _exports.default = FormsAccordionComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormsAccordionComponent);
});