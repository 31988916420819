define("itarp-components/services/search", ["exports", "@ember/service", "@ember/application", "moment", "@glimmer/tracking", "itarp-components/utils/constants/google-api"], function (_exports, _service, _application, _moment, _tracking, _googleApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SearchService = (_class = class SearchService extends _service.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "autocomplete", null);

      _initializerDefineProperty(this, "token", _descriptor, this);

      _initializerDefineProperty(this, "timestamp", _descriptor2, this);

      _initializerDefineProperty(this, "countries", _descriptor3, this);

      _initializerDefineProperty(this, "fields", _descriptor4, this);
    }

    get apiKey() {
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return config.GOOGLE_API_KEY;
    }

    get options() {
      return {
        types: [_googleApi.PLACE.TYPES.GEO],
        fields: this.fields,
        sessionToken: this.sessionToken,
        componentRestrictions: {
          country: this.countries
        }
      };
    }

    async initAutocomplete(input, opts) {
      const loaded = await this.isLoaded();
      if (loaded === false) return;
      if (opts.countries) this.countries.pushObject(opts.countries);
      this.fields.pushObjects(opts.fields);
      this.autocomplete = new google.maps.places.Autocomplete(input, this.options);
      this.autocomplete.addListener('place_changed', this.getComponents.bind(this, opts.components, opts.onSelect));
    }

    setAutocompleteCountry(country) {
      this.countries = [country];
      this.autocomplete.setOptions(this.options);
    }

    generateSessionToken() {
      this.timestamp = (0, _moment.default)(new Date());
      this.token = new google.maps.places.AutocompleteSessionToken();
      return this.token;
    }

    get tokenIsExpired() {
      if (!this.timestamp) return true;
      const tokenAge = (0, _moment.default)(new Date()).diff(this.timestamp, 'seconds');
      const expired = tokenAge > 90;
      return expired;
    }

    get sessionToken() {
      if (this.tokenIsExpired) return this.generateSessionToken();
      return this.token;
    }

    get placesService() {
      const service = new google.maps.places.PlacesService(document.createElement('div'));
      return service;
    }

    async getComponents(componentTypes, callbackFn) {
      const place = await this.autocomplete.getPlace();
      this.token = null;
      this.autocomplete.setOptions(this.options);
      return callbackFn(place);
    }

    getPlaceAddressByPlaceId(placeId) {
      var _obj, _init;

      if (!!window.google === false) return;
      const result = (_obj = {
        label: null
      }, (_applyDecoratedDescriptor(_obj, "label", [_tracking.tracked], (_init = Object.getOwnPropertyDescriptor(_obj, "label"), _init = _init ? _init.value : undefined, {
        enumerable: true,
        configurable: true,
        writable: true,
        initializer: function () {
          return _init;
        }
      }), _obj)), _obj);
      this.placesService.getDetails({
        placeId,
        fields: [_googleApi.PLACE.FIELDS.PRETTY_ADDR]
      }, place => {
        if (place && place[_googleApi.PLACE.FIELDS.PRETTY_ADDR]) {
          result.label = place[_googleApi.PLACE.FIELDS.PRETTY_ADDR];
        }
      });
      return result;
    }

    async placeDetailsById(placeId, fields = []) {
      const isLoaded = await this.isLoaded();
      if (isLoaded === false) return;
      return new Promise(resolve => {
        this.placesService.getDetails({
          placeId,
          fields
        }, result => {
          resolve(result);
        });
      });
    }

    getComponent(components, type) {
      const component = components.find(c => c.types[0] === type);
      return component;
    }

    async isLoaded() {
      let loaded = !!window.google;

      if (loaded === false) {
        await this.loadMapsScript().then(() => {
          loaded = true;
        }).catch(error => {
          return error;
        });
      }

      return loaded;
    }

    async loadMapsScript() {
      return new Promise((resolve, reject) => {
        if (!this.apiKey) return reject('No Google API key');
        const maps = document.createElement('script');
        maps.type = 'text/javascript';
        maps.src = _googleApi.URLS.MAPS(this.apiKey);
        document.body.appendChild(maps);

        maps.onload = () => resolve();
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "token", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "timestamp", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "countries", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fields", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  })), _class);
  _exports.default = SearchService;
});