define("itarp-shared-assets-addon/components/remote-frontend", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let (unique-id) as |id|}}
    <div id={{id}} {{did-insert this.mount}} {{will-destroy this.unmount}}></div>
  {{/let}}
  */
  {
    "id": "qgJuz/MR",
    "block": "[[[44,[[28,[37,1],null,null]],[[[1,\"  \"],[11,0],[16,1,[30,1]],[4,[38,2],[[30,0,[\"mount\"]]],null],[4,[38,3],[[30,0,[\"unmount\"]]],null],[12],[13],[1,\"\\n\"]],[1]]]],[\"id\"],false,[\"let\",\"unique-id\",\"did-insert\",\"will-destroy\"]]",
    "moduleName": "itarp-shared-assets-addon/components/remote-frontend.hbs",
    "isStrictMode": false
  });

  let RemoteFrontendComponent = (_class = class RemoteFrontendComponent extends _component2.default {
    async mount(element) {
      const options = {
        containerUrl: this.args.url,
        containerScope: this.args.scope,
        containerModuleName: this.args.moduleName,
        params: this.args.params,
        childProps: this.args.childProps,
        children: this.args.children,
        fallback: this.args.fallback,
        loadingComponent: this.args.loadingComponent
      };
      emberAutoImportDynamic("itarp-micro-host").then(async ({
        renderHost
      }) => {
        renderHost(element, options);
      });
    }

    async unmount(element) {
      emberAutoImportDynamic("itarp-micro-host").then(async ({
        unmountHost
      }) => {
        unmountHost(element);
      });
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "mount", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "mount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unmount", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "unmount"), _class.prototype)), _class);
  _exports.default = RemoteFrontendComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RemoteFrontendComponent);
});