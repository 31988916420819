define("itarp-opportunity-designer/models/proposal", ["exports", "@ember-data/model", "itarp-opportunity-designer/utils/string/humanize", "@ember/template", "@glimmer/tracking"], function (_exports, _model, _humanize, _template, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProposalModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('date'), _dec4 = (0, _model.belongsTo)('user', {
    async: false
  }), _dec5 = (0, _model.belongsTo)('opportunity'), _dec6 = (0, _model.belongsTo)('talent-cloud'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)(), _dec15 = (0, _model.attr)(), _dec16 = (0, _model.hasMany)('answer'), _dec17 = (0, _model.attr)('boolean'), _dec18 = (0, _model.attr)('string'), _dec19 = (0, _model.attr)('boolean'), (_class = class ProposalModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "resourceId", _descriptor, this);

      _initializerDefineProperty(this, "resourceType", _descriptor2, this);

      _initializerDefineProperty(this, "createdAt", _descriptor3, this);

      _initializerDefineProperty(this, "user", _descriptor4, this);

      _initializerDefineProperty(this, "opportunity", _descriptor5, this);

      _initializerDefineProperty(this, "talentCloud", _descriptor6, this);

      _initializerDefineProperty(this, "userId", _descriptor7, this);

      _initializerDefineProperty(this, "status", _descriptor8, this);

      _initializerDefineProperty(this, "name", _descriptor9, this);

      _initializerDefineProperty(this, "resume", _descriptor10, this);

      _initializerDefineProperty(this, "email", _descriptor11, this);

      _initializerDefineProperty(this, "coverLetter", _descriptor12, this);

      _initializerDefineProperty(this, "fit", _descriptor13, this);

      _initializerDefineProperty(this, "rate", _descriptor14, this);

      _initializerDefineProperty(this, "role", _descriptor15, this);

      _initializerDefineProperty(this, "answers", _descriptor16, this);

      _initializerDefineProperty(this, "selectedForBulk", _descriptor17, this);

      _initializerDefineProperty(this, "workflowStageId", _descriptor18, this);

      _initializerDefineProperty(this, "clientVisible", _descriptor19, this);

      _initializerDefineProperty(this, "_displayRate", _descriptor20, this);

      _initializerDefineProperty(this, "markupRate", _descriptor21, this);
    }

    get statusName() {
      return (0, _humanize.default)(this.status);
    }

    get coverLetterSanitized() {
      if (this.coverLetter) return (0, _template.htmlSafe)(this.coverLetter);
    }

    get displayRate() {
      if (this._displayRate) return this._displayRate;
      return null;
    }

    set displayRate(value) {
      this._displayRate = value;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "resourceId", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "resourceType", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "opportunity", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "talentCloud", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "userId", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "resume", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "email", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "coverLetter", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "fit", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "rate", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "role", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "answers", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "selectedForBulk", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "workflowStageId", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "clientVisible", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "_displayRate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "markupRate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ProposalModel;
});