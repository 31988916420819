define("itarp-opportunity-designer/helpers/humanize", ["exports", "@ember/component/helper", "itarp-opportunity-designer/utils/string/humanize"], function (_exports, _helper, _humanize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(([value]) => (0, _humanize.default)(value));

  _exports.default = _default;
});