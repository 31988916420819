define("shared/adapters/user-favorite", ["exports", "shared/adapters/business-application", "@ember/service"], function (_exports, _businessApplication, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class UserFavoriteAdapter extends _businessApplication.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", '/api/expert/api/opportunity/routing/data_service');
    }

    get headers() {
      const result = super.headers;
      return result;
    }

    handleResponse(status, headers, payload) {
      if (!this.isSuccess(status, headers, payload)) {
        return payload.errors ? `${payload.errors.charAt(0).toUpperCase()}${payload.errors.slice(1)}` : '';
      }

      return super.handleResponse(...arguments);
    }

    createRecord(store, schema, snapshot) {
      const data = this.serialize(snapshot, {
        includeId: true
      });
      const type = snapshot.modelName;
      const id = snapshot.id;
      let url = this.buildURL(type, id, snapshot, 'updateRecord');
      return this.ajax(url, 'POST', {
        data: data
      });
    }

    createRecord(store, type, snapshot) {
      const url = this.buildURL(type.modelName, null, snapshot, 'createRecord');
      return this.ajax(url, 'POST', {
        snapshot
      });
    }

    urlForUpdateRecord() {
      return `${this.namespace}/favorites`;
    }

    urlForCreateRecord() {
      return `${this.namespace}/favorites`;
    }

    urlForQuery(query) {
      var _query$adapterOptions, _query$adapterOptions2;

      if ((_query$adapterOptions = query.adapterOptions) !== null && _query$adapterOptions !== void 0 && _query$adapterOptions.favorites) {
        delete query.adapterOptions.favorites;
        return `${this.namespace}/favorites`;
      } else if ((_query$adapterOptions2 = query.adapterOptions) !== null && _query$adapterOptions2 !== void 0 && _query$adapterOptions2.talentCloudFavorites) {
        delete query.adapterOptions.talentCloudFavorites;
        return `/api/expert/api/talentcloud/routing/data_service/favorites`;
      } else {
        return `/api/expert/routing/data/user-favorites`;
      }
    }

    urlForDeleteRecord(id, modelName, snapshot) {
      return `${this.namespace}/favorites`;
    }

  }

  _exports.default = UserFavoriteAdapter;
});