define("itarp-workflow-designer/mirage/endpoints/workflow-templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(context) {
    context.namespace = '/routing/workflow/api/v1/workflows/templates';
    context.get('/', (schema, request) => {
      return schema.workflowTemplates.all();
    });
    context.get('/:id', (schema, request) => {
      return schema.workflowTemplates.find(request.params.id);
    });
  }
});