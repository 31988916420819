define("itarp-opportunity-ui/components/question/types/experience", ["exports", "@ember/component/template-only"], function (_exports, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class="input-group
      {{if @question.isRequired "is-required"}}
      {{if @isError "has-error"}}"
  >
    <label class="input-group__label">
      {{#if @question.isRequired}}
        <span class="label-note label-note--required"></span>
      {{/if}}
      How many years of
      {{@question.title}}
      experience you have?
  
    </label>
    <input
      type="text"
      value={{@value}}
      {{on "input" @onChange}}
      class="input-group__field disabled"
      placeholder="3"
      disabled={{@disabled}}
    />
  </div>
  */
  {
    "id": "T/BECza5",
    "block": "[[[10,0],[15,0,[29,[\"input-group\\n    \",[52,[30,1,[\"isRequired\"]],\"is-required\"],\"\\n    \",[52,[30,2],\"has-error\"]]]],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"input-group__label\"],[12],[1,\"\\n\"],[41,[30,1,[\"isRequired\"]],[[[1,\"      \"],[10,1],[14,0,\"label-note label-note--required\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"    How many years of\\n    \"],[1,[30,1,[\"title\"]]],[1,\"\\n    experience you have?\\n\\n  \"],[13],[1,\"\\n  \"],[11,\"input\"],[16,2,[30,3]],[24,0,\"input-group__field disabled\"],[24,\"placeholder\",\"3\"],[16,\"disabled\",[30,4]],[24,4,\"text\"],[4,[38,1],[\"input\",[30,5]],null],[12],[13],[1,\"\\n\"],[13]],[\"@question\",\"@isError\",\"@value\",\"@disabled\",\"@onChange\"],false,[\"if\",\"on\"]]",
    "moduleName": "itarp-opportunity-ui/components/question/types/experience.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});