define("itarp-business-application/services/model-loader", ["exports", "@ember/service", "@glimmer/tracking", "@ember/object"], function (_exports, _service, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ModelLoaderService = (_dec = (0, _service.inject)('ad-token-service'), (_class = class ModelLoaderService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "models", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "adTokenService", _descriptor3, this);
    }

    async load() {
      const adapter = this.store.adapterFor('application');
      const headers = adapter.headers;
      this.adTokenService.validateToken();
      await fetch('/routing/config/get-config/data-service/1', {
        headers: { ...headers,
          Authorization: `Bearer ${this.adTokenService.token}`
        }
      }).then(async response => {
        const result = await response.json();
        this.models = this.mapSchema(result.data);
      });
    }

    mapSchema(models) {
      const schema = {};

      for (const model of models) {
        const {
          model_name: name,
          attribute_details: attributes
        } = model;
        const modelName = name.toLowerCase();
        schema[modelName] = {
          name: modelName,
          collectionName: model.collection_name,
          relations: {}
        };

        for (const attribute of attributes) {
          const {
            dataType,
            isArray,
            attrName,
            refModelId
          } = attrData(attribute);

          if (dataType === 'model_reference' && refModelId) {
            const refModel = models.findBy('id', refModelId);
            const refModelName = refModel && refModel.model_name.toLowerCase();

            if (refModel) {
              schema[modelName].relations[attrName] = {
                type: refModelName,
                isCollection: isArray,
                attribute: attrName
              };
            }
          }
        }
      }

      return schema;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "models", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "adTokenService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "load", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "load"), _class.prototype)), _class));
  _exports.default = ModelLoaderService;

  function attrData(attribute = {}) {
    return {
      dataType: attribute.type_definition,
      isArray: attribute.is_array,
      attrName: attribute.name,
      refModelId: attribute.meta_model_id
    };
  }
});