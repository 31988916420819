define("itarp-workflow-designer/adapters/workflow", ["exports", "itarp-auth-addon/adapters/ad-token-data", "@ember-data/adapter/error", "@ember/array"], function (_exports, _adTokenData, _error, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class WorkflowAdapter extends _adTokenData.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", '/routing/workflow/api/v1/');
    }

    get headers() {
      return { ...super.headers,
        Accept: 'application/json'
      };
    }

    handleResponse(status, headers, payload) {
      if (this.isInvalid(status, headers, payload)) {
        return new _error.InvalidError(this.formatErrors(payload));
      }

      return super.handleResponse(...arguments);
    }

    urlForUpdateRecord(id, modelName, snapshot) {
      const options = snapshot.adapterOptions;
      this.publish = !!(options !== null && options !== void 0 && options.publish);
      if (this.publish) return `${this.namespace}workflows/${id}/publish`;
      return super.urlForUpdateRecord(...arguments);
    }

    urlForUnpublishedRevision(id) {
      return `${this.namespace}/workflows/${id}/unpublished_version`;
    } //Format errors for publish


    formatErrors(payload) {
      const errorString = payload.replace(':errors', '"errors"').replace('STAGES_MISSING', 'Stages Missing').replaceAll('=>', ':');
      const errorIsJsonValid = errorString.includes('errors') ? true : false;
      const errorObject = errorIsJsonValid ? JSON.parse(errorString) : JSON.parse(JSON.stringify({
        errors: {
          error: [errorString]
        }
      }));
      const jsonApiErrors = Object.keys(errorObject.errors).map(key => {
        return {
          id: key,
          status: '422',
          code: key,
          title: `Invalid: ${key}`,
          detail: errorObject.errors[key].join(', ')
        };
      });
      const jsonApiErrorsResultString = JSON.stringify({
        errors: jsonApiErrors
      });
      const jsonApiErrorsResult = JSON.parse(jsonApiErrorsResultString);
      const result = (0, _array.A)([]);

      if (jsonApiErrorsResult) {
        jsonApiErrorsResult.errors.forEach(error => {
          const source = {
            pointer: `/data/attributes/${error.code}`
          };
          const detail = error.code === 'Stages Missing' ? `Missing required stages: ${error.detail}` : error.detail;
          result.pushObject({
            source,
            detail
          });
        });
      }

      return result;
    }

  }

  _exports.default = WorkflowAdapter;
});