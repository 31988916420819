define("itarp-opportunity-ui/controllers/my-offers", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/object", "@ember/service"], function (_exports, _controller, _tracking, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MyOffersController = (_dec = (0, _service.inject)('ad-token-service'), (_class = class MyOffersController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "layout", _descriptor2, this);

      _initializerDefineProperty(this, "toast", _descriptor3, this);

      _initializerDefineProperty(this, "adTokenService", _descriptor4, this);

      _initializerDefineProperty(this, "workflows", _descriptor5, this);

      _initializerDefineProperty(this, "isOfferAccepted", _descriptor6, this);

      _initializerDefineProperty(this, "offerId", _descriptor7, this);

      _initializerDefineProperty(this, "selectedOffer", _descriptor8, this);

      _initializerDefineProperty(this, "application", _descriptor9, this);

      _initializerDefineProperty(this, "fetchingApplications", _descriptor10, this);

      _initializerDefineProperty(this, "fetchingRelevantOpportunity", _descriptor11, this);

      _initializerDefineProperty(this, "opportunity", _descriptor12, this);

      _initializerDefineProperty(this, "applyOffscreen", _descriptor13, this);

      _initializerDefineProperty(this, "errors", _descriptor14, this);
    }

    registerOffscreenAPI(options) {
      this.applyOffscreen = options;
    }

    handleOffer(status) {
      const workflowId = this.opportunity.workflowId;
      this.selectedOffer.offerStatus = status;
      this.selectedOffer.save({
        adapterOptions: {
          mode: status
        }
      }).then(() => {
        this.toast.show('You have succesfully ' + status + ' the offer.');
        const stageType = status === 'accepted' ? 'offer_accepted' : 'offer_rejected';
        this.workflows.findStageByTypeName(workflowId, stageType, 'offer').then(stage => {
          if (stage) {
            this.application.workflowStageId = stage;
            this.application.save();
          }
        });
      });
    }

    getApplications(applicationId) {
      if (applicationId && !this.fetchingApplications) {
        this.fetchingApplications = true;
        this.store.findRecord('application', applicationId).then(application => {
          this.application = application;
          this.fetchingApplications = false;
          this.getRelevantOpportunity(application.resourceId);
        });
      }
    }

    getRelevantOpportunity(resourceId) {
      if (resourceId && !this.fetchingRelevantOpportunity) {
        this.fetchingRelevantOpportunity = true;
        this.store.findRecord('opportunity', resourceId).then(opportunity => {
          this.opportunity = opportunity;
          this.fetchingRelevantOpportunity = false;
        });
      }
    }

    changeOffer(offerId) {
      if (this.offerId === offerId) return;
      this.offerId = offerId;
      (0, _object.set)(this, 'selectedOffer', this.offers.findBy('id', offerId));
      this.getApplications(this.selectedOffer.applicationId);

      if (this.applyOffscreen && this.applyOffscreen.isOpen) {
        this.applyOffscreen.close();
      }
    }

    trackElement(element) {
      window.addEventListener('scroll', this.setElementPosition.bind(this, element));
    }

    removeScrollListener() {
      window.removeEventListener('scroll', this.setElementPosition);
    }

    setElementPosition(element) {
      this.layout.filterPositionBottom = element.getBoundingClientRect().bottom;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "layout", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "toast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "adTokenService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "workflows", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isOfferAccepted", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "offerId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "selectedOffer", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "application", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "fetchingApplications", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "fetchingRelevantOpportunity", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "opportunity", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "applyOffscreen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "errors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "registerOffscreenAPI", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerOffscreenAPI"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleOffer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleOffer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getApplications", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getApplications"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getRelevantOpportunity", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getRelevantOpportunity"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeOffer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeOffer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "trackElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "trackElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeScrollListener", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeScrollListener"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setElementPosition", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setElementPosition"), _class.prototype)), _class));
  _exports.default = MyOffersController;
});