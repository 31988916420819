define("itarp-opportunity-designer/adapters/permission", ["exports", "itarp-auth-addon/adapters/ad-token-data"], function (_exports, _adTokenData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class PermissionAdapter extends _adTokenData.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", '/rbac');
    }

  }

  _exports.default = PermissionAdapter;
});