define("itarp-workflow-designer/mirage/endpoints/stages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(context) {
    context.get('stages', function (schema, request) {
      let data;

      if (request.queryParams) {
        const workflow = request.queryParams.workflow_id;
        const filter = schema.stageGroups.where(group => group.workflowId === workflow).models.map(group => group.id);
        data = schema.stages.where(stage => stage.stageGroupId && filter.includes(stage.stageGroupId));
      } else {
        data = schema.stages.all();
      }

      return this.serialize(data);
    });
    context.get('stages/:id');
    context.post('stages');
    context.patch('stages/:id');
    context.del('stages/:id');
  }
});