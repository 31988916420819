define("itarp-opportunity-ui/components/question/types/visa", ["exports", "@ember/component/template-only"], function (_exports, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class="form-options in-line
      {{if @question.isRequired "is-required"}}
      {{if @isError "has-error"}}"
  >
    <label class="form-options__label">
      {{#if @question.isRequired}}
        <span class="label-note label-note--required"></span>
      {{/if}}
      Will you now, or in the future require sponsorship for employment visa
      status (e.g. H-18 visa status)?
    </label>
    <div class="form-options__elements">
      <div class="radio-group {{if @isError "has-error"}}">
        <input
          type="radio"
          class="radio-group__field"
          name="sponsorship"
          checked={{eq @value "Yes"}}
          id="sponsorship_yes"
          value="Yes"
          disabled={{@disabled}}
          {{on "change" @onChange}}
        />
        <label class="radio-group__label" for="sponsorship_yes">Yes</label>
      </div>
      <div class="radio-group {{if @isError "has-error"}}">
        <input
          type="radio"
          class="radio-group__field"
          name="sponsorship"
          id="sponsorship_no"
          checked={{eq @value "No"}}
          value="No"
          disabled={{@disabled}}
          {{on "change" @onChange}}
        />
        <label class="radio-group__label" for="sponsorship_no">No</label>
      </div>
    </div>
  </div>
  */
  {
    "id": "fOyhggCl",
    "block": "[[[10,0],[15,0,[29,[\"form-options in-line\\n    \",[52,[30,1,[\"isRequired\"]],\"is-required\"],\"\\n    \",[52,[30,2],\"has-error\"]]]],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"form-options__label\"],[12],[1,\"\\n\"],[41,[30,1,[\"isRequired\"]],[[[1,\"      \"],[10,1],[14,0,\"label-note label-note--required\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"    Will you now, or in the future require sponsorship for employment visa\\n    status (e.g. H-18 visa status)?\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"form-options__elements\"],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[\"radio-group \",[52,[30,2],\"has-error\"]]]],[12],[1,\"\\n      \"],[11,\"input\"],[24,0,\"radio-group__field\"],[24,3,\"sponsorship\"],[16,\"checked\",[28,[37,1],[[30,3],\"Yes\"],null]],[24,1,\"sponsorship_yes\"],[24,2,\"Yes\"],[16,\"disabled\",[30,4]],[24,4,\"radio\"],[4,[38,2],[\"change\",[30,5]],null],[12],[13],[1,\"\\n      \"],[10,\"label\"],[14,0,\"radio-group__label\"],[14,\"for\",\"sponsorship_yes\"],[12],[1,\"Yes\"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[15,0,[29,[\"radio-group \",[52,[30,2],\"has-error\"]]]],[12],[1,\"\\n      \"],[11,\"input\"],[24,0,\"radio-group__field\"],[24,3,\"sponsorship\"],[24,1,\"sponsorship_no\"],[16,\"checked\",[28,[37,1],[[30,3],\"No\"],null]],[24,2,\"No\"],[16,\"disabled\",[30,4]],[24,4,\"radio\"],[4,[38,2],[\"change\",[30,5]],null],[12],[13],[1,\"\\n      \"],[10,\"label\"],[14,0,\"radio-group__label\"],[14,\"for\",\"sponsorship_no\"],[12],[1,\"No\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@question\",\"@isError\",\"@value\",\"@disabled\",\"@onChange\"],false,[\"if\",\"eq\",\"on\"]]",
    "moduleName": "itarp-opportunity-ui/components/question/types/visa.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});