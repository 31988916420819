define("shared/utils/constants/google-api", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ADDR_CPNT = _exports.PLACE = _exports.URLS = void 0;
  const BASE_URL = 'https://maps.googleapis.com/maps/api';
  const GEOCODE_URL = `${BASE_URL}/geocode/json?`;

  const MAPS_URL = (API_KEY, callback) => `${BASE_URL}/js?key=${API_KEY}&libraries=places&callback=${callback}`;

  const PLACE_TYPES = {
    GEO: 'geocode'
  };
  const PLACE_FIELDS = {
    GEO: 'geometry',
    ADDR: 'address_components',
    LOCATION: 'geometry.location',
    PLACE_ID: 'place_id',
    PRETTY_ADDR: 'formatted_address'
  };
  const ADDR_CPNT = {
    LOC: 'locality',
    ADMIN_AREA: 'administrative_area_level_1',
    ZIP: 'postal_code',
    COUNTRY: 'country'
  };
  _exports.ADDR_CPNT = ADDR_CPNT;
  const PLACE = {
    TYPES: PLACE_TYPES,
    FIELDS: PLACE_FIELDS
  };
  _exports.PLACE = PLACE;
  const URLS = {
    BASE: BASE_URL,
    GEOCODE: GEOCODE_URL,
    MAPS: MAPS_URL
  };
  _exports.URLS = URLS;
});