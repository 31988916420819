define("itarp-template-designer/adapters/template", ["exports", "itarp-auth-addon/adapters/ad-token-adapter"], function (_exports, _adTokenAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class TemplateAdapter extends _adTokenAdapter.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", '/template-designer/template-service/api/v1');
    }

    get headers() {
      const headers = super.headers || {};
      headers['Content-Type'] = 'application/json';
      return headers;
    }

    urlForCreateRecord(modelName, snapshot) {
      const cloneId = snapshot.record.cloneId;
      const adapterOptions = snapshot.adapterOptions || {};

      if (cloneId) {
        return `${this.namespace}/templates/${cloneId}/clone`;
      } else if (adapterOptions.variant) {
        return `${this.namespace}/templates/publish`;
      }

      return super.urlForCreateRecord(...arguments);
    }

    urlForUnpublishedRevision(id) {
      return `${this.namespace}/templates/${id}/unpublished_revision`;
    }

  }

  _exports.default = TemplateAdapter;
});