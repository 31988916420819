define("itarp-components/utils/taxonomy/category", ["exports", "@glimmer/tracking", "itarp-components/utils/taxonomy/helpers"], function (_exports, _tracking, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Category = (_class = class Category {
    constructor(category, store) {
      _defineProperty(this, "name", void 0);

      _defineProperty(this, "left", void 0);

      _defineProperty(this, "right", void 0);

      _defineProperty(this, "children", []);

      _defineProperty(this, "isCategory", true);

      _initializerDefineProperty(this, "loadedChildren", _descriptor, this);

      this.name = category.name;
      this.left = category.left;
      this.right = category.right;
      this.store = store;
    }

    get skills() {
      return _helpers.getChildTaxonomy.apply(this, ['skill']);
    }

    get tools() {
      return _helpers.getChildTaxonomy.apply(this, ['tool']);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "loadedChildren", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class);
  _exports.default = Category;
});