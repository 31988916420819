define("itarp-workflow-designer/serializers/workflow-template", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class WorkflowTemplateSerializer extends _jsonApi.default {
    normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.data.length) {
        for (const record of payload.data) {
          record.type = 'workflow-template';
        }
      }

      return super.normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType);
    }

  }

  _exports.default = WorkflowTemplateSerializer;
});