define("ember-file-upload/system/http-request", ["exports", "@ember/runloop", "rsvp", "ember-file-upload/system/parse-html", "ember-file-upload/system/parse-xml", "ember-file-upload/system/parse-json"], function (_exports, _runloop, _rsvp, _parseHtml, _parseXml, _parseJson) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function getHeader(headers, header) {
    let headerKeys = Object.keys(headers);
    let headerIdx = headerKeys.map(key => key.toLowerCase()).indexOf(header.toLowerCase());

    if (headerIdx !== -1) {
      return headers[headerKeys[headerIdx]];
    }

    return null;
  }

  function parseResponse(request) {
    var body = request.responseText.trim();
    var rawHeaders = request.getAllResponseHeaders().split(/\n|\r/).filter(function (header) {
      return header !== '';
    });
    var headers = rawHeaders.reduce(function (E, header) {
      var parts = header.split(/^([0-9A-Za-z_-]*:)/);

      if (parts.length > 0 && parts[1] && parts[2]) {
        E[parts[1].slice(0, -1)] = parts[2].trim();
      }

      return E;
    }, {});
    let contentType = (getHeader(headers, 'Content-Type') || '').split(';'); // Parse body according to the Content-Type received by the server

    if (contentType.indexOf('text/html') !== -1) {
      body = (0, _parseHtml.default)(body);
    } else if (contentType.indexOf('text/xml') !== -1) {
      body = (0, _parseXml.default)(body);
    } else if (contentType.indexOf('application/json') !== -1 || contentType.indexOf('application/vnd.api+json') !== -1 || contentType.indexOf('text/javascript') !== -1 || contentType.indexOf('application/javascript') !== -1) {
      body = (0, _parseJson.default)(body);
    }

    return {
      status: request.status,
      body: body,
      headers: headers
    };
  }

  function _default(options = {}) {
    let {
      resolve,
      reject,
      promise
    } = _rsvp.default.defer(`ember-file-upload: ${options.label}`);

    let request = new XMLHttpRequest();
    request.withCredentials = options.withCredentials;
    let aborted;

    promise.cancel = () => {
      if (aborted == null) {
        aborted = _rsvp.default.defer(`ember-file-upload: Abort ${options.label}`);
        request.abort();
      }

      return aborted.promise;
    };

    promise.then = function (...args) {
      let newPromise = _rsvp.default.Promise.prototype.then.apply(this, args);

      newPromise.cancel = promise.cancel;
      newPromise.then = promise.then;
      return newPromise;
    };

    request.onabort = (0, _runloop.bind)(this, function () {
      this.onabort();
      aborted.resolve();
    });

    this.setRequestHeader = function (header, value) {
      request.setRequestHeader(header, value);
    };

    this.open = function (method, url, _, username = '', password = '') {
      request.open(method, url, true, username, password);
    };

    this.send = function (data) {
      request.send(data);
      return promise;
    };

    this.onprogress = this.onprogress || function () {};

    this.ontimeout = this.ontimeout || function () {};

    this.onabort = this.onabort || function () {};

    request.onloadstart = request.onprogress = request.onloadend = (0, _runloop.bind)(this, function (evt) {
      this.onprogress(evt);
    });

    if (request.upload) {
      request.upload.onprogress = request.onprogress;
    }

    request.onload = (0, _runloop.bind)(this, function () {
      let response = parseResponse(request);

      if (Math.floor(response.status / 200) === 1) {
        resolve(response);
      } else {
        reject(response);
      }
    });
    request.onerror = (0, _runloop.bind)(this, function () {
      reject(parseResponse(request));
    });
    Object.defineProperty(this, 'timeout', {
      get() {
        return request.timeout;
      },

      set(timeout) {
        request.timeout = timeout;
      },

      enumerable: true,
      configurable: false
    });
    request.ontimeout = (0, _runloop.bind)(this, function () {
      this.ontimeout();
      reject(parseResponse(request));
    });
  }
});