define("type/enum/socket-message-type", ["require", "exports"], function (require, exports) {
  "use strict";
  Object.defineProperty(exports, "__esModule", { value: true });
  exports.SocketMessageType = void 0;
  var SocketMessageType;
  (function (SocketMessageType) {
      SocketMessageType["REGISTER"] = "REGISTER";
      SocketMessageType["NOTIFICATION"] = "NOTIFICATION";
      SocketMessageType["REGISTER_ERROR"] = "REGISTER_ERROR";
      SocketMessageType["REGISTER_SUCCESS"] = "REGISTER_SUCCESS";
  })(SocketMessageType = exports.SocketMessageType || (exports.SocketMessageType = {}));
});
define("type/registerDto", ["require", "exports"], function (require, exports) {
  "use strict";
  Object.defineProperty(exports, "__esModule", { value: true });
  exports.RegisterDto = void 0;
  /**
   * Data that describes recipient.
   */
  class RegisterDto {
  }
  exports.RegisterDto = RegisterDto;
});
/**
* Data that describes recipient.
*/
define("type/register-error.dto", ["require", "exports"], function (require, exports) {
  "use strict";
  Object.defineProperty(exports, "__esModule", { value: true });
  exports.RegisterErrorDto = void 0;
  class RegisterErrorDto {
  }
  exports.RegisterErrorDto = RegisterErrorDto;
});
define("type/socket-message", ["require", "exports"], function (require, exports) {
  "use strict";
  Object.defineProperty(exports, "__esModule", { value: true });
  exports.SocketMessage = void 0;
  /**
   * Data that describes socket message received from socket client.
   */
  class SocketMessage {
  }
  exports.SocketMessage = SocketMessage;
});
define("socket.client", ["require", "exports", "type/enum/socket-message-type"], function (require, exports, socket_message_type_1) {
  "use strict";
  Object.defineProperty(exports, "__esModule", { value: true });
  exports.SocketClient = void 0;
  /**
   * Socket client library to provide interface for successful communication
   * with Socket Service.
   */
  class SocketClient {
      /**
       * Setter.
       *
       * @param value
       */
      setOnNotificationCallback(value) {
          this.onNotificationCallback = value;
      }
      /**
       * Setter.
       *
       * @param value
       */
      setOnRegisterSuccessCallback(value) {
          this.onRegisterSuccessCallback = value;
      }
      /**
       * Setter.
       *
       * @param value
       */
      setOnRegisterErrorCallback(value) {
          this.onRegisterErrorCallback = value;
      }
      /**
       * Registering user (starting web socket connection and configuring all necessary listeners).
       */
      /* istanbul ignore next */
      registerUser(token, connectionString) {
          const socket = new WebSocket(connectionString);
          socket.addEventListener('open', () => {
              this.onConnectionOpen(socket, token);
          });
          socket.addEventListener('message', (messageEvent) => {
              this.onMessage(socket, messageEvent);
          });
      }
      /**
       * Executed when client successfully opens connection
       * with socket server.
       *
       * It registers user with the token.
       *
       * @param socket
       * @param token
       */
      onConnectionOpen(socket, token) {
          const registerMessage = {
              type: socket_message_type_1.SocketMessageType.REGISTER,
              data: {
                  token
              }
          };
          socket.send(JSON.stringify(registerMessage));
      }
      /**
       * When message from socket service is received, this method executes appropriate
       * registered callback (based on socket message type).
       *
       * @param socket
       * @param messageEvent
       */
      onMessage(socket, messageEvent) {
          const message = JSON.parse(messageEvent.data);
          if (message.type === socket_message_type_1.SocketMessageType.NOTIFICATION) {
              this.onNotificationCallback ? this.onNotificationCallback(message) : null;
          }
          else if (message.type === socket_message_type_1.SocketMessageType.REGISTER_SUCCESS) {
              this.onRegisterSuccessCallback ? this.onRegisterSuccessCallback() : null;
          }
          else if (message.type === socket_message_type_1.SocketMessageType.REGISTER_ERROR) {
              this.onRegisterErrorCallback ? this.onRegisterErrorCallback(message.data.errors) : null;
          }
      }
  }
  exports.SocketClient = SocketClient;
});
define("socket.client.spec", ["require", "exports", "socket.client", "type/enum/socket-message-type"], function (require, exports, socket_client_1, socket_message_type_2) {
  "use strict";
  Object.defineProperty(exports, "__esModule", { value: true });
  describe('SocketClient', () => {
      it('Should properly instantiate SocketClient', () => {
          const sc = new socket_client_1.SocketClient();
          expect(sc).toBeDefined();
      });
      it('Should properly send register request, on connection open', () => {
          const sc = new socket_client_1.SocketClient();
          const spy = jest.fn();
          const socket = {
              send: (data) => {
                  spy(data);
              }
          };
          sc.onConnectionOpen(socket, 'test-token');
          expect(spy.mock.calls.length).toBe(1);
          expect(JSON.parse(spy.mock.calls[0][0]).type).toBe(socket_message_type_2.SocketMessageType.REGISTER);
      });
      it('Should properly call all set callbacks, for different types of messages', () => {
          const sc = new socket_client_1.SocketClient();
          const socket = {};
          const notificationSpy = jest.fn();
          const registerSuccessSpy = jest.fn();
          const registerErrorSpy = jest.fn();
          sc.setOnNotificationCallback(() => {
              notificationSpy();
          });
          sc.setOnRegisterSuccessCallback(() => {
              registerSuccessSpy();
          });
          sc.setOnRegisterErrorCallback((errors) => {
              registerErrorSpy(errors);
          });
          sc.onMessage(socket, {
              data: JSON.stringify({ type: socket_message_type_2.SocketMessageType.NOTIFICATION, data: null })
          });
          sc.onMessage(socket, {
              data: JSON.stringify({ type: socket_message_type_2.SocketMessageType.REGISTER_SUCCESS, data: null })
          });
          sc.onMessage(socket, {
              data: JSON.stringify({ type: socket_message_type_2.SocketMessageType.REGISTER_ERROR, data: { errors: ['Error occurred!'] } })
          });
          expect(notificationSpy.mock.calls.length).toBe(1);
          expect(registerSuccessSpy.mock.calls.length).toBe(1);
          expect(registerErrorSpy.mock.calls.length).toBe(1);
          expect(registerErrorSpy.mock.calls[0][0]).toStrictEqual(['Error occurred!']);
      });
      it('Should not fail when there are no set callbacks', () => {
          const sc = new socket_client_1.SocketClient();
          const socket = {};
          expect(() => {
              sc.onMessage(socket, {
                  data: JSON.stringify({ type: socket_message_type_2.SocketMessageType.NOTIFICATION, data: null })
              });
              sc.onMessage(socket, {
                  data: JSON.stringify({ type: socket_message_type_2.SocketMessageType.REGISTER_SUCCESS, data: null })
              });
              sc.onMessage(socket, {
                  data: JSON.stringify({ type: socket_message_type_2.SocketMessageType.REGISTER_ERROR, data: { errors: ['Error occurred!'] } })
              });
          }).not.toThrow();
      });
  });
});
