define("itarp-opportunity-ui/services/moment", ["exports", "ember-moment/services/moment", "itarp-opportunity-ui/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class MomentOverride extends _moment.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "defaultFormat", _environment.default.moment && _environment.default.moment.outputFormat || null);
    }

  }

  _exports.default = MomentOverride;
});