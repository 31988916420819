define("itarp-opportunity-designer/helpers/to-initials", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toInitials = toInitials;
  _exports.default = void 0;

  function toInitials(params) {
    if (!params[0] || !params[1]) return 'NN';
    let firstName = params[0].slice(0, 1).toUpperCase();
    let lastName = params[1].slice(0, 1).toUpperCase();
    let initials = firstName + lastName;
    return initials;
  }

  var _default = (0, _helper.helper)(toInitials);

  _exports.default = _default;
});