define("itarp-template-designer/utils/constants/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RECORDS_PER_PAGE = _exports.DEFAULT_PAGE_NUMBER = void 0;
  const DEFAULT_PAGE_NUMBER = 1;
  _exports.DEFAULT_PAGE_NUMBER = DEFAULT_PAGE_NUMBER;
  const RECORDS_PER_PAGE = 20;
  _exports.RECORDS_PER_PAGE = RECORDS_PER_PAGE;
});