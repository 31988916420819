define("ember-class-based-modifier/-private/modifier-classic", ["exports", "@ember/object", "@ember/modifier", "ember-class-based-modifier/-private/modifier-manager"], function (_exports, _object, _modifier, _modifierManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const ClassBasedModifier = _object.default.extend({
    args: null,

    init() {
      this._super(...arguments);

      this.element = null;
    },

    didReceiveArguments() {},

    didUpdateArguments() {},

    didInstall() {},

    willRemove() {}

  });

  (0, _modifier.setModifierManager)(() => _modifierManager.default, ClassBasedModifier);
  var _default = ClassBasedModifier;
  _exports.default = _default;
});