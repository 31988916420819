define("itarp-business-application/components/forms/nationality-chip", ["exports", "@ember/component/template-only"], function (_exports, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span class="chip" ...attributes>
    {{@select.selected}}
    <button type="button" {{on "click" @clear}}>x</button>
  </span>
  */
  {
    "id": "qZYMQtJi",
    "block": "[[[11,1],[24,0,\"chip\"],[17,1],[12],[1,\"\\n  \"],[1,[30,2,[\"selected\"]]],[1,\"\\n  \"],[11,\"button\"],[24,4,\"button\"],[4,[38,0],[\"click\",[30,3]],null],[12],[1,\"x\"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@select\",\"@clear\"],false,[\"on\"]]",
    "moduleName": "itarp-business-application/components/forms/nationality-chip.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});