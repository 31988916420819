define("itarp-opportunity-ui/components/ui/modal/body", ["exports", "@ember/component/template-only"], function (_exports, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="app-modal-content__body">
    <div class="app-modal-group__panel">
      {{yield (hash controls=(component "ui/modal/body/controls"))}}
    </div>
  </div>
  */
  {
    "id": "npKepJ6D",
    "block": "[[[10,0],[14,0,\"app-modal-content__body\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"app-modal-group__panel\"],[12],[1,\"\\n    \"],[18,1,[[28,[37,1],null,[[\"controls\"],[[50,\"ui/modal/body/controls\",0,null,null]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-opportunity-ui/components/ui/modal/body.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});