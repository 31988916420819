define("itarp-opportunity-designer/adapters/approved-talentcloud-application", ["exports", "itarp-auth-addon/adapters/ad-token-data"], function (_exports, _adTokenData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ApprovedTalentcloudApplicationAdapter extends _adTokenData.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", '/opportunity/data_service');

      _defineProperty(this, "pathForType", () => 'applications');
    }

    urlForQuery(options) {
      if (options.approvedApplications) {
        const {
          expertId
        } = options;
        delete options.approvedApplications;
        delete options.expertId;
        return `${this.namespace}/expert/${expertId}/talentcloud_applications`;
      }

      return super.urlForQuery(...arguments);
    }

    updateRecord(store, schema, snapshot) {
      const data = this.serialize(snapshot, {
        includeId: true
      });
      delete data.user_ids;
      const type = snapshot.modelName;
      const id = snapshot.id;
      let url = this.buildURL(type, id, snapshot, 'updateRecord');
      return this.ajax(url, 'PATCH', {
        data: data
      });
    }

    updateAll(store, type, snapshots) {
      let data = snapshots.map(snapshot => {
        let attrs = {};
        snapshot.eachAttribute((key, attribute) => {
          attrs[key] = snapshot.attr(key);
        });
        return {
          _id: snapshot.id,
          workflow_stage_id: attrs.workflowStageId
        };
      });
      let url = `${this.namespace}/update_bulk`;
      return this.ajax(url, 'PATCH', {
        data: JSON.stringify({
          applications: data
        }),
        contentType: 'application/json'
      });
    }

    urlForUpdateRecord(id, modelName, snapshot) {
      return `${this.namespace}/update_bulk`;
    }

  }

  _exports.default = ApprovedTalentcloudApplicationAdapter;
});