define("itarp-business-application/components/taxonomy/list", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ul class="depth--0 depth--{{@depth}}">
    {{#each @taxonomy as |taxonomy|}}
      <li>
        <div class="recommendation-row">
          <div class="checkbox-group">
            <input
              type="checkbox"
              class="checkbox-group__field"
              checked={{if
                taxonomy.children.length
                taxonomy.isSelected
                taxonomy.selected
              }}
              id={{concat taxonomy.name "-" taxonomy.id}}
              {{on "click" taxonomy.toggle}}
            />
            <label
              class="checkbox-group__label"
              for={{concat taxonomy.name "-" taxonomy.id}}
            >
              {{taxonomy.name}}
            </label>
          </div>
        </div>
        {{#if taxonomy.children.length}}
          <Taxonomy::List
            @taxonomy={{taxonomy.children}}
            @depth={{taxonomy.depth}}
          />
        {{/if}}
      </li>
    {{/each}}
  </ul>
  */
  {
    "id": "x2fKtwY5",
    "block": "[[[10,\"ul\"],[15,0,[29,[\"depth--0 depth--\",[30,1]]]],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,2]],null]],null],null,[[[1,\"    \"],[10,\"li\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"recommendation-row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"checkbox-group\"],[12],[1,\"\\n          \"],[11,\"input\"],[24,0,\"checkbox-group__field\"],[16,\"checked\",[52,[30,3,[\"children\",\"length\"]],[30,3,[\"isSelected\"]],[30,3,[\"selected\"]]]],[16,1,[28,[37,3],[[30,3,[\"name\"]],\"-\",[30,3,[\"id\"]]],null]],[24,4,\"checkbox\"],[4,[38,4],[\"click\",[30,3,[\"toggle\"]]],null],[12],[13],[1,\"\\n          \"],[10,\"label\"],[14,0,\"checkbox-group__label\"],[15,\"for\",[28,[37,3],[[30,3,[\"name\"]],\"-\",[30,3,[\"id\"]]],null]],[12],[1,\"\\n            \"],[1,[30,3,[\"name\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,3,[\"children\",\"length\"]],[[[1,\"        \"],[8,[39,5],null,[[\"@taxonomy\",\"@depth\"],[[30,3,[\"children\"]],[30,3,[\"depth\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[3]],null],[13]],[\"@depth\",\"@taxonomy\",\"taxonomy\"],false,[\"each\",\"-track-array\",\"if\",\"concat\",\"on\",\"taxonomy/list\"]]",
    "moduleName": "itarp-business-application/components/taxonomy/list.hbs",
    "isStrictMode": false
  });

  class TaxonomyListComponent extends _component.default {}

  _exports.default = TaxonomyListComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TaxonomyListComponent);
});