define("itarp-components/components/ui/grid", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield
    (hash row=(component 'ui/grid/row') column=(component 'ui/grid/column'))
  }}
  
  */
  {
    "id": "df3u8iBd",
    "block": "[[[18,1,[[28,[37,1],null,[[\"row\",\"column\"],[[50,\"ui/grid/row\",0,null,null],[50,\"ui/grid/column\",0,null,null]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-components/components/ui/grid.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});