define("itarp-business-application/services/data-parser", ["exports", "@ember/service", "@ember/utils", "@ember/object/internals", "ember-inflector", "@glimmer/tracking"], function (_exports, _service, _utils, _internals, _emberInflector, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DataParserService = (_class = class DataParserService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "context", _descriptor2, this);

      _defineProperty(this, "modelCollection", {
        'type-definition': 'model_reference',
        'is-array': true
      });

      _defineProperty(this, "modelReference", {
        'type-definition': 'model_reference',
        'is-array': false
      });
    }

    new(transition, context) {
      this.resources = {};
      this.queryParams = transition.to.queryParams;
      this.getParamIds();
      context.models = this.resources;
      this.context = context;
      return this;
    }

    isType(type, comparisonType) {
      return Object.keys(comparisonType).reduce((bool, attr) => {
        return bool && type[attr] === comparisonType[attr];
      }, true);
    }

    async mapPlaceValues(place) {
      for (const attribute in place.attributes) {
        const {
          type,
          value
        } = place.attributes[attribute];

        if (this.isType(type, this.modelCollection)) {
          return this.setCollection(place, value);
        } else if (this.isType(type, this.modelReference)) {
          await this.setPlaceAttribute(place, attribute);
        }
      }

      if ((0, _utils.isPresent)(place.children)) {
        for (const child of place.children) {
          await this.mapPlaceValues(child);
        }
      }

      return place;
    } // async setCollection(place, modelName) {
    //   const model = this.store
    //     .peekAll('meta-model')
    //     .findBy('name', modelName.toLowerCase());
    //   const records = await model.findAll();
    //   const children = [];
    //   for (const record of records) {
    //     const child = copy(place.children.firstObject, true);
    //     for (const attribute in child.attributes) {
    //       const { type } = child.attributes[attribute];
    //       if (this.isType(type, this.modelReference)) {
    //         this.setAttributeValue(child.attributes, attribute, record);
    //       }
    //     }
    //     children.pushObject(child);
    //   }
    //   place.children = children;
    //   return place;
    // }


    async setCollection(place, modelName) {
      const records = await this.findCollectionRecords(modelName.toLowerCase());
      const children = [];

      if (!records.length) {
        place.children = [];
        return place;
      }

      for (const record of records) {
        this.populateAttributes(place, record, children);
      }

      place.children = children;
      return place;
    }

    populateAttributes(place, record, children) {
      const child = (0, _internals.copy)(place.children.firstObject, true);

      for (const attribute in child.attributes) {
        const {
          type
        } = child.attributes[attribute];

        if (this.isType(type, this.modelReference)) {
          this.setAttributeValue(child.attributes, attribute, record);
        }
      }

      children.pushObject(child);
    }

    async findCollectionRecords(value) {
      const names = value.split('->');
      let model = undefined;
      let record = undefined;
      let records = [];
      let query = undefined;

      for (const modelName of names) {
        const isLastModel = names.indexOf(modelName) === names.length - 1;

        if (isLastModel) {
          if (record && record.get('id')) {
            query = {};
            query[`${record.modelName}_id`] = record.get('id');
          }

          records = await this.getCollection(modelName, {
            filter: query
          });
        } else if (record) {
          const idKey = `${modelName.toLowerCase()}_id`;
          const id = record.get(idKey);
          model = await this.getMetaModel(modelName);
          record = await model.findRecord(id);
        } else {
          record = await this.getModelRecord(modelName.toLowerCase());
        }
      }

      return records;
    }

    async getCollection(modelName, query) {
      const name = (0, _emberInflector.singularize)(modelName.toLowerCase());
      const model = await this.store.peekAll('meta-model').findBy('name', name);
      return query ? model.queryRecords(query) : model.findAll();
    }

    async getMetaModel(name) {
      return this.store.peekAll('meta-model').findBy('name', (0, _emberInflector.singularize)(name.toLowerCase()));
    }

    async getModelRecord(name) {
      var _this$resources$model;

      const model = await this.store.peekAll('meta-model').findBy('name', name);
      if (!model) return null;
      const recordId = (_this$resources$model = this.resources[model.name]) === null || _this$resources$model === void 0 ? void 0 : _this$resources$model.id;
      const resource = this.resources[model.name];
      if (resource && resource.record) return resource.record;
      return recordId ? model.findRecord(recordId) : model.newRecord();
    }

    setAttributeValue(attributes, attribute, record) {
      const {
        attribute_name: attributeName
      } = JSON.parse(`${attributes[attribute].value}`);
      const endOfName = attributeName.substring(attributeName.length - 3);

      if (endOfName === '_id') {
        var _this$resources$model2;

        const modelName = attributeName.substring(0, attributeName.length - 3);
        const modelId = (_this$resources$model2 = this.resources[modelName]) === null || _this$resources$model2 === void 0 ? void 0 : _this$resources$model2.id;
        if (modelId) record.set(attributeName, modelId);

        if (modelName == 'user') {
          record.set(attributeName, this.getUserId());
        }
      }

      Object.defineProperty(attributes[attribute], 'value', {
        get: function () {
          return record.get(attributeName);
        },
        set: function (value) {
          record.set(attributeName, value);
        }
      });
    }

    getUserId() {
      const token = localStorage.getItem('itarp_token');
      if (!token) return null;
      const info = JSON.parse(atob(token.split('.')[1]));
      return info.sub;
    }

    async setPlaceAttribute(place, attribute) {
      const {
        model_name: modelName
      } = JSON.parse(`${place.attributes[attribute].value}`);
      const record = await this.getModelRecord(modelName === null || modelName === void 0 ? void 0 : modelName.toLowerCase());
      if (!record) return;
      this.setAttributeValue(place.attributes, attribute, record);
      this.logRetreivedResource(record);
    }

    logRetreivedResource(record) {
      if (this.resources[record.modelName]) {
        this.resources[record.modelName].record = record;
      } else {
        this.resources[record.modelName] = {
          id: undefined,
          record: record
        };
      }
    }

    getParamIds() {
      const queryParams = this.queryParams.filter ? JSON.parse(this.queryParams.filter) : this.queryParams;

      for (const paramName in queryParams) {
        if (paramName.substring(paramName.length - 3) === '_id') {
          const relationshipName = paramName.substring(0, paramName.length - 3);
          this.resources[relationshipName] = {
            id: queryParams[paramName],
            record: undefined
          };
        }
      }
    }

    async parse(data) {
      var _page$attributes, _page$attributes2;

      const result = [];
      const page = data.firstObject;
      this.context.pageTitle = (_page$attributes = page.attributes) === null || _page$attributes === void 0 ? void 0 : _page$attributes.name;

      if (((_page$attributes2 = page.attributes) === null || _page$attributes2 === void 0 ? void 0 : _page$attributes2.type) === 'form') {
        this.context.templateName = 'form/layout';
      } else {
        this.context.templateName = 'dynamic-route';
      } // this.context.controller = 'dynamic-route';


      for (const element of data.firstObject.children) {
        const value = await this.mapPlaceValues(element);
        result.pushObject(value);
      }

      return result;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "context", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = DataParserService;
});