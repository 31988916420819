define("itarp-opportunity-designer/helpers/opportunity/is-deletable", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function isDeletable([opportunity, tokenInfo]) {
    const DRAFT_STATUS = 'draft';
    const userType = tokenInfo.extension_user_type;
    const isDraftOpportunity = opportunity.status === DRAFT_STATUS;
    const isOwner = opportunity.userId === tokenInfo.sub;
    const isAdmin = userType === 'site_admin';
    return isDraftOpportunity && (isOwner || isAdmin);
  });

  _exports.default = _default;
});