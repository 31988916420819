define("itarp-business-application/controllers/application", ["exports", "@ember/controller", "@ember/service", "@ember/object", "@glimmer/tracking", "@ember/runloop"], function (_exports, _controller, _service, _object, _tracking, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApplicationController extends _controller.default {// @service router;
    // @service layout;
    // routesWithoutSidebar = [
    //   'profile.autofill',
    //   'profile.basic-info',
    //   'profile.expertise',
    //   'profile.work-arrangement',
    //   'profile-preview',
    //   'chat.index',
    //   'chat.messages',
    // ];
    // get sidebarVisible() {
    //   return !this.routesWithoutSidebar.includes(this.router.currentRouteName);
    // }
    // get sidebarCollapsed() {
    //   return this.layout.isCollapsed;
    // }
  }

  _exports.default = ApplicationController;
});