define("itarp-components/helpers/url-to-b64", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UrlToB64Helper extends _helper.default {
    compute() {
      const currentURL = window.location.href.replace(window.location.origin, '');
      const encodedURL = btoa(currentURL).replaceAll('/', '-');
      return encodedURL;
    }

  }

  _exports.default = UrlToB64Helper;
});