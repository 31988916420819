define("shared/adapters/user", ["exports", "shared/adapters/business-application", "@ember-data/adapter/error", "@ember/array", "@ember/string"], function (_exports, _businessApplication, _error, _array, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class UserAdapter extends _businessApplication.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", '/api/expert/profile');
    }

    //   urlForUpdateRecord() {
    //     return this.namespace + '/update';
    //   }
    handleResponse(status, headers, payload) {
      if (this.isInvalid(status, headers, payload)) {
        return new _error.InvalidError(this.formatErrors(payload));
      }

      return super.handleResponse(...arguments);
    }

    formatErrors(payload) {
      let errors = payload.errors;
      let model = Object.keys(errors)[0];
      errors = errors[model];
      const result = [];

      for (const field in errors) {
        result.pushObjects(this.parseErrors(errors, field, model));
      }

      return result;
    }

    parseErrors(errors, field, attrPath) {
      const attribute = `${attrPath}.${field}`;

      if (Array.isArray(errors[field])) {
        const attrArray = attribute.split('.');
        const pointer = `/data/attributes/${attribute.replaceAll('.', '/')}`;
        const source = {
          pointer
        };
        const fieldName = (0, _string.capitalize)(field.replaceAll('_', ' '));
        let detail = `${fieldName} ${errors[field]}`;

        if (attrArray[2] === 'educations' || attrArray[2] === 'experiences' || attrArray[2] === 'certificates') {
          detail = `${attrArray[2].charAt(0).toUpperCase() + attrArray[2].slice(1)} : ${fieldName} ${errors[field]}`;
        }

        return [{
          source,
          detail,
          attribute
        }];
      } else {
        const result = [];

        for (const child in errors[field]) {
          const message = this.parseErrors(errors[field], child, attribute);
          result.pushObjects(message);
        }

        return result;
      }
    }

  }

  _exports.default = UserAdapter;
});