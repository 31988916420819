define("itarp-template-designer/controllers/categories/new", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/object", "@ember/service", "itarp-template-designer/config/environment"], function (_exports, _controller, _tracking, _object, _service, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CategoriesNewController = (_class = class CategoriesNewController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "appRouter", _descriptor2, this);

      _initializerDefineProperty(this, "cancelModalVisible", _descriptor3, this);

      _initializerDefineProperty(this, "error", _descriptor4, this);

      _initializerDefineProperty(this, "categoryTypeError", _descriptor5, this);
    }

    toggleCancelModal() {
      this.cancelModalVisible = !this.cancelModalVisible;
    }

    cancelSaveModal() {
      this.cancelModalVisible = false;
      this.category.destroyRecord();
      this.appRouter.transitionTo(`${_environment.default.modulePrefix}`);
    }

    async saveCategory(e) {
      e.preventDefault();
      this.category.name = this.category.name.trim();
      const cached = this.store.peekAll('category');
      let notUnique;

      if (cached.length > 1) {
        notUnique = cached.filterBy('isNew', false).isAny('name', this.category.name);
      } else {
        notUnique = await this.store.findAll('category').then(data => {
          return data.toArray().isAny('name', this.category.name);
        });
      }

      if (!this.category.name || this.category.name === '') {
        return this.error = 'Please fill out this field';
      } else if (notUnique) {
        return this.error = 'Name must be unique';
      } else {
        this.error = '';

        if (!this.category.categoryType) {
          this.categoryTypeError = 'Please select a category type';
          return;
        } else {
          this.category.categoryType = this.category.categoryType.value;
          this.categoryTypeError = '';
        }

        this.category.save().then(() => {
          this.appRouter.transitionTo(`${_environment.default.modulePrefix}`);
        }, errors => {
          console.log(errors);
        });
      }
    }

    get categoryTypeOptions() {
      return [{
        name: 'TalentCloud Templates',
        value: 'talent_cloud'
      }, {
        name: 'Opportunity Templates',
        value: 'opportunity'
      }, {
        name: 'Custom',
        value: 'custom'
      }];
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "appRouter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "cancelModalVisible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "error", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "categoryTypeError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleCancelModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleCancelModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelSaveModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancelSaveModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveCategory", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveCategory"), _class.prototype)), _class);
  _exports.default = CategoriesNewController;
});