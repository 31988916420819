define("shared/helpers/find-by", ["exports", "@ember/component/helper", "@ember/utils", "@ember/array"], function (_exports, _helper, _utils, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findBy = findBy;
  _exports.default = void 0;

  function findBy([property, array, value]) {
    if ((0, _utils.isEmpty)(property)) {
      return [];
    }

    return (0, _array.A)(array).findBy(property, value);
  }

  var _default = (0, _helper.helper)(findBy);

  _exports.default = _default;
});