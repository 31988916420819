define("itarp-opportunity-designer/utils/string/arr-join", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = arrJoin;

  function arrJoin(arr, join = ', ') {
    return arr.compact().without('').join(join);
  }
});