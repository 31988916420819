define("itarp-talentcloud-ui/components/ui/modals/base/body", ["exports", "@ember/component/template-only"], function (_exports, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="app-modal-content__body" ...attributes>
    {{yield}}
  </div>
  */
  {
    "id": "Hb003fUg",
    "block": "[[[11,0],[24,0,\"app-modal-content__body\"],[17,1],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "itarp-talentcloud-ui/components/ui/modals/base/body.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});