define("itarp-query-filters/services/filter-chips", ["exports", "@ember/service", "@ember/object", "@ember/array", "@glimmer/tracking"], function (_exports, _service, _object, _array, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const MUSTACHE_MATCHER = /{([^}\n]*)}/g; // matches -> {value}

  let FilterChipsService = (_dec = (0, _service.inject)('route-adapters'), (_class = class FilterChipsService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "routeAdapters", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "adapter", _descriptor3, this);

      _initializerDefineProperty(this, "schema", _descriptor4, this);

      _initializerDefineProperty(this, "filters", _descriptor5, this);

      _initializerDefineProperty(this, "definitions", _descriptor6, this);
    }

    setRouteContext(route) {
      this.adapter = this.routeAdapters.get(route);
      this.schema = this.adapter.paramsSchema;
      this.filters = this.adapter.filters;
      this.definitions = Object.entries(this.schema);
    }

    resetRouteContext() {
      this.adapter = undefined;
      this.schema = undefined;
      this.filters = undefined;
      this.definitions = undefined;
    }

    generateFromSchema() {
      const chips = [];

      for (const [name, {
        key,
        valueKey,
        hidden
      }] of this.definitions) {
        const isGenerated = this.isGenerated(chips, key, valueKey);
        if (isGenerated || !this.isActiveFilter(name) || hidden) continue;
        chips.pushObjects(this.create(name, valueKey));
      }

      return chips;
    }

    isGenerated(chips, name, valueKey) {
      const schemas = Object.values(this.schema);
      const existing = chips.find(chip => {
        const isMatch = chip.name === name;

        if (valueKey) {
          const definition = s => s.key === name && s.valueKey === valueKey;

          const schema = schemas.find(definition);
          if (schema.combined && isMatch) return true;
          return isMatch && chip.valueKey === valueKey;
        }

        return isMatch;
      });
      return existing !== undefined;
    }

    generate(route) {
      let chips = [];

      try {
        this.setRouteContext(route);
        chips = this.generateFromSchema();
        this.resetRouteContext();
      } catch (e) {
        console.log(e);
        this.resetRouteContext();
      }

      return chips;
    }

    create(name, valueKey) {
      const definition = this.definitions.find(([n]) => n === name)[1];

      if (definition.combined) {
        return [combined(definition, this.definitions, this.filters, valueKey)];
      } else if (definition.multiple) {
        return multiple(name, definition, this.filters, valueKey, this.store);
      }

      return [chip(definition, name, this.filters, valueKey)];
    }

    isActiveFilter(name) {
      const filter = this.adapter.filters[name];
      const defaultValue = this.adapter.defaultValueFor(name);
      const isValid = [null, undefined].includes(filter.value) === false;
      const isDefault = filter.value === defaultValue;
      return isPresent(filter.value) && isValid && !isDefault;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "routeAdapters", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "adapter", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "schema", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "filters", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "definitions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setRouteContext", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setRouteContext"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetRouteContext", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "resetRouteContext"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generateFromSchema", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "generateFromSchema"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isGenerated", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "isGenerated"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "generate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "create", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "create"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isActiveFilter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "isActiveFilter"), _class.prototype)), _class));
  _exports.default = FilterChipsService;

  function isPresent(value) {
    if ((0, _array.isArray)(value)) {
      return value.length > 0;
    }

    return ![null, undefined].includes(value);
  }

  function isActiveFilter(name, adapter) {
    const filter = adapter.filters[name];
    const defaultValue = adapter.defaultValueFor(name);
    return isPresent(filter.value) && filter.value !== defaultValue;
  }

  function replacePlaceholder(label, value, token = 'value') {
    const placeholders = [...label.matchAll(MUSTACHE_MATCHER)];
    const replacement = [null, undefined].includes(value) ? '' : value;
    let result = `${label}`;
    if (!label) return replacement;

    for (const [placeholder, key] of placeholders) {
      if (key === token) {
        result = result.replaceAll(placeholder, replacement);
      }
    }

    return result;
  }

  function chip(schema, name, filters, valueKey) {
    let label = schema.label || '';
    const value = filters[name].value;

    if (schema.type === 'boolean' && value === false || value === 'false') {
      label = 'Not ' + label;
    }

    return {
      valueKey,
      value,
      label: replacePlaceholder(label, value),
      name: schema.key
    };
  }

  function combined(schema, definitions, filters, valueKey) {
    let {
      key,
      label
    } = schema;
    const members = definitions.filter(([n, d]) => d.key === key);

    for (const [name, {
      valueKey
    }] of members) {
      label = replacePlaceholder(label, filters[name].value, valueKey);
    }

    return {
      label,
      name: key,
      valueKey
    };
  }

  function multiple(name, schema, filters, valueKey, store) {
    return filters[name].value.map(value => {
      var _obj, _init;

      const result = (_obj = {
        value,
        label: '',
        name: schema.valueKey || schema.key
      }, (_applyDecoratedDescriptor(_obj, "label", [_tracking.tracked], (_init = Object.getOwnPropertyDescriptor(_obj, "label"), _init = _init ? _init.value : undefined, {
        enumerable: true,
        configurable: true,
        writable: true,
        initializer: function () {
          return _init;
        }
      }), _obj)), _obj);

      if (schema.object) {
        result.label = replacePlaceholder(schema.label, value[schema.objectValue]);
      } else if (schema.model) {
        getRecordAttribute(value, schema, store).then(val => {
          result.label = replacePlaceholder(schema.label, val);
        });
      } else {
        result.label = replacePlaceholder(schema.label, value);
      }

      return result;
    });
  }

  function getRecordAttribute(id, schema, store) {
    const cached = store.peekRecord(schema.modelType, id);
    if (cached) return Promise.resolve(cached.get(schema.modelAttribute));
    return store.findRecord(schema.modelType, id).then(record => record.get(schema.modelAttribute));
  }
});