define("ember-a11y-accordion/components/collapsible-list", ["exports", "@ember/component", "@ember/template-factory", "ember-a11y-accordion/utils/dom", "@ember/runloop", "@ember/array", "@glimmer/component", "@ember/object", "@ember/test-waiters", "@ember/utils", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _dom, _runloop, _array, _component2, _object, _testWaiters, _utils, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{this.className}} ...attributes>
    {{yield (hash
      item=(component "accordion-item"
        isCollapsible=true
        register=this.registerItem
        toggle=this.toggleItem
      )
    )}}
  </div>
  */
  {
    "id": "eQ0YxAmj",
    "block": "[[[11,0],[16,0,[30,0,[\"className\"]]],[17,1],[12],[1,\"\\n  \"],[18,2,[[28,[37,1],null,[[\"item\"],[[50,\"accordion-item\",0,null,[[\"isCollapsible\",\"register\",\"toggle\"],[true,[30,0,[\"registerItem\"]],[30,0,[\"toggleItem\"]]]]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-a11y-accordion/components/collapsible-list.hbs",
    "isStrictMode": false
  });

  /**
   * This slight delay is used when hiding an item with CSS transitions. Because a visible
   * panel doesn't have an inline height (for responsive reasons it's removed), first we
   * insert its computed height inline, then after this delay we reset the inline height
   * to 0px so the CSS transition will kick off.
   */
  const INLINE_HEIGHT_DELAY = 50;
  const waiter = (0, _testWaiters.buildWaiter)('ember-a11y-accordion:collapsible-list');
  /**
   * The collapsible-list component is the top-most component and is responsible
   * for registering collapsible items and toggling their panels.
   *
   * @param {Function} [onShow] Action to execute when a panel is being shown
   * @param {Function} [onAfterShow] Action to execute when a panel is fully shown
   * @param {Function} [onHide] Action to execute when a panel is being collapsed
   * @param {String} [class] Any CSS classes to be added to the component's element
   * @param {Boolean} [animation] Whether or not it should animate items
   *
   * @example
   * {{#collapsible-list as |collapsible|}}
   *   {{#collapsible.item as |item|}}
   *     {{#item.header}}Lorem Ipsum{{/item.header}}
   *     {{#item.panel}}Lorem ipsum dolor{{/item.panel}}
   *   {{/collapsible.item}}
   * {{/collapsible-list}}
   */

  let CollapsibleListComponent = (_class = class CollapsibleListComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "className", _dom.CLASS_NAMES.list);

      _defineProperty(this, "animatedHideWaitToken", void 0);

      _defineProperty(this, "animatedShowWaitToken", void 0);

      _initializerDefineProperty(this, "_currentHideTimeout", _descriptor, this);

      _defineProperty(this, "_items", (0, _array.A)());

      _initializerDefineProperty(this, "_isHiding", _descriptor2, this);
    }

    get animation() {
      return (0, _utils.isPresent)(this.args.animation) ? this.args.animation : true;
    }

    registerItem(item) {
      if (!(0, _utils.isPresent)(item)) {
        return;
      }

      this._items.push(item); // At register time close respective items


      if (!item.isExpanded) {
        this.animation ? (0, _dom.setClosedHeight)(item) : this._simpleHide(item, true);
      }
    }

    toggleItem(item) {
      if (!(0, _utils.isPresent)(item) || item.isDisabled) {
        return;
      }

      if (item.isExpanded) {
        this.animation ? this._animatedHide(item) : this._simpleHide(item);
      } else {
        if (this.animation) {
          this._animatedShow(item);

          this._triggerEvent('onShow', item);
        } else {
          this._simpleShow(item);

          this._triggerEvent('onShow', item);

          this._triggerEvent('onAfterShow', item);
        }
      }
    }

    willDestroy() {
      super.willDestroy(...arguments);
      (0, _runloop.cancel)(this._currentHideTimeout);

      this._items.splice(0, this._items.length);
    }
    /**
     * Handles hiding an item with animation.
     *
     * CSS transitions don't transition from "no value" to a value, so
     * before we set the element's height to close it, first we
     * calculate its current open height and set it.
     *
     * @param {Object} item
     * @private
     */


    _animatedHide(item) {
      this.animatedHideWaitToken = waiter.beginAsync();
      this._isHiding = true; // From open height

      (0, _dom.setOpenHeight)(item);
      (0, _runloop.cancel)(this._currentHideTimeout); // Set close height

      this._currentHideTimeout = (0, _runloop.later)(() => {
        (0, _dom.setClosedHeight)(item);
        item.isExpanded = false;
        this._isHiding = false;

        this._triggerEvent('onHide', item);

        waiter.endAsync(this.animatedHideWaitToken);
      }, INLINE_HEIGHT_DELAY);
    }
    /**
     * Handles showing an item with animation.
     *
     * When the CSS transition has ended, we clear the inline height so the
     * component's contents don't get cutt off in responsive layouts.
     *
     * @param {Object} item
     * @private
     */


    _animatedShow(item) {
      this.animatedShowWaitToken = waiter.beginAsync();
      (0, _dom.setOpenHeight)(item);
      item.isExpanded = true; // Remove the inline height after the transition so contents don't
      // get cut off when resizing the browser window.

      (0, _dom.addEventListenerOnce)(item.panelWrapper, 'transitionend', () => {
        if (item.isExpanded && !this._isHiding) {
          item.panelWrapper.style.height = null;

          this._triggerEvent('onAfterShow', item);
        }

        waiter.endAsync(this.animatedShowWaitToken);
      });
    }
    /**
     * Handles hiding an item without animation.
     *
     * @param {Object} item
     * @param {Boolean} silent
     * @private
     */


    _simpleHide(item, silent) {
      item.isExpanded = false;
      item.panelWrapper.style.display = 'none';

      if (!silent) {
        this._triggerEvent('onHide', item);
      }
    }
    /**
     * Handles showing an item without animation.
     *
     * @param {Object} item
     * @private
     */


    _simpleShow(item) {
      item.isExpanded = true;
      item.panelWrapper.style.display = null;
    }
    /**
     * Triggers an event
     *
     * @param {string} eventName
     * @param {Object} item
     * @private
     */


    _triggerEvent(eventName, item) {
      this.args[eventName] && this.args[eventName]({
        name: item.name
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_currentHideTimeout", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_isHiding", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "registerItem", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleItem", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleItem"), _class.prototype)), _class);
  _exports.default = CollapsibleListComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CollapsibleListComponent);
});