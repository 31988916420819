define("itarp-workflow-designer/models/action", ["exports", "@ember-data/model", "@ember/template"], function (_exports, _model, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ActionModel = (_dec = (0, _model.belongsTo)('stage'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.hasMany)('trigger', {
    async: false
  }), _dec5 = (0, _model.attr)('number', {
    defaultValue: 1
  }), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)({
    defaultValue: () => ({
      runtime_params: {},
      action_params: {}
    })
  }), _dec9 = (0, _model.attr)({
    defaultValue: () => ({})
  }), _dec10 = (0, _model.attr)(), _dec11 = (0, _model.belongsTo)('stage', {
    inverse: 'actions'
  }), (_class = class ActionModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "stage", _descriptor, this);

      _initializerDefineProperty(this, "icon", _descriptor2, this);

      _initializerDefineProperty(this, "name", _descriptor3, this);

      _initializerDefineProperty(this, "triggers", _descriptor4, this);

      _initializerDefineProperty(this, "displayOrder", _descriptor5, this);

      _initializerDefineProperty(this, "triggerId", _descriptor6, this);

      _initializerDefineProperty(this, "stageId", _descriptor7, this);

      _initializerDefineProperty(this, "metadata", _descriptor8, this);

      _initializerDefineProperty(this, "actionFormData", _descriptor9, this);

      _initializerDefineProperty(this, "actionType", _descriptor10, this);

      _initializerDefineProperty(this, "stage", _descriptor11, this);
    }

    get htmlSafeMessage() {
      return (0, _template.htmlSafe)(this.message);
    }

    get displayIcon() {
      return this.name.toLowerCase().replaceAll(' ', '_');
    }

    get displayName() {
      return this.name.replaceAll('_', ' ').toLowerCase().replace('For', 'for');
    }

    get displayActionName() {
      return this.name.replaceAll('_', ' ').toLowerCase().replace(/(^.|\s+.)/g, n => n.toUpperCase()).replace('For', 'for');
    }

    get isEmailAction() {
      var _this$actionFormData;

      const emailType = this.actionType === 'send_email';
      const formType = (_this$actionFormData = this.actionFormData) === null || _this$actionFormData === void 0 ? void 0 : _this$actionFormData.actionFormType;
      const formEmailType = formType === 'send_email_with_sender_and_recipients';
      return emailType || formEmailType;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "stage", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "icon", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "triggers", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "displayOrder", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "triggerId", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "stageId", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "metadata", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "actionFormData", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "actionType", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "stage", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ActionModel;
});