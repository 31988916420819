define("itarp-opportunity-designer/routes/publishing-options/free-boards", ["exports", "@ember/routing/route", "rsvp", "@ember/service"], function (_exports, _route, _rsvp, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PublishingOptionsFreeBoardsRoute = (_dec = (0, _service.inject)(), (_class = class PublishingOptionsFreeBoardsRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "integrations", _descriptor, this);
    }

    model() {
      const opportunity = this.modelFor('publishing-options');
      return (0, _rsvp.hash)({
        opportunity: opportunity,
        integrations: this.integrations.getWithType('free'),
        posts: this.store.query('publish-setting', {
          filter: {
            record_type: 'opportunity',
            record_id: opportunity.id
          }
        })
      });
    }

    setupController(controller, model) {
      const opportunity = model.opportunity;
      super.setupController(...arguments);
      controller.set('opportunity', model.opportunity);
      controller.set('posts', this.integrations.initializePosts(opportunity.id, model.integrations, model.posts));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "integrations", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PublishingOptionsFreeBoardsRoute;
});