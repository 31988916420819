define("@onehilltech/ember-cli-google-analytics/initializers/g-analytics", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(app) {
    const ENV = app.resolveRegistration('config:environment');
    const analytics = (0, _object.get)(ENV, 'ember-cli-google.analytics');

    if (analytics.version === 'v4') {
      app.inject('route', 'gtag', 'service:gtag');
      app.inject('controller', 'gtag', 'service:gtag');
    } else {
      app.inject('route', 'analytics', 'service:g-analytics');
      app.inject('controller', 'analytics', 'service:g-analytics');
    }
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});