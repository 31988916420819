define("itarp-business-application/components/ui/offscreens/base", ["exports", "itarp-business-application/components/ui/togglable"], function (_exports, _togglable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UiOffscreensBaseComponent extends _togglable.default {
    get position() {
      return this.args.position || 'right';
    }

    get size() {
      return this.args.size || 'medium';
    }

    get positionClass() {
      const classes = {
        left: 'offscreen--left',
        right: 'offscreen--right',
        top: 'offscreen--top',
        bottom: 'offscreen--bottom'
      };
      return classes[this.position] || '';
    }

    get sizeClass() {
      const classes = {
        small: 'offscreen--sm',
        medium: 'offscreen--md',
        large: 'offscreen--lg'
      };
      return classes[this.size] || '';
    }

    get additionalClasses() {
      return this.args.additionalClasses || '';
    }

    get stateClass() {
      return this.isOpen ? 'is-open' : 'is-closed';
    }

  }

  _exports.default = UiOffscreensBaseComponent;
});