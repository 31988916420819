define("itarp-workflow-designer/helpers/error-css-class", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function ([validationResult, attribute]) {
    if (validationResult && validationResult.errors && validationResult.errors[attribute]) {
      return 'has-error';
    }

    return '';
  });

  _exports.default = _default;
});