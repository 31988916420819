define("itarp-business-application/util/parser/page/index", ["exports", "itarp-business-application/util/parser/page/pages/form", "itarp-business-application/util/parser/page/pages/presentational"], function (_exports, _form, _presentational) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PageParser = PageParser;

  async function PageParser(data, options) {
    if (data.type === 'form') {
      return new _form.FormPage(data, options);
    } else {
      return new _presentational.PresentationalPage(data, options);
    }
  }
});