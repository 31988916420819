define("itarp-template-designer/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    this.route('templates', {
      path: ''
    }, function () {
      this.route('categories.edit', {
        path: 'categories/:id/edit'
      });
    });
    this.route('templates.new', {
      path: 'new'
    });
    this.route('templates.edit', {
      path: '/:id/edit'
    });
    this.route('categories.new', {
      path: 'categories/new'
    });
    this.route('settings');
    this.route('settings.edit', {
      path: 'settings/edit'
    });
  });

  _exports.default = _default;
});