define("itarp-components/components/filters/taxonomy-list", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='row'>
    <div class='col--12-12'>
      <div class='component-wrapper has-padding'>
        <Ui::Accordion as |accordion|>
          {{#unless @categories.length}}
            <div class='component-align--h-center'>
              <Icons::Loader @height='32' @width='32' />
            </div>
          {{/unless}}
          {{#each @categories as |category index|}}
            <accordion.item @value={{index}} as |item|>
              <item.header>
                {{category.name}}
              </item.header>
              <item.body>
                {{#unless category.loadedChildren}}
                  <div class='component-align--h-center'>
                    <Icons::Loader @height='32' @width='32' />
                  </div>
                {{/unless}}
                <Filters::TaxonomyList::Group
                  @group={{category}}
                  @type={{@type}}
                  @selected={{@selected}}
                  @onChange={{@onChange}}
                  class='pad-l--0'
                />
              </item.body>
            </accordion.item>
          {{/each}}
        </Ui::Accordion>
      </div>
    </div>
  </div>
  
  */
  {
    "id": "fa8NK6d1",
    "block": "[[[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"col--12-12\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"component-wrapper has-padding\"],[12],[1,\"\\n      \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[51,[30,2,[\"length\"]]],[[[1,\"          \"],[10,0],[14,0,\"component-align--h-center\"],[12],[1,\"\\n            \"],[8,[39,2],null,[[\"@height\",\"@width\"],[\"32\",\"32\"]],null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[42,[28,[37,4],[[28,[37,4],[[30,2]],null]],null],null,[[[1,\"          \"],[8,[30,1,[\"item\"]],null,[[\"@value\"],[[30,4]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[30,5,[\"header\"]],null,null,[[\"default\"],[[[[1,\"\\n              \"],[1,[30,3,[\"name\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n            \"],[8,[30,5,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[51,[30,3,[\"loadedChildren\"]]],[[[1,\"                \"],[10,0],[14,0,\"component-align--h-center\"],[12],[1,\"\\n                  \"],[8,[39,2],null,[[\"@height\",\"@width\"],[\"32\",\"32\"]],null],[1,\"\\n                \"],[13],[1,\"\\n\"]],[]],null],[1,\"              \"],[8,[39,5],[[24,0,\"pad-l--0\"]],[[\"@group\",\"@type\",\"@selected\",\"@onChange\"],[[30,3],[30,6],[30,7],[30,8]]],null],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"]],[5]]]]],[1,\"\\n\"]],[3,4]],null],[1,\"      \"]],[1]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"accordion\",\"@categories\",\"category\",\"index\",\"item\",\"@type\",\"@selected\",\"@onChange\"],false,[\"ui/accordion\",\"unless\",\"icons/loader\",\"each\",\"-track-array\",\"filters/taxonomy-list/group\"]]",
    "moduleName": "itarp-components/components/filters/taxonomy-list.hbs",
    "isStrictMode": false
  });

  class FiltersTaxonomyListComponent extends _component2.default {}

  _exports.default = FiltersTaxonomyListComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FiltersTaxonomyListComponent);
});