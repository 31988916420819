define("itarp-business-application/components/render-element", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#component
    this.componentName
    config=@element
    observer=@observer
    submit=@submit
    reset=@reset
    onChange=@onChange
    emit=@observer.publish
    index=@index
    as |options|
  }}
    {{#if options.observer}}
      {{#if @element.children.length}}
        {{#each @element.children as |childElement|}}
          <RenderElement
            @element={{childElement}}
            @observer={{options.observer}}
            @submit={{fn options.observer.publish "submit"}}
            @reset={{fn options.observer.publish "cancel"}}
            @onChange={{fn options.observer.publish "change"}}
            @emit={{options.observer.publish}}
            @index={{@index}}
          />
        {{/each}}
      {{/if}}
    {{else}}
      {{#if @element.children.length}}
        {{#each @element.children as |childElement|}}
          <RenderElement
            @element={{childElement}}
            @observer={{@observer}}
            @submit={{@submit}}
            @reset={{@reset}}
            @onChange={{@onChange}}
            @emit={{@emit}}
            @index={{@index}}
          />
        {{/each}}
      {{/if}}
    {{/if}}
  {{/component}}
  */
  {
    "id": "uOQSFGsr",
    "block": "[[[46,[30,0,[\"componentName\"]],null,[[\"config\",\"observer\",\"submit\",\"reset\",\"onChange\",\"emit\",\"index\"],[[30,1],[30,2],[30,3],[30,4],[30,5],[30,2,[\"publish\"]],[30,6]]],[[\"default\"],[[[[41,[30,7,[\"observer\"]],[[[41,[30,1,[\"children\",\"length\"]],[[[42,[28,[37,3],[[28,[37,3],[[30,1,[\"children\"]]],null]],null],null,[[[1,\"        \"],[8,[39,4],null,[[\"@element\",\"@observer\",\"@submit\",\"@reset\",\"@onChange\",\"@emit\",\"@index\"],[[30,8],[30,7,[\"observer\"]],[28,[37,5],[[30,7,[\"observer\",\"publish\"]],\"submit\"],null],[28,[37,5],[[30,7,[\"observer\",\"publish\"]],\"cancel\"],null],[28,[37,5],[[30,7,[\"observer\",\"publish\"]],\"change\"],null],[30,7,[\"observer\",\"publish\"]],[30,6]]],null],[1,\"\\n\"]],[8]],null]],[]],null]],[]],[[[41,[30,1,[\"children\",\"length\"]],[[[42,[28,[37,3],[[28,[37,3],[[30,1,[\"children\"]]],null]],null],null,[[[1,\"        \"],[8,[39,4],null,[[\"@element\",\"@observer\",\"@submit\",\"@reset\",\"@onChange\",\"@emit\",\"@index\"],[[30,9],[30,2],[30,3],[30,4],[30,5],[30,10],[30,6]]],null],[1,\"\\n\"]],[9]],null]],[]],null]],[]]]],[7]]]]]],[\"@element\",\"@observer\",\"@submit\",\"@reset\",\"@onChange\",\"@index\",\"options\",\"childElement\",\"childElement\",\"@emit\"],false,[\"component\",\"if\",\"each\",\"-track-array\",\"render-element\",\"fn\"]]",
    "moduleName": "itarp-business-application/components/render-element.hbs",
    "isStrictMode": false
  });

  const GENERIC = ['page', 'content', 'container', 'row'];

  class RenderElementComponent extends _component.default {
    constructor() {
      super(...arguments);
      const isGeneric = GENERIC.indexOf(this.args.element.type) !== -1;
      this.componentName = isGeneric ? 'generic-element' : this.args.element.type;
    }

  }

  _exports.default = RenderElementComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, RenderElementComponent);
});