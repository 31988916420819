define("itarp-shared-assets-addon/utils/permission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Permission {
    constructor(permission) {
      _defineProperty(this, "type", 'Yes');

      _defineProperty(this, "identifier", void 0);

      _defineProperty(this, "action", void 0);

      this.identifier = permission.permitableIdentifier;
      this.action = permission.action;
    }

  }

  _exports.default = Permission;
});