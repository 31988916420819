define("itarp-shared-assets-addon/services/permission-checker", ["exports", "@ember/service", "@ember/object", "itarp-shared-assets-addon/utils/permission-group"], function (_exports, _service, _object, _permissionGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PermissionCheckerService = (_dec = (0, _service.inject)(), _dec2 = (0, _service.inject)(), (_class = class PermissionCheckerService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "user", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _defineProperty(this, "permissionGroups", []);
    }

    performPermissionCheck(group, action) {
      return new Promise(resolve => {
        const check = () => {
          resolve(this.checkPermission(group, action));
        };

        group.queue.push(check);
      });
    }

    checkPermission(group, action) {
      const permittable = group.permissions.findBy('action', action);
      const permitted = permittable && permittable.permission;
      return permitted;
    }

    isPermitted(action, identifier) {
      if (!action || !identifier) return Promise.resolve(false);
      let group = this.permissionGroups.findBy('identifier', identifier);

      if (!group) {
        group = new _permissionGroup.default(identifier, this);
        this.permissionGroups.pushObject(group);
      }

      if (group.loaded) {
        return Promise.resolve(this.checkPermission(group, action));
      } else {
        return this.performPermissionCheck(group, action);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "user", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "performPermissionCheck", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "performPermissionCheck"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isPermitted", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "isPermitted"), _class.prototype)), _class));
  _exports.default = PermissionCheckerService;
});