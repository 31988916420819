define("itarp-components/components/ui/filters/block", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='filter-block' ...attributes>
    {{yield
      (hash
        header=(component
          'ui/filters/block/header'
          toggle=this.toggle
          isCollapsed=this.isCollapsed
        )
        body=(component 'ui/filters/block/body' isCollapsed=this.isCollapsed)
      )
    }}
  </div>
  */
  {
    "id": "R5zuviqS",
    "block": "[[[11,0],[24,0,\"filter-block\"],[17,1],[12],[1,\"\\n  \"],[18,2,[[28,[37,1],null,[[\"header\",\"body\"],[[50,\"ui/filters/block/header\",0,null,[[\"toggle\",\"isCollapsed\"],[[30,0,[\"toggle\"]],[30,0,[\"isCollapsed\"]]]]],[50,\"ui/filters/block/body\",0,null,[[\"isCollapsed\"],[[30,0,[\"isCollapsed\"]]]]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-components/components/ui/filters/block.hbs",
    "isStrictMode": false
  });

  let UiFiltersBlockComponent = (_class = class UiFiltersBlockComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "isCollapsed", _descriptor, this);

      if (this.args.initiallyCollapsed) this.isCollapsed = true;
    }

    toggle() {
      this.isCollapsed = !this.isCollapsed;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isCollapsed", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype)), _class);
  _exports.default = UiFiltersBlockComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiFiltersBlockComponent);
});