define("itarp-opportunity-ui/serializers/application", ["exports", "@ember-data/serializer/json", "@ember/string", "ember-inflector"], function (_exports, _json, _string, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ApplicationSerializer extends _json.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", '_id');
    }

    keyForAttribute(attr, method) {
      return (0, _string.underscore)(attr);
    }

    keyForRelationship(key, relationship, method) {
      return `${(0, _string.underscore)(key)}_id`;
    }

    extractId(modelClass, resourceHash) {
      const id = resourceHash[this.primaryKey];
      return typeof id === 'object' ? id['$oid'] : id;
    }

    normalizeFindAllResponse(store, modelClass, payload, ...rest) {
      const data = payload ? payload.records : [];
      return super.normalizeFindAllResponse(store, modelClass, data, ...rest);
    }

    normalizeQueryResponse(store, modelClass, payload, ...rest) {
      const data = payload ? payload.records : [];
      return super.normalizeFindAllResponse(store, modelClass, data, ...rest);
    }

    normalize(modelClass, data) {
      if (data) {
        data.type = modelClass.modelName;
        const result = super.normalize(modelClass, data);
        return result;
      }

      return super.normalize(...arguments);
    }

    serialize(snapshot) {
      const json = super.serialize(...arguments);
      const collectionName = (0, _emberInflector.pluralize)(snapshot.modelName);
      return {
        [collectionName]: json
      };
    }

  }

  _exports.default = ApplicationSerializer;
});