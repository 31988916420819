define("ember-modal-dialog/templates/components/tether-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D+BDHWYG",
    "block": "[[[41,[33,1],[[[6,[39,2],null,[[\"to\"],[[33,3]]],[[\"default\"],[[[[1,\"    \"],[10,0],[15,0,[36,4]],[15,\"onclick\",[28,[37,5],[[30,0],[33,6]],null]],[14,\"tabindex\",\"-1\"],[14,\"data-emd-overlay\",\"\"],[12],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]]],[]],null],[6,[39,7],null,[[\"class\",\"target\",\"attachment\",\"targetAttachment\",\"targetModifier\",\"classPrefix\",\"offset\",\"targetOffset\",\"constraints\"],[[33,8],[33,9],[33,10],[33,11],[33,12],[33,13],[33,14],[33,15],[33,16]]],[[\"default\"],[[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]]]]]],[\"&default\"],false,[\"if\",\"hasOverlay\",\"ember-wormhole\",\"destinationElementId\",\"overlayClassNamesString\",\"action\",\"onClickOverlay\",\"ember-tether\",\"containerClassNamesString\",\"tetherTarget\",\"attachment\",\"targetAttachment\",\"targetModifier\",\"tetherClassPrefix\",\"offset\",\"targetOffset\",\"constraints\",\"yield\"]]",
    "moduleName": "ember-modal-dialog/templates/components/tether-dialog.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});