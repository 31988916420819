define("shared/serializers/page", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PageSerializer extends _jsonApi.default {
    normalize(modelClass, resourceHash) {
      const result = {
        data: {
          id: super.extractId(modelClass, resourceHash),
          type: modelClass.modelName,
          attributes: { ...resourceHash.attributes,
            children: resourceHash.children
          }
        }
      };
      return result;
    }

    normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      payload.data = payload.data[0];
      payload.data.id = id;
      return super.normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType);
    }

  }

  _exports.default = PageSerializer;
});