define("itarp-components/utils/taxonomy/taxonomy", ["exports", "itarp-components/utils/taxonomy/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Taxonomy {
    constructor(taxonomy, store, data) {
      _defineProperty(this, "id", void 0);

      _defineProperty(this, "name", void 0);

      _defineProperty(this, "type", void 0);

      _defineProperty(this, "left", void 0);

      _defineProperty(this, "right", void 0);

      _defineProperty(this, "children", []);

      _defineProperty(this, "loadedChildren", true);

      this.id = taxonomy.id;
      this.name = taxonomy.name;
      this.left = taxonomy.left;
      this.right = taxonomy.right;
      this.store = store;
      this.type = taxonomy.type;
      this.children = (0, _helpers.findChildren)(data, taxonomy).map(c => new Taxonomy(c, store, data));
    }

    get skills() {
      return _helpers.getChildTaxonomy.apply(this, ['skill']);
    }

    get tools() {
      return _helpers.getChildTaxonomy.apply(this, ['tool']);
    }

    get isCategory() {
      return this.children.length > 0;
    }

  }

  _exports.default = Taxonomy;
});