define("ember-basic-dropdown/components/basic-dropdown-optional-tag", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BasicDropdownOptionalTag extends _component.default {}

  _exports.default = BasicDropdownOptionalTag;
});