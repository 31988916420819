define("itarp-talentcloud-ui/serializers/reason", ["exports", "@ember-data/serializer/json", "@ember/object/computed"], function (_exports, _json, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ReasonSerializer extends _json.default {
    normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      let data = payload ? payload.reasons : [];
      data = data.map(d => {
        return {
          id: d.id,
          title: d.attributes ? d.attributes.title : '',
          active: d.attributes ? d.attributes.active : false
        };
      });
      data = data.uniqBy('title');
      return super.normalizeFindAllResponse(store, primaryModelClass, data, id, requestType);
    }

    normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      const reasons = payload.reasons;
      delete payload.reasons;
      let data = reasons === null || reasons === void 0 ? void 0 : reasons.map(d => {
        return {
          id: d.id,
          title: d.attributes ? d.attributes.title : '',
          active: d.attributes ? d.attributes.active : false
        };
      });
      return super.normalizeQueryResponse(store, primaryModelClass, data, id, requestType);
    }

  }

  _exports.default = ReasonSerializer;
});