define("itarp-business-application/templates/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xJVm5BY3",
    "block": "[[[10,0],[14,0,\"content content--bg\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"container container--1264\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col--12-12\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"component-wrapper\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"panel mar-t--3\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"panel__header panel__header--secondary\"],[12],[1,\"\\n              \"],[10,1],[14,0,\"panel__header__note\"],[12],[1,\"\\n                Welcome to\\n              \"],[13],[1,\"\\n              \"],[10,\"h3\"],[14,0,\"panel__header__title\"],[12],[1,\"\\n                Experfy TalentClouds\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,0],[14,0,\"panel__body with-sidebar\"],[12],[1,\"\\n              \"],[8,[39,0],null,[[\"@user\"],[[30,0,[\"model\"]]]],null],[1,\"\\n              \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"panel-sidebar\",\"component\",\"-outlet\"]]",
    "moduleName": "itarp-business-application/templates/profile.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});