define("itarp-components/components/ui/accordion/header", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='filter-group__header' {{on 'click' @toggle}} ...attributes>
    <h5 class='filter-group__title'>
      {{yield}}
    </h5>
  </div>
  
  */
  {
    "id": "UdCF+q2S",
    "block": "[[[11,0],[24,0,\"filter-group__header\"],[17,1],[4,[38,0],[\"click\",[30,2]],null],[12],[1,\"\\n  \"],[10,\"h5\"],[14,0,\"filter-group__title\"],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@toggle\",\"&default\"],false,[\"on\",\"yield\"]]",
    "moduleName": "itarp-components/components/ui/accordion/header.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});