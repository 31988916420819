define("itarp-components/components/ui/input/select-group", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class='select-group {{if @required 'is-required'}} {{if @error 'has-error'}}'
    ...attributes
  >
    {{#let (unique-id) as |uid|}}
      {{yield
        (hash
          label=(component
            'ui/input/label'
            class='select-group__label'
            for=uid
            required=@required
          )
          select=(component 'power-select' id=uid)
          multiSelect=(component 'power-select-multiple' id=uid)
        )
      }}
    {{/let}}
    {{#if (and @error @errorNote)}}
      <span class='fieldset-note fieldset-note--error'>{{@errorNote}}</span>
    {{/if}}
  </div>
  */
  {
    "id": "vP/4N3ZT",
    "block": "[[[11,0],[16,0,[29,[\"select-group \",[52,[30,1],\"is-required\"],\" \",[52,[30,2],\"has-error\"]]]],[17,3],[12],[1,\"\\n\"],[44,[[28,[37,2],null,null]],[[[1,\"    \"],[18,6,[[28,[37,4],null,[[\"label\",\"select\",\"multiSelect\"],[[50,\"ui/input/label\",0,null,[[\"class\",\"for\",\"required\"],[\"select-group__label\",[30,4],[30,1]]]],[50,\"power-select\",0,null,[[\"id\"],[[30,4]]]],[50,\"power-select-multiple\",0,null,[[\"id\"],[[30,4]]]]]]]]],[1,\"\\n\"]],[4]]],[41,[28,[37,6],[[30,2],[30,5]],null],[[[1,\"    \"],[10,1],[14,0,\"fieldset-note fieldset-note--error\"],[12],[1,[30,5]],[13],[1,\"\\n\"]],[]],null],[13]],[\"@required\",\"@error\",\"&attrs\",\"uid\",\"@errorNote\",\"&default\"],false,[\"if\",\"let\",\"unique-id\",\"yield\",\"hash\",\"component\",\"and\"]]",
    "moduleName": "itarp-components/components/ui/input/select-group.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});