define("ember-file-upload/components/base-component", ["exports", "@ember/component", "@ember/service", "@ember/object"], function (_exports, _component, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    fileQueue: (0, _service.inject)(),

    didReceiveAttrs() {
      this._super(...arguments);

      if (this.queue) {
        let {
          accept,
          multiple,
          disabled,
          onFileAdd
        } = this;
        (0, _object.setProperties)(this.queue, {
          accept,
          multiple,
          disabled,
          onFileAdd
        });
      }
    },

    queue: (0, _object.computed)('name', {
      get() {
        let queueName = this.name;

        if (queueName != null) {
          let queues = this.fileQueue;
          return queues.find(queueName) || queues.create(queueName);
        } else {
          return undefined;
        }
      }

    })
  });

  _exports.default = _default;
});