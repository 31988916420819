define("itarp-opportunity-designer/serializers/taxonomies-category", ["exports", "itarp-shared-assets-addon/serializers/data"], function (_exports, _data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TaxonomiesCategorySerializer extends _data.default {
    normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      const result = {
        data: [],
        meta: payload.meta
      };
      let data = payload.records.map(this.normalizedTaxonomy(payload.records));
      result.data = data;
      return result;
    }

    normalizedTaxonomy(data) {
      const type = 'taxonomies-category';

      const relationship = taxonomy => ({
        type,
        id: taxonomy.id
      });

      return function (taxonomy) {
        const isMain = !!taxonomy.parent_id === false;
        const children = data.filterBy('parent_id', taxonomy['_id']).map(relationship);
        const isCategory = taxonomy.label || children.length > 0;
        const result = {
          type,
          id: taxonomy['_id'],
          attributes: {
            name: taxonomy['name'],
            isMainCategory: isMain,
            isCategory: isCategory,
            type: taxonomy.type,
            left: taxonomy.left,
            right: taxonomy.right
          },
          relationships: {
            [type]: {
              data: children
            }
          }
        };

        if (!isMain) {
          result.relationships.parent = {
            data: {
              type,
              id: taxonomy.parent_id
            }
          };
        }

        return result;
      };
    }

  }

  _exports.default = TaxonomiesCategorySerializer;
});