define("itarp-workflow-designer/components/forms/stage", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/string"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield
    (hash
      inputGroup=(component
        'forms/input-group'
        value=this.name
        label=@label
        placeholder=@placeholder
        didInsert=this.registerInput
        willRemove=this.unregisterInput
        message=this.validationMessage
        stageNameError=this.nameValidation
        required=@required
      )
      selectGroup=(component
        'forms/select-group'
        options=@stageGroupList
        selected=this.group
        label=@label
        placeholder=@placeholder
        errorMessage=this.validationSelect
      )
      save=(component
        'button'
        class='app-btn--primary app-btn--xs'
        onClick=(if
          (and (gte this.name.length 3) (eq this.nameValidation false))
          this.save
          this.noop
        )
      )
      cancel=(component 'button' class='app-btn--xs' onClick=(fn this.cancel))
    )
  }}
  
  */
  {
    "id": "h2nx41yO",
    "block": "[[[18,5,[[28,[37,1],null,[[\"inputGroup\",\"selectGroup\",\"save\",\"cancel\"],[[50,\"forms/input-group\",0,null,[[\"value\",\"label\",\"placeholder\",\"didInsert\",\"willRemove\",\"message\",\"stageNameError\",\"required\"],[[30,0,[\"name\"]],[30,1],[30,2],[30,0,[\"registerInput\"]],[30,0,[\"unregisterInput\"]],[30,0,[\"validationMessage\"]],[30,0,[\"nameValidation\"]],[30,3]]]],[50,\"forms/select-group\",0,null,[[\"options\",\"selected\",\"label\",\"placeholder\",\"errorMessage\"],[[30,4],[30,0,[\"group\"]],[30,1],[30,2],[30,0,[\"validationSelect\"]]]]],[50,\"button\",0,null,[[\"class\",\"onClick\"],[\"app-btn--primary app-btn--xs\",[52,[28,[37,4],[[28,[37,5],[[30,0,[\"name\",\"length\"]],3],null],[28,[37,6],[[30,0,[\"nameValidation\"]],false],null]],null],[30,0,[\"save\"]],[30,0,[\"noop\"]]]]]],[50,\"button\",0,null,[[\"class\",\"onClick\"],[\"app-btn--xs\",[28,[37,7],[[30,0,[\"cancel\"]]],null]]]]]]]]],[1,\"\\n\"]],[\"@label\",\"@placeholder\",\"@required\",\"@stageGroupList\",\"&default\"],false,[\"yield\",\"hash\",\"component\",\"if\",\"and\",\"gte\",\"eq\",\"fn\"]]",
    "moduleName": "itarp-workflow-designer/components/forms/stage.hbs",
    "isStrictMode": false
  });

  let FormsStageComponent = (_class = class FormsStageComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "group", _descriptor2, this);

      _initializerDefineProperty(this, "groupField", _descriptor3, this);

      _initializerDefineProperty(this, "nameFocused", _descriptor4, this);

      _initializerDefineProperty(this, "selectFocused", _descriptor5, this);

      _initializerDefineProperty(this, "validationMessage", _descriptor6, this);

      _initializerDefineProperty(this, "validationSelect", _descriptor7, this);

      _initializerDefineProperty(this, "nameValidation", _descriptor8, this);

      this.groupField = this.args.groupField;
      const name = this.args.stage.name;
      if (name) this.name = name;
      const group = this.args.stage.get(this.groupField);
      if (group) this.group = (0, _string.capitalize)(group).replace('_', ' ');
    }

    save() {
      if (this.group) {
        this.args.stage.setProperties({
          name: this.name,
          [this.groupField]: this.group.toLowerCase().replace(' ', '_')
        });
        if (this.args.save) this.args.save();
      }
    }

    cancel() {
      if (this.args.cancel) this.args.cancel();
    }

    noop() {
      this.validationMessage = true;
    }

    registerInput(input) {
      input.addEventListener('focusin', () => this.nameFocused = true);
      input.addEventListener('focusout', this.validateName);
      input.addEventListener('focus', () => this.selectFocused = true);
      input.addEventListener('focusout', this.validateSelect);
      input.focus();
    }

    validateName() {
      var _this$args$workflow;

      const stages = (_this$args$workflow = this.args.workflow) === null || _this$args$workflow === void 0 ? void 0 : _this$args$workflow.get('stageGroups').map(stageGroup => stageGroup.stages.map(stage => stage.name.toLowerCase()));

      if (stages !== null && stages !== void 0 && stages.flat().includes(this.name.toLowerCase())) {
        this.nameValidation = true;
      } else {
        this.nameValidation = false;
      }

      this.validationMessage = !this.name || this.name.length <= 3 || this.nameValidation;
    }

    unregisterInput(input) {
      input.removeEventListener('focusin', () => this.nameFocused = true);
      input.removeEventListener('focusout', this.validateName);
      input.removeEventListener('focus', () => this.selectFocused = true);
      input.removeEventListener('focusout', this.validateSelect);
    }

    validateSelect() {
      this.validationSelect = !this.group;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "group", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "groupField", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "nameFocused", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectFocused", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "validationMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "validationSelect", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "nameValidation", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "noop", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "noop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateName", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validateName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unregisterInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "unregisterInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateSelect", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validateSelect"), _class.prototype)), _class);
  _exports.default = FormsStageComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormsStageComponent);
});