define("itarp-template-designer/mirage/endpoints/categories", ["exports", "itarp-template-designer/mirage/helpers/paginate"], function (_exports, _paginate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(context) {
    context.get('categories', function (schema, request) {
      const tenantId = request.requestHeaders.TenantID;
      const search = request.queryParams.search_term;
      let resources = schema.categories.where({
        tenantId
      });
      resources = search ? filterCategories(search, resources) : resources;
      return (0, _paginate.default)(request, this, resources);
    });

    function filterCategories(search, categories) {
      const downcase = value => value.toLowerCase();

      return categories.filter(category => {
        const result = category.templates.filter(template => downcase(template.name).includes(downcase(search)));
        return result.length > 0;
      });
    }

    context.get('categories/:id');
    context.post('categories', function (schema, request) {
      const tenantId = request.requestHeaders.TenantID;
      const attrs = JSON.parse(request.requestBody);
      return schema.categories.create({ ...attrs,
        tenantId
      });
    });
    context.patch('categories/:id');
    context.del('categories/:id');
  }
});