define("itarp-business-application/util/schema-manager/schemas/relation-types/collection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Collection = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Collection {
    constructor(data, storeManager) {
      _defineProperty(this, "type", 'collection');

      _defineProperty(this, "value", []);

      this.loaded = false;
      this.storeManager = storeManager;
      this.name = data.name;
      this.embedded = true;
      this.isArray = true;
    }

    async instantiate(data) {
      if (this.embedded) {
        const schema = await this.schema();

        for (const item of data) {
          const record = schema.init(item);
          this.value.push(record);
        }

        this.loaded = true;
        return this.value;
      }

      return this.value;
    }

  }

  _exports.Collection = Collection;
});