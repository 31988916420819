define("itarp-talentcloud-ui/routes/application", ["exports", "itarp-auth-addon/routes/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApplicationRoute extends _application.default {}

  _exports.default = ApplicationRoute;
});