define("itarp-opportunity-ui/models/offer", ["exports", "@ember-data/model", "@ember/template", "@ember/array"], function (_exports, _model, _template, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OfferModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('date'), _dec8 = (0, _model.attr)('date'), _dec9 = (0, _model.attr)('date'), _dec10 = (0, _model.attr)('boolean'), _dec11 = (0, _model.attr)('boolean'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('date'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.attr)('string'), _dec18 = (0, _model.attr)({
    defaultValue: () => (0, _array.A)([])
  }), _dec19 = (0, _model.attr)('string'), (_class = class OfferModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "applicationId", _descriptor, this);

      _initializerDefineProperty(this, "offerName", _descriptor2, this);

      _initializerDefineProperty(this, "offerDescription", _descriptor3, this);

      _initializerDefineProperty(this, "renumeration", _descriptor4, this);

      _initializerDefineProperty(this, "rate", _descriptor5, this);

      _initializerDefineProperty(this, "weeklyLimit", _descriptor6, this);

      _initializerDefineProperty(this, "startDate", _descriptor7, this);

      _initializerDefineProperty(this, "tosClientAcceptedAt", _descriptor8, this);

      _initializerDefineProperty(this, "tosApplicantAcceptedAt", _descriptor9, this);

      _initializerDefineProperty(this, "tosClientAccepted", _descriptor10, this);

      _initializerDefineProperty(this, "tosApplicantAccepted", _descriptor11, this);

      _initializerDefineProperty(this, "offerAttachment", _descriptor12, this);

      _initializerDefineProperty(this, "offerStatus", _descriptor13, this);

      _initializerDefineProperty(this, "userId", _descriptor14, this);

      _initializerDefineProperty(this, "createdAt", _descriptor15, this);

      _initializerDefineProperty(this, "createdBy", _descriptor16, this);

      _initializerDefineProperty(this, "declineMessage", _descriptor17, this);

      _initializerDefineProperty(this, "declineReasons", _descriptor18, this);

      _initializerDefineProperty(this, "expertRate", _descriptor19, this);
    }

    get shortDescription() {
      if (this.offerDescription) return (0, _template.htmlSafe)(this.offerDescription.slice(0, 100) + '...');
    }

    get safeDescription() {
      if (this.offerDescription) {
        return (0, _template.htmlSafe)(this.offerDescription);
      }
    }

    get statusName() {
      const {
        offerStatus
      } = this;

      if (offerStatus) {
        if (offerStatus === 'pending' || offerStatus === 'approved') {
          return 'Pending';
        } else {
          return offerStatus.charAt(0).toUpperCase() + offerStatus.slice(1);
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "applicationId", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "offerName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "offerDescription", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "renumeration", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "rate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "weeklyLimit", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "startDate", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "tosClientAcceptedAt", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "tosApplicantAcceptedAt", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "tosClientAccepted", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "tosApplicantAccepted", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "offerAttachment", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "offerStatus", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "userId", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "createdBy", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "declineMessage", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "declineReasons", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "expertRate", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OfferModel;
});