define("itarp-opportunity-designer/adapters/invitation", ["exports", "itarp-auth-addon/adapters/ad-token-adapter"], function (_exports, _adTokenAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class InvitationAdapter extends _adTokenAdapter.default {
    get headers() {
      const result = super.headers;
      return result;
    }

    urlForQuery(query) {
      if (query.adapterOptions.expertInvites) {
        delete query.adapterOptions.expertInvites;
        return '/api/expert/api/opportunity/data-service/expert/invited_opportunities';
      }

      return super.urlForFindAll(...arguments);
    }

  }

  _exports.default = InvitationAdapter;
});