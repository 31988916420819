define("itarp-business-application/util/parser/page/helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.template = template;
  _exports.title = title;

  function template(data, defaultTemplate) {
    var _data$attributes;

    return ((_data$attributes = data.attributes) === null || _data$attributes === void 0 ? void 0 : _data$attributes.layout) || defaultTemplate;
  }

  function title(data) {
    var _data$attributes2;

    return ((_data$attributes2 = data.attributes) === null || _data$attributes2 === void 0 ? void 0 : _data$attributes2.name) || (data === null || data === void 0 ? void 0 : data.name) || '';
  }
});