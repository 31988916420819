define("itarp-shared-assets-addon/utils/permission-query-list", ["exports", "itarp-shared-assets-addon/utils/permission"], function (_exports, _permission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const ENDPOINT = '/rbac/user_permissions/validate';

  class PermissionQueryList {
    constructor({
      user_id,
      tenant_id
    } = {}, permissions) {
      _defineProperty(this, "user_id", void 0);

      _defineProperty(this, "tenant_id", void 0);

      _defineProperty(this, "permissions", []);

      this.user_id = user_id;
      this.tenant_id = tenant_id;
      this.permissions = permissions.map(p => new _permission.default(p));
    }

    async result() {
      const method = 'POST';
      const body = JSON.stringify(this);
      const headers = {
        'Content-Type': 'application/json'
      };
      const response = await fetch(ENDPOINT, {
        method,
        headers,
        body
      });
      return await response.json();
    }

  }

  _exports.default = PermissionQueryList;
});