define("itarp-opportunity-designer/utils/permissions/expert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PERMISSIONS = _exports.IDENTIFIER = void 0;
  const IDENTIFIER = 'expert_info';
  _exports.IDENTIFIER = IDENTIFIER;
  const PERMISSIONS = {
    view: {
      contact: 'view_contact_info',
      workArrangement: 'view_work_arrangement',
      hourlyRate: 'view_hourly_rate',
      linkedInProfile: 'view_linkedIn_profile',
      resume: 'view_resume',
      talentClouds: 'view_talentclouds',
      genderInclusion: 'view_gender_equity_inclusion'
    },
    action: {
      favorite: 'can_favorite',
      saveToPdf: 'can_save_to_pdf',
      checkAvailability: 'can_check_availability',
      share: 'can_share'
    }
  };
  _exports.PERMISSIONS = PERMISSIONS;
  var _default = {
    PERMISSIONS,
    IDENTIFIER
  };
  _exports.default = _default;
});