define("ember-bootstrap/components/bs-navbar/link-to", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <BsLinkTo
    @route={{@route}}
    @model={{@model}}
    @models={{@models}}
    @query={{@query}}
    @disabled={{@disabled}}
    @class={{@class}}
    {{on 'click' this.onClick}}
    ...attributes
  >
    {{yield}}
  </BsLinkTo>
  */
  {
    "id": "d1oDXFNu",
    "block": "[[[8,[39,0],[[17,1],[4,[38,1],[\"click\",[30,0,[\"onClick\"]]],null]],[[\"@route\",\"@model\",\"@models\",\"@query\",\"@disabled\",\"@class\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7]]],[[\"default\"],[[[[1,\"\\n  \"],[18,8,null],[1,\"\\n\"]],[]]]]]],[\"&attrs\",\"@route\",\"@model\",\"@models\",\"@query\",\"@disabled\",\"@class\",\"&default\"],false,[\"bs-link-to\",\"on\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-navbar/link-to.hbs",
    "isStrictMode": false
  });

  /**
   * Extended `{{link-to}}` component for use within Navbars.
   *
   * @class NavbarLinkTo
   * @namespace Components
   * @extends Components.NavLinkTo
   * @public
   */
  let NavbarLinkTo = (_class = class NavbarLinkTo extends _component2.default {
    /**
     * @property collapseNavbar
     * @type {Boolean}
     * @default true
     * @public
     */
    onClick() {
      if (this.args.collapseNavbar ?? true) {
        this.args.onCollapse();
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class);
  _exports.default = NavbarLinkTo;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, NavbarLinkTo);
});