define("itarp-opportunity-ui/components/ui/modal", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/object/observers"], function (_exports, _component, _tracking, _object, _observers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.open}}
    <ModalDialog
      @onClose={{@close}}
      @targetAttachment="center"
      @translucentOverlay={{true}}
      @overlayClass="app-modal-overlay"
      @containerClass="app-modal-wrapper"
    >
      <div class="app-modal app-modal--{{this.size}}">
        <div class="app-modal-content">
          {{yield
            (hash
              header=(component "ui/modal/header" close=@close)
              body=(component "ui/modal/body")
            )
          }}
        </div>
      </div>
    </ModalDialog>
  {{/if}}
  */
  {
    "id": "3Ej2MiuB",
    "block": "[[[41,[30,0,[\"open\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@onClose\",\"@targetAttachment\",\"@translucentOverlay\",\"@overlayClass\",\"@containerClass\"],[[30,1],\"center\",true,\"app-modal-overlay\",\"app-modal-wrapper\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[15,0,[29,[\"app-modal app-modal--\",[30,0,[\"size\"]]]]],[12],[1,\"\\n      \"],[10,0],[14,0,\"app-modal-content\"],[12],[1,\"\\n        \"],[18,2,[[28,[37,3],null,[[\"header\",\"body\"],[[50,\"ui/modal/header\",0,null,[[\"close\"],[[30,1]]]],[50,\"ui/modal/body\",0,null,null]]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@close\",\"&default\"],false,[\"if\",\"modal-dialog\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-opportunity-ui/components/ui/modal.hbs",
    "isStrictMode": false
  });

  let UiModalComponent = (_class = class UiModalComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "open", _descriptor, this);

      if (this.args.isOpen) this.handleState(true);
      (0, _observers.addObserver)(this, 'args.isOpen', this, 'observeOpenState');
    }

    observeOpenState() {
      const open = this.args.isOpen;
      const hasChanged = open !== this.open;
      if (hasChanged) this.handleState(open);
    }

    handleState(open) {
      this.open = open;
    }

    get size() {
      const sizes = ['md', 'lg'];
      return sizes[this.args.size] || sizes[0];
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "open", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "observeOpenState", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "observeOpenState"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleState", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleState"), _class.prototype)), _class);
  _exports.default = UiModalComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UiModalComponent);
});